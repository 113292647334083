/* eslint-disable no-console */
import { Button, Card, CardContent, CardHeader, IconButton, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import sprites from '../../../../icons/fig-icons.svg';
import config from '../../config/constants';
import { Comment } from '../../model/Video';
import { formatFigaroDate, seconds2mssmm } from '../../utils/utils';
import { primaryColor } from '../echarts/macarons';
import { IVideoPlayer } from '../video/VideoRenderer';

import EditComment, { IEditCommentState } from './EditComment';

interface IProps {
  theVideoPlayer: IVideoPlayer;
  handleDelete: (commentID: string) => void;
  handleSetPrivacy: (commentId: string, privacy: string) => void;
  handleAnnotate: (comment: Comment) => void;
  handleEdit: (commentId: string, values: IEditCommentState) => void;
  comment: Comment;
  isAnnotating: boolean;
  isPlaying: boolean;
  videoDuration: number;
}
interface IState {
  editCommentDialogOpened: boolean;
}

class CommentRenderer extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      editCommentDialogOpened: false,
    };
  }

  openEditCommentDialog = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ editCommentDialogOpened: true });
  };
  onCloseEditCommentDialog = () => {
    this.setState({ editCommentDialogOpened: false });
  };
  onSaveEdit = (highlightId: string, values: IEditCommentState) => {
    this.props.handleEdit(highlightId, values);
    this.onCloseEditCommentDialog();
  };

  renderEditCommentDialog = () => {
    return this.state.editCommentDialogOpened ? (
      <EditComment
        comment={this.props.comment}
        handleEdit={this.onSaveEdit}
        handleClose={this.onCloseEditCommentDialog}
        handleFormSubmit={null}
        videoDuration={this.props.videoDuration}
      ></EditComment>
    ) : null;
  };

  handlePrivacyChange = (privacy) => {
    this.props.handleSetPrivacy(this.props.comment.id, privacy);
  };
  onDelete = (event) => {
    this.props.handleDelete(this.props.comment.id);
  };
  onGoTo = (event) => {
    this.props.theVideoPlayer.seek(this.props.comment.time);
  };
  onAnnotate = (event) => {
    this.props.handleAnnotate(this.props.comment);
  };

  render() {
    const textStyle: any = { paddingRight: '10%' };
    if (this.props.isPlaying) textStyle.color = primaryColor;
    return (
      <div>
        {this.props.comment ? (
          <>
            <Card className="fig-main-comment-container">
              <CardHeader
                className="fig-space-between"
                action={
                  <div className="icons-container">
                    <div>
                      {/* {
                      <Button color="primary" variant="text" onClick={this.onGoTo} className="text-lowercase text-capitalize">
                        @ {seconds2mssmm(this.props.comment.time)}
                      </Button>
                    } */}
                      {/* <Button
                      size="large"
                      variant="text"
                      onClick={this.onAnnotate}
                      style={
                        this.props.isAnnotating
                          ? {
                              backgroundColor: config.video.annotationColorDraft,
                              paddingRight: '10px',
                              paddingLeft: '10px',
                            }
                          : {
                              paddingRight: '10px',
                              paddingLeft: '10px',
                            }
                      }
                      color={'primary'}
                      className="text-lowercase text-capitalize"
                    >
                      Annotate
                    </Button> */}
                    </div>
                    {/* <PrivacyButtons
                    privacy={this.props.comment.privacy}
                    handlePrivacyChange={this.handlePrivacyChange}
                  /> */}
                    <Tooltip title="Annotate">
                      <IconButton
                        onClick={this.onAnnotate}
                        style={
                          this.props.isAnnotating
                            ? {
                                backgroundColor: config.video.annotationColorDraft,
                              }
                            : {}
                        }
                      >
                        <svg className="fig-anotate-icon">
                          <use href={sprites + '#anotate-icon'} />
                        </svg>
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Edit">
                      <IconButton onClick={this.openEditCommentDialog}>
                        <svg className="fig-edit-icon">
                          <use href={sprites + '#edit-icon'} />
                        </svg>
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Delete">
                      <IconButton onClick={this.onDelete}>
                        <svg className="fig-delete-icon">
                          <use href={sprites + '#delete-icon'} />
                        </svg>
                      </IconButton>
                    </Tooltip>
                  </div>
                }
                title={
                  <div>
                    <span>{this.props.comment.userID}</span>
                    <Tooltip title={`Go To ${seconds2mssmm(this.props.comment.time)}`}>
                      <Button
                        variant="text"
                        onClick={this.onGoTo}
                        className="text-lowercase text-capitalize"
                        // style={{
                        //   fontFamily: 'Inter, sans-serif',
                        //   fontSize: '10px',
                        //   fontWeight: 600,
                        //   paddingLeft: 0,
                        // }}
                      >
                        @ {seconds2mssmm(this.props.comment.time)}
                      </Button>
                    </Tooltip>
                    {
                      <span style={{ marginLeft: 20 }}>
                        {formatFigaroDate(this.props.comment.updated_at)}
                      </span>
                    }
                  </div>
                }
              />
              <CardContent>
                <Typography
                  variant="body1"
                  component="span"
                  {...(this.props.isPlaying && {
                    color: 'primary',
                  })}
                  style={textStyle}
                >
                  {this.props.comment.text}
                </Typography>
                <Typography variant="body1" component="button" disabled style={{ border: 'none', color: 'lightgray' }}>
                  {this.props.comment.annotation && config.dev.annotations
                    ? `annotation: (${Math.round(this.props.comment.annotation.x)}, ${Math.round(this.props.comment.annotation.y)})`
                    : ''}
                </Typography>
              </CardContent>
              <div className="fig-comment-left-shape"></div>
            </Card>
            {this.renderEditCommentDialog()}
          </>
        ) : null}
      </div>
    );
  }
}
export default CommentRenderer;
