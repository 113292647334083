import { Typography } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import React from 'react';
import { ISelectableItem } from '../shared/BorderedSelector';

interface IProps {
  categoryName: string;
  items: ISelectableItem[];
  toggleItem: (key: string) => void;
}

export default class SingleOption extends React.PureComponent<IProps, any> {
  render() {
    console.log('SingleOption render  props: ', this.props);
    return (
      <div className="fig-options-pannel-category">
        <div className="fig-options-pannel-category__row">
          <div className="category-title" style={{ fontWeight: 600 }}>
            {this.props.categoryName}
          </div>
        </div>
        {this.props.items.map((item, indx) => this.makeItemLine(item))}
      </div>
    );
  }

  makeItemLine = (item: ISelectableItem) => (
    <div className="fig-options-pannel-category__row" key={item.key}>
      <div className="subcategory-title">
        <Typography color="textPrimary" variant="body1">
          {item.displayedValue}
        </Typography>
      </div>
      <div>
        <Typography color="textPrimary" variant="body1">
          <Switch
            size="small"
            checked={item.selected}
            onChange={(event, changed) => {
              this.props.toggleItem(item.key);
            }}
            name={item.key}
          />
        </Typography>
      </div>
    </div>
  );
}
