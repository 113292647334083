import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import SelectedVideoPanel from '../../../demo/components/submission/SelectedVideoPanel';
import { IRootState } from '../../../shared/reducers';
import Header from '../header/Header';

type IProps = PropsFromRedux & { history: any; match: any }; // TODO FFS RouteComponentProps<P>

class OneVideo extends React.Component<IProps, any> {
  componentDidMount = () => {
    console.log('OneVideo did mount,  props ', this.props);
  };
  componentWillUnmount = () => {
    console.log('OneVideo will unmount ', this.props);
  };

  render() {
    console.log('OneVideo render ', this.props);
    let className = 'figaro-content';
    return (
      <div className="figaro-container">
        <Header selectedMenu="videos" history={this.props.history} />
        {/* <div className={className}>{this.props.selectedVideoId ? <SelectedVideoPanel match={this.props.match} /> : null}</div> */}
        <div className={className}>
          {this.props.match && this.props.match.params && this.props.match.params.id ? (
            <SelectedVideoPanel match={this.props.match} history={this.props.history} />
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => {
  console.log('OneVideo mapState2Props ', state);
  return {};
};

const mapDispatchToProps = {};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(OneVideo);
