export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const CHANGE_SETTINGS = 'CHANGE_SETTINGS';

export const UPDATE_PLAYER_HEIGHT = 'UPDATE_PLAYER_HEIGHT';
export const UPDATE_WINDOW_HEIGHT = 'UPDATE_WINDOW_HEIGHT';
export const UPDATE_LEGEND_HEIGHT = 'UPDATE_LEGEND_HEIGHT';
export const UPDATE_GRAPHICS_SIDE_WIDTH = 'UPDATE_GRAPHICS_SIDE_WIDTH';

export const SET_ALERT = 'SET_ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';

export const CONFIG_UPDATED_DURATION_THRESHOLD = 'CONFIG_UPDATED_DURATION_THRESHOLD';
