import {
  ALLFOLDERS_SUBMISSIONS,
  AuthClearAction,
  AUTH_CLEAR,
  FoldersActionTypes,
  FolderSubmissionActionTypes,
  FOLDER_ADDED,
  FOLDER_DELETED,
  FOLDER_SUBMISSION_ADDED,
  FOLDER_SUBMISSION_DELETED,
  FOLDER_UPDATED,
  OWN_FOLDERS,
} from '../actions/figaroApiActions/types';
import { Folder, FolderSubmission } from '../model/ApiTypes';

const initialFoldersMap: FoldersMap = {};
const initialFolder2SubmissionsMap: Folder2SubmissionsMap = {};
const initialSubmission2FoldersMap: Submission2FoldersMap = {};

const initialState = {
  foldersMap: initialFoldersMap,
  folder2SubmissionsMap: initialFolder2SubmissionsMap,
  submission2FoldersMap: initialSubmission2FoldersMap,
};

export type FoldersState = Readonly<typeof initialState>;
export type FoldersMap = { [id: string]: Folder };
export type Folder2SubmissionsMap = { [folderName: string]: FolderSubmission[] };
export type Submission2FoldersMap = { [submissionId: number]: FolderSubmission[] };

// Reducers
// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state: FoldersState = initialState,
  action: FoldersActionTypes | AuthClearAction | FolderSubmissionActionTypes
): FoldersState => {
  switch (action.type) {
    case AUTH_CLEAR: {
      return { ...initialState };
    }
    case OWN_FOLDERS: {
      const map = {};
      action.payload.folders.forEach((folder) => {
        map[folder.id] = folder;
      });
      return {
        ...state,
        foldersMap: map,
      };
    }
    case FOLDER_DELETED: {
      let newState = { ...state.foldersMap };
      delete newState[action.payload.folderId];
      let folder2SubmissionsMap = { ...state.folder2SubmissionsMap };
      let submission2FoldersMap = { ...state.submission2FoldersMap };
      if (folder2SubmissionsMap[action.payload.folder.name]) {
        folder2SubmissionsMap[action.payload.folder.name].forEach((folderSubmission) => {
          submission2FoldersMap[folderSubmission.submission.id] = submission2FoldersMap[folderSubmission.submission.id].filter(
            (crtt) => crtt.name !== action.payload.folder.name
          );
          if (submission2FoldersMap[folderSubmission.submission.id].length === 0) {
            delete submission2FoldersMap[folderSubmission.submission.id];
          }
        });
        delete folder2SubmissionsMap[action.payload.folder.name];
      }

      return { ...state, foldersMap: newState, folder2SubmissionsMap, submission2FoldersMap };
    }
    case FOLDER_ADDED:
    case FOLDER_UPDATED:
      return { ...state, foldersMap: { ...state.foldersMap, [action.payload.folderId]: action.payload.folder } };

    case ALLFOLDERS_SUBMISSIONS: {
      let folder2SubmissionsMap = {};
      let submission2FoldersMap = {};
      action.payload.foldersSubmissions.forEach((folderSubmission) => {
        if (folder2SubmissionsMap[folderSubmission.name]) {
          folder2SubmissionsMap[folderSubmission.name] = [...folder2SubmissionsMap[folderSubmission.name]];
          folder2SubmissionsMap[folderSubmission.name].push(folderSubmission);
        } else folder2SubmissionsMap[folderSubmission.name] = [folderSubmission];

        if (submission2FoldersMap[folderSubmission.submission.id]) {
          submission2FoldersMap[folderSubmission.submission.id] = [...submission2FoldersMap[folderSubmission.submission.id]];
          submission2FoldersMap[folderSubmission.submission.id].push(folderSubmission);
        } else {
          submission2FoldersMap[folderSubmission.submission.id] = [folderSubmission];
        }
      });
      return { ...state, folder2SubmissionsMap, submission2FoldersMap };
    }
    case FOLDER_SUBMISSION_ADDED: {
      let folder2SubmissionsMap = { ...state.folder2SubmissionsMap };
      let submission2FoldersMap = { ...state.submission2FoldersMap };
      let folderSubmission = action.payload.folderSubmission;
      if (folder2SubmissionsMap[folderSubmission.name]) {
        folder2SubmissionsMap[folderSubmission.name] = [...folder2SubmissionsMap[folderSubmission.name]];
        folder2SubmissionsMap[folderSubmission.name].push(folderSubmission);
      } else folder2SubmissionsMap[folderSubmission.name] = [folderSubmission];

      if (submission2FoldersMap[folderSubmission.submission.id]) {
        submission2FoldersMap[folderSubmission.submission.id] = [...submission2FoldersMap[folderSubmission.submission.id]];
        submission2FoldersMap[folderSubmission.submission.id].push(folderSubmission);
      } else {
        submission2FoldersMap[folderSubmission.submission.id] = [folderSubmission];
      }
      return { ...state, folder2SubmissionsMap, submission2FoldersMap };
    }
    case FOLDER_SUBMISSION_DELETED: {
      let folder2SubmissionsMap = { ...state.folder2SubmissionsMap };
      let submission2FoldersMap = { ...state.submission2FoldersMap };
      let folderSubmission = action.payload.folderSubmission;
      if (folder2SubmissionsMap[folderSubmission.name]) {
        folder2SubmissionsMap[folderSubmission.name] = [...folder2SubmissionsMap[folderSubmission.name]];
        folder2SubmissionsMap[folderSubmission.name] = folder2SubmissionsMap[folderSubmission.name].filter(
          (folderSubmit) => folderSubmit.submission.id !== folderSubmission.submission.id
        );
        if (folder2SubmissionsMap[folderSubmission.name].length === 0) {
          delete folder2SubmissionsMap[folderSubmission.name]; // TODO FFS
        }
      } else {
        console.warn('folder submission mapping folder2SubmissionsMap to delete not found ', folderSubmission);
      }

      if (submission2FoldersMap[folderSubmission.submission.id]) {
        submission2FoldersMap[folderSubmission.submission.id] = [...submission2FoldersMap[folderSubmission.submission.id]];
        submission2FoldersMap[folderSubmission.submission.id] = submission2FoldersMap[folderSubmission.submission.id].filter(
          (folderSubmit) => folderSubmit.name !== folderSubmission.name
        );
        if (submission2FoldersMap[folderSubmission.submission.id].length === 0) {
          delete submission2FoldersMap[folderSubmission.submission.id]; // TODO FFS
        }
      } else {
        console.warn('folder submission mapping submission2FoldersMap to delete not found ', folderSubmission);
      }
      return { ...state, folder2SubmissionsMap, submission2FoldersMap };
    }
    default:
      return state;
  }
};
