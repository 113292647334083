import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import React, { FormEvent } from 'react';
import { Rubric } from '../../../model/ApiTypes';
import BoldCloseButton from '../../shared/BoldCloseButton';
import { IChildProps } from './AddRubricForm';

interface IState {
  term: string;
  searchKeywords: string[];
}

class ChooseRubricForm extends React.PureComponent<IChildProps & { setSelectedIdx: (string) => void }, IState> {
  constructor(props) {
    super(props);
    this.state = { term: '', searchKeywords: [] };
  }

  noRubricSelected = () => !this.props.values || this.props.values.selectedRubricIdx === null || isNaN(this.props.values.selectedRubricIdx);

  continue = (e) => {
    e.preventDefault();
    if (this.noRubricSelected()) {
      this.props.setGlobalAlert('Please select a rubric');
    } else {
      this.props.nextStep();
    }
  };

  render() {
    return (
      <Dialog open fullWidth maxWidth="sm" onClose={this.props.onClose} scroll="paper" className="fig-modal-dialog-main-container-v2">
        <BoldCloseButton width={14} height={14} top={15} right={15} handleClose={this.props.onClose}></BoldCloseButton>

        <DialogTitle id="scroll-dialog-title" disableTypography>
          <div className="row padding-top--35">
            <div className="col-5 no-padding fig-vertical-middle">
              <span className="modal-title">First, select your Rubric</span>
            </div>
            <div className="col-7 no-padding">{this.renderSearch()}</div>
          </div>
        </DialogTitle>
        <DialogContent dividers={false}>
          <div className="row">
            <div className="col-12 no-padding">{this.renderRubricsList()}</div>
          </div>
        </DialogContent>
        {/* <DialogActions /> */}

        <div className="row modal-dialog-footer">
          <div className="col-12 no-padding">
            <div className="modal-button-box">
              <Button
                className={`fig-button in-modal-dialog ${this.noRubricSelected() ? 'fig-button__white' : 'fig-button__grey'}`}
                onClick={this.continue}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
  renderSearch() {
    return (
      <div className="row">
        <TextField
          className="col-12 modal-searchbox"
          placeholder="Search Rubric"
          value={this.state.term}
          onChange={this.handleInputChange}
          onKeyPress={this.onEnterKey}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
                <IconButton className="delete-search" aria-label="clear" onClick={this.onClearSearch}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
    );
  }
  renderRubricsList() {
    let rubrics = this.props.values.rubrics;
    return (
      <div className="modal-list-box">
        <List className="padding-none" component="div" aria-labelledby="nested-list-subheader">
          {rubrics.map((rubric, idx) => {
            return !this.matchesFilter(rubric) ? null : <div key={rubric.id}>{this.renderRubricItem(rubric, idx)}</div>;
          })}
        </List>
      </div>
    );
  }
  renderRubricItem = (rubric: Rubric, idx: number) => {
    return (
      // <div key={`itemrubric_${rubric.id}`}>
      <ListItem
        className={`modal-list-item ${this.props.values.selectedRubricIdx === idx ? 'selected' : ''}`}
        key={`itemrubric_${rubric.id}`}
        // button
        onClick={() => this.props.setSelectedIdx('' + idx)}
      >
        <ListItemText primary={rubric.name} />
        <ListItemSecondaryAction>
          <ListItemText secondary={`Last Modified ${new Date(rubric.updated_at).toLocaleString()}`} style={{}} />
        </ListItemSecondaryAction>
      </ListItem>
      // </div>
    );
  };
  onClearSearch = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.resetState();
  };

  matchesFilter = (rubric: Rubric) => {
    if (!this.state.searchKeywords || this.state.searchKeywords.length === 0) {
      return true;
    }
    let regexp = this.makeKeywordRegexp();
    return rubric.name.match(regexp);
    // ||
    // rubric.template.categories.some(
    //   (category) => category.name.match(regexp) || category.questions.some((question) => question.match(regexp))
    // )
  };
  makeKeywordRegexp() {
    return new RegExp(['\\b', this.state.searchKeywords.join('\\b|\\b'), '\\b'].join(''), 'i');
  }

  private resetState(): void {
    this.setState({
      term: '',
      searchKeywords: [],
    });
  }

  handleInputChange = (event) => {
    this.setState({ term: event.target.value });
  };

  onEnterKey = (e: any) => {
    if (e.which === 13) {
      this.onFormSubmit(e);
    }
  };
  separators = new RegExp([',', ' '].join('|'));
  onFormSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.setState({
      searchKeywords: this.state.term.split(this.separators),
    });
  };
}
export default ChooseRubricForm;
