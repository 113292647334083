import { IconButton, InputAdornment, List, TextField, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import React, { FormEvent } from 'react';
import { getUsers } from '../../actions/figaroApiActions';
import { IUser } from '../../model/ApiTypes';
import { EllipseAvatarIcon } from '../shared/EllipseAvatarIcon';

type ISearchFormProps = {
  onAddUncheckedAddress: (term: string) => void;
  onToggleSelectUser: (user: IUser) => (event) => void;
  onSearchBoxChanged: (boolean) => void;
  token: string;

  selectedUsers: { [userId: string]: IUser };
  eMailsOfSelectedUsers: string[]; //emails of selectedUsers:
  selectedUncheckedEMails: { [address: string]: string }; //raw emails which have no IUSer
  maxHeight: number;
};

interface ISearchFormState {
  searchedTerm: string;
  matchingUsers: IUser[];
}

export default class SearchUsersPanel extends React.PureComponent<ISearchFormProps, ISearchFormState> {
  constructor(props: ISearchFormProps) {
    super(props);
    this.state = {
      searchedTerm: '',
      matchingUsers: [],
      // selectedUsers: {},
      // selectedUncheckedAddresses: {},
      // alreadySelectedEMails: [],
    };
    console.log('SearchUsersForm ctor,  props ', props);
  }
  onClearSearch = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    this.reInitialize();
    this.props.onSearchBoxChanged(true);
  };

  private reInitialize(): void {
    this.setState(
      {
        searchedTerm: '',
        matchingUsers: [],
      }
      // () => this.queryUsers()
    );
  }
  render() {
    return (
      <>
        <div className="w-100">{this.renderSearch()}</div>

        {this.renderUsersList()}
      </>
    );
  }

  renderSearch() {
    return (
      <div className="fig-request-a-video-left-side__search-container">
        <TextField
          className="col-12"
          placeholder="Search by email address"
          value={this.state.searchedTerm}
          onChange={this.handleInputChange}
          onKeyUp={this.onKeyUp}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Tooltip title="Search">
                  <IconButton onClick={this.onSearch} size="small">
                    <SearchIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="Add e-mail to list">
                  <IconButton aria-label="next" size="small" onClick={this.onAddUncheckedAddress}>
                    <AddIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Clear search">
                  <IconButton aria-label="clear" size="small" onClick={this.onClearSearch}>
                    <ClearIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </div>
    );
  }

  renderUsersList() {
    let hasEntries = this.state.matchingUsers && this.state.matchingUsers.length > 0;
    return hasEntries ? (
      <div className="fig-request-a-video-left-side__search-results-holder" style={{ height: this.props.maxHeight }}>
        <List component="div" aria-labelledby="nested-list-subheader">
          {this.state.matchingUsers.map((user, idx) =>
            this.props.eMailsOfSelectedUsers.includes(user.email) ? null : <div key={user.id}>{this.renderUser(user, idx)}</div>
          )}
        </List>
      </div>
    ) : null;
  }
  renderUser = (user: IUser, idx: number) => {
    return (
      <div className="user-result-holder" key={user.id} onClick={this.props.onToggleSelectUser(user)}>
        <EllipseAvatarIcon viewBox="0 0 36 35" />
        <div className="user-result-holder-names">
          <div>{user.username}</div>
          <div>{user.email}</div>
        </div>
      </div>
    );
  };
  handleInputChange = (event) => {
    this.setState({ searchedTerm: event.target.value });
  };

  onKeyUp = (e: any) => {
    if (e.which === 13) {
      this.onSearch(e);
    } else if (e.which === 27) {
      this.onClearSearch(e);
    }
  };
  onSearch = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    this.queryUsers();
  };

  queryUsers = () => {
    getUsers(this.state.searchedTerm, this.props.token).then(
      (users: IUser[]) =>
        this.setState(
          {
            matchingUsers: users.filter((user) => !this.props.eMailsOfSelectedUsers.includes(user.email)),
          },
          () => {
            this.props.onSearchBoxChanged(this.state.matchingUsers.length ? false : true);
          }
        ),
      (err) => {
        console.log('getUsers err ', err);
      }
    );
  };
  onAddUncheckedAddress = () => {
    this.props.onAddUncheckedAddress(this.state.searchedTerm);
  };
}
