export const DROPPABLE_CONTAINER_ID_EDITED_RUBRIC = 'EditedRubric';
export const TYPE_DROPPABLE_QUESTION = 'QUESTION';
export const TYPE_DROPPABLE_CATEGORY = 'CATEGORY';
export const TYPE_DROPPABLE_RUBRIC = 'RUBRIC';

export const isTemplateID = (id: string) => id.startsWith('TEMPLATE');

export const generateDroppableTemplRubricContainerId = (rubricId: number, rubricIdx: number) => `TEMPLATEDROPPABLE4RUBRIC_${rubricId}`;
export const isSourceTemplRubricContainer = (droppableId: string) => droppableId.startsWith('TEMPLATEDROPPABLE4RUBRIC_');

export const generateDroppableTemplCategoryContainerId = (rubricId: number, idxCategory: number, isClone: boolean) => {
  let rez = `TEMPLATEDROPPABLE4CAT_${rubricId}_${idxCategory}_${isClone}`;
  return rez;
};

export const generateDraggableTemplCategoryId = (rubricId: number, idxCategory: number, isClone: boolean) => {
  let rez = `TEMPLATEDRAGGABLECAT_${rubricId}_${idxCategory}_${isClone}`;
  return rez;
};

export const generateDraggableTemplRubricId = (rubricId: number) => `TEMPLATEDRAGGABLERUBRIC_${rubricId}`;

export const generateDroppableTemplQuestionContainerId = (
  rubricId: number,
  idxCategory: number,
  idxQuestion: number,
  isClone: boolean,
  parentIsClone: boolean
) => {
  let rez = `TEMPLATEDROPPABLE4QUEST_${rubricId}_${idxCategory}_${idxQuestion}_${isClone}_${parentIsClone}`;
  return rez;
};

export const generateDraggableTemplQuestionId = (
  rubricId: number,
  idxCategory: number,
  idxQuestion: number,
  isClone: boolean,
  parentIsClone: boolean
) => {
  let rez = `TEMPLATEDRAGGABLEQUEST_${rubricId}_${idxCategory}_${idxQuestion}_${isClone}_${parentIsClone}`;
  return rez;
};
export const parseDnDFormattedIds = (id: string) => {
  let parts = id.split('_');
  if (!parts || parts.length < 2) {
    console.warn('Unexpected DraggableTemplateID: ', id);
    return {};
  }
  return {
    rubricId: parseInt(parts[1], 10),
    categoryIdx: parseInt(parts[2], 10),
    questionIdx: parts.length > 2 ? parseInt(parts[3], 10) : null,
  };
};
export const parseDnDRubricId = (id: string) => {
  let parts = id.split('_');
  if (!parts || parts.length < 1) {
    console.warn('Unexpected DraggableDnDTemplateID: ', id);
    return null;
  }
  return parseInt(parts[1], 10);
};
