import { Checkbox, FormControl, Grid, IconButton, Input, ListItemText, MenuItem, Select, Tooltip } from '@material-ui/core';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import TuneIcon from '@material-ui/icons/SettingsEthernet';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import { selectedFaceAnalysisPersons, selectedFaceStreams, selectedVoiceStreams } from '../../actions/selectionActions';
import { AnalysisItemStatus, StreamCategories } from '../../model/ApiTypes';
import { categoriesAndTags2StreamRefs, isStreamTagIncluded } from '../../model/utils';
import { CATEGORY_ANALYSIS, CATEGORY_EMOTIONS, FAKE_EMOTION_COMPLEXITY, FAKE_EMOTION_FLUIDITY, StreamRef } from '../../model/Video';
import CategoryLegend from './CategoryLegend';
import { isComplexityStream } from './utils';

interface IProps extends PropsFromRedux {
  isFaceAnalysis: boolean;
  seriesColors: { [seriesName: string]: string };
  toggleDrawer: any;
  containerHeight: number;
  isPseudo?: boolean; //legend does not allow toggling streams, it just shows which are the selected streams
  isComplexityOnly: boolean;
  speaker?: string;
  speakerFriendlyName?: string;
  speakerHiddenStatus?: boolean; //mandatory for face
}

class Legend extends React.PureComponent<IProps> {
  //  {/* width for each category is either "one column", or 2-3,
  //   depending on ho wmany oems the category has and the current height of the container:
  // if height is enough for all items, then 1 column , else either 2 or even 3*/}
  computeCategoryLegendWidth = (legendItemsCount, debugInfo) => {
    //console.log('LEGEND: compute for ', debugInfo.categoryName);
    if (this.props.isComplexityOnly) {
      //console.log('LEGEND for Complexity ', ': ', CategoryLegend.MAX_LEGEND_ITEM_WIDTH_II);
      return CategoryLegend.MAX_LEGEND_ITEM_WIDTH_I;
    }
    if (!this.props.containerHeight) {
      //console.log('LEGEND intorc WIDTH ', ': dunnno, ', CategoryLegend.LEGEND_ITEM_WIDTH);
      return CategoryLegend.LEGEND_ITEM_WIDTH;
    }
    if (!legendItemsCount) {
      return 0;
    }
    //console.log('LEGEND height , container: ', this.props.containerHeight);
    let isVoiceCategory = debugInfo.tag === ''; //voice has  empty tag ''
    let rez =
      this.props.containerHeight >= legendItemsCount * CategoryLegend.LEGEND_ITEM_HEIGHT + 70
        ? isVoiceCategory
          ? CategoryLegend.MAX_LEGEND_ITEM_WIDTH_II
          : CategoryLegend.MAX_LEGEND_ITEM_WIDTH_I
        : Math.ceil(legendItemsCount / 2) * CategoryLegend.LEGEND_ITEM_HEIGHT + 70 <= this.props.containerHeight
        ? isVoiceCategory
          ? CategoryLegend.LEGEND_ITEM_WIDTH + CategoryLegend.MAX_LEGEND_ITEM_WIDTH_II
          : CategoryLegend.LEGEND_ITEM_WIDTH + CategoryLegend.MAX_LEGEND_ITEM_WIDTH_I
        : isVoiceCategory
        ? 2 * CategoryLegend.LEGEND_ITEM_WIDTH + CategoryLegend.MAX_LEGEND_ITEM_WIDTH_II
        : 2 * CategoryLegend.LEGEND_ITEM_WIDTH + CategoryLegend.MAX_LEGEND_ITEM_WIDTH_I;
    //console.log('LEGEND returns WIDTH ', rez);
    return rez;
  };
  render() {
    console.log('Legend render, pseudo: ', this.props.isPseudo);
    let { elementsAttrs, selectedStreams } = this.buildLegendCategories();
    //remap face categories Focus, Valence, Analysis to just one category,  to gain visual space
    elementsAttrs = this.remapCategories(elementsAttrs);

    return (
      <div className="col no-padding h-100">
        <Grid container className="fig-legend-holder">
          {!this.props.isPseudo ? this.renderSelectBtns(1) : null}
          <div className={`fig-legend-left-side  w-100`}>
            {elementsAttrs.map((elementAttr, idx) => {
              const itemsCount = this.selectedStreamsCount(elementAttr.streams, elementAttr.tag, elementAttr.selectedStreams);
              const computedWidth = this.computeCategoryLegendWidth(
                this.props.isPseudo ? itemsCount : elementAttr.streams.length,
                elementAttr
              );
              return !this.props.isPseudo || itemsCount > 0 ? (
                <div
                  className="fig-category-legend-box"
                  key={elementAttr.categoryName + '.' + elementAttr.tag}
                  style={{ minWidth: computedWidth }}
                >
                  <Grid item>
                    <CategoryLegend
                      categoryName={elementAttr.categoryName}
                      tag={elementAttr.tag}
                      friendlyName={elementAttr.friendlyName}
                      streams={elementAttr.streams}
                      streamsSelected4Graph={elementAttr.selectedStreams}
                      toggleSelectedStream={this.toggleSelectedStream}
                      seriesColors={this.props.seriesColors}
                      alwaysShowEmotionsColors={
                        elementAttr.categoryName === CATEGORY_EMOTIONS &&
                        isStreamTagIncluded(elementAttr.tag, FAKE_EMOTION_FLUIDITY, selectedStreams)
                      }
                      isPseudo={this.props.isPseudo}
                      isComplexityOnly={this.props.isComplexityOnly}
                      isHidden={(!this.props.isPseudo && this.props.speakerHiddenStatus) || elementAttr.hidden}
                    ></CategoryLegend>
                  </Grid>
                </div>
              ) : null;
            })}
          </div>
        </Grid>
      </div>
    );
  }

  renderSelectBtns(tagsCounter: number) {
    return (
      <div style={{ width: '100%', maxHeight: 16, position: 'relative' }}>
        <Tooltip title={`${this.props.isFaceAnalysis ? 'Global Face' : 'Voice'} Analysis Options`}>
          <IconButton
            size="small"
            style={{
              marginTop: '1px',
              marginRight: '2px',
              visibility: !tagsCounter ? 'hidden' : 'inherit',
              position: 'absolute',
              right: 5,
            }}
            onClick={this.props.toggleDrawer}
            // checked={true}
            color="primary"
          >
            <TuneIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Hide All">
          <IconButton
            size="small"
            style={{
              marginTop: '1px',
              marginRight: '2px',
              visibility: !tagsCounter ? 'hidden' : 'inherit',
              position: 'absolute',
              right: 35,
              color: '#c0c0c0',
            }}
            onClick={this.onHideAllStreams}
            color="default"

            // checked={true}
          >
            <SelectAllIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Show All ">
          <IconButton
            size="small"
            style={{
              marginTop: '1px',
              marginRight: '2px',
              visibility: !tagsCounter ? 'hidden' : 'inherit',
              position: 'absolute',
              right: 65,
            }}
            onClick={this.onShowAllAvailableStreams}
            // checked={true}
            color="primary"
          >
            <SelectAllIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  }

  selectedStreamsCount = (streams: string[], tag, streamsSelected4Graph) => {
    let itemCount = 0;
    streams.forEach((stream, idx) => {
      const isSelected = isStreamTagIncluded(tag, stream, streamsSelected4Graph);
      if (isSelected) {
        itemCount++;
      }
    });
    return itemCount;
  };

  // for face analysis legend, if multiple speakers exist, add speakers filtering menu
  renderSpeakersSelector() {
    return this.props.isFaceAnalysis && this.props.allPersonsTags.length > 1 ? (
      <FormControl style={{ minWidth: 60, maxWidth: 120, padding: 7, marginRight: 3 }}>
        <Select
          labelId="speakers-label"
          id="speakers"
          multiple
          value={this.props.selectedPersonsTags}
          onChange={this.onSpeakersSelected}
          input={<Input />}
          renderValue={(selectedSpeakers) => 'Speakers'}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          style={{ fontWeight: 'bold', fontSize: 'small', background: 'white' }}
        >
          {this.props.allPersonsTags.map((name) => (
            <MenuItem key={name} value={name} style={{ maxHeight: 30, paddingRight: 0 }}>
              <ListItemText primary={name} />
              <Checkbox checked={this.props.selectedPersonsTags.includes(name)} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ) : null;
  }
  onSpeakersSelected = (event) => {
    this.props.selectedFaceAnalysisPersons([...event.target.value]);
  };
  toggleSelectedStream = (category: string, tag: string, stream: string, friendlyName: string) => {
    if (!this.props.isComplexityOnly) {
      const oldSelectedStreams = this.props.isFaceAnalysis ? this.props.selectedStreamsFace : this.props.selectedStreamsVoice;
      const wasSelected = isStreamTagIncluded(tag, stream, oldSelectedStreams);
      let newSelectedStreams = [...oldSelectedStreams];
      if (wasSelected) {
        //remove it from selections
        newSelectedStreams = newSelectedStreams.filter((streamRef) => streamRef.analysisTag !== tag || streamRef.stream !== stream);
      } else {
        // add it
        newSelectedStreams.push({
          stream,
          analysisTag: tag,
          analysisIdx: this.props.isFaceAnalysis ? this.props.tag2FaceAnalysisIdx[tag] : 0,
          tagFriendlyName: friendlyName,
        });
      }
      this.props.isFaceAnalysis
        ? this.props.selectedFaceStreams(newSelectedStreams, null)
        : this.props.selectedVoiceStreams(newSelectedStreams, null);
    }
  };

  onShowAllAvailableStreams = (event) => {
    let availableStreamsPerCategory = this.props.isFaceAnalysis ? this.props.availableFaceCategories : this.props.availableVoiceCategories;
    if (!this.props.faceAnalyses || !availableStreamsPerCategory) return;
    if (!this.props.isFaceAnalysis) {
      const streamRefs = categoriesAndTags2StreamRefs(availableStreamsPerCategory, [''], [''],[0]);
      this.props.selectedVoiceStreams(streamRefs, null);
    } else {
      let faceStreamsOfOtherSpeakers = this.props.selectedStreamsFace.filter((stream) => stream.analysisTag !== this.props.speaker);
      const streamRefs4ThisSpeaker = categoriesAndTags2StreamRefs(
        availableStreamsPerCategory,
        [this.props.speaker],
        [this.props.speakerFriendlyName],[this.props.tag2FaceAnalysisIdx[this.props.speaker]]
      ).filter((streamRef) => !isComplexityStream(streamRef));
      this.props.selectedFaceStreams(streamRefs4ThisSpeaker.concat(faceStreamsOfOtherSpeakers), null);
    }
  };
  onHideAllStreams = (event) => {
    if (!this.props.isFaceAnalysis) {
      this.props.selectedVoiceStreams([], null);
    } else {
      this.props.selectedFaceStreams(
        this.props.selectedStreamsFace.filter((stream) => stream.analysisTag !== this.props.speaker),
        null
      );
    }
  };
  filterSpeakers = (streamRefs: StreamRef[]) => {
    return streamRefs.filter((streamRef) => this.props.selectedPersonsTags.includes(streamRef.analysisTag));
  };
  filterOutComplexity = (streamRefs: StreamRef[]) => {
    return !this.props.isComplexityOnly ? streamRefs.filter((streamRef) => streamRef.stream !== FAKE_EMOTION_COMPLEXITY) : streamRefs;
  };

  buildLegendCategories = () => {
    let categories: StreamCategories = {};
    let analysisTags: string[] = [''];
    let selectedStreams: StreamRef[] = [];
    let elementsAttrs: {
      tag: string;
      friendlyName: string;
      categoryName: string;
      streams: string[];
      selectedStreams: StreamRef[];
      hidden?: boolean;
    }[] = [];

    if (this.props.isComplexityOnly) {
      //legend of special Complexity graph
      categories = {};
      categories[CATEGORY_ANALYSIS] = this.props.allFaceCategories[CATEGORY_ANALYSIS];
      if (!this.props.faceAnalyses || !categories) return null;
      analysisTags = Object.keys(this.props.tag2FaceAnalysisIdx);
      if (categories) {
        Object.keys(categories).forEach((category, idx) => {
          if (categories[category] && categories[category].length > 0)
            analysisTags.forEach((analysisTag) => {
              elementsAttrs.push({
                tag: analysisTag,
                friendlyName: this.props.tag2FriendlyName[analysisTag],
                hidden:
                  this.props.metaFaceAnalysesMap[this.props.faceAnalyses[this.props.tag2FaceAnalysisIdx[analysisTag]].urlid].status ===
                  AnalysisItemStatus.hidden,
                categoryName: category,
                streams: categories[category],
                selectedStreams: (selectedStreams = [
                  {
                    analysisIdx: this.props.tag2FaceAnalysisIdx[analysisTag],
                    stream: FAKE_EMOTION_COMPLEXITY,
                    analysisTag,
                  } as StreamRef,
                ]),
              });
            });
        });
      }
    } else if (!this.props.isPseudo) {
      //legend of regular Analysis graph
      categories = this.props.isFaceAnalysis ? this.props.availableFaceCategories : this.props.availableVoiceCategories;
      if (!this.props.faceAnalyses || !categories) return null;
      analysisTags = [this.props.isFaceAnalysis ? this.props.speaker : ''];
      selectedStreams = this.props.isFaceAnalysis ? this.props.selectedStreamsFace : this.props.selectedStreamsVoice;
      if (categories) {
        Object.keys(categories).forEach((category, idx) => {
          if (categories[category] && categories[category].length > 0)
            analysisTags.forEach((analysisTag) => {
              // if face analysis, filter streams by selected speakers
              if (!this.props.isFaceAnalysis || this.props.selectedPersonsTags.includes(analysisTag)) {
                elementsAttrs.push({
                  tag: analysisTag,
                  friendlyName: this.props.isFaceAnalysis ? this.props.tag2FriendlyName[analysisTag] : '',
                  categoryName: category,
                  streams: categories[category],
                  selectedStreams,
                });
              }
            });
        });
      }
    } else {
      //pseudo legend of Analysis graph - show all selected face and voice streams for all selected speakers
      analysisTags = Object.keys(this.props.tag2FaceAnalysisIdx);
      selectedStreams = this.props.selectedStreamsFace;
      categories = this.props.availableFaceCategories;
      if (!this.props.faceAnalyses || !categories) return null;
      if (categories) {
        Object.keys(categories).forEach((category, idx) => {
          if (categories[category] && categories[category].length > 0)
            analysisTags.forEach((analysisTag) => {
              // if face analysis, filter streams by selected speakers
              if (this.props.selectedPersonsTags.includes(analysisTag)) {
                elementsAttrs.push({
                  tag: analysisTag,
                  friendlyName: this.props.tag2FriendlyName[analysisTag],
                  categoryName: category,
                  streams: categories[category],
                  selectedStreams,
                  hidden:
                    this.props.metaFaceAnalysesMap[this.props.faceAnalyses[this.props.tag2FaceAnalysisIdx[analysisTag]].urlid].status ===
                    AnalysisItemStatus.hidden,
                });
              }
            });
        });
      }
      categories = this.props.availableVoiceCategories;
      if (!this.props.faceAnalyses || !categories) return null;
      analysisTags = [''];
      selectedStreams = this.props.selectedStreamsVoice;
      if (categories) {
        Object.keys(categories).forEach((category, idx) => {
          if (categories[category] && categories[category].length > 0)
            analysisTags.forEach((analysisTag) => {
              // if face analysis, filter streams by selected speakers
              elementsAttrs.push({
                tag: analysisTag,
                friendlyName: '',
                categoryName: category,
                streams: categories[category],
                selectedStreams,
              });
            });
        });
      }
    }
    return { elementsAttrs, selectedStreams };
  };

  /*
      remap Face categories Focus, Valence, Analysis to just one category,  to gain space
  */
  remapCategories = (
    elementsAttrs: {
      tag: string;
      friendlyName: string;
      categoryName: string;
      streams: string[];
      selectedStreams: StreamRef[];
      hidden?: boolean;
    }[]
  ) => {
    if (!this.props.isComplexityOnly) {
      if (this.props.isFaceAnalysis) {
        let remappedElementsAttrs = null;
        const newCombinedCategoryEl = {
          tag: this.props.speaker,
          friendlyName: this.props.speakerFriendlyName,
          categoryName: '',
          streams: [],
          selectedStreams: [],
          hidden: false,
        };
        elementsAttrs.forEach((element) => {
          if (element.categoryName === CATEGORY_EMOTIONS) {
            remappedElementsAttrs = element;
          } else {
            newCombinedCategoryEl.categoryName += element.categoryName + ', ';
            newCombinedCategoryEl.streams = newCombinedCategoryEl.streams.concat(element.streams);
            newCombinedCategoryEl.selectedStreams = newCombinedCategoryEl.selectedStreams.concat(element.selectedStreams);
            newCombinedCategoryEl.hidden = element.hidden;
          }
        });
        elementsAttrs = [];
        if (newCombinedCategoryEl.streams.length) {
          elementsAttrs.push(newCombinedCategoryEl);
        }
        if (remappedElementsAttrs) elementsAttrs.push(remappedElementsAttrs);
        return elementsAttrs;
      } else if (this.props.isPseudo) {
        const remappedElementsAttrs = [];
        let newCombinedCategoryEls = {};
        let allSpeakers = Object.keys(this.props.tag2FaceAnalysisIdx);
        allSpeakers.forEach(
          (speaker) =>
            (newCombinedCategoryEls[speaker] = {
              tag: speaker,
              friendlyName: this.props.tag2FriendlyName[speaker],
              categoryName: '',
              streams: [],
              selectedStreams: [],
              hidden:
                this.props.metaFaceAnalysesMap[this.props.faceAnalyses[this.props.tag2FaceAnalysisIdx[speaker]].urlid].status ===
                AnalysisItemStatus.hidden,
            })
        );
        elementsAttrs.forEach((element) => {
          if (
            element.categoryName === CATEGORY_EMOTIONS ||
            !this.props.allFaceCategories ||
            !this.props.allFaceCategories[element.categoryName] /*voice categ*/
          ) {
            remappedElementsAttrs.push(element);
          } else {
            newCombinedCategoryEls[element.tag].categoryName += element.categoryName + ', ';
            newCombinedCategoryEls[element.tag].streams = newCombinedCategoryEls[element.tag].streams.concat(element.streams);
            newCombinedCategoryEls[element.tag].selectedStreams = newCombinedCategoryEls[element.tag].selectedStreams.concat(
              element.selectedStreams
            );
          }
        });
        elementsAttrs = [];
        Object.keys(newCombinedCategoryEls).forEach((key) => {
          if (newCombinedCategoryEls[key].streams.length) {
            elementsAttrs.push(newCombinedCategoryEls[key]);
          }
        });
        return elementsAttrs.concat(remappedElementsAttrs);
      }
    }
    return elementsAttrs;
  };
}

const selectorFaceAnalyses = (state: IRootState) => {
  console.log('legend: SELECTOR faces called with params ', state);
  if (state.video.currentVideoId && state.video.videosMap[state.video.currentVideoId]) {
    return state.video.videosMap[state.video.currentVideoId].faceAnalyses;
  } else return null;
};
const selectorMetaFaceAnalysesMap = (state: IRootState) => {
  if (state.video.currentVideoId && state.video.videosMap[state.video.currentVideoId]) {
    return state.video.videosMap[state.video.currentVideoId].metaFaceAnalysesMap;
  } else return null;
};
const tag2FaceAnalysisIdxSelector = (state: IRootState) =>
  state.video.currentVideoId && state.video.videosMap[state.video.currentVideoId]
    ? state.video.videosMap[state.video.currentVideoId].tag2FaceAnalysisIdx
    : null;

const tag2FriendlyNameSelector = (state: IRootState) =>
  state.video.currentVideoId && state.video.videosMap[state.video.currentVideoId]
    ? state.video.videosMap[state.video.currentVideoId].tag2FriendlyName
    : null;

const mapStateToProps = (state: IRootState) => {
  return {
    faceAnalyses: selectorFaceAnalyses(state),
    metaFaceAnalysesMap: selectorMetaFaceAnalysesMap(state),
    tag2FaceAnalysisIdx: tag2FaceAnalysisIdxSelector(state),
    tag2FriendlyName: tag2FriendlyNameSelector(state),
    availableVoiceCategories: state.selections.availableVoiceCategories,
    availableFaceCategories: state.selections.availableFaceCategories,
    selectedStreamsVoice: state.selections.selectedStreamsVoice,
    selectedStreamsFace: state.selections.selectedStreamsFace,
    selectedPersonsTags: state.selections.selectedPersonsTags,
    allPersonsTags: state.selections.allPersonsTags,
    allFaceCategories: state.selections.allFaceCategories,
  };
};
const mapDispatchToProps = {
  selectedFaceStreams,
  selectedVoiceStreams,
  selectedFaceAnalysisPersons,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Legend);
