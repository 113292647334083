import { SvgIcon } from '@material-ui/core';
import React from 'react';

export function EllipseAvatarIcon(props) {
  let { inverse, ...rest } = props;
  return (
    <SvgIcon {...rest}>
      <circle cx="17.9219" cy="17.5" r="17.5" fill={inverse ? '#F2F2F2' : '#9BA7BC'} />
      <path
        d="M22.9452 23.5231L22.6401 22.757C22.4858 22.3682 22.2461 22.0195 21.9393 21.7354L21.8835 21.684C21.4006 21.2359 20.766 20.9866 20.1071 20.9866H15.7366C15.0777 20.9866 14.4431 21.2359 13.9593 21.6831L13.9036 21.7354C13.5967 22.0195 13.3579 22.3682 13.2028 22.757L12.8977 23.5231"
        stroke={inverse ? '#1B335E' : '#F5F6F8'}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0789 13.5314C21.2703 14.7228 21.2703 16.6545 20.0789 17.8459C18.8875 19.0373 16.9559 19.0373 15.7645 17.8459C14.5731 16.6545 14.5731 14.7228 15.7645 13.5314C16.9559 12.34 18.8875 12.34 20.0789 13.5314"
        stroke={inverse ? '#1B335E' : '#F5F6F8'}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.469 11.9529C26.5326 15.0165 26.5326 19.9835 23.469 23.0471C20.4054 26.1107 15.4383 26.1107 12.3748 23.0471C9.31116 19.9835 9.31116 15.0164 12.3748 11.9529C15.4384 8.88925 20.4054 8.88925 23.469 11.9529"
        stroke={inverse ? '#1B335E' : '#F5F6F8'}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

//<svg width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
