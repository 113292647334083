/* eslint-disable no-console */
import { Button, Dialog, TextField } from '@material-ui/core';
import React from 'react';
import config from '../../config/constants';
import { Comment } from '../../model/Video';
import BoldCloseButton from '../shared/BoldCloseButton';
import { ISelectableItem } from '../shared/BorderedSelector';
import { SelectionList } from '../shared/SelectionList';
interface IProps {
  comment: Comment;
  handleFormSubmit: (form: IEditCommentState) => void;
  handleEdit: (commentId: string, values: IEditCommentState) => void;
  handleClose: () => void;
  videoDuration: number;
}

export interface IEditCommentState {
  privacyOptions: ISelectableItem[];
  time: number;
  text: string;
}

export default class EditComment extends React.PureComponent<IProps, IEditCommentState> {
  constructor(props: IProps) {
    super(props);
    console.log('ctor edit comm, props', this.props);
    this.state = {
      text: this.props.comment.text,
      privacyOptions: config.comments.defaultPrivacyItems.map((item) => ({
        ...item,
        selected: item.key === this.props.comment.privacy ? true : false,
      })),

      time: this.props.comment.time,
    };
  }

  handleTextChange = (event) => {
    this.setState({ text: event.target.value });
  };

  handlePrivacyChange = (privacyOptions: ISelectableItem[]) => {
    this.setState({ privacyOptions });
  };

  handleTimeChange = (event) => {
    this.setState({ time: event.target.value !== '' ? Math.min(event.target.value, this.props.videoDuration) : 0 });
  };

  //TODO REUSE
  trunc2 = (a: number) => {
    return Math.trunc(a * 100) / 100;
  };

  onFormSubmit = (event) => {
    console.log('onFormSubmit', this.state);
    this.onSubmitEditCommentDialog(event);
  };
  onSubmitEditCommentDialog = (event) => {
    //event.preventDefault();
    console.log('onSubmitEditCommDialog', this.state);
    if (this.state.time === null || isNaN(this.state.time) || this.state.text.length === 0) {
      return;
    }
    const res: IEditCommentState = { ...this.state };
    this.props.handleEdit(this.props.comment.id, res);
    this.setState(this.initState());
  };

  private initState() {
    const rez: IEditCommentState = {
      privacyOptions: this.state && this.state.privacyOptions ? this.state.privacyOptions : config.comments.defaultPrivacyItems,
      time: null,
      text: '',
    };
    return rez;
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('componentDidUpdate, props', this.props, ' , state: ', this.state, ', oldProps: ', prevProps, ', oldState: ', prevState);
  }

  render() {
    return (
      <Dialog open fullWidth maxWidth="sm" onClose={this.props.handleClose}>
        <form className="row" onSubmit={this.onFormSubmit} /*style={{ position: 'relative' }}*/>
          <div className="col-12 fig-modal-dialog-main-container">
            <BoldCloseButton width={16} height={16} top={10} right={10} handleClose={this.props.handleClose}></BoldCloseButton>
            <div className="row">
              <TextField
                className="col-9 fig-modal-dialog-input"
                label={`COMMENT @ ${this.state.time}`}
                //TODO FFS new edited times, or initial prop times ?
                value={this.state.text}
                onChange={this.handleTextChange}
                required
                InputLabelProps={{
                  shrink: true,
                  required: false,
                }}
                placeholder={'Enter Comment'}
                error={!this.state.text || !this.state.text.length}
              />
              {/* <PrivacyButtons
                privacy={this.state.privacy}
                handlePrivacyChange={this.handlePrivacyChange}
                classNames="col-3 fig-modal-dialog-input"
              /> */}
              <SelectionList listItems={this.state.privacyOptions} selectionChanged={this.handlePrivacyChange} />
            </div>
            <div className="row">
              <TextField
                className="col-12 fig-modal-dialog-input"
                label="START"
                value={this.state.time}
                onChange={this.handleTimeChange}
                required
                type="number"
                InputLabelProps={{
                  shrink: true,
                  required: false,
                }}
                InputProps={{ inputProps: { min: 0, max: this.props.videoDuration } }}
                placeholder={'Enter Start Time'}
                error={this.state.time === null || isNaN(this.state.time)}
              />
            </div>

            <div className="row fig-modal-dialog-buttons" style={{ justifyContent: 'center' }}>
              <Button className="fig-button in-modal-dialog" color="primary" variant="outlined" onClick={this.onSubmitEditCommentDialog}>
                Submit
              </Button>
            </div>
          </div>
        </form>
      </Dialog>
    );
  }
}
