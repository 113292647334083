import { FormControl, Select } from '@material-ui/core';
import React from 'react';
import { ISelectableItem } from '../shared/BorderedSelector';
import BorderedSelectorTicks from '../shared/BorderedSelectorTicks';

interface IProps {
  selectionChanged: (listItems: ISelectableItem[]) => void;
  classNames?: string; //this will be passed as className to inner Select

  /* exactly one item select */
  listItems: ISelectableItem[];
  position?: 'left' | 'right';
}
interface IState {
  listOpened: boolean;
}

export class SelectionList extends React.PureComponent<IProps, IState> {
  listAnchor = null;
  constructor(props: IProps) {
    super(props);
    this.state = {
      listOpened: false,
    };
  }

  render() {
    return (
      <div className="row">
        <FormControl
          ref={(e) => {
            this.listAnchor = e;
          }}
          // style={{ width: '100%', cursor: 'pointer' }}
        >
          <Select
            {...(this.props.classNames
              ? { className: this.props.classNames }
              : { className: 'col fig-modal-dialog-input fig-selection-list-pointer' })}
            native={false}
            labelId="selectionList"
            id="selectionList"
            value={''}
            open={this.state.listOpened}
            onOpen={this.toggleListOpen}
            onClose={this.toggleListOpen}
            onClick={this.toggleListOpen}
            MenuProps={{
              PaperProps: { elevation: 0, hidden: true },
            }}
            SelectDisplayProps={{ style: { background: 'transparent' } }}
            displayEmpty
            renderValue={() => this.props.listItems.find((item) => item.selected).displayedValue}
          ></Select>
          <BorderedSelectorTicks
            anchorRef={this.state.listOpened ? this.listAnchor : null}
            items={this.props.listItems}
            toggleSelection={this.toggleOption}
            width={200}
            height={145}
            onClose={this.toggleListOpen}
            position={this.props.position}
          ></BorderedSelectorTicks>
        </FormControl>
      </div>
    );
  }
  toggleOption = (key: string) => {
    let existing = this.props.listItems.find((item) => item.key === key);
    let existingSelected = existing ? existing.selected : null;
    if (!existingSelected) {
      let newOptions: ISelectableItem[] = [...this.props.listItems].map((item) => {
        return { selected: item.key === key ? true : false, key: item.key, displayedValue: item.displayedValue };
      });
      this.setState({ listOpened: false }, () => {
        this.props.selectionChanged(newOptions);
      });
    }
  };

  toggleListOpen = () => {
    const opened = this.state.listOpened;
    this.setState({ listOpened: !opened });
  };
}
