/* eslint-disable import/no-anonymous-default-export */
import { AUTH_CLEAR, AUTH_ERROR, AUTH_USER } from '../actions/figaroApiActions/types';
import { IUser, LoginResponse } from '../model/ApiTypes';

const STORAGE_KEY_TOKEN = 'token';
const STORAGE_KEY_USER = 'user';

export const userValue = (): IUser => {
  try {
    return JSON.parse(window.localStorage.getItem(STORAGE_KEY_USER));
  } catch (e) {
    return null;
  }
};

const tokenValue = (): string => {
  try {
    return window.localStorage.getItem(STORAGE_KEY_TOKEN);
  } catch (e) {
    return null;
  }
};

const clearAuthStorage = () => {
  window.localStorage.removeItem(STORAGE_KEY_USER);
  window.localStorage.removeItem(STORAGE_KEY_TOKEN);
};

const INITIAL_STATE = {
  isSignedIn: false,
  loginError: false,
  isAdmin: false,
  user: userValue(),
  token: tokenValue(),
};

export type FigaroAuthenticationState = Readonly<typeof INITIAL_STATE>;

function toValid(state: FigaroAuthenticationState) {
  if (state && state.token && state.user && state.user.role) {
    // TODO FFS check all fields
    return state;
  } else {
    return {
      isSignedIn: false,
      loginError: false,
      isAdmin: false,
      user: null,
      token: null,
    };
  }
}

// Reducers
export default (state: FigaroAuthenticationState = toValid(INITIAL_STATE), action: any): FigaroAuthenticationState => {
  let isAdmin = false;

  switch (action.type) {
    case AUTH_USER: {
      const loginResponse: LoginResponse = action.payload;
      if (loginResponse.user.role.name === 'Admin') {
        // TODO  ASK about role names
        isAdmin = true;
      }
      window.localStorage.setItem(STORAGE_KEY_USER, JSON.stringify(loginResponse.user));
      window.localStorage.setItem(STORAGE_KEY_TOKEN, loginResponse.jwt); // todo FFS

      return {
        ...state,
        isSignedIn: true,
        isAdmin,
        user: loginResponse.user,
        token: loginResponse.jwt,
      };
    }
    case AUTH_ERROR: {
      return { ...state, isSignedIn: false, loginError: true }; // todo  anulare user, token etc ?!
    }
    case AUTH_CLEAR: {
      clearAuthStorage();
      return {
        ...state,
        isSignedIn: false,
        loginError: false,
        user: null,
        token: null,
      }; // todo anulare user, token etc ?!
    }
    default:
      return state;
  }
};
