import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import { deleteSubmissionShare, shareSubmission } from '../../actions/figaroApiActions';
import Tags from '../tag/Tags';
import { IVideoPlayer } from '../video/VideoRenderer';

interface IProps extends PropsFromRedux {
  theVideoPlayer: IVideoPlayer;
}
interface IState {
  usersListAnchor: any /* if null, popper with Users is not rendered*/;
}

class VideoInfo extends React.PureComponent<IProps, IState> {
  anchorElement = null; //used as anchor for Users Popper
  constructor(props: IProps) {
    super(props);
    this.state = {
      usersListAnchor: null, //if not null, show Users popper
    };
  }

  toggleUsersList = () => {
    const opened = this.state.usersListAnchor !== null;
    this.setState({ usersListAnchor: opened ? null : this.anchorElement });
  };
  closeUsersList = () => {
    if (this.state.usersListAnchor) this.setState({ usersListAnchor: null });
  };
  componentDidMount() {
    this.forceUpdate(); // rerender with known anchor element
  }

  render() {
    console.log(' render VideoInfotab ', this.props);
    return this.props.selectedVideoId ? (
      <div className="row" style={{ width: '100%' }}>
        <Tags theVideoPlayer={this.props.theVideoPlayer} />
      </div>
    ) : null;
  }
  addShare = (addresses: string[]) => {
    addresses &&
      addresses.forEach((address) => {
        this.props.shareSubmission(this.props.selectedSubmission.id, address, this.props.currentToken);
      });
  };
}

const mapStateToProps = (state: IRootState) => {
  console.log('VideoInfo mapStateToProps, state ', state);
  return {
    selectedVideoId: state.video.currentVideoId,
    selectedSubmission: state.video.currentVideoId ? state.submissions.submissionsMap[state.video.currentVideoId] : null,
    currentToken: state.authentication.token,
  };
};

const mapDispatchToProps = { deleteSubmissionShare, shareSubmission };

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(VideoInfo);
