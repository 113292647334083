import { Typography } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import React from 'react';
import { ARRAY_NONE, IFilteringCategory } from './FilteringSortingDialog';

interface IProps extends IFilteringCategory {
  selectedItemsChanged: (category: string, newMultiItemsSelections: string[]) => void;
  categoryName: string;
}

export default class AllVsMultiOptions extends React.PureComponent<IProps, any> {
  render() {
    console.log('AllVsMultiOptions render  props: ', this.props);
    const indent = this.props.multiItemGroupName && this.props.multiItemGroupName.length;
    return (
      <div className="fig-options-pannel-category">
        <div className="fig-options-pannel-category__row">
          <div className="category-title" style={{ fontWeight: 600 }}>
            {this.props.categoryName}
          </div>
        </div>
        {indent ? <div style={{ paddingRight: 70 }}>{this.makeItemLine(this.props.allName)}</div> : this.makeItemLine(this.props.allName)}
        {indent ? (
          <>
            <div className="fig-options-pannel-category__row" style={{ paddingRight: 70 }} key={this.props.multiItemGroupName}>
              <div className="subcategory-title">
                <Typography color="textPrimary" variant="body1">
                  {this.props.multiItemGroupName}
                </Typography>
              </div>
              <div>
                <Typography color="textPrimary" variant="body1">
                  <Switch
                    size="small"
                    checked={this.props.selectedMultiItems.length !== 0}
                    onChange={this.handleChangeMultiRoot}
                    name={'Fake'}
                  />
                </Typography>
              </div>
            </div>
            <div style={{ paddingLeft: 50, paddingRight: 0 }}>{this.props.allMultiItems.map((item, indx) => this.makeItemLine(item))}</div>
          </>
        ) : (
          this.props.allMultiItems.map((item, indx) => this.makeItemLine(item))
        )}
      </div>
    );
  }

  makeItemLine = (item: string) => (
    <div className="fig-options-pannel-category__row" key={item}>
      <div className="subcategory-title">
        <Typography color="textPrimary" variant="body1">
          {item}
        </Typography>
      </div>
      <div>
        <Typography color="textPrimary" variant="body1">
          <Switch
            size="small"
            checked={(item === this.props.allName && !this.props.selectedMultiItems.length) || this.props.selectedMultiItems.includes(item)}
            onChange={this.handleChange}
            name={item}
          />
        </Typography>
      </div>
    </div>
  );

  handleChangeMultiRoot = (event) => {
    let newItems = [...this.props.selectedMultiItems];
    if (event.target.checked) {
      if (!newItems.length) {
        newItems = [this.props.allMultiItems[0]];
      }
    } else {
      if (newItems && newItems.length) {
        newItems = ARRAY_NONE;
      }
    }
    console.log(' selectedItems for ', this.props.categoryName, newItems);
    this.props.selectedItemsChanged(this.props.categoryName, newItems);
  };

  handleChange = (event) => {
    let newItems = [...this.props.selectedMultiItems];
    if (event.target.name === this.props.allName) {
      if (event.target.checked) {
        if (newItems && newItems.length) {
          newItems = ARRAY_NONE;
        }
      } else {
        if (!newItems.length) {
          newItems = [this.props.allMultiItems[0]];
        }
      }
    } else if (event.target.checked && !newItems.includes(event.target.name)) {
      newItems.push(event.target.name);
    } else if (!event.target.checked && newItems.includes(event.target.name)) {
      newItems = newItems.filter((stream) => stream !== event.target.name);
    }
    console.log(' selectedItems for ', this.props.categoryName, newItems);
    this.props.selectedItemsChanged(this.props.categoryName, newItems);
  };
}
