import { Menu, MenuItem } from '@material-ui/core';
import IconStop from '@material-ui/icons/Stop';
import IconZoomOut from '@material-ui/icons/ZoomOut';
import React from 'react';
import config from '../../config/constants';

interface IProps {
  shouldOpen: boolean;
  left: number;
  top: number;
  height: number;
  onMenuCommitHighlight: any;
  onMenuFinishEditingClose: any;
  onMenuAbortHighlight: any;
}

class MenuFinishEditingHighlight extends React.PureComponent<IProps> {
  menuItemStyle = {
    dense: true,
    style: { color: config.theme.getPopupMenuColor() },
  };

  render() {
    // console.log('rendering MenuFinishEditingHighlight, props ', this.props);

    return (
      <Menu
        keepMounted
        open={this.props.shouldOpen}
        onClose={this.props.onMenuFinishEditingClose}
        anchorReference="anchorPosition"
        anchorPosition={this.props.shouldOpen ? { left: this.props.left, top: this.props.top } : undefined}
      >
        {this.props.shouldOpen ? (
          <MenuItem onClick={this.props.onMenuCommitHighlight} {...this.menuItemStyle}>
            <IconZoomOut />
            Commit
          </MenuItem>
        ) : null}
        {this.props.shouldOpen ? (
          <MenuItem onClick={this.props.onMenuAbortHighlight} {...this.menuItemStyle}>
            <IconStop />
            Abort
          </MenuItem>
        ) : null}
      </Menu>
    );
  }
}
export default MenuFinishEditingHighlight;
