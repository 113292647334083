import { Button } from '@material-ui/core';
import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import { getDatagroups, getDisfluencies, getGlobalRubrics, getRubrics } from '../../actions/figaroApiActions';
import EditRubric from './EditRubric';
import RubricTemplates from './RubricTemplates';

type IProps = PropsFromRedux & { match: any; history: any };
type IState = {};

class OneRubricPanel extends React.PureComponent<IProps, IState> {
  editPanelComponent: React.RefObject<any> = null;
  constructor(props: IProps) {
    super(props);
    this.editPanelComponent = React.createRef();
    console.log('OneRubricPanel ctor,  props ', props);
  }

  componentDidMount = () => {
    console.log('OneRubricPanel did mount, loading Rubrics,  props ', this.props);
    this.loadRubrics();
  };
  componentWillUnmount = () => {
    console.log('OneRubricPanel will unmount ', this.props);
    this.clearSelections();
  };

  clearSelections = () => {};

  componentDidUpdate(prevProps: IProps) {
    const id = Number.parseInt(this.props.match.params.id, 10);
    const prevId = Number.parseInt(prevProps.match.params.id, 10);
    if (id !== prevId) {
      // new ID received directly from the browser
      this.clearSelections();
      this.loadRubrics();
    }
  }

  loadRubrics() {
    // anyway we need all rubbrics for the templates panel
    this.props.getRubrics(this.props.authentication.token);
    this.loadSettings();
  }

  //TODO FFS WHERE TO LOAD STUFF WHEN LOGIN IS SKIPPED ??
  loadSettings() {
    if (!this.props.disfluenciesLoaded) this.props.getDisfluencies(this.props.authentication.token);
    if (!this.props.dataGroupsLoaded) this.props.getDatagroups(this.props.authentication.token);
    if (!this.props.globalRubricsLoaded) this.props.getGlobalRubrics(this.props.authentication.token);
  }

  render() {
    const id = Number.parseInt(this.props.match.params.id, 10);
    console.log('OneRubricPanel render ', this.props, id);

    return (
      <div className="row no-gutters fig-rubrics">
        <div className="col-12">
          <div className="fig-rubrics-header">
            <span>Create a New Rubric</span>
            <Button
              className="fig-button in-modal-dialog "
              size="medium"
              onClick={() => {
                this.props.history.push('/rubrics');
              }}
            >
              Back to Rubric Dashboard
            </Button>
          </div>

          <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
            <div className="fig-rubrics-your-new-rubric">
              <div className="row no-gutters">
                <div className="col-4">
                  <RubricTemplates></RubricTemplates>
                </div>
                <div className="col-8">
                  {/* todo  daca nu o am si vine direct din browser */}
                  <EditRubric rubricId={id} history={this.props.history} ref={this.editPanelComponent}></EditRubric>
                </div>
              </div>
            </div>
          </DragDropContext>
        </div>
      </div>
    );
  }
  onDragStart = (info) => {
    if (this.editPanelComponent.current != null) {
      this.editPanelComponent.current.onDragStart(info);
    }
  };
  onDragEnd = (result) => {
    if (this.editPanelComponent.current != null) {
      this.editPanelComponent.current.onDragEnd(result);
    }
  };
}

const mapStateToProps = (state: IRootState) => {
  console.log('OneRubricPanel mapStateToProps ', state);
  return {
    authentication: state.authentication,
    disfluenciesLoaded: state.settings.disfluencies && true,
    globalRubricsLoaded: state.settings.globalRubricsMap && true,
    dataGroupsLoaded:
      state.settings.allFaceCategories &&
      state.settings.allVoiceCategories &&
      Object.keys(state.settings.allFaceCategories).length > 0 &&
      Object.keys(state.settings.allVoiceCategories).length > 0,
  };
};

const mapDispatchToProps = {
  getDisfluencies,
  getDatagroups,
  getRubrics,
  getGlobalRubrics,
};
const connector = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true });
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(OneRubricPanel);
