import { successNotification } from '../../../utils/utils';
import { ISelectableItem } from '../../components/shared/BorderedSelector';
import { StreamCategories } from '../../model/ApiTypes';
import { StreamRef } from '../../model/Video';
import { logErrorResponse } from '../figaroApiActions';
import {
  AnalysisOptionsDrawerActionTypes,
  ChartsTabsAction,
  FaceAnalysisPersonsSelectedAction,
  FACEANALYSIS_PERSONS_SELECTED,
  FaceStreamsSelectedAction,
  FaceThresholdAction,
  FACE_ANALYSIS_OPTIONS_DRAWER,
  FACE_CATEGORIES_AVAILABLE,
  FACE_STREAMS_SELECTED,
  FACE_THRESHOLD,
  GraphicsSideTabsAction,
  Image4ReportAction,
  IMAGE_4_REPORT_ADDED,
  LegendTabsAction,
  PdfReportAction,
  ReportDrawerAction,
  REPORT_DRAWER,
  REPORT_PDF,
  SELECTED_CARDS_FILTERING_SORTING,
  SELECTED_CHARTS_TAB,
  SELECTED_GRAPHICS_SIDE_TAB,
  SELECTED_LEGEND_TAB,
  SELECTED_TEXT_SIDE_TAB,
  SelectionsActionTypes,
  StreamCategoriesActionTypes,
  TextSideTabsAction,
  VoiceStreamsSelectedAction,
  VoiceThresholdAction,
  VOICE_ANALYSIS_OPTIONS_DRAWER,
  VOICE_CATEGORIES_AVAILABLE,
  VOICE_STREAMS_SELECTED,
  VOICE_THRESHOLD,
} from './types';

export const addImage = (name: string, url: string) => async (dispatch: (action: Image4ReportAction) => void) => {
  try {
    dispatch({
      type: IMAGE_4_REPORT_ADDED,
      payload: {
        name,
        url,
      },
    });
    successNotification(`Image ${name} added!`);
  } catch (err) {
    logErrorResponse(err, 'addImage failed ');
  }
};

export const setVoiceThreshold = (threshold: number) => async (dispatch: (action: VoiceThresholdAction) => void) => {
  try {
    dispatch({
      type: VOICE_THRESHOLD,
      payload: { threshold },
    });
  } catch (err) {
    console.error(err);
  }
};

export const setFaceThreshold = (threshold: number) => async (dispatch: (action: FaceThresholdAction) => void) => {
  try {
    dispatch({
      type: FACE_THRESHOLD,
      payload: { threshold },
    });
  } catch (err) {
    console.error(err);
  }
};

/** null array means ignore = do not change,  empty array means clear all*/
export const selectedFaceStreams =
  (selectedStreamsFace: StreamRef[], selectedFakeStreams: string[]) => async (dispatch: (action: FaceStreamsSelectedAction) => void) => {
    try {
      dispatch({
        type: FACE_STREAMS_SELECTED,
        payload: { selectedStreamsFace, selectedFakeStreams },
      });
    } catch (err) {
      console.error(err);
    }
  };

/** null array means ignore = do not change,  empty array means clear all*/
export const selectedVoiceStreams =
  (selectedStreamsVoice: StreamRef[], selectedFakeStreams: string[]) => async (dispatch: (action: VoiceStreamsSelectedAction) => void) => {
    try {
      dispatch({
        type: VOICE_STREAMS_SELECTED,
        payload: { selectedStreamsVoice, selectedFakeStreams },
      });
    } catch (err) {
      console.error(err);
    }
  };

export const selectedFaceAnalysisPersons =
  (selectedPersons: string[]) => async (dispatch: (action: FaceAnalysisPersonsSelectedAction) => void) => {
    try {
      dispatch({
        type: FACEANALYSIS_PERSONS_SELECTED,
        payload: { selectedPersons },
      });
    } catch (err) {
      console.error(err);
    }
  };
export const availableFaceCategories =
  (availableFaceCategories: StreamCategories) => async (dispatch: (action: StreamCategoriesActionTypes) => void) => {
    try {
      dispatch({
        type: FACE_CATEGORIES_AVAILABLE,
        payload: { availableFaceCategories },
      });
    } catch (err) {
      console.error(err);
    }
  };
export const availableVoiceCategories =
  (availableVoiceCategories: StreamCategories) => async (dispatch: (action: StreamCategoriesActionTypes) => void) => {
    try {
      dispatch({
        type: VOICE_CATEGORIES_AVAILABLE,
        payload: { availableVoiceCategories },
      });
    } catch (err) {
      console.error(err);
    }
  };
export const setPDFReport = (pdfDoc: any) => async (dispatch: (action: PdfReportAction) => void) => {
  try {
    dispatch({
      type: REPORT_PDF,
      payload: { pdfDoc },
    });
  } catch (err) {
    console.error(err);
  }
};

export const setReportDrawerVisibility = (visible: boolean) => async (dispatch: (action: ReportDrawerAction) => void) => {
  try {
    dispatch({
      type: REPORT_DRAWER,
      payload: { reportDrawerOpened: visible },
    });
  } catch (err) {
    console.error(err);
  }
};

export const setVoiceAnalysisDrawerVisibility =
  (visible: boolean) => async (dispatch: (action: AnalysisOptionsDrawerActionTypes) => void) => {
    try {
      dispatch({
        type: VOICE_ANALYSIS_OPTIONS_DRAWER,
        payload: { voiceAnalysisOptionsDrawerOpened: visible },
      });
    } catch (err) {
      console.error(err);
    }
  };
export const setFaceAnalysisDrawerVisibility =
  (visible: boolean) => async (dispatch: (action: AnalysisOptionsDrawerActionTypes) => void) => {
    try {
      dispatch({
        type: FACE_ANALYSIS_OPTIONS_DRAWER,
        payload: { faceAnalysisOptionsDrawerOpened: visible },
      });
    } catch (err) {
      console.error(err);
    }
  };
export const setSelectedTextSideTab = (index: number) => async (dispatch: (action: TextSideTabsAction) => void) => {
  try {
    dispatch({
      type: SELECTED_TEXT_SIDE_TAB,
      payload: { selectedTabIdx: index },
    });
  } catch (err) {
    console.error(err);
  }
};
export const setSelectedGraphicsSideTab = (index: number) => async (dispatch: (action: GraphicsSideTabsAction) => void) => {
  try {
    dispatch({
      type: SELECTED_GRAPHICS_SIDE_TAB,
      payload: { selectedTabIdx: index },
    });
  } catch (err) {
    console.error(err);
  }
};
export const setSelectedLegendTab = (index: number) => async (dispatch: (action: LegendTabsAction) => void) => {
  try {
    dispatch({
      type: SELECTED_LEGEND_TAB,
      payload: { selectedTabIdx: index },
    });
  } catch (err) {
    console.error(err);
  }
};

export const setSelectedChartsTab = (index: number) => async (dispatch: (action: ChartsTabsAction) => void) => {
  try {
    dispatch({
      type: SELECTED_CHARTS_TAB,
      payload: { selectedTabIdx: index },
    });
  } catch (err) {
    console.error(err);
  }
};
export const setSelectedCardsFiltersSorting =
  (filtersState: { State: string[]; Coaches: string[]; Presenter: string[] }, sortByItems: ISelectableItem[]) =>
  async (dispatch: (action: SelectionsActionTypes) => void) => {
    try {
      dispatch({
        type: SELECTED_CARDS_FILTERING_SORTING,
        payload: { filtersState, sortByItems },
      });
    } catch (err) {
      console.error(err);
    }
  };
