import { Grid, Icon, Menu, MenuItem } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import IconZoomIn from '@material-ui/icons/ZoomIn';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { createSelector } from 'reselect';
import { seekTimeWindow } from '../../../actions/utils';
import { IRootState } from '../../../shared/reducers';
import { addHighlight } from '../../actions/figaroApiActions';
import { IVideoPlayer } from '../../components/video/VideoRenderer';
import config from '../../config/constants';
import {
  computeThresholdExceededProp,
  computeUtterancesProps,
  Datum3,
  Highlight,
  isRTLLanguage,
  NO_LANGUAGE,
  StreamRef,
  TimeWindow,
  toStringId,
  UterranceProps,
} from '../../model/Video';
import { generateFakeId } from '../../utils/utils';
import EditHLName from '../highlight/EditHLName';
import TabsComponent from '../tabs/TabsComponent';
import Chunk from './Chunk';
import SearchText, { ISearchState } from './SearchText';
import AUtterance from './Utterance';

interface IProps extends PropsFromRedux {
  theVideoPlayer: IVideoPlayer;
  // videoId: number;
}

interface IState {
  /** X where should the menu appear */
  mouseX: number;
  /** Y where should the menu appear */
  mouseY: number;
  /** start and end time of the transcript parts currently selected with the mouse/keyboard */
  selection: { start: number; end: number; text: string; allText: string };
  /** true if transcript auto-scroll (auto scrolling to the playing moment) is enabled */
  autoscroll: boolean;
  /** true if we should render different background for the moments  included in currentVideo.Highlights */
  showHighlights: boolean;
  /** true if we should render different border/background for the moments  when configured data stream exceeds configured threshold value */
  showExceeded: boolean;

  found: boolean[]; // for each utternace, boolean flag set if last search found [a part of] the utterance
  foundIndices: number[]; // indices in fullText where the searched term was found
  foundUtterances: number[][]; // indexes of searched&found utterances, one array per match (one match can span over multiple consecutive utterances)
  crtFoundIdx: number; // the crt navigation pos in foundUtterances
  term: string; // the term to be passed to the SearchText component, for displaying
  contextMenuOpened: boolean;

  showSetHLNameDlg: boolean;
  hlName: string;
}

export const enhancedIndexOf = (sourceStr: string, searchStr: string) => {
  // console.log(`searching "${makeWholeWordRegexMultiple(searchStr)}`);
  return [...sourceStr.matchAll(makeRegex(searchStr))].map((a: RegExpMatchArray, idx) => {
    console.log(a[0], a.index, idx);
    return { index: a.index, term: a[0] };
  });
};

// const makeWholeWordRegexSingle = (word: string) => {
//   return `\\b${word}\\b`;
// };

// const addBetweenWB = '(\\.|,|\\!|\\?|;)? (_ )?';
//test with https://regexr.com/
// const makeWholeWordRegexMultipleWithWordBoundaries = (word: string) => {
//   const words = word.trim().split(' ');
//   let final = '';
//   words.forEach((w) => (final += `\\b${w}\\b${addBetweenWB}`));
//   final = final.substring(0, final.length - addBetweenWB.length);
//   console.log(` final:"${final}"`);
//   return final;
// };
//test with https://regexr.com/

const makeRegex = (expr: string) => {
  let rez = new RegExp('(?=x)(?!x)', 'g'); //no match
  if (expr && expr.match(/^("|').*("|')$/g)) {
    //quoted: unquote then match exactly
    let exp = expr.substr(1, expr.length - 1);
    exp = exp.substr(0, exp.length - 1);
    console.log('unquoted term ', exp);
    try {
      rez = new RegExp(exp, 'gu');
    } catch (err) {
      console.log(err);
    }
    return rez;
  } else {
    //unquoted: search as whole words sequence (optionally having punctuation in between words), case insesitive , but escape the regex special chars
    try {
      rez = new RegExp(makeWholeWordRegexMultiple(escapeRegexp(expr)), 'giu');
    } catch (err) {
      console.log(err);
    }
    return rez;
  }
};

function escapeRegexp(text) {
  return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

const addPrefix = '(?<=(^| ))';
const addBetween = '(\\p{P})? (_ )?';
const addSuffix = '(?=($|(\\p{P})? ))';
const makeWholeWordRegexMultiple = (word: string) => {
  const words = word.trim().split(' ');
  let final = addPrefix;
  words.forEach((w) => (final += `(${w})${addBetween}`));
  final = final.substring(0, final.length - addBetween.length) + addSuffix;
  console.log(` final:"${final}"`);
  return final;
};

// //todo ffs
// const buildPunctuation = (word: string) => {
//   let punctuationRegexp = '';
//   let signs2Escape = ['.', '?', '!', '\\'];
//   config.transcript.punctuation.forEach((sign) => (punctuationRegexp += signs2Escape.includes(sign) ? `\\${sign}|` : `${sign}|`));
// };

class Transcript extends React.PureComponent<IProps, IState> {
  static ID_LABEL = 'Transcript_';
  stats = { updates: 0, totalUttRenderTime: 0, lastUttRenderTime: 0 };
  crtGridRef = null;
  anchorEl = null;
  constructor(props: Readonly<IProps>) {
    super(props);
    this.state = {
      mouseX: null,
      mouseY: null,
      selection: null,
      autoscroll: config.transcript.autoscroll,
      showHighlights: config.highlights.showHighlightsOnTranscript,
      showExceeded: config.trigger.showExceeded,
      found: [],
      crtFoundIdx: -1,
      foundIndices: [],
      foundUtterances: [],
      term: null,
      contextMenuOpened: false,

      showSetHLNameDlg: false,
      hlName: null,
    };
  }

  handleContextMenu = (e) => {
    e.preventDefault();
    this.setState({ contextMenuOpened: true, mouseX: e.clientX - 2, mouseY: e.clientY - 2 });
  };

  componentDidMount() {
    // console.log('transcript did mount');

    this.clearSearch();
    // console.log('transcript did mount - after clear search');
    //this.forceUpdate();
    // document.addEventListener('copy', this.onDocCopy);
  }
  onDocCopy = (ev) => {
    // ev.clipboardData.setData('text/plain', this.props.fullTextInfo ? this.props.fullTextInfo.fullText : '');
    ev.clipboardData.setData('text/plain', this.state.selection ? this.state.selection.allText : '');
    ev.preventDefault();
  };

  componentDidUpdate(prevProps, prevState) {
    if (!this.crtGridRef) return;
    const sel = window.getSelection();
    let scrollIdx = null;
    let causedBySearch = false;
    if (this.state.crtFoundIdx !== prevState.crtFoundIdx && this.state.crtFoundIdx >= 0) {
      scrollIdx = this.state.foundUtterances[this.state.crtFoundIdx][0];
      //console.log('scroll: found changed, Returned ', scrollIdx);
      causedBySearch = true;
    } else if (
      this.state.autoscroll &&
      !this.state.selection &&
      !(sel && sel.type === 'Range') &&
      prevProps.curentTime !== this.props.curentTime
    ) {
      scrollIdx = this.getFirstOfCurrentSecond();
      //console.log('scroll: getFirstOfCurrentSecond() Returned ', scrollIdx);
    }
    if (!scrollIdx) {
      return;
    }
    const selector = this.utteranceIdToElementId(scrollIdx);
    //console.log(`should scroll, to show the ${scrollIdx}th item, to element id ${selector}`);
    const node = this.crtGridRef.querySelector(`#${selector}`);
    //console.log('node scroll', node);
    if (node) {
      let nodeToScrollTo: Element = null;
      if (node.length > 1) {
        nodeToScrollTo = node[0];
      } else {
        nodeToScrollTo = node;
      }
      nodeToScrollTo.scrollIntoView({
        block: causedBySearch ? 'nearest' : 'center',
      });
    }
  }

  onContainerMouseUp = (event: React.MouseEvent) => {
    console.log('container on mouse up  ', event.clientX, event.clientY, event);
    // const selection = window.getSelection();
    // selection && console.log('type ', selection.type, 'anchor ', selection.anchorNode, 'focus', selection.focusNode);
    this.updatePosSelectionState(event.clientX, event.clientY);
  };
  onContainerSelect = (event) => {
    console.log('onContainer Select ', event);
  };
  onMenuClose = (event: React.SyntheticEvent) => {
    this.resetPosSelectionState();
  };

  // this is a workaround for Firefox: Firefox looses window.selection when the menu/modal opens;
  // in order to fix this, we just save the selection when the menu is about to open, and restore it after the menu opened
  // AND it needs autoFocus=false on Menu
  savedRange = null;
  onMenuOpen = (event) => {
    let selection = window.getSelection();
    // console.log('on menu open, selection ' + selection);
    if (selection && selection.type === 'Range') {
      this.savedRange = window.getSelection().getRangeAt(0);
    } else {
      this.savedRange = null;
    }
  };
  onMenuEntered = (event) => {
    let selection = window.getSelection();
    // console.log('on menu entered, selection ' + selection + '; saved selection : ' + this.savedRange);
    if (this.savedRange && (!selection || selection.type !== 'Range')) {
      selection.addRange(this.savedRange);
    }
  };

  onMenuToggleAutoscroll = (event: React.MouseEvent) => {
    this.toggleAutoscrollState();
  };
  onMenuToggleShowHighlights = (event: React.MouseEvent) => {
    this.toggleShowHighlightsState();
  };
  onMenuToggleShowExceeded = (event: React.MouseEvent) => {
    this.toggleShowExceededState();
  };
  onMenuCopyAll = () => {
    this.copyToClipboard(this.props.fullTextInfo ? this.props.fullTextInfo.fullText : '');
  };
  onMenuCopySelected = () => {
    this.copyToClipboard(this.state.selection ? this.state.selection.allText : '');
  };
  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
    this.setState({
      mouseX: null,
      mouseY: null,
    });
  }
  onMenuSearch = (event: React.MouseEvent) => {
    const term = this.state.selection.allText;
    this.setState({ term });
    window.getSelection().empty();
    this.resetPosSelectionState();
    this.handleSearch({
      term,
    });
  };

  renderSetHLNameDlg = () => {
    if (this.state.showSetHLNameDlg) {
      return (
        <EditHLName
          dialogTitle="Enter the Heading for the new highlight"
          okTitle="Add highlight"
          cancelTitle="Cancel"
          onClose={this.onCloseSetHlNameDlg}
          textPlaceholder="HEADING"
        />
      );
    }
  };

  onCloseSetHlNameDlg = (heading: string) => {
    this.setState({ showSetHLNameDlg: false, hlName: heading }, () => {
      if (heading) {
        this.doAddHighlight(null);
      } else {
        this.onMenuClose(null);
      }
    });
  };

  onMenuAddHighlight = (event: React.MouseEvent) => {
    this.setState({ showSetHLNameDlg: true });
  };

  doAddHighlight = (event: React.MouseEvent) => {
    // console.log('adding  highlight w: ', this.state.selection);
    const newHighlight: Highlight = {
      id: generateFakeId(),
      startTime: Math.min(this.state.selection.start, this.state.selection.end),
      endTime: Math.max(this.state.selection.start, this.state.selection.end),
      text: this.state.selection.text,
      privacy: config.highlights.getDefaultPrivacy().key,
      userID: this.props.currentUser.username,
      //name: 'Highlight made via Transcript ',
      name: this.state.hlName,
      voiceThreshold: this.props.voiceThreshold,
      faceThreshold: this.props.faceThreshold,
      selectedStreamsVoice: this.props.voiceSelections,
      selectedStreamsFace: this.props.faceSelections,
    };
    this.props.addHighlight(toStringId(this.props.selectedVideoId), newHighlight, this.props.currentToken);
    window.getSelection().empty();
    this.resetPosSelectionState();
    // this.seekTimeWindow(newHighlight);
  };
  onMenuFocus = (event: React.MouseEvent) => {
    // console.log('focusing  selection w: ', this.state.selection);
    this.setState({
      mouseX: null,
      mouseY: null,
    });
    this.seekTimeWindow({ startTime: this.state.selection.start, endTime: this.state.selection.end });
  };

  // seek the player at start time, inform Graphs/etc about the time origin and span
  seekTimeWindow = (timeWindow: TimeWindow) => {
    seekTimeWindow(timeWindow, this.props.theVideoPlayer);
  };
  onLinkClick = (event: React.MouseEvent) => {
    console.log('link on click anchor ', event);
    // event.preventDefault();
    this.props.theVideoPlayer.seek(this.utteranceId2StartTime(this.elementIdToUtteranceId(event.currentTarget.id)));
  };
  onLinkDoubleClick = (event: React.MouseEvent) => {
    console.log('link on double click anchor ', event);
    // event.preventDefault();
    // this.props.theVideoPlayer.pause();
    // this.props.theVideoPlayer.seek(this.utteranceId2StartTime(this.elementIdToUtteranceId(event.currentTarget.id)));
  };
  onLinkMouseUp = (event: React.MouseEvent) => {
    console.log('link on mouse up  ', event.clientX, event.clientY, event);
    const selection = window.getSelection();
    if (selection && selection.type === 'Range') {
      console.log(' range selection, let container handle it');
    } else {
      // console.log(' NOT range selection, do NOT let container handle it');
      event.preventDefault();
      event.stopPropagation();
    }
  };
  handleSearch = (form: ISearchState) => {
    console.log('searching ', form.term, ' in: ', this.props.fullTextInfo.fullText);
    const term = form.term;
    const newFoundState = [];
    let finalFound = [];
    let allFoundUtterances = [];
    if (this.props.uterrancesEnhanced.length > 0) {
      let foundIndices = enhancedIndexOf(this.props.fullTextInfo.fullText, term);
      console.log('foundIndices ', foundIndices);
      for (let a = 0; a < this.props.uterrancesEnhanced.length; a++) {
        newFoundState.push(false);
      }
      let matchingUtts = [];
      for (let i = 0; i < foundIndices.length; i++) {
        matchingUtts = [];
        for (let u = 0; u < this.props.uterrancesEnhanced.length; u++) {
          if (
            this.areOverlapping(
              this.props.fullTextInfo.fullTextIndices[u].start,
              this.props.fullTextInfo.fullTextIndices[u].end,
              foundIndices[i].index,
              foundIndices[i].term.length
            )
          ) {
            matchingUtts.push(u);
            newFoundState[u] = true;
          }
        }
        allFoundUtterances.push(matchingUtts);
      }
      finalFound = foundIndices;
    }
    this.setState({
      found: newFoundState,
      foundIndices: finalFound,
      crtFoundIdx: finalFound.length > 0 ? 0 : -1,
      foundUtterances: allFoundUtterances,
    });
  };
  onPrevious = () => {
    console.log('on previous ');
    const crt = this.state.crtFoundIdx;
    if (crt > 0) {
      this.setState({ crtFoundIdx: crt - 1 });
    }
  };
  onNext = () => {
    console.log('on next ');
    const crt = this.state.crtFoundIdx;
    if (crt < this.state.foundIndices.length - 1) this.setState({ crtFoundIdx: crt + 1 });
  };

  clearSearch2 = (newTerm, callback: any) => {
    // console.log('Transcript clearing search2 ');
    const newFoundState = [];
    for (let i = 0; i < this.props.uterrancesEnhanced.length; i++) {
      newFoundState.push(false);
    }
    this.setState({ found: newFoundState, crtFoundIdx: -1, foundIndices: [], term: null, foundUtterances: [] }, () => callback(newTerm));
  };

  clearSearch = () => {
    // console.log('Transcript clearing search ');
    const newFoundState = [];
    for (let i = 0; i < this.props.uterrancesEnhanced.length; i++) {
      newFoundState.push(false);
    }
    this.setState({ found: newFoundState, crtFoundIdx: -1, foundIndices: [], term: null, foundUtterances: [] });
  };

  // clearSearch = (callback?: any) => {
  //   console.log('FLUENCY clearing search ');
  //   const newFoundState = [];
  //   for (let i = 0; i < this.props.uterrancesEnhanced.length; i++) {
  //     newFoundState.push(false);
  //   }
  //   if (!callback) {
  //     this.setState({ found: newFoundState, crtFoundIdx: -1, foundIndices: [], term: null, foundUtterances: [] });
  //   } else {
  //     this.setState({ found: newFoundState, crtFoundIdx: -1, foundIndices: [], term: null, foundUtterances: [] }, callback);
  //   }
  // };

  areOverlapping(utteranceStart: number, utteranceEnd: number, termStart: number, termLength: number) {
    const r =
      (termStart <= utteranceStart && termStart + termLength - 1 >= utteranceStart) ||
      (termStart < utteranceEnd && termStart + termLength - 1 > utteranceEnd) ||
      (termStart >= utteranceStart && termStart + termLength - 1 <= utteranceEnd);
    // console.log(
    //   'utterance start: ',
    //   utteranceStart,
    //   ' end: ',
    //   utteranceEnd,
    //   r ? ' does ' : 'doesn;t ',
    //   ' overlap with string at termStart: ',
    //   termStart,
    //   ' termLength: ',
    //   termLength
    // );
    return r;
  }
  /**
   * Check if the utterance given by its start/end position in the fullText overlaps partially/totally with a searched term found at given termIndices
   * @param slot the time span
   * @param video the video
   */
  utteranceOverlaps(utteranceStart: number, utteranceEnd: number, termIndices: number[], termLength: number) {
    const overlapsThatUtterance = (index: number) => {
      const r =
        (index <= utteranceStart && index + termLength - 1 >= utteranceStart) ||
        (index < utteranceEnd && index + termLength - 1 > utteranceEnd) ||
        (index >= utteranceStart && index + termLength - 1 <= utteranceEnd);
      // console.log(
      //   'utterance start: ',
      //   utteranceStart,
      //   ' end: ',
      //   utteranceEnd,
      //   r ? ' does ' : 'doesn;t ',
      //   ' overlap with string at index: ',
      //   index,
      //   ' termLength: ',
      //   termLength
      // );
      return r;
    };
    const rez = termIndices && termIndices.length > 0 && termIndices.some(overlapsThatUtterance, termLength);
    return rez;
  }

  static HEIGHT_TRANSCRIPT_STATS = 31;
  render() {
    console.log('Transcript render ', this.props);
    if (!this.props.selectedVideoId) {
      this.resetStats();
      return <div />;
    }
    let transcriptBoxStyle = {} as any;
    if (this.props.playerHeight) {
      transcriptBoxStyle.height = this.computePanelHeight(this.props.playerHeight); //+ 'px';
    }
    return (
      <div className="row fig-direction-column" onSelect={this.onContainerSelect} onContextMenu={this.handleContextMenu}>
        <SearchText
          handleFormSubmit={this.handleSearch}
          clearSearch={this.clearSearch}
          clearSearch2={this.clearSearch2}
          onNext={this.onNext}
          onPrevious={this.onPrevious}
          crtPos={this.state.crtFoundIdx}
          counter={this.state.foundIndices.length}
          externalTerm={this.state.term}
        />
        <div
          className="col fig-transcript-box"
          ref={(e) => {
            this.anchorEl = e;
          }}
        >
          <div className="fig-text-container" style={transcriptBoxStyle}>
            {this.renderUtterance()}
          </div>
        </div>
        {this.renderMenu()}
        {this.renderSetHLNameDlg()}
      </div>
    );
  }

  computePanelHeight = (videoHeight: number) => {
    //TODO FFS only if player is docked, otherwise consider videoHeight=0
    //console.log('compute transcript height for video=', videoHeight);

    // return `calc(100vh - ${videoHeight}px - 30px - 41px - 72px - 55px)`;
    const rez = window.innerHeight - videoHeight - 30 - 41 - 72 - 55 - Transcript.HEIGHT_TRANSCRIPT_STATS;
    //console.log('compute TranscriptTab height for videoH=', videoHeight, ' rez = ', rez, 'window: ', window.innerHeight);
    return rez > 0 ? rez : 0;
  };
  renderUtterance() {
    const renderStart = new Date().getTime();
    const rez = (
      <Grid
        container
        onMouseUp={this.onContainerMouseUp}
        ref={(e) => {
          this.crtGridRef = e;
        }}
        style={{
          flexDirection: isRTLLanguage(this.props.selectedVideoId, this.props.language, config) ? 'row-reverse' : 'row',
        }}
      >
        {!config.transcript.useChunks ? this.renderDirectly() : this.renderWithChunks()}
      </Grid>
    );

    const renderEnd = new Date().getTime();
    this.updateStats(renderEnd - renderStart);
    return rez;
  }

  // TODO adjust Search/Copy etc
  renderDirectly() {
    const rez = this.props.uterrancesEnhanced.map((utterance: UterranceProps, idx) => {
      const isCurrent = this.isACurrentUtterance(utterance.data);
      const childProps = { ...this.props.uterrancesEnhanced[idx] }; // TODO FFS WHY NOT utterance ?
      childProps.exceedsThreshold = this.props.thresholdExceeded[idx];
      childProps.found = this.state.found[idx]; // overiden
      childProps.foundAndSeeked = this.state.crtFoundIdx >= 0 ? this.state.foundUtterances[this.state.crtFoundIdx].includes(idx) : false; // overiden
      if (!this.state.showHighlights) {
        childProps.highlighted = false;
      }
      if (!this.state.showExceeded) {
        childProps.exceedsThreshold = false;
      }
      const { onLinkClick, onLinkDoubleClick, onLinkMouseUp, utteranceIdToElementId } = this;

      // eslint-disable-next-line no-constant-condition
      return true /* childProps.isntGap */ ? (
        <AUtterance
          key={idx}
          theVideoPlayer={this.props.theVideoPlayer}
          {...childProps}
          isPlaying={isCurrent}
          idx={idx}
          onLinkClick={onLinkClick}
          onLinkDoubleClick={onLinkDoubleClick}
          onLinkMouseUp={onLinkMouseUp}
          utteranceIdToElementId={utteranceIdToElementId}
        ></AUtterance>
      ) : null;
    });
    return rez;
  }
  notFound = [];
  renderWithChunks() {
    const chunks = Math.ceil(this.props.uterrancesEnhanced.length / config.transcript.chunkLength);
    return Array.from(Array(chunks).keys()).map((chunkIdx) => {
      const startIdx = chunkIdx * config.transcript.chunkLength;
      const endIdx = Math.min((chunkIdx + 1) * config.transcript.chunkLength, this.props.uterrancesEnhanced.length) - 1;
      return (
        <Chunk
          key={chunkIdx}
          idx={chunkIdx}
          start={startIdx}
          end={endIdx}
          data={this.props.uterrancesEnhanced}
          foundTermInfo={this.state.found}
          thresholdExceeded={this.props.thresholdExceeded}
          currentTime={this.isChunkCurrent(startIdx, endIdx) ? this.props.curentTime : -1} // so that only the current and previously current chunk will re-render
          showHighlights={this.state.showHighlights}
          showExceeded={this.state.showExceeded}
          currentFound={
            this.chunkIncludesCurrentFoundUtterances(startIdx, endIdx) ? this.state.foundUtterances[this.state.crtFoundIdx] : this.notFound
          } // so that only the found and previously/next found chunk will re-render
          theVideoPlayer={this.props.theVideoPlayer}
          onLinkClick={this.onLinkClick}
          onLinkDoubleClick={this.onLinkDoubleClick}
          onLinkMouseUp={this.onLinkMouseUp}
          utteranceIdToElementId={this.utteranceIdToElementId}
          isCurrentUtterance={this.isACurrentUtterance}
        />
      );
    });
  }

  renderMenu() {
    const menuItemStyle = {
      dense: true,
      style: { color: config.theme.getPopupMenuColor() },
    };
    return this.state.selection || this.state.contextMenuOpened ? (
      <Menu
        keepMounted
        open={this.state.mouseY !== null}
        onClose={this.onMenuClose}
        onEnter={this.onMenuOpen}
        onEntered={this.onMenuEntered}
        anchorReference="anchorPosition"
        autoFocus={false}
        anchorPosition={
          this.state.mouseY !== null && this.state.mouseX !== null ? { top: this.state.mouseY, left: this.state.mouseX } : undefined
          // { top: 30, left: 30 }
        }
      >
        {this.state.selection ? (
          <MenuItem onClick={this.onMenuAddHighlight} {...menuItemStyle}>
            <Icon>add</Icon>Highlight
          </MenuItem>
        ) : null}
        {this.state.selection ? (
          <MenuItem onClick={this.onMenuFocus} {...menuItemStyle} divider>
            <IconZoomIn />
            Focus
          </MenuItem>
        ) : null}
        {this.state.selection ? (
          <MenuItem onClick={this.onMenuSearch} {...menuItemStyle}>
            <FindReplaceIcon />
            Search
          </MenuItem>
        ) : null}
        {this.state.selection ? (
          <MenuItem onClick={this.onMenuCopySelected} {...menuItemStyle}>
            <FileCopyIcon />
            Copy
          </MenuItem>
        ) : null}

        <MenuItem onClick={this.onMenuCopyAll} {...menuItemStyle} divider>
          <FileCopyIcon />
          Copy All
        </MenuItem>

        <MenuItem onClick={this.onMenuToggleAutoscroll} {...menuItemStyle}>
          {this.state.autoscroll ? <Icon>block</Icon> : <Icon>check</Icon>} Autoscroll
        </MenuItem>

        <MenuItem onClick={this.onMenuToggleShowHighlights} {...menuItemStyle}>
          {this.state.showHighlights ? <Icon>block</Icon> : <Icon>check</Icon>} All Highlights
        </MenuItem>

        <MenuItem onClick={this.onMenuToggleShowExceeded} {...menuItemStyle}>
          {this.state.showExceeded ? <Icon>block</Icon> : <Icon>check</Icon>} Thresholds
        </MenuItem>
      </Menu>
    ) : null;
  }

  utteranceId2StartTime(idx: number) {
    return this.props.uterrancesEnhanced[idx].data.start;
  }
  utteranceId2EndTime(idx: number) {
    return this.props.uterrancesEnhanced[idx].data.end;
  }

  getInAdvanceActivationDistance() {
    // return config.transcript.renderAtSecond ? 1 : 0.1;
    let rez =
      this.props.videoDuration > config.graph.downSamplingTrigger && this.props.selectedGraphicsSideTab === TabsComponent.TAB_IDX_ANALYSIS
        ? config.transcript.distanceMultiplier * config.transcript.activationDistance
        : config.transcript.activationDistance;
    //console.log('distance', rez);

    return rez;
  }

  isACurrentUtterance = (data: Datum3) => {
    const current = this.props.curentTime;
    if (data.start <= current && data.end > current) {
      return true;
    } else {
      return current <= data.start && data.start - current < this.getInAdvanceActivationDistance();
    }
  };

  utteranceIdToElementId = (nth) => {
    return `${Transcript.ID_LABEL}${nth}`;
  };

  elementIdToUtteranceId = (elementID) => {
    return elementID.replace(Transcript.ID_LABEL, '');
  };

  getIdOfSelectionEndpointNode(node: Node) {
    if (node.nodeType === node.TEXT_NODE) {
      return node.parentElement.id;
    } else if (node.nodeType === node.ELEMENT_NODE) {
      const child: HTMLElement = node.firstChild as HTMLElement;
      if (child.nodeType === node.TEXT_NODE) {
        return (node as HTMLElement).id;
      } // else if (child.nodeType === node.ELEMENT_NODE) {
      //   let child2 = child.firstChild as HTMLElement;
      //   if (child2.nodeType === node.TEXT_NODE) {
      //     return (child as HTMLElement).id;
      //   }
      // }
      return child.id;
    }
  }
  updatePosSelectionState(clientX: number, clientY: number) {
    const selection = window.getSelection();
    console.log('update pos selection state ?', selection);
    console.log('type ', selection.type, 'anchor ', selection.anchorNode, 'focus', selection.focusNode);
    let range = null;
    if (selection && selection.type === 'Range') {
      const id1 = this.getIdOfSelectionEndpointNode(selection.anchorNode);
      const id2 = this.getIdOfSelectionEndpointNode(selection.focusNode);
      let ostart = -1;
      let oend = -1;
      if (id1 && id2) {
        const nid1 = this.elementIdToUtteranceId(id1);
        const nid2 = this.elementIdToUtteranceId(id2);
        ostart = Math.min(nid1, nid2);
        oend = Math.max(nid1, nid2);
        range = {
          start: this.utteranceId2StartTime(ostart),
          end: this.utteranceId2EndTime(oend),
          text:
            this.props.uterrancesEnhanced[ostart].data.text +
            (ostart !== oend ? '...' + this.props.uterrancesEnhanced[oend].data.text : ''),
          allText: this.getTextOfUtterances(ostart, oend),
        };
      }
      console.log(
        'id1/id2, start/end: ',
        id1,
        id2,
        ostart,
        oend,
        this.props.uterrancesEnhanced[ostart].data.text,
        this.props.uterrancesEnhanced[oend].data.text
      );
    }
    console.log(range ? 'mouse up with selection ' + range.start + ':' + range.end : 'mouse up without selection ');
    this.setState({
      mouseX: clientX - 2,
      mouseY: clientY - 4,
      selection: range,
    });
  }
  getTextOfUtterances(startIdx, endIdx) {
    let res = '';
    for (let i = startIdx; i <= endIdx; i++) {
      res += this.props.uterrancesEnhanced[i].data.text + ' ';
    }
    return res;
  }

  resetPosSelectionState() {
    console.log('reset pos selection state');
    this.setState({
      mouseX: null,
      mouseY: null,
      selection: null,
      contextMenuOpened: false,
    });
  }

  toggleAutoscrollState = () => {
    config.transcript.autoscroll = !config.transcript.autoscroll;
    console.log('autoscroll was just set to ' + config.transcript.autoscroll);
    this.setState({
      mouseX: null,
      mouseY: null,
      selection: null,
      autoscroll: config.transcript.autoscroll,
    });
  };
  toggleShowHighlightsState = () => {
    config.highlights.showHighlightsOnTranscript = !config.highlights.showHighlightsOnTranscript;
    console.log('showHighlights was just set to ' + config.highlights.showHighlightsOnTranscript);
    this.setState({
      mouseX: null,
      mouseY: null,
      selection: null,
      showHighlights: config.highlights.showHighlightsOnTranscript,
    });
  };
  toggleShowExceededState = () => {
    config.trigger.showExceeded = !config.trigger.showExceeded;
    console.log('showExceeded was just set to ' + config.trigger.showExceeded);
    this.setState({
      mouseX: null,
      mouseY: null,
      selection: null,
      showExceeded: config.trigger.showExceeded,
    });
  };
  // todo ffs de ce nu imi baga un gap la poz 0 auth ?
  isChunkCurrent(startIdx, endIdx) {
    for (let i = startIdx; i <= endIdx; i++) {
      if (this.isACurrentUtterance(this.props.uterrancesEnhanced[i].data)) {
        return true;
      }
    }
    return false;
  }
  // chunkIncludesCurrentFoundUtterances(startIdx, endIdx) {
  //   // includes crt, prev or next searched
  //   if (
  //     this.state.foundUtterances.length > 0 &&
  //     ((this.state.foundUtterances[this.state.crtFoundIdx] <= endIdx && this.state.foundUtterances[this.state.crtFoundIdx] >= startIdx) ||
  //       (this.state.crtFoundIdx > 0 &&
  //         this.state.foundUtterances[this.state.crtFoundIdx - 1] <= endIdx &&
  //         this.state.foundUtterances[this.state.crtFoundIdx - 1] >= startIdx) ||
  //       (this.state.crtFoundIdx < this.state.foundUtterances.length - 1 &&
  //         this.state.foundUtterances[this.state.crtFoundIdx + 1] <= endIdx &&
  //         this.state.foundUtterances[this.state.crtFoundIdx + 1] >= startIdx))
  //   ) {
  //     return true;
  //   }
  // }

  chunkIncludesCurrentFoundUtterances(startIdx, endIdx) {
    // includes crt, prev or next searched
    if (
      this.state.foundUtterances.length > 0 &&
      (this.chunkIncludesUtterances(startIdx, endIdx, this.state.foundUtterances[this.state.crtFoundIdx]) ||
        (this.state.crtFoundIdx > 0 &&
          this.chunkIncludesUtterances(startIdx, endIdx, this.state.foundUtterances[this.state.crtFoundIdx - 1])) ||
        (this.state.crtFoundIdx < this.state.foundUtterances.length - 1 &&
          this.chunkIncludesUtterances(startIdx, endIdx, this.state.foundUtterances[this.state.crtFoundIdx + 1])))
    ) {
      return true;
    }
  }
  chunkIncludesUtterances(startIdx, endIdx, uttIdxs: number[]) {
    for (let i = 0; i < uttIdxs.length; i++) {
      if (this.chunkIncludesUtterance(startIdx, endIdx, uttIdxs[i])) {
        return true;
      }
    }
  }

  chunkIncludesUtterance(startIdx, endIdx, uttIdx) {
    if (uttIdx <= endIdx && uttIdx >= startIdx) return true;
  }

  getFirstOfCurrentSecond() {
    for (let i = 0; i < this.props.uterrancesEnhanced.length; i++) {
      if (
        true /* this.props.uterrancesEnhanced[i].isntGap */ &&
        Math.floor(this.props.uterrancesEnhanced[i].data.start) === Math.floor(this.props.curentTime)
      ) {
        return i;
      }
      if (
        true /* this.props.uterrancesEnhanced[i].isntGap */ &&
        this.props.curentTime < Math.floor(this.props.uterrancesEnhanced[i].data.start)
      ) {
        return i;
      }
    }
    return null;
  }

  resetStats() {
    this.stats = { updates: 0, totalUttRenderTime: 0, lastUttRenderTime: 0 };
  }
  updateStats(renderDuration) {
    this.stats.lastUttRenderTime = renderDuration;
    this.stats.updates++;
    if (this.stats.updates > 1) {
      this.stats.totalUttRenderTime += this.stats.lastUttRenderTime;
      console.log(
        'stats: lastDurration: ',
        this.stats.lastUttRenderTime,
        ', updates: ',
        this.stats.updates,
        ' average (ms): ',
        this.stats.totalUttRenderTime / (this.stats.updates - 1)
      );
    } else {
      console.log('FIRST TRANSCRIPT Rendering stats: durration: ', this.stats.lastUttRenderTime);
    }
  }
  indexOf = (sourceStr: string, searchStr: string): number[] =>
    [...sourceStr.matchAll(new RegExp(searchStr, 'gi'))].map((a: RegExpMatchArray, idx) => {
      console.log(a, idx);
      return a.index;
    });
}

const selectorCrtVideoHighlights = (state: IRootState) =>
  state.video.videosMap[state.video.currentVideoId] ? state.video.videosMap[state.video.currentVideoId].highlights : null;
const selectorCrtVideoComments = (state: IRootState) =>
  state.video.videosMap[state.video.currentVideoId] ? state.video.videosMap[state.video.currentVideoId].comments : null;
const selectorCrtVideoFaceAnalyses = (state: IRootState) =>
  state.video.videosMap[state.video.currentVideoId] ? state.video.videosMap[state.video.currentVideoId].faceAnalyses : null;
const selectorCrtVideoVoiceAnalyses = (state: IRootState) =>
  state.video.videosMap[state.video.currentVideoId] ? state.video.videosMap[state.video.currentVideoId].voiceAnalyses : null;

const selectorCrtVoiceThreshold = (state: IRootState) => state.selections.voiceThreshold;
const selectorCrtFaceThreshold = (state: IRootState) => state.selections.faceThreshold;
const selectorAppSelectedHL = (state: IRootState) => state.video.currentHighlight;
const selectorSelectedVoiceStreams = (state: IRootState) => state.selections.selectedStreamsVoice;
const selectorSelectedFaceStreams = (state: IRootState) => state.selections.selectedStreamsFace;
const selectorCrtVideoTranscript = (state: IRootState) =>
  state.video.videosMap[state.video.currentVideoId] ? state.video.videosMap[state.video.currentVideoId].transcript : null;

// todo ffs cum fac cu config
const retrieveEnhancedUtterances = createSelector(
  [selectorCrtVideoTranscript, selectorCrtVideoHighlights, selectorCrtVideoComments, selectorAppSelectedHL],
  (transcript, crtVideoHighlights, crtVideoComments, appSelectedHighlight) =>
    computeUtterancesProps(transcript, crtVideoHighlights, crtVideoComments, config, appSelectedHighlight)
);

const retrieveThresholdExceeededInfo = createSelector(
  [
    selectorSelectedVoiceStreams,
    selectorCrtVoiceThreshold,
    selectorSelectedFaceStreams,
    selectorCrtFaceThreshold,
    selectorCrtVideoTranscript,
    selectorCrtVideoFaceAnalyses,
    selectorCrtVideoVoiceAnalyses,
  ],
  (
    selectedVoiceStreams: StreamRef[],
    voiceThreshold: number,
    selectedFaceStreams: StreamRef[],
    faceThreshold: number,
    transcript,
    faceAnalyses,
    voiceAnalyses
  ) =>
    computeThresholdExceededProp(
      selectedVoiceStreams,
      voiceThreshold,
      selectedFaceStreams,
      faceThreshold,
      transcript,
      faceAnalyses,
      voiceAnalyses
    )
);
const retrieveFullTextInfo = createSelector([selectorCrtVideoTranscript], (transcript) => {
  return {
    fullText: transcript ? transcript.fullText : '',
    fullTextIndices: transcript ? transcript.fullTextIndices : [],
  };
});

const retrieveLanguageInfo = (state: IRootState) => {
  const video = state.video.videosMap[state.video.currentVideoId];
  return video && video.transcript && video.transcript.language ? video.transcript.language : NO_LANGUAGE;
};

const mapStateToProps = (state: IRootState) => {
  return {
    language: retrieveLanguageInfo(state),
    selectedVideoId: state.video.currentVideoId,
    uterrancesEnhanced: retrieveEnhancedUtterances(state), // this no longer computes threshold exceeded
    thresholdExceeded: retrieveThresholdExceeededInfo(state),
    curentTime: config.transcript.renderAtSecond ? Math.floor(state.video.currentTime) : state.video.currentTime,
    videoDuration: state.video.videoDuration,
    currentUser: state.authentication.user,
    currentToken: state.authentication.token,
    isPaused: state.video.isPaused,
    fullTextInfo: retrieveFullTextInfo(state),
    voiceThreshold: state.selections.voiceThreshold,
    faceThreshold: state.selections.faceThreshold,
    voiceSelections: state.selections.selectedStreamsVoice,
    faceSelections: state.selections.selectedStreamsFace,
    appSelectedHighlight: selectorAppSelectedHL(state),
    playerHeight: state.utils.playerHeight,
    windowHeight: state.utils.windowHeight,
    selectedGraphicsSideTab: state.selections.selectedGraphicsSideTab,
  };
};
const mapDispatchToProps = {
  addHighlight,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Transcript);
