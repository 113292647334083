import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import { availableFaceCategories, availableVoiceCategories } from '../../actions/selectionActions/';
import CategoryOptions from './CategoryOptions';
import sprites from '../../../../icons/fig-icons.svg';

interface IProps extends PropsFromRedux {
  isFaceAnalysis: boolean;
  toggleDrawer: any;
}

class AnalysisOptions extends React.PureComponent<IProps, any> {
  render() {
    let categories = this.props.isFaceAnalysis ? this.props.allFaceCategories : this.props.allVoiceCategories;
    let title = this.props.isFaceAnalysis ? 'Face Analysis Options' : 'Voice Analysis Options';
    let selectedStreams = this.props.isFaceAnalysis ? this.props.selectedFaceCategories : this.props.selectedVoiceCategories;
    return categories ? (
      <div className="fig-legend-options-pannel">
        <div className="pannel-title-mainbox">
          <div className="pannel-title">
            {title}
          </div>
          <div>
            <Button onClick={this.props.toggleDrawer}>
              <svg className="fig-close-icon" onClick={this.props.toggleDrawer}>
                <use href={sprites + '#close-icon'} />
              </svg>
            </Button>
            
          </div>
        </div>
        
        <Grid container>
          {Object.keys(categories).map((category, idx) => {
            let selectedCategories = selectedStreams && selectedStreams[category] ? selectedStreams[category] : [];
            return (
              <Grid key={category} item>
                <CategoryOptions
                  categoryName={category}
                  allStreams={categories[category]}
                  selectedStreams={selectedCategories}
                  selectedStreamsChanged={this.selectedStreamsChanged}
                ></CategoryOptions>
              </Grid>
            );
          })}
        </Grid>
      </div>
    ) : null;
  }

  selectedStreamsChanged = (category: string, newSelections: string[]) => {
    this.props.isFaceAnalysis
      ? this.props.availableFaceCategories({ ...this.props.selectedFaceCategories, [category]: newSelections })
      : this.props.availableVoiceCategories({ ...this.props.selectedVoiceCategories, [category]: newSelections });
  };
}

const mapStateToProps = (state: IRootState) => {
  // console.log('Analysis options mapStateToProps, new state ', state);
  return {
    allVoiceCategories: state.settings.allVoiceCategories,
    allFaceCategories: state.settings.allFaceCategories,
    selectedVoiceCategories: state.selections.availableVoiceCategories,
    selectedFaceCategories: state.selections.availableFaceCategories,
  };
};
const mapDispatchToProps = { availableFaceCategories, availableVoiceCategories };
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(AnalysisOptions);
