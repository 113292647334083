import { Button, IconButton, List, ListItem, ListItemIcon, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import React, { Fragment } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { connect, ConnectedProps } from 'react-redux';
import sprites from '../../../../icons/fig-icons.svg';
import { IRootState } from '../../../shared/reducers';
import { createRubric, updateRubric } from '../../actions/figaroApiActions';
import config, { getColor } from '../../config/constants';
import { Category, RubricBase } from '../../model/ApiTypes';
import { isGlobalRubric } from '../../reducers/settingsReducer';
import { applyOpacity } from '../../utils/utils';
import { primaryColor } from '../echarts/macarons';
import { ISelectableItem } from '../shared/BorderedSelector';
import { SelectionList } from '../shared/SelectionList';
import {
  DROPPABLE_CONTAINER_ID_EDITED_RUBRIC,
  isTemplateID,
  parseDnDFormattedIds,
  TYPE_DROPPABLE_CATEGORY,
  TYPE_DROPPABLE_QUESTION,
  TYPE_DROPPABLE_RUBRIC,
} from './utils';

interface IProps extends PropsFromRedux {
  history: any;
  rubricId?: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IState {
  rubricName: string;
  categories: Category[];
  isDraggingRubric: boolean;
  // rubricType: string;
  privacyOptions: ISelectableItem[];
}

class EditRubric extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { ...this.getInitialRubricState() };
  }
  getInitialRubricState = () => {
    return {
      rubricName: '',
      rubricType: 'draft',
      categories: [{ name: '', questions: [''] }],
      isDraggingRubric: false,
      privacyOptions: [...config.rubrics.defaultPrivacyItems],
    };
  };
  cloneCategories = (categories: Category[]) => {
    if (!categories) return [];
    const rez = [...categories];
    rez.forEach((category, idx) => {
      rez[idx] = { name: category.name, questions: [...category.questions] };
    });
    return rez;
  };

  componentDidMount = () => {};
  componentWillUnmount = () => {
    console.log('EditRubric will unmount ', this.props);
  };

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.rubrics !== this.props.rubrics) {
      if (this.props.rubrics) {
        if (this.props.rubrics[this.props.rubricId]) {
          let rubricToEdit = this.props.rubrics[this.props.rubricId];
          this.setState({
            rubricName: rubricToEdit.name,
            // rubricType: rubricToEdit.type,
            privacyOptions: config.rubrics.defaultPrivacyItems.map((item) => ({
              ...item,
              selected: item.key === rubricToEdit.type ? true : false,
            })),
            categories: rubricToEdit.template.categories ? this.cloneCategories(rubricToEdit.template.categories) : [],
          });
        } else if (!isNaN(this.props.rubricId)) {
          // ID not found, show list
          this.props.history.push(`/rubrics`);
        } else {
          //new
          this.clearRubricState();
        }
      } else {
        this.clearRubricState();
      }
    }
  }
  clearRubricState() {
    this.setState(this.getInitialRubricState());
  }

  handleNameChange = (event) => {
    this.setState({ rubricName: event.target.value });
  };

  // handleTypeChange = (event) => {
  //   this.setState({ rubricType: event.target.value });
  // };
  handleTypeChangeNew = (privacyOptions: ISelectableItem[]) => {
    this.setState({ privacyOptions });
  };

  getQuestion(rubricId, categoryIdx, questionIdx) {
    let rubrics = isGlobalRubric(rubricId) ? this.props.globalRubrics : this.props.rubrics;
    return !isNaN(rubricId) &&
      !isNaN(categoryIdx) &&
      !isNaN(questionIdx) &&
      rubrics &&
      rubrics[rubricId] &&
      rubrics[rubricId].template &&
      rubrics[rubricId].template.categories &&
      rubrics[rubricId].template.categories.length > categoryIdx &&
      rubrics[rubricId].template.categories[categoryIdx].questions &&
      rubrics[rubricId].template.categories[categoryIdx].questions.length > questionIdx
      ? rubrics[rubricId].template.categories[categoryIdx].questions[questionIdx]
      : null;
  }
  getCategoryClone = (rubricId: number, categoryIdx: number) => {
    let rubrics = isGlobalRubric(rubricId) ? this.props.globalRubrics : this.props.rubrics;
    let cat =
      !isNaN(rubricId) &&
      !isNaN(categoryIdx) &&
      rubrics &&
      rubrics[rubricId] &&
      rubrics[rubricId].template &&
      rubrics[rubricId].template.categories &&
      rubrics[rubricId].template.categories.length > categoryIdx
        ? rubrics[rubricId].template.categories[categoryIdx]
        : null;
    return cat ? { ...cat, questions: [...cat.questions] } : null;
  };

  getRubricCategories = (rubricId: number) => {
    let rubrics = isGlobalRubric(rubricId) ? this.props.globalRubrics : this.props.rubrics;
    return rubrics && rubrics[rubricId] && rubrics[rubricId].template ? rubrics[rubricId].template.categories : null;
  };

  onDragStart = (info) => {
    console.log(`DRAG-n-Drop START: `, info);
    if (info && info.type === TYPE_DROPPABLE_RUBRIC) {
      this.setState({ isDraggingRubric: true });
    }
  };

  onDragEnd = (result) => {
    console.log(`DRAG-n-Drop full result `, result);
    const { destination, source } = result;
    if (!destination || !source) {
      this.setState({ isDraggingRubric: false });
      return;
    }
    console.log(
      `DRAG-n-Drop result: from source.droppableId ${source.droppableId}, source.index: ${source.index} to destination.droppableId ${destination.droppableId} , destination.index: ${destination.index}`
    );

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      this.setState({ isDraggingRubric: false });
      return;
    }

    let newCategories = this.cloneCategories(this.state.categories);

    if (result.type === TYPE_DROPPABLE_CATEGORY || result.type === TYPE_DROPPABLE_RUBRIC) {
      if (isTemplateID(source.droppableId)) {
        // template category or rubric dropped
        let templateIds = parseDnDFormattedIds(result.draggableId);
        if (!isNaN(templateIds.categoryIdx)) {
          console.log('Template Category Dropped');
          let newCategory = this.getCategoryClone(templateIds.rubricId, templateIds.categoryIdx);
          if (newCategory) {
            newCategories.splice(destination.index, 0, newCategory);
            this.setState({ categories: newCategories });
          } else {
            this.setState({ isDraggingRubric: false });
            console.warn('template category does not exist', templateIds.rubricId, templateIds.categoryIdx);
          }
        } else {
          console.log('Template Rubric Dropped');
          let categoriesToInsert = this.cloneCategories(this.getRubricCategories(templateIds.rubricId));
          if (categoriesToInsert) {
            let finalIndex =
              result.type === TYPE_DROPPABLE_CATEGORY
                ? destination.index
                : parseDnDFormattedIds(result.destination.droppableId).categoryIdx;
            newCategories.splice(finalIndex, 0, ...categoriesToInsert);
            this.setState({ categories: newCategories, isDraggingRubric: false });
          } else {
            this.setState({ isDraggingRubric: false });
            console.warn('template category does not exist', templateIds.rubricId, templateIds.categoryIdx);
          }
        }
      } else {
        // own category dropped, move it
        let movedCateg = newCategories[source.index];
        newCategories.splice(source.index, 1);
        newCategories.splice(destination.index, 0, movedCateg);
        this.setState({ categories: newCategories, isDraggingRubric: false });
      }
    } else if (result.type === TYPE_DROPPABLE_QUESTION) {
      if (isTemplateID(source.droppableId)) {
        console.log('Template Question Dropped');
        let templateIds = parseDnDFormattedIds(result.draggableId);
        let templateQuestion = this.getQuestion(templateIds.rubricId, templateIds.categoryIdx, templateIds.questionIdx);
        if (templateQuestion) {
          let destCategoryIdx = parseInt(destination.droppableId, 10);
          newCategories[destCategoryIdx].questions.splice(destination.index, 0, templateQuestion);
          this.setState({ categories: newCategories, isDraggingRubric: false });
        } else {
          console.warn('template question does not exist', templateIds.rubricId, templateIds.categoryIdx, templateIds.questionIdx);
          this.setState({ isDraggingRubric: false });
        }
      } else {
        // own question dropped, move it
        // droppableId={`${idxCategory}`}>
        // draggableId={`${idxCategory}.${idxQuestion}`}
        let sourceCategoryIdx = parseInt(source.droppableId, 10);
        let question = newCategories[sourceCategoryIdx].questions[source.index];
        newCategories[sourceCategoryIdx].questions.splice(source.index, 1);

        let destCategoryIdx = parseInt(destination.droppableId, 10);
        newCategories[destCategoryIdx].questions.splice(destination.index, 0, question);

        // create an empty Q if last Q was dragged (removed) from source categ
        if (newCategories[sourceCategoryIdx].questions.length === 0) {
          newCategories[sourceCategoryIdx].questions = [''];
        }
        this.setState({ categories: newCategories, isDraggingRubric: false });
      }
    }
  };

  render() {
    console.log('EditRubric render ', this.props, this.state);
    const shownCateg = this.state.categories;
    return (
      <div className="fig-rubrics-right-side">
        <div className="fig-rubrics-right-side-title">
          <span>Your New Rubric</span>
        </div>
        <div className="fig-rubrics-right-side-content">
          <form className="row no-gutters" onSubmit={null}>
            <TextField
              className="col-10 fig-rubrics-give-rubric-name"
              label={this.state.rubricName ? null : 'Give your rubric a name'}
              value={this.state.rubricName}
              onChange={this.handleNameChange}
            />
            <div className="col-2 fig-rubrics-select-type">
              <SelectionList listItems={this.state.privacyOptions} selectionChanged={this.handleTypeChangeNew} position={'left'} />
            </div>
          </form>

          <Droppable droppableId={DROPPABLE_CONTAINER_ID_EDITED_RUBRIC} type={TYPE_DROPPABLE_CATEGORY}>
            {(provided1, snapshot1) => {
              return (
                <List
                  component="div"
                  disablePadding
                  style={{
                    overflowY: 'auto',
                    backgroundColor: snapshot1.isDraggingOver ? applyOpacity(getColor(1), 0.1) : 'inherit',
                    transition: 'background-color 0.3s ease',
                  }}
                  innerRef={provided1.innerRef}
                  {...provided1.droppableProps}
                >
                  {shownCateg.map((category, idxCategory) => {
                    return (
                      <Fragment key={`${idxCategory}`}>
                        <Draggable key={`${idxCategory}`} draggableId={`RUBRIC_CATEGORY#${idxCategory}`} index={idxCategory}>
                          {(providedCategory, snapshotCategory) => (
                            <div ref={providedCategory.innerRef} {...providedCategory.draggableProps}>
                              {idxCategory === 0
                                ? this.renderRubricDropTarget(this.props.rubricId ? this.props.rubricId : -1, 0, true, false)
                                : null}
                              <div
                                className="fig-rubrics-category-container"
                                key={'itself'}
                                style={{
                                  backgroundColor: snapshotCategory.isDragging ? applyOpacity('#ffffff', 1) : applyOpacity('#ffffff', 1),
                                  transition: 'border-color 0.2s ease',
                                  borderColor: snapshotCategory.isDragging ? applyOpacity(getColor(1), 1) : 'lightgray',

                                  borderWidth: snapshotCategory.isDragging ? 2 : 1,
                                  margin: snapshotCategory.isDragging ? 10 : 'inherit',
                                }}
                              >
                                <List className="fig-rubrics-category" component="div" disablePadding>
                                  <ListItem className="fig-rubrics-category-item" key="catitem">
                                    <ListItemIcon {...providedCategory.dragHandleProps}>
                                      <DragHandleIcon color={snapshotCategory.isDragging ? 'secondary' : 'inherit'} />
                                    </ListItemIcon>
                                    <TextField
                                      label="CATEGORY NAME"
                                      // InputLabelProps={{
                                      //   shrink: true,
                                      //   required: false,
                                      // }}
                                      value={category.name}
                                      onChange={(event) => this.onChangeCategoryName(idxCategory, event.target.value)}
                                    />
                                    <IconButton
                                      className="fig-button"
                                      aria-label="delete"
                                      size="small"
                                      onClick={() => this.onDeleteCategory(idxCategory)}
                                    >
                                      <svg className="fig-icon-delete">
                                        <use href={sprites + '#delete-icon'} />
                                      </svg>
                                    </IconButton>
                                  </ListItem>
                                </List>
                                <Droppable droppableId={`${idxCategory}`} type={TYPE_DROPPABLE_QUESTION}>
                                  {(providedQuestion, snapshotQuestion) => {
                                    // console.log(
                                    //   'render function for Droppable id: ',
                                    //   idxCategory,
                                    //   ' called with snapshot: ',
                                    //   snapshotQuestion
                                    // );

                                    return (
                                      <>
                                        <List
                                          component="div"
                                          disablePadding
                                          style={{
                                            width: '100%',
                                            backgroundColor: snapshotQuestion.isDraggingOver ? applyOpacity(getColor(1), 0.1) : 'inherit',
                                            transition: 'background-color 0.3s ease',
                                          }}
                                          innerRef={providedQuestion.innerRef}
                                          {...providedQuestion.droppableProps}
                                        >
                                          {category.questions.map((question, idxQuestion) => {
                                            return (
                                              <Fragment key={`${idxCategory}.${idxQuestion}`}>
                                                <Draggable draggableId={`${idxCategory}.${idxQuestion}`} index={idxQuestion}>
                                                  {(provided, snapshot) => {
                                                    return (
                                                      <ListItem
                                                        className="fig-rubrics-category-item"
                                                        key={`${idxCategory}.${idxQuestion}`}
                                                        {...provided.draggableProps}
                                                        innerRef={provided.innerRef}
                                                      >
                                                        <ListItemIcon {...provided.dragHandleProps}>
                                                          <DragHandleIcon color={snapshot.isDragging ? 'secondary' : 'inherit'} />
                                                        </ListItemIcon>
                                                        <TextField
                                                          label={question && question.length > 0 ? 'QUESTION' : 'Type in question'}
                                                          style={{
                                                            backgroundColor: snapshot.isDragging ? applyOpacity(getColor(1), 0.1) : 'white',
                                                            transition: 'border-color 0.2s ease',
                                                            borderColor: snapshot.isDragging ? applyOpacity(getColor(1), 1) : 'lightgray',

                                                            borderWidth: snapshot.isDragging ? 2 : 1,
                                                            marginRight: snapshot.isDragging ? 20 : 20,
                                                          }}
                                                          // InputLabelProps={{
                                                          //   shrink: true,
                                                          //   required: false,
                                                          // }}
                                                          value={question}
                                                          onChange={(event) =>
                                                            this.onChangeQuestion(idxCategory, idxQuestion, event.target.value)
                                                          }
                                                          onKeyPress={(e) => {
                                                            if (e.which === 13 && idxQuestion === category.questions.length - 1) {
                                                              this.onAddEmptyQuestion(idxCategory, idxQuestion);
                                                            }
                                                          }}
                                                        />
                                                        <IconButton
                                                          className="fig-button"
                                                          aria-label="delete"
                                                          size="small"
                                                          onClick={() => {
                                                            idxQuestion === category.questions.length - 1
                                                              ? this.onAddEmptyQuestion(idxCategory, idxQuestion)
                                                              : this.onDeleteQuestion(idxCategory, idxQuestion);
                                                          }}
                                                        >
                                                          {idxQuestion === category.questions.length - 1 ? (
                                                            <AddIcon
                                                              fontSize="small"
                                                              color={snapshot.isDragging ? 'secondary' : 'inherit'}
                                                            />
                                                          ) : (
                                                            <svg className={`fig-icon-delete ${snapshot.isDragging ? 'disabled' : ''}`}>
                                                              <use href={sprites + '#delete-icon'} />
                                                            </svg>
                                                          )}
                                                        </IconButton>
                                                      </ListItem>
                                                    );
                                                  }}
                                                </Draggable>
                                              </Fragment>
                                            );
                                          })}
                                          <Fragment key="placeholder">{providedQuestion.placeholder}</Fragment>
                                        </List>
                                        <Button
                                          className="fig-button rubrics margin-left--20 margin-top--20 margin-bottom--20"
                                          size="small"
                                          onClick={() => {
                                            this.onAddEmptyCategory(idxCategory);
                                          }}
                                          key="buton"
                                        >
                                          Add A Category
                                        </Button>
                                      </>
                                    );
                                  }}
                                </Droppable>
                              </div>
                              {this.renderRubricDropTarget(
                                this.props.rubricId ? this.props.rubricId : -1,
                                idxCategory + 1,
                                false,
                                idxCategory === shownCateg.length - 1
                              )}
                            </div>
                          )}
                        </Draggable>
                      </Fragment>
                    );
                  })}
                  {provided1.placeholder}
                </List>
              );
            }}
          </Droppable>
        </div>
        <div className="fig-rubrics-right-side-buttons">
          <Button className="fig-button in-modal-dialog margin-right--10" size="small" onClick={this.onClearAll}>
            CLEAR ALL
          </Button>
          <Button className="fig-button in-modal-dialog rubric-primary" size="small" onClick={this.onSaveRubric}>
            SAVE RUBRIC
          </Button>
        </div>
      </div>
    );
  }

  //className="fig-button rubric-primary" size="medium"
  onClearAll = () => {
    this.clearRubricState();
  };
  onDeleteQuestion = (idxCategory: number, idxQuestion: number) => {
    const crtCategories = this.state.categories;
    if (crtCategories.length > idxCategory) {
      const crtQuestions = crtCategories[idxCategory].questions;
      if (crtQuestions.length > idxQuestion) {
        this.setState({
          categories: crtCategories.map((category, idx) =>
            idx !== idxCategory
              ? category
              : {
                  ...category,
                  questions: category.questions.length > 1 ? category.questions.filter((question, idx) => idx !== idxQuestion) : [''],
                }
          ),
        });
      }
    }
  };
  onAddEmptyQuestion = (idxCategory: number, idxQuestion: number) => {
    const crtCategories = this.state.categories;
    if (crtCategories.length > idxCategory) {
      let crtQuestions = crtCategories[idxCategory].questions;
      if (crtQuestions.length > idxQuestion) {
        crtQuestions = [...crtQuestions];
        crtQuestions.splice(idxQuestion + 1, 0, '');
        this.setState({
          categories: crtCategories.map((category, idx) => (idx !== idxCategory ? category : { ...category, questions: crtQuestions })),
        });
      }
    }
  };
  onChangeQuestion = (idxCategory: number, idxQuestion: number, value): void => {
    const crtCategories = this.state.categories;
    if (crtCategories.length > idxCategory) {
      const crtQuestions = crtCategories[idxCategory].questions;
      if (crtQuestions.length > idxQuestion) {
        this.setState({
          categories: crtCategories.map((category, idx) =>
            idx !== idxCategory
              ? category
              : { ...category, questions: category.questions.map((question, idx) => (idx !== idxQuestion ? question : value)) }
          ),
        });
      }
    }
  };

  //add after idx
  onAddEmptyCategory = (idxCategory: number) => {
    const crtCategories = [...this.state.categories];
    if (crtCategories.length > idxCategory) {
      crtCategories.splice(idxCategory + 1, 0, { name: '', questions: [''] });
      this.setState({ categories: crtCategories });
    }
  };
  onDeleteCategory = (idxCategory: number): void => {
    const crtCategories = this.state.categories;
    if (crtCategories.length > idxCategory) {
      if (crtCategories.length > 1) {
        this.setState({ categories: crtCategories.filter((category, idx) => idx !== idxCategory) });
      } else {
        this.setState({ categories: [{ name: '', questions: [''] }] });
      }
    }
  };
  onChangeCategoryName = (idxCategory: number, value): void => {
    const crtCategories = this.state.categories;
    if (crtCategories.length > idxCategory) {
      this.setState({
        categories: crtCategories.map((category, idx) => (idx !== idxCategory ? category : { ...category, name: value })),
      });
    }
  };
  onSaveRubric = () => {
    let newRubric = {
      name: this.state.rubricName,
      type: this.state.privacyOptions.find((item) => item.selected).key,
      template: { categories: this.cloneCategories(this.state.categories) },
    } as RubricBase;
    if (isNaN(this.props.rubricId)) {
      //create rubric
      this.props.createRubric(newRubric, this.props.authentication.token).then(
        (okId) => this.props.history.push(`/rubrics/${okId}`),
        (err) => {
          console.log('create rubric  err ', err);
        }
      );
    } else {
      // update rubric
      this.props.updateRubric(this.props.rubricId, newRubric, this.props.authentication.token);
    }
  };

  renderRubricDropTarget = (rubricId, idx, before: boolean, last: boolean) => {
    return (
      <Droppable
        droppableId={`DROPPABLECONTAINERID4RUBRIC_${rubricId}_${idx}`}
        type={TYPE_DROPPABLE_RUBRIC}
        key={before ? `before_${rubricId}_${idx}` : `after_${rubricId}_${idx}`}
      >
        {(providedLast, snapshotLast) => (
          <>
            <div
              key="droppable"
              ref={providedLast.innerRef}
              {...providedLast.droppableProps}
              style={{
                minHeight: this.state.isDraggingRubric ? 10 : 1,
                backgroundColor: snapshotLast.isDraggingOver ? applyOpacity(getColor(1), 0.1) : 'inherit',
                transition: 'background-color 0.3s ease',
                fontSize: 8,
                fontWeight: 800,
                fontStyle: 'italic',
                textAlign: 'center',
                color: primaryColor,
              }}
            >
              {this.state.isDraggingRubric ? '---------> drop rubric here <---------' : null}
            </div>
            <div
              key="placeholder"
              style={{
                backgroundColor: snapshotLast.isDraggingOver ? applyOpacity(getColor(1), 0.1) : 'inherit',
                //backgroundColor: 'yellow',
                transition: 'background-color 0.3s ease',
              }}
            >
              {providedLast.placeholder}
            </div>
          </>
        )}
      </Droppable>
    );
  };
}

const mapStateToProps = (state: IRootState) => {
  console.log('EditRubric mapStateToProps ', state);
  return {
    authentication: state.authentication,
    rubrics: state.rubrics.rubricsMap,
    globalRubrics: state.settings.globalRubricsMap,
  };
};

const mapDispatchToProps = {
  updateRubric,
  createRubric,
};
const connector = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true });
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(EditRubric);
