/* eslint-disable no-console */
import { IconButton, InputAdornment, TextField, Tooltip, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ClearIcon from '@material-ui/icons/Clear';
import RemoveIcon from '@material-ui/icons/Remove';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from '@material-ui/lab';
import React, { FormEvent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { createSelector } from 'reselect';
import { IRootState } from '../../../shared/reducers';
import { addSubmissionDisfluency, removeSubmissionDisfluency } from '../../actions/figaroApiActions';
import config from '../../config/constants';
import { isRTLLanguage, NO_LANGUAGE } from '../../model/Video';
import { DURATION_UNKNOWN } from '../../reducers/videoReducer';
import Transcript, { enhancedIndexOf } from './Transcript';

interface IProps extends PropsFromRedux {
  handleFormSubmit: (form: ISearchState) => void;
  clearSearch: (callback?: any) => void;
  clearSearch2: (newTerm, callback: any) => void;
  onPrevious: () => void;
  onNext: () => void;
  crtPos: number;
  counter: number;
  externalTerm: string;
}
export interface ISearchState {
  term: string;
  searchonCurrentTermPerformed?: boolean;
  input?: string;
}

class SearchText extends React.PureComponent<IProps, ISearchState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      term: this.props.externalTerm ? this.props.externalTerm : '',
      searchonCurrentTermPerformed: false,
      input: '',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log('did update, prevProps/ State: ', prevProps, prevState, ' current props/state', this.props, this.state);
    if (prevProps.externalTerm !== this.props.externalTerm && this.props.externalTerm !== this.state.term) {
      // console.log(`did update, changing state term to"${this.props.externalTerm}"`);
      console.log('SearchText: did update, set state');
      // this.setState({ term: this.props.externalTerm, searchonCurrentTermPerformed: true });
      this.setState({ term: this.props.externalTerm, searchonCurrentTermPerformed: true });
    }
  }
  private resetState(): void {
    console.log('SearchText: resetstate');
    this.setState({
      term: '',
      searchonCurrentTermPerformed: false,
      input: '',
    });
  }

  handleInputChange = (event, newValue, reason) => {
    console.log('SearchText: handle input change ', newValue, reason);
    if (reason === 'reset' && (newValue === null || newValue === '')) {
      //this is the case when manually setting term=null  onOpen;
      // when seting term via Search, it has a non-null value
      console.log('SearchText: reset, nothing');
      return;
    }
    if (reason === 'input') {
      if (this.state.searchonCurrentTermPerformed)
        this.props.clearSearch2(newValue, (newTerm) => {
          // console.log(`handleTermValueChange: callback clearSearch2, set term: "${newTerm}"`);
          this.setState({ searchonCurrentTermPerformed: false, input: newValue }); // to make sure the last applied is the term changed, not the term reset from the parent
        });
    }
    this.setState({ input: newValue });
  };

  handleTermChange = (event, newValue, reason) => {
    console.log(`handleTermValueChange value/reason: "${newValue}" ,`, reason);
    this.handleTermValueChange(newValue);
  };

  handleTermValueChange = (newValue) => {
    //console.log(`handleTermValueChange: "${newValue}"`);
    if (this.state.searchonCurrentTermPerformed) {
      this.props.clearSearch2(newValue, (newTerm) => {
        // console.log(`handleTermValueChange: callback clearSearch2, set term: "${newTerm}"`);
        this.setState({ term: newTerm, searchonCurrentTermPerformed: false }); // to make sure the last applied is the term changed, not the term reset from the parent
      });
    } else {
      // console.log(` handleTermValueChange direct handleChange, term: "${newValue}"`);
      this.setState({ term: newValue, searchonCurrentTermPerformed: false });
    }
  };

  onFormSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | FormEvent<HTMLFormElement>) => {
    if (event) event.preventDefault();
    console.log('onFormSubmit', this.state);
    if (!this.state.term || this.state.term.length === 0) {
      return;
    } else {
      this.props.handleFormSubmit({ ...this.state });
      this.setState({
        searchonCurrentTermPerformed: true,
      });
    }
  };
  onClear = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | FormEvent<HTMLFormElement>) => {
    // console.log('SearchText: onClear ');
    event.preventDefault();
    this.resetState();
    // console.log('SearchText: calling clearSeacrh ');
    this.props.clearSearch();
  };
  onNext = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.props.onNext();
  };
  onPrevious = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.props.onPrevious();
  };
  onAddDisfluency = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // this.props.onPrevious();
    console.log('add disfluency ', this.state.term, this.state);
    this.props.addSubmissionDisfluency(this.props.submission, this.state.term, this.props.authentication.token);
  };
  onRemoveDisfluency = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // this.props.onPrevious();
    console.log('remove disfluency ', this.state.term, this.state);
    this.props.removeSubmissionDisfluency(this.props.submission, this.state.term, this.props.authentication.token);
  };

  render() {
    console.log('SearchText render called: state/props: ', this.state, this.props);
    let addDisabled =
      !this.state.term ||
      this.state.term.length === 0 ||
      this.state.term !== this.state.input ||
      this.props.disfluencyInfo.allDisfluencies.includes(this.state.term);

    let removeDisabled =
      !this.state.term ||
      this.state.term.length === 0 ||
      this.state.term !== this.state.input ||
      !this.props.privateDisfluencyWords.includes(this.state.term);
    let btnAdd = (
      <IconButton aria-label="add" size="small" onClick={this.onAddDisfluency} disabled={addDisabled}>
        <AddIcon fontSize="inherit" />
      </IconButton>
    );
    let btnRemove = (
      <IconButton aria-label="remove" size="small" onClick={this.onRemoveDisfluency} disabled={removeDisabled}>
        <RemoveIcon fontSize="inherit" />
      </IconButton>
    );
    let spacer = <span style={{ width: 10 }} />;
    let dir = isRTLLanguage(this.props.selectedVideoId, this.props.language, config) ? 'rtl' : 'ltr';
    return (
      <>
        <div className="col-12 fig-player-top">
          <Typography
            component="div"
            className="fig-player-top-inner"
            variant="body2"
            style={{
              maxHeight: Transcript.HEIGHT_TRANSCRIPT_STATS,
            }}
          >
            <span>{`Disfluencies: ${this.props.disfluencyInfo.totalGlobalOccurences}`}</span>
            <span>{`Keywords: ${this.props.disfluencyInfo.totalPrivateOccurences}`}</span>
            <span>{`Speaking Rate: ${this.props.disfluencyInfo.speakingRate} wpm`}</span>
          </Typography>
        </div>
        <div className="col-12">
          <form
            className="fig-player-bottom"
            style={{
              //backgroundColor: config.transcript.fluencyColor,
              visibility: this.props.handleFormSubmit ? 'inherit' : 'hidden',
            }}
            onSubmit={this.onFormSubmit}
          >
            <div className="row" style={{ width: '100%' }}>
              <Autocomplete 
                className="col"
                value={this.state.term}
                inputValue={this.state.input}
                onChange={this.handleTermChange}
                onInputChange={this.handleInputChange}
                //   blurOnSelect            //   autoSelect  //   selectOnFocus
                clearOnBlur={false}
                clearOnEscape={true}
                onOpen={() => {
                  //console.log('onOpen, seting term NULL');
                  //this is needed to have nothing selected in the popup list
                  this.setState({ term: '' });
                }}
                handleHomeEndKeys={false}
                id="free-solo-with-text-demo"
                options={this.props.disfluencyInfo.allDisfluencies}
                groupBy={(option) => (this.props.globalDisfluencyWords.includes(option) ? 'Disfluencies' : 'Saved Keywords')}
                freeSolo
                renderInput={(params) => {
                  //console.log('params', params);
                  return (
                    <TextField
                      {...params}
                      placeholder="Keyword Search"
                      margin="dense"
                      // style={{
                      //   textDecorationLine: this.state.input && this.state.input.length ? 'underline' : 'unset',
                      // }}
                      dir={dir}
                      inputProps={{
                        ...params.inputProps,
                        style: {
                          ...(params.inputProps as any).style,
                          //textDecorationLine: this.state.input && this.state.input.length ? 'underline' : 'unset',
                          textDecorationLine: 'underline',
                        },
                        dir,
                      }}
                      InputProps={{
                        ...params.InputProps,
                        dir: 'ltr', //otherwise it switchs start /end adornments for arabic
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            style={
                              {
                                /*backgroundColor: 'pink', maxWidth: '10%' */
                              }
                            }
                          >
                            {addDisabled ? btnAdd : <Tooltip title="Save Keyword">{btnAdd}</Tooltip>}
                            {removeDisabled ? btnRemove : <Tooltip title="Remove Keyword">{btnRemove}</Tooltip>}
                            {/* <span style={{ width: 5 }} /> */}
                            {/* <SearchIcon /> */}
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment
                            position="end" // style={{ backgroundColor: 'blue', }}
                          >
                            {/* <IconButton  
                          TODO FFS USE this if you want to clear just the input box but keep the search results
                          size="small"
                          onClick={() => {
                            this.setState({ input: '' });
                          }}
                        >
                          <ClearIcon fontSize="inherit" />
                        </IconButton> */}
                            {/* {!this.state.termChanged /*|| (this.state.input === this.props.externalTerm && this.props.counter) */}
                            {this.state
                              .searchonCurrentTermPerformed /*|| (this.state.input === this.props.externalTerm && this.props.counter) */ ? (
                              <span>{`(${this.props.crtPos + 1}/${this.props.counter})`}</span>
                            ) : (
                              <Tooltip title="Search">
                                <IconButton aria-label="search" size="small" onClick={this.onFormSubmit}>
                                  <SearchIcon fontSize="inherit" />
                                </IconButton>
                              </Tooltip>
                            )}

                            <Tooltip title="Next Match">
                              <IconButton aria-label="next" size="small" onClick={this.onNext}>
                                <ArrowDownwardIcon fontSize="inherit" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Previous Match">
                              <IconButton aria-label="previous" size="small" onClick={this.onPrevious}>
                                <ArrowUpwardIcon fontSize="inherit" />
                              </IconButton>
                            </Tooltip>
                            {spacer}
                            {/*
                        TODO FFS  USE this if you want to clear both the input box and the search results*/}
                            <IconButton aria-label="clear" size="small" onClick={this.onClear}>
                              <ClearIcon fontSize="inherit" />
                            </IconButton>
                            {/* {(params.InputProps.endAdornment as ReactElement).props.children 
                          /* there's a default ClearIcon adornment, but it shows just when the item is focused */}
                          </InputAdornment>
                        ),
                      }}
                      //onKeyPress={this.onEnterKey}
                    />
                  );
                }}
                fullWidth
                renderOption={(option) => {
                  let matches = this.props.disfluencyInfo.occurencesMap[option];
                  return (
                    <div
                      className="row"
                      style={{
                        width: '100%',
                      }}
                    >
                      <span className="col-10">'{option}'</span>
                      {matches ? <span className="col-2">{matches}</span> : null}
                    </div>
                  );
                }}
              />
            </div>
          </form>
        </div>
      </>
    );
  }
  // onEnterKey = (e: any) => {
  //   if (e.which === 13) {
  //     this.onFormSubmit(e);
  //   }
  // };
}

const mergeUnique = (...args) => [...new Set([].concat(...args))];

const GLOBAL_DISFLUENCIES_NONE = [];
const PRIVATE_DISFLUENCIES_NONE = [];
const selectorCrtVideoTranscript = (state: IRootState) =>
  state.video.videosMap[state.video.currentVideoId] ? state.video.videosMap[state.video.currentVideoId].transcript : null;
const selectorCrtVideoDuration = (state: IRootState) => state.video.videoDuration;
const selectorGlobalDisfluencies = (state: IRootState) =>
  state.settings.disfluencies && state.settings.disfluencies.options && state.settings.disfluencies.options.words
    ? state.settings.disfluencies.options.words
    : GLOBAL_DISFLUENCIES_NONE;
const selectorPrivateDisfluencies = (state: IRootState) =>
  state.video.currentVideoId &&
  state.submissions.submissionsMap[Number.parseInt(state.video.currentVideoId, 10)] &&
  state.submissions.submissionsMap[Number.parseInt(state.video.currentVideoId, 10)].options &&
  state.submissions.submissionsMap[Number.parseInt(state.video.currentVideoId, 10)].options.text_search
    ? state.submissions.submissionsMap[Number.parseInt(state.video.currentVideoId, 10)].options.text_search
    : PRIVATE_DISFLUENCIES_NONE;

const retrieveAllDisfluencies = createSelector(
  [selectorCrtVideoTranscript, selectorCrtVideoDuration, selectorGlobalDisfluencies, selectorPrivateDisfluencies],
  (transcript, videoDuration, globals, privates) => {
    console.log('retrieveAllDisfluencies called with params ', transcript, videoDuration, globals, privates);
    let rezult = {
      allDisfluencies: [] as string[],
      occurencesMap: {} as { [disfluency: string]: number },
      totalGlobalOccurences: 0,
      totalPrivateOccurences: 0,
      speakingRate: 0,
    };

    rezult.allDisfluencies = [...mergeUnique(globals, privates)].sort((a, b) => {
      if (globals.includes(a)) {
        if (globals.includes(b)) {
          return a > b ? +1 : a < b ? -1 : 0;
        } else {
          return -1;
        }
      } else {
        if (globals.includes(b)) {
          return +1;
        } else {
          return a > b ? +1 : a < b ? -1 : 0;
        }
      }
    });
    if (transcript && transcript.fullText) {
      rezult.allDisfluencies.forEach((disfluency) => {
        rezult.occurencesMap[disfluency] = enhancedIndexOf(transcript.fullText, disfluency).length;
        if (globals.includes(disfluency)) {
          rezult.totalGlobalOccurences += rezult.occurencesMap[disfluency];
        } else {
          rezult.totalPrivateOccurences += rezult.occurencesMap[disfluency];
        }
      });
      if (videoDuration !== DURATION_UNKNOWN) {
        rezult.speakingRate = Math.round((transcript.streams.utterance.data.length - transcript.gapsCount) / (videoDuration / 60));
      }
    }
    return rezult;
  }
);

const retrieveLanguageInfo = createSelector([selectorCrtVideoTranscript], (transcript) => {
  return transcript && transcript.language ? transcript.language : NO_LANGUAGE;
});

const mapStateToProps = (state: IRootState) => {
  return {
    submission:
      state.video.currentVideoId && state.submissions.submissionsMap[state.video.currentVideoId]
        ? state.submissions.submissionsMap[state.video.currentVideoId]
        : null,
    globalDisfluencyWords: selectorGlobalDisfluencies(state),
    privateDisfluencyWords: selectorPrivateDisfluencies(state),
    disfluencyInfo: retrieveAllDisfluencies(state),
    authentication: state.authentication,
    selectedVideoId: state.video.currentVideoId,
    language: retrieveLanguageInfo(state),
  };
};
const mapDispatchToProps = { addSubmissionDisfluency, removeSubmissionDisfluency };

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(SearchText);

// onChange={(event, newValue, reason) => {
//   //create-option => type+ enter; //select-option =>select  //clear null
//   //event null clear   //event text create-option //event title select-option //event title reset  ??
//   console.log('onChange ', event, newValue, reason);
//   console.log('STRING onChange typeof');
//   this.handleTermValueChange(newValue);
// }}

// onInputChange={(event, newValue, reason) => {
//   console.log('onInputChange ', event, newValue, reason);
//   this.handleTermValueChange(newValue);
//   //event null clear              //event text input //event title reset
// }}
