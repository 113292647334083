import { StreamCategories } from './ApiTypes';
import { StreamRef } from './Video';

export const isStreamRefIncluded = (stream: StreamRef, streamsPerCategory: StreamCategories) => {
  if (streamsPerCategory && stream) {
    return Object.keys(streamsPerCategory).some((category) => streamsPerCategory[category].includes(stream.stream));
  }
  return false;
};
export const isStreamTagIncluded = (tag: string, stream: string, streams: StreamRef[]) => {
  return streams && streams.some((oneStream) => oneStream.analysisTag === tag && oneStream.stream === stream);
};

export const getCategory = (stream: StreamRef, categories: StreamCategories) => {
  if (categories && stream) {
    return Object.keys(categories).find((category) => categories[category].includes(stream.stream));
  }
  return null;
};
export const categoriesAndTags2StreamRefs = (
  streamsPerCategory: StreamCategories,
  analysisTags: string[],
  analysisTagsFriendlyNames: string[],
  analysisTagIdxs: number[]
) => {
  const streamRefs = [] as StreamRef[];
  if (streamsPerCategory) {
    Object.keys(streamsPerCategory).forEach((category) => {
      analysisTags.forEach((analysisTag, indx) => {
        if (streamsPerCategory[category] && streamsPerCategory[category].length > 0)
          streamsPerCategory[category].forEach((stream) => {
            streamRefs.push({
              analysisIdx: analysisTagIdxs[indx],
              stream: stream,
              analysisTag: analysisTag,
              tagFriendlyName: analysisTagsFriendlyNames[indx],
            });
          });
      });
    });
  }
  return streamRefs;
};
