/* eslint-disable no-console */
import { Button, Dialog, TextField } from '@material-ui/core';
import React from 'react';
import { Submission } from '../../model/ApiTypes';
import { toDateTimeLocalInput } from '../../utils/utils';
import BoldCloseButton from '../shared/BoldCloseButton';
import DeleteConfirmationDialog from '../shared/DeleteConfirmationDialog';
import { getDefaultDeadline } from './RequestVideoPanel';
interface IProps {
  submission: Submission;
  saveSubmission: (values: IEditSubmissionState) => void;
  handleClose: () => void;

  deleteSubmission: any;
}

export interface IEditSubmissionState {
  details: string;
  name: string;
  deadline: Date;

  deleteSubmissionDialogOpened: boolean;
}

export default class EditSubmission extends React.PureComponent<IProps, IEditSubmissionState> {
  constructor(props: IProps) {
    super(props);
    console.log('ctor edit comm, props', this.props);
    this.state = {
      details: this.props.submission.details,
      name: this.props.submission.name,
      deleteSubmissionDialogOpened: false,
      deadline: new Date(this.props.submission.deadline),
    };
  }

  handleDetailsChange = (event) => {
    this.setState({ details: event.target.value });
  };
  handleNameChange = (event) => {
    this.setState({ name: event.target.value });
  };
  handleDeadlineChange = (date) => {
    let nDate = new Date(date.target.value);
    if (new Date().getTime() <= nDate.getTime()) this.setState({ deadline: new Date(date.target.value) });
  };

  onFormSubmit = (event) => {
    console.log('onFormSubmit', this.state);
    this.onSaveSubmission(event);
  };
  hasErrors = () => this.state.details === null || !this.state.name;

  onSaveSubmission = (event) => {
    console.log('onSaveSubmissionDlg', this.state);
    if (this.hasErrors()) {
      return;
    }
    this.props.saveSubmission({ ...this.state });
    this.setState(this.initState());
  };
  onDeleteSubmission = (event) => {
    this.setState({ deleteSubmissionDialogOpened: true });
  };
  onDeleteSubmissionConfirmed = () => {
    this.closeDeleteSubmissionConfirmationDialog();
    console.log('onDeleteSubmissionDlg', this.state);
    this.props.deleteSubmission();
    this.setState(this.initState());
  };
  closeDeleteSubmissionConfirmationDialog = () => {
    this.setState({ deleteSubmissionDialogOpened: false });
  };

  private initState() {
    const rez: IEditSubmissionState = {
      details: '',
      name: '',
      deleteSubmissionDialogOpened: false,
      deadline: getDefaultDeadline(),
    };
    return rez;
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('componentDidUpdate, props', this.props, ' , state: ', this.state, ', oldProps: ', prevProps, ', oldState: ', prevState);
  }

  render() {
    return (
      <>
        <Dialog open fullWidth maxWidth="sm" onClose={this.props.handleClose}>
          <form className="row" onSubmit={this.onFormSubmit} /*style={{ position: 'relative' }}*/>
            <div className="col-12 fig-modal-dialog-main-container">
              <BoldCloseButton width={16} height={16} top={10} right={10} handleClose={this.props.handleClose}></BoldCloseButton>
              <div className="row">
                <TextField
                  className="col-12 fig-modal-dialog-input"
                  label={`SUBMISSION NAME`}
                  value={this.state.name}
                  onChange={this.handleNameChange}
                  required
                  InputLabelProps={{
                    shrink: true,
                    required: false,
                  }}
                  placeholder={'Enter Name'}
                  error={!this.state.name}
                />
              </div>
              <div className="row">
                <TextField
                  className="col-12 fig-modal-dialog-input"
                  multiline={true}
                  rows={5}
                  label="DETAILS"
                  value={this.state.details}
                  onChange={this.handleDetailsChange}
                  required
                  InputLabelProps={{
                    shrink: true,
                    required: false,
                  }}
                  placeholder={'Enter Details'}
                  error={this.state.details === null}
                />
              </div>
              <div className="row">
                <TextField
                  id="date"
                  label="DEADLINE"
                  type="datetime-local"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: toDateTimeLocalInput(new Date()),
                    step: 60,
                  }}
                  className="col-12 fig-modal-dialog-input"
                  value={toDateTimeLocalInput(this.state.deadline)}
                  onChange={this.handleDeadlineChange}
                />
              </div>

              <div className="row modal-dialog-footer-two-buttons" style={{ width: '100%', paddingBottom: 20, marginLeft: 0 }}>
                <div className="col-12 no-padding fig-space-between">
                  <div className="modal-button-box padding-left--40">
                    <Button className="fig-button in-modal-dialog fig-button__white" onClick={this.onDeleteSubmission}>
                      Delete Submission
                    </Button>
                  </div>

                  <div className="modal-button-box padding-right--40">
                    <Button
                      className={`fig-button in-modal-dialog ${!this.hasErrors() ? 'fig-button__grey' : 'fig-button__white'}`}
                      onClick={this.onSaveSubmission}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Dialog>
        {this.renderDeleteSubmissionConfirmation()}
      </>
    );
  }
  renderDeleteSubmissionConfirmation() {
    return this.state.deleteSubmissionDialogOpened ? (
      <DeleteConfirmationDialog
        handleDelete={this.onDeleteSubmissionConfirmed}
        handleClose={this.closeDeleteSubmissionConfirmationDialog}
        title={'Delete Submission'}
        text={`Are you sure you want to delete the submission "${this.state.name}"?`}
      />
    ) : null;
  }
}
