import { Button, Dialog, DialogTitle, IconButton, InputAdornment, TextField } from '@material-ui/core';
import PenIcon from '@material-ui/icons/Edit';
import React from 'react';
import BoldCloseButton from '../shared/BoldCloseButton';

interface IState {
  text: string;
}
interface IProps {
  dialogTitle: string;
  okTitle: string;
  cancelTitle: string;
  textPlaceholder: string;
  onClose(text: string): void;
}
class EditHLName extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { text: null };
  }

  hasErrors = () => !this.state.text;

  handleChange = (e: { target: { value: string } }) => {
    this.setState({ text: e.target.value });
  };
  render() {
    return (
      <Dialog
        open
        fullWidth
        maxWidth="sm"
        onClose={() => this.props.onClose(null)}
        scroll="paper"
        className="fig-modal-dialog-main-container-v2"
      >
        <BoldCloseButton width={14} height={14} top={15} right={15} handleClose={() => this.props.onClose(null)}></BoldCloseButton>

        <DialogTitle id="scroll-dialog-title" disableTypography>
          <div className="row padding-top--35 padding-bottom--10">
            <div className="col-12 no-padding fig-vertical-middle">
              <span className="modal-title">{this.props.dialogTitle}</span>
            </div>
          </div>

          <div className="row">
            <div className="col-12 no-padding fig-modal-dialog-edit">
              <TextField
                placeholder={this.props.textPlaceholder}
                value={this.state.text}
                onChange={this.handleChange}
                fullWidth
                error={!this.state.text || !this.state.text.length}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton className="fig-edit-modal">
                        <PenIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
        </DialogTitle>
        <div className="row modal-dialog-footer-two-buttons">
          <div className="col-12 no-padding fig-space-between">
            <div className="modal-button-box padding-left--25">
              <Button className="fig-button in-modal-dialog fig-button__white" onClick={() => this.props.onClose(null)}>
                {this.props.cancelTitle}
              </Button>
            </div>

            <div className="modal-button-box padding-right--25">
              <Button
                className={`fig-button in-modal-dialog ${!this.hasErrors() ? 'fig-button__grey' : 'fig-button__white'}`}
                onClick={() => this.props.onClose(this.state.text)}
              >
                {this.props.okTitle}
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}
export default EditHLName;
