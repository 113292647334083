import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { getUnauthedHomePath } from '../../../../app';

const PrivateRoute = ({ component: Component, authed, userRole, accessRole, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        authed === true && accessRole.includes(userRole) ? <Component {...props} /> : <Redirect to={{ pathname: getUnauthedHomePath() }} />
      }
    />
  );
};

export default PrivateRoute;
