import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { getColor } from '../../config/constants';
import { Category, Rubric } from '../../model/ApiTypes';
import { applyOpacity } from '../../utils/utils';
import { primaryColor } from '../echarts/macarons';
import {
  generateDraggableTemplCategoryId,
  generateDraggableTemplQuestionId,
  generateDraggableTemplRubricId,
  generateDroppableTemplCategoryContainerId,
  generateDroppableTemplQuestionContainerId,
  generateDroppableTemplRubricContainerId,
  TYPE_DROPPABLE_CATEGORY,
  TYPE_DROPPABLE_QUESTION,
  TYPE_DROPPABLE_RUBRIC,
} from './utils';

interface IProps {
  rubrics: Rubric[];
  searchKeywords: string[];
}
interface IState {
  opened: boolean[];
  id2IdxMap: { [id: number]: number };
}
class RubricsTree extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = { opened: this.initOpenedState(this.props.rubrics.length), id2IdxMap: this.initIdsMap(this.props.rubrics) };
  }

  initIdsMap(rubrics: Rubric[]) {
    let rez = {} as { [id: number]: number };
    rubrics.forEach((rubric, idx) => {
      rez[rubric.id] = idx;
    });
    return rez;
  }

  initOpenedState(len) {
    return Array.from({ length: len }, (i) => (i = false));
  }
  toggleExpand = (idx: number) => {
    this.setState({
      opened: this.state.opened.map((open, pos) => (pos === idx ? !open : open)),
    });
  };
  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.props.rubrics !== prevProps.rubrics) {
      let resOpenedState = this.initOpenedState(this.props.rubrics.length);
      // preserve opened state per rubricID
      this.props.rubrics.forEach((newRubric, idx) => {
        resOpenedState[idx] = prevState.id2IdxMap[newRubric.id] ? prevState.opened[prevState.id2IdxMap[newRubric.id]] : false;
      });
      this.setState({
        opened: resOpenedState,
        id2IdxMap: this.initIdsMap(this.props.rubrics),
      });
    }
  }
  matchesFilter = (rubric: Rubric) => {
    if (!this.props.searchKeywords || this.props.searchKeywords.length === 0) {
      return true;
    }
    let regexp = this.makeKeywordRegexp();
    return (
      rubric.name.match(regexp) ||
      rubric.template.categories.some(
        (category) => category.name.match(regexp) || category.questions.some((question) => question.match(regexp))
      )
    );
  };
  keywordsMatch = (name: String) => {
    if (!this.props.searchKeywords || this.props.searchKeywords.length === 0) {
      return false;
    }
    return name.match(this.makeKeywordRegexp());
  };

  makeKeywordRegexp() {
    return new RegExp(['\\b', this.props.searchKeywords.join('\\b|\\b'), '\\b'].join(''), 'i');
  }

  render() {
    console.log(' render RubricsTree , ', this.props.rubrics, this.props.searchKeywords);
    let rubrics = this.props.rubrics;
    return (
      <div>
        <List component="div" aria-labelledby="nested-list-subheader">
          {rubrics.map((rubric, idx) => {
            return !this.matchesFilter(rubric) ? null : (
              <Droppable
                key={`dropable4rubric_${rubric.id}`}
                droppableId={generateDroppableTemplRubricContainerId(rubric.id, idx)}
                type={/*TYPE_DROPPABLE_CATEGORY */ TYPE_DROPPABLE_RUBRIC}
                isDropDisabled={true}
              >
                {(providedRubricExt) => {
                  // console.log(
                  //   'render for droppable rubric ',
                  //   generateDroppableTemplRubricContainerId(rubric.id, idx),
                  //   ': providedRubricExt: ',
                  //   providedRubricExt
                  // );
                  return (
                    <div ref={providedRubricExt.innerRef} {...providedRubricExt.droppableProps}>
                      <Draggable key={`${rubric.id}`} draggableId={generateDraggableTemplRubricId(rubric.id)} index={idx}>
                        {(providedDragableRubric, snapshotDragableRubric) => {
                          // console.log('render for draggable rubric ', generateDraggableTemplRubricId(rubric.id));
                          return (
                            <>
                              {this.renderRubricItem(false, providedDragableRubric, snapshotDragableRubric, rubric, idx)}
                              {snapshotDragableRubric.isDragging &&
                                this.renderRubricItem(true, providedDragableRubric, snapshotDragableRubric, rubric, idx)}
                            </>
                          );
                        }}
                      </Draggable>

                      {/* NO LONGER USED SINCE WE RENDER THE CLONE */}
                      <div style={{ display: 'none' }}>{providedRubricExt.placeholder}</div>
                    </div>
                  );
                }}
              </Droppable>
            );
          })}
        </List>
      </div>
    );
  }

  renderRubricItem = (isClone: boolean, providedDragableRubric, snapshotDragableRubric, rubric, idx) => {
    let rez = (
      <div
        key={`itemrubric_${rubric.id}.${isClone}`}
        {...(!isClone ? { ref: providedDragableRubric.innerRef } : {})}
        {...(!isClone ? providedDragableRubric.draggableProps : {})}
      >
        <ListItem
          button
          onClick={() => this.toggleExpand(idx)}
          style={{
            border: 'solid',
            borderRadius: 3,
            margin: 2,
            fontWeight: 800,
            fontStyle: 'normal',
            fontSize: 12,
            lineHeight: 15,
            textAlign: 'left',
            color: primaryColor,
            borderColor: snapshotDragableRubric.isDragging ? applyOpacity(getColor(1), 1) : '#EBEDF2',
            borderWidth: snapshotDragableRubric.isDragging ? 2 : 1,

            backgroundColor: snapshotDragableRubric.isDragging ? applyOpacity('#ffffff', 1) : 'inherit',
          }}
        >
          <ListItemIcon {...(!isClone ? providedDragableRubric.dragHandleProps : {})}>
            <DragHandleIcon />
          </ListItemIcon>
          <ListItemText primary={rubric.name} style={this.keywordsMatch(rubric.name) ? { fontStyle: 'italic' } : {}} />
          {this.state.opened[idx] ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
        </ListItem>
        <Collapse in={this.state.opened[idx]} timeout="auto">
          <List
            component="div"
            disablePadding
            style={{
              border: 'solid',
              borderColor: snapshotDragableRubric.isDragging ? applyOpacity(getColor(1), 1) : '#EBEDF2', //'#9BA7BC',
              borderWidth: 1,
              borderTopWidth: 0,
              margin: 2,
              marginTop: -2,
            }}
          >
            {rubric.template.categories.map((category, idxCategory) => {
              return (
                <Droppable
                  key={generateDroppableTemplCategoryContainerId(rubric.id, idxCategory, isClone)}
                  droppableId={generateDroppableTemplCategoryContainerId(rubric.id, idxCategory, isClone)}
                  type={TYPE_DROPPABLE_CATEGORY}
                  isDropDisabled={true}
                >
                  {(providedDropableCategory) => {
                    // console.log(
                    //   'render function for Dropable categ ',
                    //   generateDroppableTemplCategoryContainerId(rubric.id, idxCategory, isClone)
                    // );
                    return (
                      <div ref={providedDropableCategory.innerRef} {...providedDropableCategory.droppableProps}>
                        <Draggable
                          // key={`draggableCat_${rubric.id}.${idxCategory}.${isClone}`}
                          draggableId={generateDraggableTemplCategoryId(rubric.id, idxCategory, isClone)}
                          index={idxCategory}
                        >
                          {(providedDragableCategory, snapshotDragableCategory) => {
                            // console.log('render for DragableCateg ', generateDraggableTemplCategoryId(rubric.id, idxCategory, isClone));
                            return (
                              <>
                                {this.renderCategoryItem(
                                  false,
                                  providedDragableCategory,
                                  snapshotDragableCategory,
                                  snapshotDragableRubric,
                                  rubric,
                                  category,
                                  idxCategory,
                                  isClone
                                )}
                                {snapshotDragableCategory.isDragging &&
                                  this.renderCategoryItem(
                                    true,
                                    providedDragableCategory,
                                    snapshotDragableCategory,
                                    snapshotDragableRubric,
                                    rubric,
                                    category,
                                    idxCategory,
                                    isClone
                                  )}
                              </>
                            );
                          }}
                        </Draggable>
                        {/* NO LONGER USED SINCE WE RENDER THE CLONE */}
                        <div style={{ display: 'none' }}>{providedDropableCategory.placeholder}</div>
                      </div>
                    );
                  }}
                </Droppable>
              );
            })}
            {/* end rubric.template.categories.map */}
          </List>
        </Collapse>
      </div>
    );
    return rez;
  };

  /**
   * @param isClone true for generating a silent clone of the draggable category item
   * @param providedDragableCategory the provided for the draggable category
   * @param snapshotDraggableCategory the snapshot for the draggable category
   * @param snapshotDragableRubric the snapshot for the draggable Rubric parent
   * @param rubric the parent rubric
   * @param category the category to be rendered
   * @param idxCategory the index of the category in the parent rubric
   * @returns
   */
  renderCategoryItem(
    isClone: boolean,
    providedDragableCategory,
    snapshotDraggableCategory,
    snapshotDragableRubric,
    rubric: Rubric,
    category: Category,
    idxCategory: number,
    parentIsClone: boolean
  ) {
    let rez = (
      <div
        key={`divrubriccat_${rubric.id}.${idxCategory}.${isClone}.${parentIsClone}`}
        {...(!isClone ? { ref: providedDragableCategory.innerRef } : {})}
        {...(!isClone ? providedDragableCategory.draggableProps : {})}
        {...(!isClone ? providedDragableCategory.dragHandleProps : {})}
      >
        <div
          style={{
            borderStyle: 'solid',
            backgroundColor:
              snapshotDraggableCategory.isDragging || snapshotDragableRubric.isDragging ? applyOpacity('#ffffff', 1) : 'inherit',
            transition: 'border-color 0.2s ease',
            borderColor:
              snapshotDraggableCategory.isDragging || snapshotDragableRubric.isDragging ? applyOpacity(getColor(1), 1) : 'inherit',
            borderWidth: snapshotDraggableCategory.isDragging || snapshotDragableRubric.isDragging ? 2 : 0,
            borderRadius: 3,
          }}
        >
          <ListItem button={false}>
            <ListItemText
              primary={category.name}
              style={{
                fontWeight: 800,
                fontStyle: this.keywordsMatch(category.name) ? 'italic' : 'normal',
                fontSize: 11,
                lineHeight: 13,
                textAlign: 'left',
                color: '#1B335E',
              }}
            />
          </ListItem>
          <Collapse in={true} timeout="auto" /*unmountOnExit*/>
            <List component="div" className="margin-left--10">
              {category.questions.map((question, idxQuestion) => {
                return (
                  <Droppable
                    droppableId={generateDroppableTemplQuestionContainerId(rubric.id, idxCategory, idxQuestion, isClone, parentIsClone)}
                    type={TYPE_DROPPABLE_QUESTION}
                    isDropDisabled={true}
                    key={`dropable4quest_${rubric.id}.${idxCategory}.${idxQuestion}.${isClone}.${parentIsClone}`}
                  >
                    {(providedQuestion, snapshotQuestion) => {
                      // console.log(
                      //   'render function for Droppable question ',
                      //   generateDroppableTemplQuestionContainerId(rubric.id, idxCategory, idxQuestion, isClone, parentIsClone),
                      //   ' called with snapshot: ',
                      //   snapshotQuestion
                      // );
                      return (
                        <div ref={providedQuestion.innerRef} {...providedQuestion.droppableProps}>
                          <Draggable
                            draggableId={generateDraggableTemplQuestionId(rubric.id, idxCategory, idxQuestion, isClone, parentIsClone)}
                            index={idxQuestion}
                            // key={`${rubric.id}.${idxCategory}.${idxQuestion}.${isClone}.${parentIsClone}`}
                          >
                            {(provided, snapshot) => {
                              // console.log(
                              //   'render function for dragable question: ',
                              //   generateDraggableTemplQuestionId(rubric.id, idxCategory, idxQuestion, isClone, parentIsClone),
                              //   ' called with snapshot: ',
                              //   snapshot
                              // );
                              return (
                                <>
                                  {this.renderQuestionItem(
                                    false,
                                    provided,
                                    snapshot,
                                    snapshotDragableRubric,
                                    question,
                                    isClone,
                                    parentIsClone
                                  )}
                                  {snapshot.isDragging &&
                                    this.renderQuestionItem(
                                      true,
                                      provided,
                                      snapshot,
                                      snapshotDragableRubric,
                                      question,
                                      isClone,
                                      parentIsClone
                                    )}
                                </>
                              );
                            }}
                          </Draggable>
                          {/*NO LONGER USED SINCE WE RENDER THE CLONE*/}
                          <div style={{ display: 'none' }}>{providedQuestion.placeholder}</div>
                        </div>
                      );
                    }}
                  </Droppable>
                );
              })}
            </List>
          </Collapse>
        </div>
      </div>
    );
    return rez;
  }
  /**
   *
   * @param isClone true for generating a silent clone of the draggable question item (a blank clone of the item in its non-dragging state, i.e. silent, having nothing highlighted/modified); false for coloring/highlighting the item, to mark its dragging state
   * @param provided the provided for the draggable question
   * @param snapshot the snapshot for the draggable question
   * @param snapshotD the snapshot for the draggable Rubric parent
   * @param question the question text
   * @returns
   */
  renderQuestionItem = (isClone: boolean, provided, snapshot, snapshotD, question: string, parentIsClone, grandParentIsClone) => {
    let always = { backgroundColor: '#F9FAFB', marginBottom: 3 };
    let itemStyle = !isClone ? { ...provided.draggableProps.style, ...always } : always;
    let itemToRender = (
      <ListItem
        button={true}
        {...{ ...(!isClone ? provided.draggableProps : {}), style: itemStyle }}
        {...(!isClone ? { innerRef: provided.innerRef } : {})}
        key={`quest_${isClone}.${parentIsClone}.${grandParentIsClone}`}
      >
        <ListItemIcon {...(!isClone ? provided.dragHandleProps : {})}>
          <DragHandleIcon color={snapshot.isDragging ? 'secondary' : 'inherit'} />
        </ListItemIcon>
        <ListItemText
          primary={question}
          style={{
            borderStyle: 'solid',
            backgroundColor: !isClone
              ? snapshot.isDragging || snapshotD.isDragging
                ? applyOpacity(getColor(1), 0.1)
                : '#F9FAFB'
              : '#F9FAFB',
            transition: 'border-color 0.2s ease',
            borderColor: snapshot.isDragging || snapshotD.isDragging ? applyOpacity(getColor(1), 1) : 'inherit',
            borderWidth: snapshot.isDragging ? 2 : 0,
            borderRadius: 3,
            margin: !isClone ? (snapshot.isDragging ? 10 : 'inherit') : 'inherit',
            fontStyle: this.keywordsMatch(question) ? 'italic' : 'inherit',
          }}
        />
      </ListItem>
    );
    return itemToRender;
  };
}

export default RubricsTree;
