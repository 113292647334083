import { Button, TextField } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import { errorNotification, successNotification } from '../../../utils/utils';
import { reRequestConfirmation } from '../../actions/figaroApiActions';

type IProps = { history: any; match: any; location: any };

interface IState {
  email: string;
  attempted: boolean;
  successful: boolean;
}

class RequestRegistrationConfirmationPanel extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    let email = new URLSearchParams(props.location.search).get('email');
    console.log('RequestRegistrationConfirmationPanel ctor,  props ', props);
    console.log('email: ', email);
    this.state = {
      successful: false,
      email: email,
      attempted: false,
    };
  }

  render() {
    return (
      <div className="login-wrapper">
        <Paper>
          <div className="login-wrapper__header">
            <div className="header-container no-margin text-center w-100">
              <p className="no-margin">Request Figaro Registration Confirmation </p>
            </div>
          </div>
          <form className="login-wrapper__form">{this.renderUserProfile()}</form>
        </Paper>
      </div>
    );
  }

  private renderUserProfile() {
    return (
      <div className="fig-vertical-middle fig-direction-column w-100">
        <TextField
          className="w-100"
          id="email"
          //label="Email"
          type="email"
          value={this.state.email}
          onChange={(e) =>
            this.setState({
              email: e.target.value,
            })
          }
          InputLabelProps={{
            shrink: true,
          }}
          placeholder={'EMAIL'}
          margin="normal"
          variant="outlined"
          required
          error={this.state.attempted && (!this.state.email || this.state.email.length === 0)}
        />

        <Button className="fig-button ghost-to-dark-blue margin-top--30" onClick={this.onRequestConfirmation}>
          Request Confirmation
        </Button>
      </div>
    );
  }
  onSuccesfullyRequestedConfirmation = () => {
    successNotification(
      'Registration confirmation request sent successfuly! To complete the registration, please check your e-mail and click the confirmation link.'
    );
    this.setState({
      successful: true,
    });
  };
  onRequestConfirmation = () => {
    if (this.state.email && this.state.email.length > 0) {
      reRequestConfirmation(this.state.email).then(
        () => this.onSuccesfullyRequestedConfirmation(),
        (err) => {
          errorNotification('Request confirmation failed!');
          console.log('Request confirmation err ', err);
        }
      );
    } else {
      alert('Mandatory field missing');
    }
    this.setState({
      attempted: true,
    });
  };
}

export default RequestRegistrationConfirmationPanel;
