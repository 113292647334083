/* eslint-disable no-console */
import { Button, Card, CardContent, CardHeader, IconButton, Tooltip, Typography } from '@material-ui/core';
import ReportIcon from '@material-ui/icons/Attachment';
import React from 'react';
import sprites from '../../../../icons/fig-icons.svg';
import config from '../../config/constants';
import { Datum3, getWordsCountOfUtterances, Highlight, StreamRef } from '../../model/Video';
import { formatFigaroDate, seconds2mssmm } from '../../utils/utils';
import { primaryColor } from '../echarts/macarons';
import { IVideoPlayer } from '../video/VideoRenderer';
import EditHighlight, { IEditHighlightState } from './EditHighlight';

interface IProps {
  theVideoPlayer: IVideoPlayer;
  handleDelete: (highlightID: string) => void;
  handleEdit: (highlightId: string, values: IEditHighlightState) => void;
  handleSetPrivacy: (highlightId: string, privacy: string) => void;
  highlight: Highlight;
  isPlaying: boolean;
  isSelected: boolean;
  hasBeenEdited: boolean;
  highlightSelected: (highlight: Highlight) => void;
  updateStreamsSelections: (highlight: Highlight) => void;
  getGlobalStreamsSelections: () => {
    voiceSelections: StreamRef[];
    faceSelections: StreamRef[];
    voiceThreshold: number;
    faceThreshold: number;
  };
  videoDuration: number;
  toggleSelect4Reporting: any;
  isSelected4Reporting: boolean;
  transcript: Datum3[];
}
interface IState {
  editHLDialogOpened: boolean;
}

class AHighlight extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      editHLDialogOpened: false,
    };
  }

  private initState() {
    const rez = {
      editHLDialogOpened: false,
    };
    return rez;
  }

  openEditHLDialog = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ editHLDialogOpened: true });
  };
  onCloseEditHLDialog = () => {
    this.setState({ editHLDialogOpened: false });
  };
  onSaveEdit = (highlightId: string, values: IEditHighlightState) => {
    this.props.handleEdit(highlightId, values);
    this.onCloseEditHLDialog();
  };

  renderEditHLDialog = () => {
    return this.state.editHLDialogOpened ? (
      <EditHighlight
        highlight={this.props.highlight}
        handleEdit={this.onSaveEdit}
        handleClose={this.onCloseEditHLDialog}
        handleFormSubmit={null}
        updateStreamsSelections={this.props.updateStreamsSelections}
        getGlobalStreamsSelections={this.props.getGlobalStreamsSelections}
        videoDuration={this.props.videoDuration}
      ></EditHighlight>
    ) : null;
  };

  ////////////////////////

  handlePrivacyChange = (privacy) => {
    this.props.handleSetPrivacy(this.props.highlight.id, privacy);
  };

  onDelete = (event) => {
    this.props.handleDelete(this.props.highlight.id);
  };
  onGoTo = (event) => {
    this.props.theVideoPlayer.seek(this.props.highlight.startTime);
  };
  onToggleHighlightSelected = (event) => {
    this.props.highlightSelected(this.props.isSelected ? null : this.props.highlight);
  };
  onUpdateStreamsSelections = (event) => {
    this.props.updateStreamsSelections(this.props.highlight);
  };
  onSelect4Report = (event) => {
    this.props.toggleSelect4Reporting(this.props.highlight, !this.props.isSelected4Reporting);
  };

  getTextForSelectButton() {
    return this.props.isSelected ? (this.props.hasBeenEdited ? 'Unselect*' : 'Unselect') : 'Select';
  }

  render() {
    let annotateBtnStyle: any = {};
    if (this.props.isSelected) {
      annotateBtnStyle.backgroundColor = config.highlights.selectedHighlightBackground;
    }
    const textStyle: any = { paddingRight: '10%' };
    if (this.props.isPlaying) textStyle.color = primaryColor;

    // console.log(
    //   'render AHiglight w start/end time, words: ',
    //   this.props.highlight.startTime,
    //   this.props.highlight.endTime,
    //   getWordsCountOfUtterances(this.props.transcript, this.props.highlight.startTime, this.props.highlight.endTime)
    // );
    return (
      <div>
        {this.props.highlight ? (
          <Card className="fig-main-highlights-container">
            <CardHeader
              className="fig-space-between"
              action={
                <div className="icons-container">
                  <Tooltip title={this.getTextForSelectButton()}>
                    <IconButton onClick={this.onToggleHighlightSelected} style={annotateBtnStyle}>
                      <svg className="fig-edit-icon">
                        <use href={sprites + '#anotate-icon'} />
                      </svg>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit">
                    <IconButton onClick={this.openEditHLDialog}>
                      <svg className="fig-edit-icon">
                        <use href={sprites + '#edit-icon'} />
                      </svg>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton onClick={this.onDelete}>
                      <svg className="fig-delete-icon">
                        <use href={sprites + '#delete-icon'} />
                      </svg>
                    </IconButton>
                  </Tooltip>
                  {/*                   
                  <Button className="fig-button highlights" onClick={this.onUpdateStreamsSelections}>
                    Update Charts Selection
                  </Button> */}
                </div>
              }
              title={
                <div>
                  <span>{this.props.highlight.userID}</span>
                  <Tooltip title={`Go To ${seconds2mssmm(this.props.highlight.startTime)}`}>
                    <Button
                      variant="text"
                      onClick={this.onGoTo}
                      className="highlights fig-video-details-highlight"
                      style={{
                        fontFamily: 'Inter, sans-serif',
                        fontSize: '10px',
                        fontWeight: 600,
                        paddingLeft: 0,
                      }}
                    >
                      @ {seconds2mssmm(this.props.highlight.startTime)}
                    </Button>
                  </Tooltip>
                  {<span style={{ marginLeft: 20 }}>{formatFigaroDate(this.props.highlight.created_at)}</span>}
                  {
                    <span style={{ marginLeft: 20 }}>
                      {`${Math.round(
                        getWordsCountOfUtterances(this.props.transcript, this.props.highlight.startTime, this.props.highlight.endTime) /
                          ((this.props.highlight.endTime - this.props.highlight.startTime) / 60)
                      )} wpm`}
                    </span>
                  }
                  <Tooltip title={`Select for Reporting`}>
                    <IconButton onClick={this.onSelect4Report} /*disabled={this.props.isSelected4Reporting}*/>
                      <ReportIcon fontSize="small" color={!this.props.isSelected4Reporting ? 'inherit' : 'secondary'} />
                    </IconButton>
                  </Tooltip>
                </div>
              }
            />
            <CardContent>
              <Typography
                variant="body1"
                component="span"
                {...(this.props.isPlaying && {
                  color: 'primary',
                })}
                style={textStyle}
              >
                {/* {this.props.highlight.text} */}
                {this.props.highlight.name}
              </Typography>
              <Typography variant="body1" component="button" disabled style={{ border: 'none', color: 'lightgray' }}>
                {config.dev.highlights
                  ? `(${seconds2mssmm(this.props.highlight.startTime)}, ${seconds2mssmm(this.props.highlight.endTime)})`
                  : ''}
              </Typography>
            </CardContent>
            <div className="fig-highlight-left-shape"></div>
          </Card>
        ) : null}
        {this.renderEditHLDialog()}
      </div>
    );
  }
}

export default AHighlight;
