import { Icon, Menu, MenuItem } from '@material-ui/core';
import IconStop from '@material-ui/icons/Stop';
import IconZoomIn from '@material-ui/icons/ZoomIn';
import React from 'react';
import config from '../../config/constants';

interface IProps {
  shouldOpen: boolean;
  left: number;
  top: number;
  height: number;
  onMenuAddHighlightClose: any;
  onMenuAddHighlight: any;
  onMenuAddHighlightAndGoto: any;
  onMenuFocus: any;
  onMenuDiscard: any;
}

class MenuAddHighlight extends React.PureComponent<IProps> {
  menuItemStyle = {
    dense: true,
    style: { color: config.theme.getPopupMenuColor() },
  };

  render() {
    // console.log('rendering MenuAddHighlight, props ', this.props);
    const { left, top } = this.props.shouldOpen ? this.props : { left: 0, top: 0 };
    return (
      <Menu
        keepMounted
        open={this.props.shouldOpen}
        onClose={this.props.onMenuAddHighlightClose}
        anchorReference="anchorPosition"
        anchorPosition={this.props.shouldOpen ? { left, top } : undefined}
      >
        {this.props.shouldOpen ? (
          <MenuItem onClick={this.props.onMenuAddHighlight} {...this.menuItemStyle}>
            <Icon>add</Icon>Highlight
          </MenuItem>
        ) : null}
        {this.props.shouldOpen ? (
          <MenuItem onClick={this.props.onMenuAddHighlightAndGoto} {...this.menuItemStyle}>
            <Icon>add</Icon>Highlight&Seek
          </MenuItem>
        ) : null}
        {this.props.shouldOpen ? (
          <MenuItem onClick={this.props.onMenuFocus} {...this.menuItemStyle}>
            <IconZoomIn />
            Focus
          </MenuItem>
        ) : null}
        {this.props.shouldOpen ? (
          <MenuItem onClick={this.props.onMenuDiscard} {...this.menuItemStyle}>
            <IconStop />
            Discard
          </MenuItem>
        ) : null}
      </Menu>
    );
  }
}
export default MenuAddHighlight;
