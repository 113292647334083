import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { createSelector } from 'reselect';
import { IRootState } from '../../../shared/reducers';
import { getAllFoldersSubmissions } from '../../actions/figaroApiActions';
import { SubmissionState } from '../../model/ApiTypes';
import BoldCloseButton from '../shared/BoldCloseButton';
import { ISelectableItem } from '../shared/BorderedSelector';
import AllVsMultiOptions from './AllVsMultiOptions';
import SingleOption from './SingleOption';

export interface IFilteringCategory {
  allName: string;
  multiItemGroupName: string;
  allMultiItems: string[];
  selectedMultiItems: string[];
}

type FilteringCategories = {
  [category: string]: IFilteringCategory;
};
export interface IFiltersState {
  allCategories: FilteringCategories;
  sortByItems: ISelectableItem[];
}

export const ARRAY_NONE = [];
export const ME_COACH = "Me (I'm the Coach)";
export const ME_PRESENTER = "Me (I'm the Presenter)";
export const STATE_ALL_VIDEOS_NAME = 'All Videos (Excluding Deleted)';
export const STATE_ALL_COACHES_NAME = 'All';
export const STATE_ALL_PRESENTERS_NAME = 'All';

export const CATEGORY_STATE = 'State';
export const CATEGORY_COACHES = 'Coaches';
export const CATEGORY_PRESENTER = 'Presenter';
export const CATEGORY_SORT = 'Sort By';

interface IProps extends PropsFromRedux {
  handleSave: (state: IFiltersState) => void;
  handleClose: () => void;
  singleCategory: string;
}

export const SubmissionId_Latest = 'Date Created';
export const Title_Latest = 'Title';
export const Coach_Latest = 'Coach';
export const Presenter_Latest = 'Presenter';

export const defaultSortByItems = [
  { key: SubmissionId_Latest, displayedValue: SubmissionId_Latest, selected: true },
  { key: Title_Latest, displayedValue: Title_Latest, selected: false },
  { key: Coach_Latest, displayedValue: Coach_Latest, selected: false },
  { key: Presenter_Latest, displayedValue: Presenter_Latest, selected: false },
];
class FilteringSortingDialog extends React.PureComponent<IProps, IFiltersState> {
  // sortCriteriaListAnchor: any = null;
  constructor(props: IProps) {
    super(props);

    this.state = {
      allCategories: {
        [CATEGORY_STATE]: {
          allName: STATE_ALL_VIDEOS_NAME,
          multiItemGroupName: null,
          allMultiItems: [
            SubmissionState.available,
            SubmissionState.requested,
            SubmissionState.processing,
            SubmissionState.error,
            SubmissionState.deleted,
          ],
          selectedMultiItems: props.selectedStates,
        },
        [CATEGORY_COACHES]: {
          allName: STATE_ALL_COACHES_NAME,
          multiItemGroupName: 'Selected Coach Videos',
          allMultiItems: props.allCoaches,
          selectedMultiItems: props.selectedCoaches,
        },
        [CATEGORY_PRESENTER]: {
          allName: STATE_ALL_PRESENTERS_NAME,
          multiItemGroupName: 'Selected Presenter Videos ',
          allMultiItems: props.allPresenters,
          selectedMultiItems: props.selectedPresenters,
        },
      },
      sortByItems: props.sortByItems,
    };
  }

  getTitle() {
    if (!this.props.singleCategory) {
      return 'Submissions Filtering and Sorting';
    } else if (this.props.singleCategory === CATEGORY_SORT) {
      return `Submissions Sorting`;
    } else {
      return `Submissions Filtering`;
    }

    //
  }

  render() {
    return (
      <Dialog open onClose={this.props.handleClose} fullWidth maxWidth="sm" scroll="paper">
        <BoldCloseButton width={14} height={14} top={15} right={15} handleClose={this.props.handleClose}></BoldCloseButton>
        <DialogTitle className="modal-confirmation-dialog__title" id="alert-dialog-title">
          {this.getTitle()}
        </DialogTitle>
        <DialogContent className="modal-confirmation-dialog__content">{this.renderFilteringOptions()}</DialogContent>
        <DialogActions className="padding--20">
          <Button onClick={this.props.handleClose} color="primary" className="fig-button in-modal-dialog">
            Cancel
          </Button>
          <Button onClick={() => this.props.handleSave(this.state)} color="primary" className="fig-button in-modal-dialog delete">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  renderFilteringOptions() {
    let categories = this.state.allCategories;
    console.log('FilteringOptions render props: ', this.props);
    return categories ? (
      <div className="row ">
        <div
          className="col no-padding"
          style={{
            position: 'relative',
            overflowY: 'auto',
          }}
        >
          <div className="fig-legend-options-pannel" style={{ position: 'relative' }}>
            <Grid container>
              {Object.keys(categories).map((category, idx) => {
                return !this.props.singleCategory || this.props.singleCategory === category ? (
                  <Grid key={category} item>
                    <AllVsMultiOptions
                      categoryName={category}
                      allMultiItems={categories[category].allMultiItems}
                      selectedMultiItems={categories[category].selectedMultiItems}
                      selectedItemsChanged={this.selectedItemsChanged}
                      allName={categories[category].allName}
                      multiItemGroupName={categories[category].multiItemGroupName}
                    ></AllVsMultiOptions>
                  </Grid>
                ) : null;
              })}
              {!this.props.singleCategory || this.props.singleCategory === CATEGORY_SORT ? (
                <Grid key={'sort'} item>
                  <SingleOption categoryName={CATEGORY_SORT} items={this.state.sortByItems} toggleItem={this.toggleSortBy}></SingleOption>
                </Grid>
              ) : null}
            </Grid>
          </div>
        </div>
      </div>
    ) : null;
  }

  selectedItemsChanged = (category: string, newSelections: string[]) => {
    const newSel: IFilteringCategory = { ...this.state.allCategories[category], selectedMultiItems: newSelections };
    this.setState({ allCategories: { ...this.state.allCategories, [category]: newSel } });
  };

  toggleSortBy = (key: string) => {
    console.log('toggle sort by ', key, this.state.sortByItems);
    let existingSelected = this.state.sortByItems.find((item) => item.key === key).selected;
    if (!existingSelected) {
      let newCriteria: ISelectableItem[] = [...this.state.sortByItems].map((item) => {
        return { selected: item.key === key ? true : false, key: item.key, displayedValue: item.displayedValue };
      });
      this.setState({ sortByItems: newCriteria }, () => {});
    }
  };
}

const selectorSubmissions = (state: IRootState) => state.submissions.submissionsMap;
const selectorMe = (state: IRootState) => state.authentication.user.username;
const retrieveAllCoaches = createSelector([selectorSubmissions, selectorMe], (submissionsMap, me) => {
  let rezult = [];
  if (submissionsMap) {
    Object.values(submissionsMap).forEach((submission) => {
      let coach = submission.createdby.username;
      if (!rezult.includes(coach) && coach !== me) {
        rezult.push(coach);
      }
    });
  }
  rezult.sort();
  return [ME_COACH].concat(rezult);
});
const retrieveAllPresenters = createSelector([selectorSubmissions, selectorMe], (submissionsMap, me) => {
  let rezult = [];
  if (submissionsMap) {
    Object.values(submissionsMap).forEach((submission) => {
      let user = submission.user ? submission.user.username : submission.email;
      if (!rezult.includes(user) && user !== me) {
        rezult.push(user);
      }
    });
  }
  rezult.sort();
  return [ME_PRESENTER].concat(rezult);
});

const mapStateToProps = (state: IRootState) => {
  console.log('VideosPanel mapStateToProps ', state);
  return {
    selectedVideoId: state.video.currentVideoId,
    authentication: state.authentication,
    submissions: state.submissions.submissionsMap,
    allCoaches: retrieveAllCoaches(state),
    allPresenters: retrieveAllPresenters(state),

    selectedCoaches: state.selections.filtersState[CATEGORY_COACHES],
    selectedStates: state.selections.filtersState[CATEGORY_STATE],
    selectedPresenters: state.selections.filtersState[CATEGORY_PRESENTER],
    sortByItems: state.selections.sortByItems,
  };
};

const mapDispatchToProps = {
  getAllFoldersSubmissions,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(FilteringSortingDialog);
