import React from 'react';
import { connect } from 'react-redux';
import VideosPanel from '../../../demo/components/submission/VideosPanel';
import { IRootState } from '../../../shared/reducers';
import Header from '../header/Header';

interface IProps {
  history: any;
  match: any;
}

class Videos extends React.Component<IProps, any> {
  // componentDidMount() {
  //   if (
  //     !(
  //       this.props.match &&
  //       this.props.match.params &&
  //       this.props.match.params.folder &&
  //       this.props.match.params.page &&
  //       !isNaN(Number.parseInt(this.props.match.params.folder, 10)) &&
  //       !isNaN(Number.parseInt(this.props.match.params.page, 10))
  //     )
  //   ) {
  //     this.props.history.push(`/folders/${VideosPanel.FAKE_FOLDER_ID_ALL}/${VideosPanel.INITIAL_PAGE}`);
  //   }
  // }

  render() {
    console.log('render VIdeos');
    let className = 'figaro-content';
    return (
      <div className="figaro-container" >
        <Header selectedMenu="videos" history={this.props.history} />
        <div className={className}>
          {/* {this.props.match &&
          this.props.match.params &&
          this.props.match.params.folder &&
          this.props.match.params.page &&
          !isNaN(Number.parseInt(this.props.match.params.folder, 10)) &&
          !isNaN(Number.parseInt(this.props.match.params.page, 10)) ? ( */}
          <VideosPanel match={this.props.match} history={this.props.history} />
          {/* ) : (
            <div />
          )} */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => {
  return {
    windowHeight: state.utils.windowHeight,
  };
};

export default connect(mapStateToProps, {})(Videos);
