import { Button, Grid, IconButton, List, ListItem, Tooltip, Typography } from '@material-ui/core';
import FilterIcon from '@material-ui/icons/FilterList';
// import FolderIcon from '@material-ui/icons/Folder';
// import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import Pagination from '@material-ui/lab/Pagination';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { connect, ConnectedProps } from 'react-redux';
import { createSelector } from 'reselect';
import { getVideosPath } from '../../../../app';
import sprites from '../../../../icons/fig-icons.svg';
import { setGlobalAlert } from '../../../actions/utils';
import { IRootState } from '../../../shared/reducers';
import {
  addSubmissionToFolder,
  createFolder,
  deleteFolder,
  deleteFolderSubmission,
  deleteSubmission,
  getAllFoldersSubmissions,
  getDatagroups,
  getDisfluencies,
  getFolders,
  getGlobalRubrics,
  getSubmissions,
  getSubmissionsAskedToOthers,
  getSubmissionsToUpload,
  updateSubmissionDetails,
} from '../../actions/figaroApiActions';
import { setSelectedCardsFiltersSorting } from '../../actions/selectionActions';
import { noVideoSelected, videoSelected } from '../../actions/videoActions';
import { getColor } from '../../config/constants';
import { Folder, FolderSubmission, Submission, SubmissionState } from '../../model/ApiTypes';
import { primaryColor } from '../echarts/macarons';
import DeleteConfirmationDialog from '../shared/DeleteConfirmationDialog';
import CreateFolderForm from './CreateFolderForm';
import { IEditSubmissionState } from './EditSubmission';
import FilteringSortingDialog, {
  CATEGORY_COACHES,
  CATEGORY_PRESENTER,
  CATEGORY_SORT,
  CATEGORY_STATE,
  Coach_Latest,
  IFiltersState,
  ME_COACH,
  ME_PRESENTER,
  Presenter_Latest,
  STATE_ALL_COACHES_NAME,
  STATE_ALL_PRESENTERS_NAME,
  STATE_ALL_VIDEOS_NAME,
  SubmissionId_Latest,
  Title_Latest,
} from './FilteringSortingDialog';
import SubmissionCard from './SubmissionCard';

// import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
// import FolderSpecialIcon from '@material-ui/icons/FolderSpecial';

interface IProps extends PropsFromRedux {
  history: any;
  match: any;
}

interface IState {
  selectedFolder: Folder;
  draggingSubmisionId: number;

  submissionsSelected: number[]; //even if this does not contain draggingSubmisionId, draggingSubmisionId will be added to folder

  currentPage: number;

  deleteFolderDialogOpened: boolean;
  deleteFolderSubmissionsDialogOpened: boolean;
  deleteSelectedSubmissionsDialogOpened: boolean;
  filteringDialogOpened: boolean;
  singleCategory: string; //not null if filtering Dlg should show single catgory
}

//alpha, asc
const sortAlpha = (a: string, b: string) =>
  a.toUpperCase() < b.toUpperCase() ? -1 : a.toUpperCase() > b.toUpperCase() ? 1 : a < b ? -1 : a > b ? +1 : 0;

//alpha,  asc, then numeric, desc
const sortAlphaThenNumeric = (a: string, b: string, aId: number, bId: number) => {
  const firstSorted = sortAlpha(a, b);
  if (!firstSorted) {
    //sort by id
    return -aId + bId;
  } else return firstSorted;
};

class VideosPanel extends React.PureComponent<IProps, IState> /*implements IVideoPlayer */ {
  // videoComponent: React.RefObject<IVideoPlayer> = null;
  videoComponent: React.RefObject<any> | null = null;

  constructor(props: IProps) {
    super(props);
    console.log('Videospanel ctor ', this.props);
    this.videoComponent = React.createRef();
    this.state = {
      //selectedFolder: VideosPanel.FAKE_FOLDER_ALL,
      selectedFolder: null,
      draggingSubmisionId: null,
      submissionsSelected: VideosPanel.NoSubmissionsSelected,
      currentPage: VideosPanel.INITIAL_PAGE,
      deleteFolderDialogOpened: false,
      deleteFolderSubmissionsDialogOpened: false,
      deleteSelectedSubmissionsDialogOpened: false,
      filteringDialogOpened: false,
      singleCategory: null,
    };
  }

  componentDidMount = () => {
    console.log('Videospanel did mount, reloading Submissions Meta DB with props/state ', this.props);
    this.loadSettings();
    this.reloadSubmissions();
    this.loadFolders();
  };
  componentWillUnmount = () => {
    console.log('Videospanel will unmount ', this.props);
  };

  //   if (id !== prevId) {
  //     // new ID received directly from the browser
  //     this.clearSelections();
  //     this.loadSubmission();

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    const folderId = Number.parseInt(this.props.match.params.folder, 10);
    const pageId = Number.parseInt(this.props.match.params.page, 10);
    if (this.props.submissions !== prevProps.submissions || this.props.foldersMap !== prevProps.foldersMap) {
      // video was changed
      console.log('VideosPanel submissions/folders changed, reset DnD selection state');
      let newState = { submissionsSelected: VideosPanel.NoSubmissionsSelected } as IState;
      if (
        !isNaN(folderId) &&
        !isNaN(pageId) &&
        (folderId === VideosPanel.FAKE_FOLDER_ID_ALL || this.props.foldersMap[folderId]) &&
        (!this.state.selectedFolder ||
          (folderId !== this.state.selectedFolder.id && this.props.foldersMap[folderId]) ||
          pageId !== this.state.currentPage)
      ) {
        newState.currentPage = pageId;
        newState.selectedFolder =
          folderId !== VideosPanel.FAKE_FOLDER_ID_ALL ? this.props.foldersMap[folderId] : VideosPanel.FAKE_FOLDER_ALL;
      }
      this.setState(newState);
    } else {
      if (isNaN(folderId) /*|| (folderId !== VideosPanel.FAKE_FOLDER_ID_ALL && !this.props.foldersMap[folderId])*/) {
        // console.log('componentDidUpdate, set state si pushez ', prevProps, prevState, this.props, this.state);
        this.setState({
          selectedFolder: VideosPanel.FAKE_FOLDER_ALL,
          currentPage: VideosPanel.INITIAL_PAGE,
          submissionsSelected: VideosPanel.NoSubmissionsSelected,
        });
        this.props.history.push(getVideosPath());
        return;
      } else if (
        !isNaN(folderId) &&
        !isNaN(pageId) &&
        (!this.state.selectedFolder || folderId !== this.state.selectedFolder.id || pageId !== this.state.currentPage)
      ) {
        if (folderId === VideosPanel.FAKE_FOLDER_ID_ALL || this.props.foldersMap[folderId]) {
          let newState = { submissionsSelected: VideosPanel.NoSubmissionsSelected } as IState;
          newState.currentPage = pageId;
          newState.selectedFolder =
            folderId !== VideosPanel.FAKE_FOLDER_ID_ALL ? this.props.foldersMap[folderId] : VideosPanel.FAKE_FOLDER_ALL;
          this.setState(newState);
        } else {
          let newState = { submissionsSelected: VideosPanel.NoSubmissionsSelected, selectedFolder: null } as IState;
          this.setState(newState);
        }
      }
    }
  }
  // whenever we reload, we loose the enhanced info about the submissions, so state should reflect that;
  // an explicit select needs be done to re-get the enhanced info
  reloadSubmissions() {
    this.props.getSubmissions(this.props.authentication.token);
  }
  loadFolders() {
    this.props.getFolders(this.props.authentication.token);
    this.props.getAllFoldersSubmissions(this.props.authentication.token);
  }

  onSubmissionSelect = (submission: Submission) => {
    // either this.props.noVideoSelected(); // or     // this.props.videoSelected(submission.id); but the SelectedVideoPanel component should anyway set it
    this.props.videoSelected(submission.id); // but the component should anyway set it
    this.props.history.push(`/video/${submission.id}`);
  };

  onToggleSelection = (submission: Submission) => {
    if (this.props.submissions && this.props.submissions[submission.id]) {
      const posSelected = this.state.submissionsSelected.indexOf(submission.id);
      if (posSelected !== -1) {
        let newArray = [...this.state.submissionsSelected];
        newArray.splice(posSelected, 1);
        this.setState({ submissionsSelected: newArray });
      } else {
        this.setState({ submissionsSelected: [...this.state.submissionsSelected, submission.id] });
      }
    }
  };
  onPageSelectAllCards = () => {
    let submissions = this.getCrtPageFolderCards();
    this.setState({
      submissionsSelected: submissions.map((submission) => submission.id),
    });
  };
  onUnSelectAllCards = () => {
    this.setState({
      submissionsSelected: VideosPanel.NoSubmissionsSelected,
    });
  };
  onDeleteSelectedCards = () => {
    if (this.state.selectedFolder !== VideosPanel.FAKE_FOLDER_ALL && this.getSelectedSubmissionsCounter()) {
      this.setState({ deleteSelectedSubmissionsDialogOpened: true });
    }
  };
  onShowFiltersDialog = (category: string) => (event) => {
    this.setState({ filteringDialogOpened: true, singleCategory: category });
  };

  getSelectedSubmissionsCounter = () => {
    return this.state.submissionsSelected.length;
  };
  getSelectedSubmissionNames = () => {
    return this.state.submissionsSelected.map((submissionId) => this.props.submissions[submissionId].name);
  };
  onDragStart = (info) => {
    console.log(`DRAG-n-Drop START: `, info);
    this.setState({ draggingSubmisionId: this.parseDnDFormattedId(info.source.droppableId) });
  };

  onDragEnd = (result) => {
    console.log(`DRAG-n-Drop full result `, result);
    const { destination, source } = result;
    if (!destination || !source) {
      this.setState({ draggingSubmisionId: null, submissionsSelected: VideosPanel.NoSubmissionsSelected });
      return;
    }
    console.log(
      `DRAG-n-Drop result: from source.droppableId ${source.droppableId}, source.index: ${source.index} to destination.droppableId ${destination.droppableId} , destination.index: ${destination.index}`
    );

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      this.setState({ draggingSubmisionId: null, submissionsSelected: VideosPanel.NoSubmissionsSelected });
      return;
    }
    let submissionId = this.parseDnDFormattedId(source.droppableId);
    let folderId = this.parseDnDFormattedId(destination.droppableId);
    if (!isNaN(submissionId) && !isNaN(folderId)) {
      let folder = this.props.foldersMap[folderId];
      this.addFolderSubmissionIfNotExisting(folder, submissionId);
      //add the remaining submissions
      this.state.submissionsSelected.forEach((selectedSubmissionId) => {
        if (selectedSubmissionId !== submissionId) {
          this.addFolderSubmissionIfNotExisting(folder, selectedSubmissionId);
        }
      });
    } else {
      this.props.setGlobalAlert(`Invalid DnD ID(s): submissionId: ${submissionId}, folderId:  ${folderId}!`);
    }
    this.setState({ draggingSubmisionId: null, submissionsSelected: VideosPanel.NoSubmissionsSelected });
  };

  addFolderSubmissionIfNotExisting = (folder: Folder, submissionId: number) => {
    if (this.isFolderSubmission(folder, submissionId)) {
      this.props.setGlobalAlert('Folder ' + folder.name + ' already contains submission ' + submissionId);
    } else {
      this.props.addSubmissionToFolder({ name: folder.name }, submissionId, this.props.authentication.token);
    }
  };

  parseDnDFormattedId = (id: string) => {
    let parts = id.split('_');
    if (!parts || parts.length < 2) {
      console.warn('Unexpected DnD ID: ', id);
      return null;
    }
    return parseInt(parts[1], 10);
  };

  static TYPE_DROPPABLE_SUBMISSION_TAG = 'TYPE_DROPPABLE_SUBMISSION_TAG';

  // renderFolderDropTarget = (folder: Folder) => {
  //   return (
  //     <Droppable
  //       droppableId={`DROPPABLECONTAINERID4FOLDER_${folder.id}`}
  //       type={VideosPanel.TYPE_DROPPABLE_SUBMISSION_TAG}
  //       key={`dropTgt_${folder.id}`}
  //       isDropDisabled={
  //         this.state.selectedFolder ===
  //         folder /*|| this.isFolderSubmission(folder, this.state.draggingSubmisionId) TODO FFS check for all selected cards?*/
  //       }
  //     >
  //       {(provided, snapshot) => (
  //         <div
  //           className="row "
  //           style={{
  //             width: '100%',
  //           }}
  //         >
  //           <div
  //             className="col "
  //             key="droppable"
  //             id="droppable"
  //             ref={provided.innerRef}
  //             {...provided.droppableProps}
  //             style={{
  //               minWidth: '100%',
  //               width: '100%',
  //               maxWidth: '100%',
  //               padding: 0,
  //               margin: 0,
  //               height: 1,
  //               opacity: 1,

  //               fontSize: 8,
  //               fontWeight: 800,
  //               fontStyle: 'italic',
  //               textAlign: 'center',
  //               alignContent: 'center',
  //               color: primaryColor,
  //             }}
  //           >
  //             {snapshot.isDraggingOver ? '---------> drop submission(s) here <---------' : null}
  //           </div>
  //         </div>
  //       )}
  //     </Droppable>
  //   );
  // };

  applySortingCriteria = (submissions: Submission[]) => {
    let selectedSortCriteria = this.props.sortByItems.find((item) => item.selected).key;
    switch (selectedSortCriteria) {
      case SubmissionId_Latest:
        submissions.sort((s1, s2) => -s1.id + s2.id);
        break;
      case Title_Latest:
        //submissions.sort((s1, s2) => sortAlpha(s1.name, s2.name));
        submissions.sort((s1, s2) => sortAlphaThenNumeric(s1.name, s2.name, s1.id, s2.id));
        break;
      case Coach_Latest:
        //submissions.sort((s1, s2) => sortAlpha(s1.createdby.username, s2.createdby.username));
        submissions.sort((s1, s2) => sortAlphaThenNumeric(s1.createdby.username, s2.createdby.username, s1.id, s2.id));
        break;
      case Presenter_Latest:
        //submissions.sort((s1, s2) => sortAlpha(s1.user ? s1.user.username : s1.email, s2.user ? s2.user.username : s2.email));
        submissions.sort((s1, s2) =>
          sortAlphaThenNumeric(s1.user ? s1.user.username : s1.email, s2.user ? s2.user.username : s2.email, s1.id, s2.id)
        );
    }
    return submissions;
  };

  applyFilters = (submmissions: Submission[]) => {
    let filteredSubmissions = submmissions.filter((submission) => {
      if (
        (this.props.filtersState[CATEGORY_STATE].length > 0 && this.props.filtersState[CATEGORY_STATE].includes(submission.state)) ||
        (this.props.filtersState[CATEGORY_STATE].length === 0 && submission.state !== SubmissionState.deleted)
      ) {
        return true;
      }
      return false;
    });
    filteredSubmissions = filteredSubmissions.filter((submission) => {
      if (
        (this.props.filtersState[CATEGORY_COACHES].length > 0 &&
          (this.props.filtersState[CATEGORY_COACHES].includes(submission.createdby.username) ||
            (this.props.filtersState[CATEGORY_COACHES].includes(ME_COACH) &&
              submission.createdby.username === this.props.authentication.user.username))) ||
        this.props.filtersState[CATEGORY_COACHES].length === 0
      ) {
        return true;
      }
      return false;
    });
    filteredSubmissions = filteredSubmissions.filter((submission) => {
      if (
        (this.props.filtersState[CATEGORY_PRESENTER].length > 0 &&
          (this.props.filtersState[CATEGORY_PRESENTER].includes(submission.user ? submission.user.username : submission.email) ||
            (this.props.filtersState[CATEGORY_PRESENTER].includes(ME_PRESENTER) &&
              this.props.authentication.user.username === (submission.user ? submission.user.username : submission.email)))) ||
        this.props.filtersState[CATEGORY_PRESENTER].length === 0
      ) {
        return true;
      }
      return false;
    });
    return filteredSubmissions;
  };

  render() {
    console.log('Videospanel render ', this.props);
    if (!this.state.selectedFolder) {
      return <div> </div>;
      //return <div >NO SELECTED FOLDER IN VIDEO PANEL</div>; //TODO FFS RENDER SOMETHING?
    }
    let folderSubmissions = this.getCrtFolderCardsFilteredSorted();
    let pageSubmissions = this.getCrtPageCards(folderSubmissions);
    return (
      <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
        <div className="row no-gutters fig-rubrics" style={{ height: '100%' }}>
          <div className="col-12 " style={{ height: '100%' }}>
            <div className="fig-rubrics-header">{this.renderCardsFolderHeader()}</div>

            <div className="row">
              <div className="col-3 fig-videos-left-side">
                {this.renderFoldersList()}
                <CreateFolderForm createFolder={this.onCreateFolder} />
                {this.renderRequestVideo()}
              </div>
              <div className="col-9 fig-videos-right-side">
                {/* {this.renderCardsFolderHeader()} */}
                {this.renderFiteringSortingDialog()}
                {/* todo render crt filter options */}
                {this.renderCards(pageSubmissions)}
                {this.renderPagination(folderSubmissions.length)}
                {this.renderDeleteFolderConfirmation()}
                {this.renderDeleteFolderSubmissionsConfirmation()}
                {this.renderDeleteFolderSelectedSubmissionsConfirmation()}
              </div>
            </div>
          </div>
        </div>
      </DragDropContext>
    );
  }
  closeFilterinSortingDialog = () => {
    this.setState({ filteringDialogOpened: false });
  };
  saveFiltersAndSortingCriteria = (newState: IFiltersState) => {
    this.props.setSelectedCardsFiltersSorting(
      {
        [CATEGORY_STATE]: newState.allCategories[CATEGORY_STATE].selectedMultiItems,
        [CATEGORY_COACHES]: newState.allCategories[CATEGORY_COACHES].selectedMultiItems,
        [CATEGORY_PRESENTER]: newState.allCategories[CATEGORY_PRESENTER].selectedMultiItems,
      },
      newState.sortByItems
    );
    this.setState({
      filteringDialogOpened: false,
    });
  };

  renderFiteringSortingDialog() {
    return this.state.filteringDialogOpened ? (
      <FilteringSortingDialog
        handleSave={this.saveFiltersAndSortingCriteria}
        handleClose={this.closeFilterinSortingDialog}
        singleCategory={this.state.singleCategory}
      />
    ) : null;
  }

  renderDeleteFolderSelectedSubmissionsConfirmation() {
    return this.state.deleteSelectedSubmissionsDialogOpened ? (
      <DeleteConfirmationDialog
        handleDelete={this.onDeleteFolderSelectedSubmissionsConfirmed}
        handleClose={this.closeFolderSelectedSubmissionsConfirmationDialog}
        title={`Remove ${this.getSelectedSubmissionsCounter()} Videos from Folder`}
        text={`Are you sure you want to remove the selected videos from folder "${this.state.selectedFolder.name}"? `}
      />
    ) : null;
  }
  renderDeleteFolderSubmissionsConfirmation() {
    return this.state.deleteFolderSubmissionsDialogOpened ? (
      <DeleteConfirmationDialog
        handleDelete={this.onDeleteFolderSubmissionsConfirmed}
        handleClose={this.closeFolderSubmissionsConfirmationDialog}
        title={'Delete All Videos from Folder'}
        text={`Are you sure you want to remove all the videos from folder "${this.state.selectedFolder.name}"? `}
      />
    ) : null;
  }

  renderDeleteFolderConfirmation() {
    return this.state.deleteFolderDialogOpened ? (
      <DeleteConfirmationDialog
        handleDelete={this.onDeleteFolderConfirmed}
        handleClose={this.closeFolderConfirmationDialog}
        title={'Delete Folder'}
        text={`Are you sure you want to delete the folder "${this.state.selectedFolder.name}"? This will only delete the folder but not the videos.`}
      />
    ) : null;
  }
  renderRequestVideo() {
    return (
      <div className="fig-videos-button-row margin-top--10 fig-horizontal-center">
        <Button variant="outlined" className="fig-button video-panel " onClick={this.onRequestVideo}>
          Request Video
        </Button>
      </div>
    );
  }

  renderPagination(submissionCount: number) {
    return submissionCount ? (
      <div className="fig-videos-pagination-mainbox">
        <Pagination
          className="fig-videos-pagination-holder"
          count={Math.ceil(submissionCount / VideosPanel.PAGE_SIZE)}
          page={this.state.currentPage}
          onChange={this.onPageChange}
          color="secondary"
          variant="outlined"
          siblingCount={1}
          boundaryCount={2}
        />
      </div>
    ) : null;
  }
  onPageChange = (event, value) => {
    this.setState({ currentPage: value });
    console.log('videosPanel push ', this.state.selectedFolder.id, value);
    this.props.history.push(getVideosPath(this.state.selectedFolder.id, value));
  };
  onRequestVideo = () => {
    this.props.history.push(`/requestvideo`);
  };
  renderCardsFolderHeader() {
    if (!this.state.selectedFolder) {
      return null;
    }
    const isFolderAll = VideosPanel.FAKE_FOLDER_ALL === this.state.selectedFolder;
    const tagsCounter = isFolderAll
      ? this.props.submissions
        ? Object.keys(this.props.submissions).length
        : 0
      : this.props.folder2SubmissionsMap && this.props.folder2SubmissionsMap[this.state.selectedFolder.name]
      ? this.props.folder2SubmissionsMap[this.state.selectedFolder.name].length
      : 0;
    return (
      <div className="row no-gutters fig-videos-right-side-header w-100">
        <div
          className="col-5 fig-vertical-middle"
          style={
            {
              /*color: getColor(0) */
            }
          }
        >
          {/* <PermMediaIcon /*htmlColor={'primary'}* style={{ marginLeft: '10px', marginRight: '10px' }} /> */}
          <b>{this.state.selectedFolder.name}</b>
        </div>
        <div className="col-7 fig-align-right fig-vertical-middle">
          <Button
            className="fig-button margin-right--20"
            variant="outlined"
            onClick={() => {
              this.setState({ deleteFolderSubmissionsDialogOpened: true });
            }}
            color="secondary"
            style={{
              visibility: isFolderAll ? 'hidden' : 'inherit',
            }}
            disabled={isFolderAll || !tagsCounter ? true : false}
          >
            EMPTY FOLDER
          </Button>
          <Button
            className="fig-button margin-right--20"
            variant="outlined"
            color="secondary"
            style={{
              visibility: isFolderAll ? 'hidden' : 'inherit',
            }}
            //onClick={this.onDeleteFolder(this.state.selectedFolder.id)}
            onClick={() => {
              this.setState({ deleteFolderDialogOpened: true });
            }}
            disabled={isFolderAll || tagsCounter ? true : false}
          >
            DELETE FOLDER
          </Button>
          {this.renderSelectBtns(tagsCounter, this.state.submissionsSelected.length)}
        </div>
      </div>
    );
  }

  renderSelectBtns(tagsCounter: number, selectedCounter: number) {
    const isFolderAll = this.state.selectedFolder === VideosPanel.FAKE_FOLDER_ALL;
    return (
      <>
        <Tooltip title="Select All Submissions " key="selectAll">
          <IconButton
            size="small"
            style={{ marginTop: '1px', marginRight: '2px' /*float: 'right',*/ /*visibility: !tagsCounter ? 'hidden' : 'inherit'*/ }}
            onClick={this.onPageSelectAllCards}
            // checked={true}
            color="primary"
            // disabled={!tagsCounter}
          >
            <SelectAllIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Unselect All Submissions" key="unselectAll">
          <IconButton
            size="small"
            style={{
              marginTop: '1px',
              marginRight: '2px',
              // visibility: !selectedCounter ? 'hidden' : 'inherit',
              color: '#c0c0c0',
            }}
            onClick={this.onUnSelectAllCards}
            color="default"
            // checked={true}
            // disabled={!selectedCounter}
          >
            <SelectAllIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={`Remove Selected Submissions From This Folder`} key="deleteSelected">
          <IconButton
            aria-label="deleteSelectedVideos"
            size="small"
            onClick={this.onDeleteSelectedCards}
            color="default"
            style={{
              width: 23,
              height: 23,
              visibility: isFolderAll ? 'hidden' : 'inherit',
            }}
            // disabled={isFolderAll ? true : false}
          >
            <svg className="fig-icon-delete">
              <use href={sprites + '#delete-icon'} />
            </svg>
          </IconButton>
        </Tooltip>
      </>
    );
  }

  deleteAllSelectedFolderTags = (event) => {
    this.state.selectedFolder &&
      this.props.folder2SubmissionsMap &&
      this.props.folder2SubmissionsMap[this.state.selectedFolder.name] &&
      this.props.folder2SubmissionsMap[this.state.selectedFolder.name].forEach((folderSubmission) => {
        console.log('deleting tag ', folderSubmission.id, folderSubmission);
        this.props.deleteFolderSubmission(folderSubmission.id, this.props.authentication.token);
      });
  };
  onDeleteFolderSelectedSubmissionsConfirmed = () => {
    this.setState({ deleteSelectedSubmissionsDialogOpened: false });
    if (this.state.selectedFolder && this.props.folder2SubmissionsMap && this.props.folder2SubmissionsMap[this.state.selectedFolder.name]) {
      let folderSubmissions = this.props.folder2SubmissionsMap[this.state.selectedFolder.name];
      this.state.submissionsSelected.forEach((selectedSubmissionId) => {
        let tag2delete = folderSubmissions.find((folderSubmission) => folderSubmission.submission.id === selectedSubmissionId);
        if (tag2delete) {
          this.props.deleteFolderSubmission(tag2delete.id, this.props.authentication.token);
        }
      });
    }
  };
  closeFolderSelectedSubmissionsConfirmationDialog = () => {
    this.setState({ deleteSelectedSubmissionsDialogOpened: false });
  };

  onDeleteFolderSubmissionsConfirmed = () => {
    this.setState({ deleteFolderSubmissionsDialogOpened: false });
    this.state.selectedFolder &&
      this.props.folder2SubmissionsMap &&
      this.props.folder2SubmissionsMap[this.state.selectedFolder.name] &&
      this.props.folder2SubmissionsMap[this.state.selectedFolder.name].forEach((folderSubmission) => {
        console.log('deleting tag ', folderSubmission.id, folderSubmission);
        this.props.deleteFolderSubmission(folderSubmission.id, this.props.authentication.token);
      });
  };
  closeFolderSubmissionsConfirmationDialog = () => {
    this.setState({ deleteFolderSubmissionsDialogOpened: false });
  };

  static NoSubmissionsSelected = [] as number[];
  static INITIAL_PAGE = 1;
  onFolderClick = (folder: Folder) => (event) => {
    if (this.state.selectedFolder !== folder) {
      this.setState({
        selectedFolder: folder,
        submissionsSelected: VideosPanel.NoSubmissionsSelected,
        currentPage: VideosPanel.INITIAL_PAGE,
      });
      console.log('videosPanel push ', folder.id, 1);
      this.props.history.push(getVideosPath(folder.id, VideosPanel.INITIAL_PAGE));
    }
  };

  //called from the CreateFolderForm
  onCreateFolder = (name) => {
    this.props.createFolder({ name }, this.props.authentication.token);
  };
  // onDeleteFolder = (folderId: number) => (event) => {
  //   if (this.state.selectedFolder && this.state.selectedFolder.id === folderId) {
  //     //this.setState({ selectedFolder: VideosPanel.FAKE_FOLDER_ALL, currentPage: VideosPanel.INITIAL_PAGE });
  //     this.props.history.push(`/videos/${VideosPanel.FAKE_FOLDER_ALL.id}/${VideosPanel.INITIAL_PAGE}`);
  //   }
  //   this.props.deleteFolder(folderId, this.props.authentication.token);
  //   // event.preventDefault();
  //   // event.stopPropagation();
  // };
  onDeleteFolderConfirmed = () => {
    if (
      this.props.folder2SubmissionsMap[this.state.selectedFolder.name] &&
      this.props.folder2SubmissionsMap[this.state.selectedFolder.name].length
    ) {
      this.setState({ deleteFolderDialogOpened: false });
    } else {
      this.setState({
        selectedFolder: VideosPanel.FAKE_FOLDER_ALL,
        currentPage: VideosPanel.INITIAL_PAGE,
        deleteFolderDialogOpened: false,
      });
      this.props.history.push(getVideosPath());
    }
    this.props.deleteFolder(this.state.selectedFolder.id, this.props.authentication.token);
  };
  closeFolderConfirmationDialog = () => {
    this.setState({ deleteFolderDialogOpened: false });
  };

  getFoldersSorted = () => {
    return [...Object.keys(this.props.foldersMap)].sort((a, b) => sortAlpha(this.props.foldersMap[a].name, this.props.foldersMap[b].name));
  };
  renderFoldersList() {
    return (
      <div className="fig-videos-all-folders-list">
        <List component="div" aria-labelledby="nested-list-subheader">
          <div key={VideosPanel.FAKE_FOLDER_ID_ALL}>{this.renderFolder(VideosPanel.FAKE_FOLDER_ALL)}</div>
          {this.getFoldersSorted().map((folderId) => (
            <div key={folderId}>{this.renderDroppableFolder(this.props.foldersMap[folderId])}</div>
          ))}
        </List>
      </div>
    );
  }

  public static FAKE_FOLDER_ID_ALL = -1;
  public static FAKE_FOLDER_ALL = { name: 'All Videos', id: VideosPanel.FAKE_FOLDER_ID_ALL } as Folder;

  renderDroppableFolder = (folder: Folder) => {
    return (
      <Droppable
        droppableId={`DROPPABLECONTAINERID4FOLDER_${folder.id}`}
        type={VideosPanel.TYPE_DROPPABLE_SUBMISSION_TAG}
        key={`dropTgt_${folder.id}`}
        isDropDisabled={
          this.state.selectedFolder ===
          folder /*|| this.isFolderSubmission(folder, this.state.draggingSubmisionId) TODO FFS check for all selected cards?*/
        }
      >
        {(provided, snapshot) => (
          <div
            id={`dropTgt_${folder.id}`}
            className="row "
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              width: '100%',
              backgroundColor: snapshot.isDraggingOver ? '#F2F2F2' : 'inherit',
              transition: 'background-color 0.3s ease',
            }}
          >
            <div
              className="col hide"
              // hide it for now
              key="dropmsg"
              id="dropmsg"
              style={{
                minWidth: '100%',
                width: '100%',
                maxWidth: '100%',
                padding: 0,
                margin: 0,
                height: 1,
                opacity: 1,
                fontSize: 8,
                fontWeight: 800,
                fontStyle: 'italic',
                textAlign: 'center',
                alignContent: 'center',
                color: primaryColor,
              }}
            >
              {/* {snapshot.isDraggingOver ? '---------> drop to add here <---------' : null} UNCOMMENT THIS IF PREFERENCE IS TO DISPLAY DnD HINT*/}
            </div>
            {this.renderFolder(folder)}
          </div>
        )}
      </Droppable>
    );
  };

  renderFolder = (folder: Folder) => {
    return (
      <ListItem
        className={`${this.isSelectedFolder(folder) ? 'selected' : ''}`}
        key={folder.id}
        {...(!this.state.draggingSubmisionId && {
          button: true,
        })}
      >
        <div className="fig-video-folder-row col" onClick={this.onFolderClick(folder)}>
          <div className="row fig-space-between fig-vertical-middle">
            <div
              className="fig-video-folder-name"
              style={{
                color: this.isSelectedFolder(folder) ? getColor(0) : '#1B335E',
              }}
            >
              <PermMediaIcon htmlColor={this.isSelectedFolder(folder) ? 'primary' : 'gray'} />
              {`${folder.name}`}
            </div>
            <div
              className="fig-video-label-numbers"
              style={{
                color: !this.isSelectedFolder(folder) ? '#1B335D' : getColor(0),
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  color: !this.isSelectedFolder(folder) ? '#1B335E' : getColor(0),
                  borderRadius: this.state.draggingSubmisionId ? 0 : 5,
                }}
              >
                {this.isRealFolder(folder)
                  ? this.props.folder2SubmissionsMap && this.props.folder2SubmissionsMap[folder.name]
                    ? this.props.folder2SubmissionsMap[folder.name].length
                    : 0
                  : this.props.submissions !== null
                  ? Object.keys(this.props.submissions).length
                  : 0}
              </div>
            </div>
          </div>
        </div>
      </ListItem>
    );
  };

  //check if real folder or the "virtual" "All" folder
  isRealFolder(folder: Folder) {
    return folder.id >= 0;
  }
  isSelectedFolder(folder: Folder) {
    return folder.id === this.state.selectedFolder.id;
  }

  isFolderSubmission = (folder: Folder, submissionId: number) => {
    if (folder && submissionId && this.props.submission2FoldersMap) {
      return (
        this.props.submission2FoldersMap[submissionId] &&
        this.props.submission2FoldersMap[submissionId].some((folderSubmission) => folderSubmission.name === folder.name)
      );
    }
    return false;
  };

  getCrtFolderCardsNotFiltered = () => {
    if (!this.props.submissions) {
      return [];
    }
    return Object.values(this.props.submissions).filter(
      (submission) =>
        this.state.selectedFolder === VideosPanel.FAKE_FOLDER_ALL || this.isFolderSubmission(this.state.selectedFolder, submission.id)
    );
    //.sort((a, b) => new Date(b.submitdate).getTime() - new Date(a.submitdate).getTime());
    //.sort((a, b) => a.id - b.id)
  };

  getCrtFolderCardsFilteredSorted = () => {
    return this.applySortingCriteria(this.applyFilters(this.getCrtFolderCardsNotFiltered()));
  };
  static PAGE_SIZE = 6; //TODO FFS adjust f(screensize)

  getCrtPageFolderCards = () => {
    let folderSubmissions = this.getCrtFolderCardsFilteredSorted();
    return this.getCrtPageCards(folderSubmissions);
  };
  getCrtPageCards = (allSubmissions: Submission[]) => {
    let pageSubmissions = allSubmissions.slice(
      (this.state.currentPage - 1) * VideosPanel.PAGE_SIZE,
      this.state.currentPage * VideosPanel.PAGE_SIZE
    );
    return pageSubmissions;
  };

  static EMPTY_TAGS: FolderSubmission[] = [];
  renderCards(allSubmissions: Submission[]) {
    console.log('render list with  currentSubmissionId ', this.props.selectedVideoId, ', selectedFolder:  ', this.state.selectedFolder);

    const rez =
      this.props.submissions !== null ? (
        <div style={{ height: `calc(100% - 62px)` /* space 4 pagination */ }}>
          {this.renderFilterSortHeader()}
          <Grid
            container
            spacing={0}
            style={{ height: `calc(100% - 39px)` /* space for filters */, overflowY: 'auto', alignItems: 'center', margin: 'auto' }}
          >
            {allSubmissions.map((submission) => {
              return (
                <Grid item key={submission.id} xs={12} sm={6} md={4}>
                  <Droppable
                    key={`dropable4submission_${submission.id}`}
                    droppableId={`dropable4submission_${submission.id}`}
                    type={VideosPanel.TYPE_DROPPABLE_SUBMISSION_TAG}
                    isDropDisabled={true}
                  >
                    {(provided4Droppable) => {
                      return (
                        <div ref={provided4Droppable.innerRef} {...provided4Droppable.droppableProps}>
                          <Draggable
                            key={`draggable4submission_${submission.id}`}
                            draggableId={`draggable4submission_${submission.id}`}
                            index={0}
                          >
                            {(provided4Dragable, snapshot4Dragable) => {
                              return (
                                <>
                                  {/* <div key={submission.id}> */}
                                  <SubmissionCard
                                    key={submission.id}
                                    submission={submission}
                                    highlighted={false}
                                    onSelect={this.onSubmissionSelect}
                                    allFolders={this.props.allFolders}
                                    taggedFolders={
                                      this.props.submission2FoldersMap && this.props.submission2FoldersMap[submission.id]
                                        ? this.props.submission2FoldersMap[submission.id]
                                        : VideosPanel.EMPTY_TAGS
                                    }
                                    deleteFolderSubmission={this.onDeleteFolderSubmission}
                                    addFolderSubmission={this.onAddFolderSubmission}
                                    provided4Dragable={provided4Dragable}
                                    snapshot4Dragable={snapshot4Dragable}
                                    toggleDnDSelectionFor={this.onToggleSelection}
                                    isSelectedForDnD={this.state.submissionsSelected.includes(submission.id)}
                                    getSelectedForDnDCounter={this.getSelectedSubmissionsCounter}
                                    getSelectedForDnDNames={this.getSelectedSubmissionNames}
                                    login={this.props.authentication.user}
                                    saveSubmissionDetails={this.saveSubmissionDetails}
                                    deleteSubmission={this.deleteSubmission}
                                    setGlobalAlert={this.props.setGlobalAlert}
                                  ></SubmissionCard>
                                  {/* </div> */}
                                </>
                              );
                            }}
                          </Draggable>

                          {/* NO LONGER USED SINCE WE RENDER THE CLONE */}
                          <div style={{ display: 'none' }}>{provided4Droppable.placeholder}</div>
                        </div>
                      );
                    }}
                  </Droppable>
                </Grid>
              );
            })}
          </Grid>
        </div>
      ) : null;
    return rez;
  }

  /* FILTERS */
  renderFilterSortHeader = () => {
    return (
      <div className="row margin-top--10  no-gutters filters-header">
        <div className="col-1">
          <div>
            <Tooltip title="Set all filtering and sorting options" placement="bottom-start">
              <IconButton className="col-1 filter-button" onClick={this.onShowFiltersDialog(null)} size="small">
                {/* <svg className="fig-filters-icon">
                  <use href={sprites + '#filters-icon'} />
                </svg> */}
                <FilterIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        <div className="col-11">
          <div className="row">
            {this.renderFilterHeaderCategory(CATEGORY_STATE, this.renderFilterStateDetails(), 'Set filtering by submission State options')}
            {this.renderFilterHeaderCategory(
              CATEGORY_COACHES,
              this.renderFilterCoachesDetails(),
              'Set filtering by submission Coach options'
            )}
            {this.renderFilterHeaderCategory(
              CATEGORY_PRESENTER,
              this.renderFilterPresentersDetails(),
              'Set filtering by submission Presenter options'
            )}
            {this.renderFilterHeaderCategory(
              CATEGORY_SORT,
              this.props.sortByItems.find((criteria) => criteria.selected).displayedValue,
              'Set submissions Sort options'
            )}
          </div>
        </div>
      </div>
    );
  };

  renderFilterHeaderCategory(categoryTitle: string, categoryContents: JSX.Element | JSX.Element[] | string, tooltip?: string) {
    return (
      <Tooltip title={tooltip} placement="bottom-start">
        <div className="col-3 fig-direction-column" onClick={this.onShowFiltersDialog(categoryTitle)}>
          <Typography variant="body1" component="span" className="fig-filters-title-header">
            {categoryTitle}
          </Typography>
          <Typography variant="body2" component="span" className="fig-filters-results">
            {categoryContents}
          </Typography>
        </div>
      </Tooltip>
    );
  }

  renderFilterCoachesDetails = () => {
    if (this.props.filtersState[CATEGORY_COACHES].length) {
      let others = 0;
      let me = false;
      this.props.filtersState[CATEGORY_COACHES].forEach((coach) => {
        if (coach === ME_COACH) {
          me = true;
        } else {
          others++;
        }
      });
      return (
        <span>
          {me ? 'Me ' : ''}
          {me && others ? '+' : ''}
          {others ? others + ' Coaches' : ''}
        </span>
      );
    } else {
      return <span>{STATE_ALL_COACHES_NAME}</span>;
    }
  };

  renderFilterPresentersDetails = () => {
    if (this.props.filtersState[CATEGORY_PRESENTER].length) {
      let others = 0;
      let me = false;
      this.props.filtersState[CATEGORY_PRESENTER].forEach((presenter) => {
        if (presenter === ME_PRESENTER) {
          me = true;
        } else {
          others++;
        }
      });
      return (
        <span>
          {me ? 'Me ' : ''}
          {me && others ? '+' : ''}
          {others ? others + ' Presenters' : ''}
        </span>
      );
    } else {
      return <span>{STATE_ALL_PRESENTERS_NAME}</span>;
    }
  };

  renderFilterStateDetails = () => {
    if (this.props.filtersState[CATEGORY_STATE].length) {
      return this.props.filtersState[CATEGORY_STATE].map((state) => (
        <span className="fig-filters-results__each">{state.substr(0, 1).toUpperCase()}</span>
      ));
    } else {
      return <span>{STATE_ALL_VIDEOS_NAME}</span>;
    }
  };

  addToFolder = (submission: Submission, folderIdx) => (event) => {
    if (this.props.foldersMap) {
      let folders = Object.values(this.props.foldersMap);
      if (folders.length >= folderIdx && folderIdx >= 1) {
        this.props.addSubmissionToFolder({ name: folders[folderIdx - 1].name }, submission.id, this.props.authentication.token);
        return;
      }
    }
    this.props.setGlobalAlert('Folder does not exist');
  };

  deleteSubmissionTags = (submissionId) => (event) => {
    this.props.submission2FoldersMap &&
      this.props.submission2FoldersMap[submissionId] &&
      this.props.submission2FoldersMap[submissionId].forEach((folderSubmission) => {
        console.log('deleting tag ', folderSubmission.id, folderSubmission);
        this.props.deleteFolderSubmission(folderSubmission.id, this.props.authentication.token);
      });
  };
  onDeleteFolderSubmission = (folderSubmission) => {
    console.log('deleting tag ', folderSubmission.id, folderSubmission);
    this.props.deleteFolderSubmission(folderSubmission.id, this.props.authentication.token);
  };
  onAddFolderSubmission = (folder: Folder, submission: Submission) => {
    console.log('adding tag ', folder.name, submission.name);
    this.props.addSubmissionToFolder({ name: folder.name }, submission.id, this.props.authentication.token);
  };
  static ARRAY_EMPTY_FOLDERS = [] as Folder[];

  saveSubmissionDetails = (submission: Submission, values: IEditSubmissionState) => {
    console.log('saving submission details for ', submission, values);
    this.props.updateSubmissionDetails(submission.id, values, this.props.authentication.token);
  };
  deleteSubmission = (submission) => {
    console.log('deleting submisssion ', submission.id, submission);
    this.props.removeSubmission(submission.id, this.props.authentication.token);
  };
  loadSettings() {
    if (!this.props.disfluenciesLoaded) this.props.getDisfluencies(this.props.authentication.token);
    if (!this.props.dataGroupsLoaded) this.props.getDatagroups(this.props.authentication.token);
    if (!this.props.globalRubricsLoaded) this.props.getGlobalRubrics(this.props.authentication.token);
  }
}

const selectorFolders = (state: IRootState) => state.folders.foldersMap;
const retrieveAllFoldersArray = createSelector([selectorFolders], (foldersMap) =>
  foldersMap ? Object.values(foldersMap) : VideosPanel.ARRAY_EMPTY_FOLDERS
);

const mapStateToProps = (state: IRootState) => {
  console.log('VideosPanel mapStateToProps ', state);
  return {
    selectedVideoId: state.video.currentVideoId,
    authentication: state.authentication,
    submissions: state.submissions.submissionsMap,
    foldersMap: state.folders.foldersMap,
    // allFolders: state.folders.foldersMap ? Object.values(state.folders.foldersMap) : [], BAD, leads to rerender of ALL cards at  submission(s)  DnD
    allFolders: retrieveAllFoldersArray(state),
    folder2SubmissionsMap: state.folders.folder2SubmissionsMap,
    submission2FoldersMap: state.folders.submission2FoldersMap,
    filtersState: state.selections.filtersState,
    sortByItems: state.selections.sortByItems,

    disfluenciesLoaded: state.settings.disfluencies && true,
    globalRubricsLoaded: state.settings.globalRubricsMap && true,
    dataGroupsLoaded:
      state.settings.allFaceCategories &&
      state.settings.allVoiceCategories &&
      Object.keys(state.settings.allFaceCategories).length > 0 &&
      Object.keys(state.settings.allVoiceCategories).length > 0,
  };
};

const mapDispatchToProps = {
  getSubmissionsAskedToOthers,
  getSubmissionsToUpload,
  getSubmissions,
  videoSelected,
  noVideoSelected,
  getFolders,
  createFolder,
  deleteFolder,
  getAllFoldersSubmissions,
  addSubmissionToFolder,
  deleteFolderSubmission,
  updateSubmissionDetails,
  removeSubmission: deleteSubmission,
  getDisfluencies,
  getDatagroups,
  getGlobalRubrics,
  setGlobalAlert,
  setSelectedCardsFiltersSorting,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(VideosPanel);
