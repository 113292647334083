import { Button } from '@material-ui/core';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import { createRubric, deleteRubric, getRubrics, updateRubric } from '../../actions/figaroApiActions';
import { getRubricBase } from '../../model/ApiTypes';
import RubricsTable from './RubricsTable';

interface IProps extends PropsFromRedux {
  history: any;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IState {}

class RubricsPanel extends React.PureComponent<IProps, IState> {
  componentDidMount = () => {
    this.props.getRubrics(this.props.authentication.token);
  };
  componentWillUnmount = () => {
    console.log('Rubricspanel will unmount ', this.props);
  };

  render() {
    console.log('rubricspanel render ', this.props);
    const rubrics = this.props.rubrics;
    const rows = rubrics
      ? Object.keys(rubrics).map((rubricId) => {
          let { id, name, updated_at, type, createdby } = rubrics[rubricId];
          return { id, name, updated_at, type, createdby: createdby.username };
        })
      : [];
    return (
      <div className="row no-gutters fig-rubrics">
        <div className="col-12">
          <div className="fig-rubrics-header">
            <span>Rubrics</span>
            <Button className="fig-button rubric-primary"  size="medium" onClick={this.createRubric}>
              Create A New Rubric
            </Button>
          </div>
          <div className="fig-rubrics-table-holder" >
            <RubricsTable
              rows={rows}
              handleDelete={this.deleteRubric}
              handleClone={this.cloneRubric}
              handleEdit={this.editRubric}
            ></RubricsTable>
          </div>
        </div>
      </div>
    );
  }
  createRubric = () => {
    this.props.history.push(`/rubrics/new`);
  };
  deleteRubric = (id) => {
    this.props.deleteRubric(id, this.props.authentication.token);
  };
  editRubric = (id) => {
    this.props.history.push(`/rubrics/${id}`);
  };

  updateRubric = () => {
    this.props.updateRubric(
      3,
      {
        name: 'updatedName',
        template: {
          categories: [
            { name: 'updateFirst Category', questions: ['updatePresentation', 'Content'] },
            { name: 'updateSecond Category', questions: ['Face Expession', '2'] },
          ],
        },
      },
      this.props.authentication.token
    );
  };
  cloneRubric = (id) => {
    let newRubric = getRubricBase(this.props.rubrics[id]);
    newRubric.name = `Clone of ${newRubric.name}`;
    this.props.createRubric(newRubric, this.props.authentication.token);
  };
}

const mapStateToProps = (state: IRootState) => {
  console.log('RubricsPanel mapStateToProps ', state);
  return {
    authentication: state.authentication,
    rubrics: state.rubrics.rubricsMap,
  };
};

const mapDispatchToProps = {
  getRubrics,
  deleteRubric,
  updateRubric,
  createRubric,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(RubricsPanel);
