// Login

import { Coords } from './Video';

export interface LoginRequest {
  identifier: string;
  password: string;
}

export interface LoginResponse {
  jwt: string;
  user: IUser;
}

export interface IUser {
  id: number;
  username: string;
  email: string;
  provider: string;
  confirmed: boolean;
  blocked?: any;
  role: IRole; // TODO ASK in SUSER raspunsul la Submit  rolul este doar ca ID, aici e struct
  created_at: string;
  updated_at: string;
}

export interface IRole {
  id: number;
  name: string;
  description: string;
  type: string;
}

export const API_ROLE_USER = 'User'; // TODO ASK Wing
export const API_ROLE_COACH = 'Coach'; // TODO ASK Wing

// Highlights

export interface HighlightRequest {
  submission: number;
  state: string; // // Possible States: public, shared, private, removed TODO ASK REMOVED
  name: string;
  description: string;
  starttime: number;
  endtime: number;
  options?: HLOptions;
}

export interface HighlightResponse {
  id: number;
  submission: CommentResponseSubmission;
  name: string;
  description: string;
  starttime: number;
  endtime: number;
  state: string;
  options?: HLOptions;
  data?: any;
  created_at: string;
  updated_at: string;
  createdby: SUser;
}

export interface HLOptions {
  face_state: FaceState;
  voice_state: VoiceState;
}

export interface VoiceState {
  threshold: number;
  highlight?: boolean;
  playing?: boolean;
  selections: string[];
}

export interface FaceState {
  threshold: number;
  highlight?: boolean;
  playing?: boolean;
  selections: FaceSelections;
}

export interface FaceSelections {
  [faceTag: string]: string[];
}
// End Highlights

export interface FaceTagRequest {
  name: string;
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}
// Comments

export interface CommentRequest {
  submission?: number;
  state?: string; // Possible States: public, shared, private, removed
  comment?: string;
  videotime?: number;
  options?: { annotation?: Annotation };
}

export interface CommentResponseSubmission extends VarSubmissionInfo {
  id: number;
  user: number; // TODO ASK Submission din response de la postComment are doar user = number, createdBy = number , pe cand in Submission Response de la getSubmissions, user si createdBy sunts struct SUser
  email?: any;
  analysis: Analysis;
  data?: any;
  uuid: string;
  source: string;
  createdby: number; // TODO ASK Submission din response de la postComment are doar user = number, createdBy = number , pe cand in Submission Response de la getSubmissions, user si createdBy sunts struct SUser
  created_at: string;
}

//info that might change during submission lifespan
//FFS to which extent can the changes be promptly reflected in the GUI
export interface VarSubmissionInfo {
  name: string;
  details: string;
  state: string;
  options: Options;
  deadline: string;
  submitdate: string;
  updated_at: string;
}

/*builds VarSubmissionInfo out of the given submission */
export const extractVarInfo = (submission: Submission) => {
  let { name, details, state, options, deadline, submitdate, updated_at } = submission;
  return { name, details, state, options, deadline, submitdate, updated_at } as VarSubmissionInfo;
};
/*builds a VarSubmissionInfo with standard fields and fields order
  FFS "options" object?
*/
export const toStdVarInfo = (submission: VarSubmissionInfo) => {
  let { name, details, state, options, deadline, submitdate, updated_at } = submission;
  return { name, details, state, options, deadline, submitdate, updated_at } as VarSubmissionInfo;
};
// true if the updated VarSubmissionInfo differs from VarSubmissionInfo of the initial submission
export const differs = (submission: Submission, updated: VarSubmissionInfo) => {
  return JSON.stringify(extractVarInfo(submission)) !== JSON.stringify(toStdVarInfo(updated));
};

export interface CommentResponse {
  id: number;
  state: string;
  comment: string;
  videotime: number;
  options: CommentOptions;
  data?: any;
  submission: CommentResponseSubmission;
  createdby: SUser;
  created_at: string;
  updated_at: string;
}

interface CommentOptions {
  annotation: Annotation;
}

interface Annotation {
  x: number;
  y: number;
}
// End Comments

// Submissions
// actually, Submission Response
export interface Submission extends VarSubmissionInfo {
  id: number;
  user: SUser; // TODO ASK Submission din response de la postComment are doar user = number, createdBy = number , pe cand in asta, user si createdBy sunts struct SUser
  email?: any; // TODO ASK
  analysis: Analysis; //TODO FFS AnalysisExt ?
  data?: any; // TODO ASK
  uuid: string; // TODO ASK
  source: string; // TODO ASK
  createdby: SUser; // TODO ASK Submission din response de la postComment are doar user = number, createdBy = number , pe cand in asta, user si createdBy sunts struct SUser
  created_at: string;
  threshold?: number;
}

export const SubmissionState = {
  requested: 'requested',
  available: 'available',
  processing: 'processing',
  deleted: 'deleted',
  error: 'error', //TODO FFS
} as const;

//TODO FFS REDO this duplicates most part of Submission .. todo deduplicate .....AnalysisExt ?
export interface SubmissionUploadInfo extends VarSubmissionInfo {
  id: number;
  user: SUser;
  email?: any;
  analysis: { 'aws-upload': AWSUploadFields };
  data?: any;
  uuid: string;
  source: string;
  createdby: SUser;
  created_at: string;
  threshold?: number;
}

export interface Options {
  hide_rubric_evaluators?: boolean;
  text_search?: string[];
}

export type AnalysisExt = Analysis | { 'aws-upload': AWSUploadFields };

export interface Analysis {
  faces: FaceAnalysisItem[];
  video: AnalysisItem;
  voice: AnalysisItem;
  charts: AnalysisItem;
  source: string; // TODO ASK this seems to be the url base of all pieces, once the video has been uploaded
  sentiment: AnalysisItem;
  thumbnail: AnalysisItem;
  transcript: AnalysisItem;
}
export interface AWSUploadFields {
  // "url": "https://s3.amazonaws.com/fig-rocan",
  // "fields": {
  //     "key": "dev1/videos/db80f558-e646-4d9a-bf60-149a536667b8/video",
  //     "bucket": "fig-rocan",
  //     "X-Amz-Algorithm": "AWS4-HMAC-SHA256",
  //     "X-Amz-Credential": "AKIARCOUHIHKEXIR6NPV/20210618/us-east-1/s3/aws4_request",
  //     "X-Amz-Date": "20210618T071753Z",
  //     "Policy": "eyJleHBpcmF0aW9uIjoiMjAyMS0wNi0xOFQxNToxNzo1M1oiLCJjb25kaXRpb25zIjpbWyJzdGFydHMtd2l0aCIsIiRDb250ZW50LVR5cGUiLCJ2aWRlby8iXSxbImNvbnRlbnQtbGVuZ3RoLXJhbmdlIiwwLDUxMjAwMDAwMF0seyJrZXkiOiJkZXYxL3ZpZGVvcy9kYjgwZjU1OC1lNjQ2LTRkOWEtYmY2MC0xNDlhNTM2NjY3YjgvdmlkZW8ifSx7ImJ1Y2tldCI6ImZpZy1yb2NhbiJ9LHsiWC1BbXotQWxnb3JpdGhtIjoiQVdTNC1ITUFDLVNIQTI1NiJ9LHsiWC1BbXotQ3JlZGVudGlhbCI6IkFLSUFSQ09VSElIS0VYSVI2TlBWLzIwMjEwNjE4L3VzLWVhc3QtMS9zMy9hd3M0X3JlcXVlc3QifSx7IlgtQW16LURhdGUiOiIyMDIxMDYxOFQwNzE3NTNaIn1dfQ==",
  //     "X-Amz-Signature": "54c468f2930bf2484efa8cd8454f71d7f8d08864d173c0a8a6c9f834235bae85"
  url: string;
  fields: {
    key: string;
    bucket: string;
    'X-Amz-Algorithm': string;
    'X-Amz-Credential': string;
    'X-Amz-Date': string;
    Policy: string;
    'X-Ams-Signature': string;
  };
}

export const AnalysisItemStatus = {
  hidden: 'hidden',
  available: 'available',
  processing: 'processing',
  hasData(status: string) {
    return status === AnalysisItemStatus.available || status === AnalysisItemStatus.hidden;
  },
} as const;

export interface AnalysisItem {
  url: string;
  status: string;
  message: string;
}

export interface FaceAnalysisItem extends AnalysisItem {
  name: string;
  tag?: Coords;
}

export interface SUser {
  id: number;
  username: string;
  email: string;
  provider: string;
  confirmed: boolean;
  blocked?: any;
  role: number;
  created_at: string;
  updated_at: string;
}
// End Submissions

export interface SettingsDisfluencies {
  id: number;
  key: string;
  options: DisfluencyOptions;
  created_at: string;
  updated_at: string;
}

export interface DisfluencyOptions {
  name: string;
  words: string[];
}

export interface SettingsDatagroups {
  id: number;
  key: string;
  options: DatagroupOptions;
  created_at: string;
  updated_at: string;
}

export interface DatagroupOptions {
  name: string;
  groups: StreamGroups;
}

export interface StreamGroups {
  Face: StreamCategories;
  Voice: StreamCategories;
}

export interface StreamCategories {
  [category: string]: string[];
}
// End  DataGroups
//Folders
export interface Folder extends FolderBase {
  id: number;
  name: string;
  data?: any;
  createdby: SUser;
  created_at: string;
  updated_at: string;
}
export interface FolderBase {
  name?: string;
}

export interface FolderSubmission {
  id: number;
  name: string;
  data?: any;
  createdby: SUser;
  created_at: string;
  updated_at: string;
  submission: { id: number };
}

//End Folders

// Rubrics
export interface Rubric extends RubricBase {
  id: number;
  name: string;
  type: string;
  template: Template;
  data?: any;
  createdby: SUser;
  created_at: string;
  updated_at: string;
}
export interface RubricBase {
  name?: string;
  type?: string;
  template?: Template;
  data?: any;
}
export const getRubricBase = (rubric: Rubric) => {
  let newRubric = { ...rubric };
  delete newRubric.created_at;
  delete newRubric.createdby;
  delete newRubric.id;
  delete newRubric.updated_at;
  return newRubric as RubricBase;
};
export const fakeRubric = (rubricBase: RubricBase, id: number) => {
  let newRubric = { ...rubricBase, created_at: null, createdby: null, updated_at: null, id: id };
  return newRubric as Rubric;
};
export interface Template {
  categories: Category[];
}

export interface Category {
  name: string;
  questions: string[];
  //FFS POC evaluations - show just server-computed averages for regular users
  averages?: number[];
}

// End Rubrics
//Submission Rubrics
export interface SubmissionRubricTemplate extends Template {
  score: number;
}

export interface SubmissionRubric {
  submission: number;
  name: string;
  state: string;
  rubric: SubmissionRubricTemplate;
  id?: number;
  createdby?: SUser;
}
export interface SubmissionRubricResponse {
  id: number;
  submission: CommentResponseSubmission;

  state: string;
  name: string;
  rubric: SubmissionRubricTemplate;

  data?: any;
  createdby: SUser;
  created_at: string;
  updated_at: string;
}

//End Submisssion Rubrics

export interface ShareBase {
  id?: number;
  user?: IUser; // null if shared to email not matching figaro user
  state?: string;
  email?: string; // null if shared to email matching figaro user
  type?: any;
  data?: any;
  createdby?: SUser;
  created_at?: string;
  updated_at?: string;
}

export const getEmailAddress = (share: ShareBase) => {
  if (share.user) return share.user.email;
  else return share.email;
};
export interface SubmissionShareResponse extends ShareBase {
  submission: CommentResponseSubmission;
}

export interface SubmissionRubricInEvaluationResponse {
  //TODO FFS this is similar to SubmissionRubricResponse, but submission is od-only, not  full object
  id: number;
  submission: number;

  state: string;
  name: string;
  rubric: SubmissionRubricTemplate;

  data?: any;
  createdby: SUser;
  created_at: string;
  updated_at: string;
}
export interface EvaluationResponse {
  categories: Score[];
}
export interface Score {
  response: number[];
  averages?: number[]; //this is used just in the response to UPDATE submissionevaluations
}
export interface SubmissionRubricEvaluationResponse {
  id: number;
  submissionrubrics: SubmissionRubricInEvaluationResponse;
  evaluation: EvaluationResponse;
  state: string;
  data?: any;
  submission: CommentResponseSubmission;
  createdby: SUser;
  created_at: string;
  updated_at: string;
}
