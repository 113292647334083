import { Button, TextField, Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import config from '../../config/constants';
import { AnalysisItemStatus, FaceAnalysisItem } from '../../model/ApiTypes';
import { primaryColor } from '../echarts/macarons';
interface IProps {
  updateTagStatus: (videoId: string, tag: FaceAnalysisItem) => void;
  updateTagName: (videoId: string, tag: FaceAnalysisItem) => void;
  tags: FaceAnalysisItem[];
  selectedVideoId: string;
}
interface IState {
  isEditing: boolean[];
  tagNames: string[];
}

// const headCells = [
//   { id: 'name', numeric: false, label: 'Face Tags' },
//   { id: 'edit', numeric: false, label: '' },
//   { id: 'show', numeric: false, label: '' },
// ];

export default class TagsTable extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.tags !== this.props.tags) {
      this.setState(this.getInitialState());
    }
  }
  getInitialState = () => {
    return { isEditing: this.props.tags.map((tag) => true), tagNames: this.props.tags.map((tag) => tag.name) };
  };
  handleEditSave = (index) => (event) => {
    let isEditing = this.state.isEditing[index];
    let tag = this.props.tags[index];
    if (isEditing) {
      //save
      this.props.updateTagName(this.props.selectedVideoId, {
        ...tag,
        name: this.state.tagNames[index],
      });
      //this.setState({ isEditing: this.state.isEditing.map((entry, idx) => (idx === index ? !entry : entry)) });
    } else {
      //allow editing
      this.setState({ isEditing: this.state.isEditing.map((entry, idx) => (idx === index ? !entry : entry)) });
    }
  };

  handleNameChange = (index) => (e: { target: { value: string } }) => {
    let isEditing = this.state.isEditing[index];
    if (isEditing) {
      this.setState({ tagNames: this.state.tagNames.map((name, idx) => (idx === index ? e.target.value : name)) });
    }
  };

  handleStatusChange = (index) => (e) => {
    const tag = this.props.tags[index];
    const isHidden = tag.status === AnalysisItemStatus.hidden;
    const isProcessing = tag.status === AnalysisItemStatus.processing;
    if (!isProcessing) {
      this.props.updateTagStatus(this.props.selectedVideoId, {
        ...tag,
        status: isHidden ? AnalysisItemStatus.available : AnalysisItemStatus.hidden,
      });
    }
  };

  onEnterKey = (index: number) => (e: any) => {
    if (e.which === 13) {
      this.handleEditSave(index)(e);
    }
  };
  render() {
    let { tags } = this.props;

    return (
      <div>
        <TableContainer className="padding-bottom--40">
          <Table aria-labelledby="tableTitle" size={'small'} aria-label="enhanced table" stickyHeader>
            <TableBody>
              {tags.map((tag: FaceAnalysisItem, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                const nameModified = tag.name !== this.state.tagNames[index];
                const nameEmpty = !this.state.tagNames[index] || this.state.tagNames[index].length === 0;
                const disabled = !nameModified || nameEmpty;

                const saveEditBtn = (
                  <IconButton aria-label="edit" size="small" onClick={this.handleEditSave(index)} disabled={!nameModified || nameEmpty}>
                    {!this.state.isEditing[index] ? (
                      <EditIcon fontSize="large" style={{ color: nameModified && !nameEmpty ? primaryColor : 'inherit' }} />
                    ) : (
                      <DoneIcon fontSize="large" style={{ color: nameModified && !nameEmpty ? primaryColor : 'inherit' }} />
                    )}
                  </IconButton>
                );

                return (
                  <TableRow hover role="checkbox" aria-checked={false} tabIndex={-1} key={tag.url} selected={false}>
                    <TableCell>
                      <TextField
                        className="col-12"
                        value={this.state.tagNames[index]}
                        onChange={this.handleNameChange(index)}
                        required
                        disabled={!this.state.isEditing[index]}
                        error={nameEmpty}
                        onKeyPress={this.onEnterKey(index)}
                        InputLabelProps={{
                          shrink: true,
                          required: false,
                        }}
                        placeholder={'Enter Speaker Name'}
                        style={tag.status === AnalysisItemStatus.hidden ? { color: 'gray', textDecoration: 'line-through' } : {}}
                      />
                      <Button disabled  style={{ border: 'none', textTransform: 'capitalize' }}>
                        {tag.tag && config.dev.tags && !isNaN(tag.tag.x1) && !isNaN(tag.tag.x2) && !isNaN(tag.tag.y1) && !isNaN(tag.tag.y2)
                          ? `coords: (${tag.tag.x1.toFixed(2)}, ${tag.tag.y1.toFixed(2)}) (${tag.tag.x2.toFixed(2)}, ${tag.tag.y2.toFixed(
                              2
                            )} )`
                          : ''}
                      </Button>
                    </TableCell>

                    <TableCell align="justify" component="th" id={labelId} scope="row">
                      {disabled ? (
                        saveEditBtn
                      ) : (
                        <Tooltip title={!this.state.isEditing[index] ? 'Edit Name' : 'Save'}>{saveEditBtn}</Tooltip>
                      )}
                    </TableCell>

                    <TableCell align="justify" className="text-center">
                      {tag.status === AnalysisItemStatus.processing ? (
                        'processing ...'
                      ) : (
                        <Button
                          className="fig-button"
                          size="small"
                          color="primary"
                          variant="outlined"
                          onClick={this.handleStatusChange(index)}
                        >
                          {tag.status === AnalysisItemStatus.hidden ? 'Show' : 'Hide'}
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}

// handleDelete: (tagID: string) => void;
// handleTagPositioning: (tag: FaceAnalysisItem) => void;
// onDelete = (event) => {
//   this.props.handleDelete(this.props.tag.url);
// };
// onTag = (event) => {
//   this.props.handleTagPositioning(this.props.tag);
// };
