import { Icon, Menu, MenuItem } from '@material-ui/core';
import IconPlayArrow from '@material-ui/icons/PlayArrow';
import IconZoomIn from '@material-ui/icons/ZoomIn';
import React from 'react';
import config from '../../config/constants';

interface IProps {
  shouldOpen: boolean;
  left: number;
  top: number;
  height: number;
  onMenuEditHighlightClose: any;
  onMenuGotoHighlight: any;
  onMenuDeleteHighlight: any;
  onMenuEditHighlight: any;
  onMenuSelectHighlight: any;
  doSelect: boolean;
}

class MenuEditHighlight extends React.PureComponent<IProps> {
  menuItemStyle = {
    dense: true,
    style: { color: config.theme.getPopupMenuColor() },
  };

  render() {
    // console.log('rendering MenuEditHighlight, props ', this.props);
    return (
      <Menu
        keepMounted
        open={this.props.shouldOpen}
        onClose={this.props.onMenuEditHighlightClose}
        anchorReference="anchorPosition"
        anchorPosition={
          this.props.shouldOpen
            ? {
                left: this.props.left,
                top: this.props.top,
              }
            : undefined
        }
      >
        {this.props.shouldOpen ? (
          <MenuItem onClick={this.props.onMenuSelectHighlight} {...this.menuItemStyle}>
            <IconPlayArrow />
            {this.props.doSelect ? 'Select Highlight' : 'UnSelect Highlight'}
          </MenuItem>
        ) : null}
        {this.props.shouldOpen ? (
          <MenuItem onClick={this.props.onMenuGotoHighlight} {...this.menuItemStyle}>
            <IconPlayArrow />
            Focus
          </MenuItem>
        ) : null}
        {this.props.shouldOpen ? (
          <MenuItem onClick={this.props.onMenuDeleteHighlight} {...this.menuItemStyle}>
            <Icon>remove</Icon>Highlight
          </MenuItem>
        ) : null}
        {this.props.shouldOpen ? (
          <MenuItem onClick={this.props.onMenuEditHighlight} {...this.menuItemStyle}>
            <IconZoomIn />
            Edit Times
          </MenuItem>
        ) : null}
      </Menu>
    );
  }
}
export default MenuEditHighlight;
