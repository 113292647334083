import React from 'react';
import config from '../../config/constants';
import { UterranceProps } from '../../model/Video';
import { IVideoPlayer } from '../video/VideoRenderer';
import Utterance from './Utterance';

interface IProps {
  start: number;
  end: number;
  data: UterranceProps[];
  thresholdExceeded: boolean[];
  showHighlights: boolean;
  showExceeded: boolean;
  idx: number;
  currentTime: number;

  theVideoPlayer: IVideoPlayer;
  onLinkClick: any;
  onLinkDoubleClick: any;
  onLinkMouseUp: any;
  utteranceIdToElementId: any;
  isCurrentUtterance: any;
  foundTermInfo: boolean[];
  currentFound: number[];
}

class Chunk extends React.PureComponent<IProps, any> {
  render() {
    const utterances = this.props.data.slice(this.props.start, this.props.end + 1);
    const rez = utterances.map((data: UterranceProps, idx) => {
      const isCurrent = this.props.currentTime >= 0 ? this.props.isCurrentUtterance(data.data) : false;
      // if (isCurrent) console.log(crtSecond, data, ' is current: ', isCurrent);
      const childProps = { ...utterances[idx] };
      childProps.exceedsThreshold = this.props.thresholdExceeded[this.props.start + idx];
      if (!this.props.showHighlights) {
        childProps.highlighted = false;
      }
      if (!this.props.showExceeded) {
        childProps.exceedsThreshold = false;
      }

      const { onLinkClick, onLinkDoubleClick, onLinkMouseUp, utteranceIdToElementId } = this.props;
      const uttIdx = this.props.idx * config.transcript.chunkLength + idx;
      childProps.found = this.props.foundTermInfo[uttIdx]; // overiden
      childProps.foundAndSeeked = this.props.currentFound && this.props.currentFound.includes(uttIdx); // overiden

      // eslint-disable-next-line no-constant-condition
      return true /* childProps.isntGap */ ? (
        <Utterance
          key={uttIdx}
          theVideoPlayer={this.props.theVideoPlayer}
          {...childProps}
          isPlaying={isCurrent}
          idx={uttIdx}
          onLinkClick={onLinkClick}
          onLinkDoubleClick={onLinkDoubleClick}
          onLinkMouseUp={onLinkMouseUp}
          utteranceIdToElementId={utteranceIdToElementId}
        ></Utterance>
      ) : null;
    });
    return rez;
  }
}

export default Chunk;
