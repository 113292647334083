import { ListItem, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import React, { FormEvent } from 'react';
import sprites from '../../../../icons/fig-icons.svg';

interface IProps {
  createFolder: any;
}

interface IState {
  newFolderName?: string;
  inCreateFolder: boolean;
}

class CreateFolderForm extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { newFolderName: '', inCreateFolder: false };
  }

  render() {
    return (
      <div className="fig-videos-button-row">
        {this.state.inCreateFolder ? (
          <ListItem disableGutters>
            <div className="w-100">
              <div className="row">
                <div className="fig-create-folder-box">
                  <PermMediaIcon color={'secondary'} fontSize="small" style={{ marginRight: 10 }} />
                  {/* <CreateNewFolderIcon color={'secondary'} fontSize="small" style={{ marginRight: 10 }} /> */}

                  <TextField
                    className="fig-create-folder-input-box"
                    color="secondary"
                    margin="none"
                    onKeyUp={this.onCreateFolderEnterKey}
                    onChange={this.handleInputChange}
                    inputProps={{ autoFocus: true }}
                    value={this.state.newFolderName}
                  />
                  {/* <Button
                    size="small"
                    color="secondary"
                    variant="text"
                    className="col-1"
                    style={{
                      textTransform: 'capitalize',
                      fontWeight: 'bolder',
                      maxWidth: 27,
                      minWidth: 27,
                      padding: '6px 6px',
                      height: 27,
                      margin: 'auto',
                      textAlign: 'center',
                      borderRadius: 5,
                      background: '#F2F2F2',
                      marginLeft: 23,
                    }}
                    onClick={this.onCreateFolderExit}
                  >
                    X
                  </Button> */}
                  <Button onClick={this.onCreateFolderExit}>
                    <svg className="fig-close-icon">
                      <use href={sprites + '#close-icon'} />
                    </svg>
                  </Button>
                </div>
              </div>
            </div>
          </ListItem>
        ) : (
          <div />
        )}
        <div className="fig-horizontal-center">
          <Button variant="outlined" className="fig-button video-panel" onClick={this.onCreateFolderClick}>
            CREATE FOLDER
          </Button>
        </div>
      </div>
    );
  }
  handleInputChange = (event) => {
    this.setState({ newFolderName: event.target.value });
  };

  onCreateFolderClick = (event) => {
    this.setState({ inCreateFolder: true });
  };
  onCreateFolderExit = (event) => {
    this.setState({ inCreateFolder: false, newFolderName: '' });
  };
  onCreateFolderEnterKey = (e: any) => {
    console.log(e.which);
    if (e.which === 13) {
      this.onCreateFolder(e);
    } else if (e.which === 27) {
      this.onCreateFolderExit(e);
    }
  };

  onCreateFolder = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log('onCreateFolder', this.state);
    if (!this.state.newFolderName || this.state.newFolderName.length === 0) {
      return;
    } else {
      let name = this.state.newFolderName;
      this.setState({ newFolderName: '', inCreateFolder: false });
      this.props.createFolder(name);
    }
  };
}
export default CreateFolderForm;
