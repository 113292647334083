import { TimeWindow } from '../model/Video';

export const TIME_WINDOW_SELECTED = 'TIME_WINDOW_SELECTED';
export type EventNames = typeof TIME_WINDOW_SELECTED; // |

interface TimeWindowSelectedEvent {
  type: typeof TIME_WINDOW_SELECTED;
  payload: { timeWindow: TimeWindow };
}
export type CustomEventTypes = TimeWindowSelectedEvent; // |

class CustomEvents extends EventTarget {
  subscribe(eventName: EventNames, cb: EventListener) {
    window.addEventListener(eventName, cb);
  }
  unsubscribe(eventName: EventNames, cb: EventListener) {
    window.removeEventListener(eventName, cb);
  }
  emit(event: CustomEventTypes) {
    window.dispatchEvent(new CustomEvent(event.type, { detail: event.payload }));
  }
}
export const events = new CustomEvents();
