/* eslint-disable no-console */
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { createSelector } from 'reselect';
import { IRootState } from '../../../shared/reducers';
import { addImage, setSelectedChartsTab } from '../../actions/selectionActions';
import { getColor } from '../../config/constants';
import { AnalysisItemStatus } from '../../model/ApiTypes';
import { ALL_EMOTIONS, StreamRef } from '../../model/Video';
import { SpeakerEmotionsRates } from './ChartForSpeaker';
import ChartsTabs from './ChartsTabs';
import graphUtils, { isComplexityStream } from './utils';

interface IProps extends PropsFromRedux {}

interface IState {}

export class Charts extends React.PureComponent<IProps, IState> {
  graphContainer: any = null;

  constructor(props: IProps) {
    super(props);
    this.state = {};
    console.log(' Charts constructor called, props ', props);
  }
  // shouldComponentUpdate(nextProps, nextState, nextContext) {
  //   console.log('should comp update graph nextProps: ', nextProps, ' nextState: ', nextState);
  //   // return super.shouldComponentUpdate(nextProps, nextState, nextContext);
  //   return true;
  // }

  componentDidMount() {}
  componentWillUnmount() {
    console.log('WILL UNmount');
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.props.selectedVideoId !== prevProps.selectedVideoId || this.props.speakersEmotionsRates !== prevProps.speakersEmotionsRates) {
    }
  }

  render() {
    console.log('*****Charts RENDER*********** ');
    if (!this.props.selectedVideoId || !this.props.speakersEmotionsRates || this.props.speakersEmotionsRates.length === 0) {
      console.log('*****RENDER*********** Charts intoarce div kior');
      return <div />;
    }
    const start = new Date().getTime();
    let height = window.innerHeight - 200;
    if (height < 0) height = 30;

    const rez = (
      <div className="row" style={{ height, width: '100%' }}>
        <div
          className={'col '}
          style={{ height, width: '100%' }}
          ref={(internalComp) => {
            this.graphContainer = internalComp;
          }}
        >
          <div className="row " style={{ height, overflowY: 'auto', width: '100%' }}>
            <ChartsTabs
              containerHeight={height}
              speakersEmotionsRates={this.props.speakersEmotionsRates}
              setSelectedChartsTab={this.props.setSelectedChartsTab}
              selectedTab={this.props.selectedChartsTab}
              addImage={this.props.addImage}
            ></ChartsTabs>
          </div>
        </div>
      </div>
    );
    console.log('*****RENDER*********** Charts took ', new Date().getTime() - start, ' returned ', rez);
    return rez;
  }

  /*  renderOld() {
    console.log('*****Charts RENDER*********** ');
    if (!this.props.selectedVideoId || !this.props.speakersEmotionsRates || this.props.speakersEmotionsRates.length === 0) {
      console.log('*****RENDER*********** Charts intoarce div kior');
      return <div />;
    }
    const start = new Date().getTime();
    // console.log('*****RENDER*********** AGraph for ', this.props.currentTime);
    //TODO FFS COMPUTE ADI CSS
    let height = window.innerHeight - 200;
    if (height < 0) height = 30;

    const rez = (
      <div className="row" style={{ height, overflowY: 'auto', width: '100%' }}>
        <div
          className={'col '}
          style={{ height, overflowY: 'auto', width: '100%' }}
          ref={(internalComp) => {
            this.graphContainer = internalComp;
          }}
        >
          {this.props.speakersEmotionsRates.map((speakerRates, idx) => (
            <ChartsForSpeaker
              {...speakerRates}
              speakersCount={this.props.speakersEmotionsRates.length}
              key={speakerRates.analysisTag + idx}
              // added idx as a workaround for cases when bakend returns mutiple fae analyses having the same tag video=20
              height={height}
            />
          ))}
        </div>
      </div>
    );
    console.log('*****RENDER*********** Charts took ', new Date().getTime() - start, ' returned ', rez);
    return rez;
  }*/
}

const selectorVerbalEmotions = (state: IRootState) => {
  // console.log('graph: SELECTOR faces called with params ', state);
  if (
    state.video.currentVideoId &&
    state.video.videosMap[state.video.currentVideoId] &&
    state.video.videosMap[state.video.currentVideoId].sentiment
  ) {
    return state.video.videosMap[state.video.currentVideoId].sentiment.data;
  } else return null;
};

const selectorFaceAnalyses = (state: IRootState) => {
  // console.log('graph: SELECTOR faces called with params ', state);
  if (state.video.currentVideoId && state.video.videosMap[state.video.currentVideoId]) {
    return state.video.videosMap[state.video.currentVideoId].faceAnalyses;
  } else return null;
};
const selectorMetaFaceAnalysesMap = (state: IRootState) => {
  if (state.video.currentVideoId && state.video.videosMap[state.video.currentVideoId]) {
    return state.video.videosMap[state.video.currentVideoId].metaFaceAnalysesMap;
  } else return null;
};

const selectorGlobalFaceCategories = (state: IRootState) => {
  return state.settings.allFaceCategories;
};

const isGloballyConfiguredFaceStream = (stream: StreamRef, globalFaceCategories) => {
  if (globalFaceCategories) {
    return Object.keys(globalFaceCategories).some((categoryName) => globalFaceCategories[categoryName].includes(stream.stream));
  }
  return true;
};

export const retrieveEmotionsRates = createSelector(
  [selectorFaceAnalyses, selectorMetaFaceAnalysesMap, selectorVerbalEmotions, selectorGlobalFaceCategories],
  (analyses, metaFaceAnalysesMap, verbalEmotionsRate, globalFaceCategories) => {
    //console.log('retrieveEmotionsRates analyses: ', analyses);
    let faceAnalysesData = graphUtils
      .getAllFaceAnalysesData(analyses, metaFaceAnalysesMap)
      .filter((faceStream) => isGloballyConfiguredFaceStream(faceStream.streamRef, globalFaceCategories));
    let seriesColors = [] as { [seriesName: string]: string }[];
    analyses.forEach((item) => seriesColors.push({} as { [seriesName: string]: string }));
    if (faceAnalysesData) {
      const analysesData = faceAnalysesData.filter((streamInfo) => !isComplexityStream(streamInfo.streamRef));
      analysesData.forEach((streamInfo, index) => {
        //if it's an emotion, compute and store its color THE SAME WAY UGraph does, to get the same colors
        //TODO FFS unify code
        if (streamInfo.streamRef && ALL_EMOTIONS.includes(streamInfo.streamRef.stream)) {
          seriesColors[streamInfo.streamRef.analysisIdx][streamInfo.streamRef.stream] = getColor(index);
        }
      });
    }
    return analyses
      ? analyses.map((analysis, idx) => {
          return {
            analysisIndex: idx,
            analysisTag: analysis.tag,
            analysisFriendlyTag: metaFaceAnalysesMap[analysis.urlid].name,
            facialEmotionsRate: analysis.summedEmotionsRate,
            reducedFacialEmotionsRate: analysis.reducedSummedEmotionsRate,
            verbalEmotionsRate,
            emotionColors: seriesColors[idx],
            speakerHidden: metaFaceAnalysesMap[analysis.urlid].status === AnalysisItemStatus.hidden,
          } as SpeakerEmotionsRates;
        })
      : null;
  }
);

const mapStateToProps = (state: IRootState) => {
  // console.log('map state to pros called with state ', state);
  return {
    selectedVideoId: state.video.currentVideoId,
    speakersEmotionsRates: retrieveEmotionsRates(state),
    windowHeight: state.utils.windowHeight,
    playerHeight: state.utils.playerHeight,
    selectedChartsTab: state.selections.selectedChartsTab,
  };
};
const mapDispatchToProps = {
  setSelectedChartsTab,
  addImage,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Charts);
