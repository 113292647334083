import config from '../../config/constants';
import { Datum3, Transcript } from '../../model/Video';

/*
function addIndexBasedTimestamps(o: { streams }) {
  Object.values(o.streams).forEach((stream: { data: any[] }) => {
    stream.data.forEach((dataentry: { time: number }, index: number) => {
      dataentry.time = index;
    });
  });
  return o;
}
*/

function buildSentence(words: Datum3[]) {
  if (!words || words.length === 0) {
    return null;
  }
  return {
    start: words[0].start,
    end: words[words.length - 1].end,
    text: words.reduce((accumulator, crtWord) => {
      return accumulator + config.transcript.wordsSeparator + crtWord.text;
    }, ''),
  };
}

/** consolidates all utterances preceding a zero time-length punctuation utterance into one single utterance that ends with that punctuation mark.
 */
function fromWords2Clauses(o: Transcript) {
  if (o.streams && o.streams.utterance && o.streams.utterance.data) {
    console.log('initial transcript slots: ', o.streams.utterance.data.length);
    const newData: Datum3[] = [];
    let words: Datum3[] = [];
    o.streams.utterance.data.forEach((dataentry, index: number) => {
      words.push(dataentry);
      if (dataentry.start === dataentry.end) {
        if (!config.transcript.punctuation.includes(dataentry.text)) {
          console.error('unexpected non-punctuation zero time-length utterance: ', dataentry, index);
        }
        newData.push(buildSentence(words));
        words = [];
      }
    });
    if (words.length > 0) {
      newData.push(buildSentence(words));
    }
    o.streams.utterance.data = newData;
    console.log('final transcript slots: ', o.streams.utterance.data.length);
  }
  return o;
}

function fillTranscriptGaps(o: Transcript) {
  if (o.streams && o.streams.utterance && o.streams.utterance.data) {
    console.log('initial transcript slots: ', o.streams.utterance.data.length);
    let previousEnd = null;
    const newData: Datum3[] = [];
    o.streams.utterance.data.forEach((dataentry, index: number) => {
      const gap = Math.trunc((dataentry.start - previousEnd) * 100) / 100; // todo corelate to /* config.transcript.maxGap*/
      if (previousEnd && gap > config.transcript.maxGap) {
        // create a new slot to fill the gap in time  (dataentry.start - previousEnd).toFixed(2)
        newData.push({
          text: config.transcript.lengthInGapText ? config.transcript.gapFakeText + gap : config.transcript.gapFakeText,
          start: previousEnd,
          end: dataentry.start,
        });
      }
      newData.push(dataentry);
      previousEnd = dataentry.end;
    });
    o.streams.utterance.data = newData;
    console.log('final transcript slots: ', o.streams.utterance.data.length);
  }
  return o;
}

function fillTranscriptGapsAndConcatPunctuation(o: Transcript) {
  let gaps = 0;
  if (o.streams && o.streams.utterance && o.streams.utterance.data) {
    console.log('initial transcript slots: ', o.streams.utterance.data.length);
    let previousEnd = 0;
    const newData: Datum3[] = [];
    o.streams.utterance.data.forEach((dataentry, index: number) => {
      // TODO this is for fixing old generated transcripts, incompatible with the current format FFS
      if (!dataentry.start) {
        dataentry.start = index;
      }
      if (!dataentry.end) {
        dataentry.end = index + 1 - config.transcript.maxGap;
      }
      const gap = Math.trunc((dataentry.start - previousEnd) * 100) / 100;
      if (/* previousEnd && */ gap > config.transcript.maxGap) {
        // create a new slot to fill the gap in time  (dataentry.start - previousEnd).toFixed(2)
        newData.push({
          text: config.transcript.lengthInGapText ? config.transcript.gapFakeText + gap : config.transcript.gapFakeText,
          start: previousEnd,
          end: dataentry.start,
        });
        gaps++;
      }
      if (dataentry.start !== dataentry.end) {
        newData.push(dataentry);
      } else {
        if (![',', '.', ';', '!', '?', "'"].includes(dataentry.text)) {
          console.log('unexpected zero time length utterance: ', dataentry, index);
        }
        // fake slot (punctuation sign), append its text to previous slot
        if (newData.length >= 1) {
          newData[newData.length - 1].text += dataentry.text;
        } else {
          newData.push(dataentry);
        }
      }
      previousEnd = dataentry.end;
    });
    o.streams.utterance.data = newData;
    o.gapsCount = gaps;
    console.log('final transcript slots: ', o.streams.utterance.data.length);
  }
  console.log('with added gaps: ', gaps);
  return o;
}
export function processTranscript(o: Transcript) {
  return config.transcript.useClauses ? fillTranscriptGaps(fromWords2Clauses(o)) : fillTranscriptGapsAndConcatPunctuation(o);
}

/*
export const sampleVideoDnc: Video = {
  id: 'DNC',
  faceAnalyses: [sampleFaceAnalysisDnc],
  voiceAnalyses: [sampleVoiceAnalysisDnc],
  transcript: processTranscript(sampleTranscriptDnc),
  creatorId: 'creator_id',
  dateCreated: '2021-01-15 9:00:00 UTC',
  videoRequestId: 'video_request_idDnc',
  url: 'https://s3.amazonaws.com/rationalplan.temp/dnc/dnc.mp4',
  // url: 'content/dnc.mp4',
  comments: sampleCommentsDnc,
  // comments: { comments: buildSampleComments(sampleTranscriptDnc.streams.utterance.data.length, 20) },
  // comments: { comments: [] },
  highlights: { highlights: [] },
};

export const sampleVideoDebate: Video = {
  id: 'Debate',
  faceAnalyses: [sampleFaceAnalysisTrump, sampleFaceAnalysisBiden],
  voiceAnalyses: [sampleVoiceAnalysisDebate],
  transcript: processTranscript(sampleTranscriptDebate),
  creatorId: 'creator_id',
  dateCreated: '2021-01-05 9:00:00 UTC',
  videoRequestId: 'video_request_idDebate',
  // url: 'content/debate.mp4',
  url: 'https://s3.amazonaws.com/rationalplan.temp/debate/debate.mp4',
  comments: sampleCommentsDebate,
  highlights: { highlights: [] },
};
export const sampleVideoAuthenticity: Video = {
  id: 'Authenticity',
  faceAnalyses: [sampleFaceAnalysisAuthenticity],
  voiceAnalyses: [sampleVoiceAnalysisAuthenticity],
  transcript: processTranscript(sampleTranscriptAuthenticity),
  creatorId: 'creator_id',
  dateCreated: '2021-01-06 9:00:00 UTC',
  videoRequestId: 'video_request_idAuth',
  url: 'https://s3.amazonaws.com/rationalplan.temp/authenticity/authenticity.mp4',
  // url: 'content/authenticity.mp4',
  comments: sampleCommentsAuthenticity,
  highlights: { highlights: [] },
};
*/
