import { Button, Dialog, DialogContent, DialogTitle, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core';
import PenIcon from '@material-ui/icons/Edit';
import React from 'react';
import { Category } from '../../../model/ApiTypes';
import BoldCloseButton from '../../shared/BoldCloseButton';
import AddRubricForm, { IChildProps } from './AddRubricForm';

class SetRubricValuesForm extends React.PureComponent<IChildProps> {
  continue = (e) => {
    e.preventDefault();
    if (this.hasErrors()) return;
    else this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  hasErrors = () => !this.props.values.score || this.props.values.score < 4 || this.props.values.score > 10;

  render() {
    return (
      <Dialog open fullWidth maxWidth="sm" onClose={this.props.onClose} scroll="paper" className="fig-modal-dialog-main-container-v2">
        <BoldCloseButton width={14} height={14} top={15} right={15} handleClose={this.props.onClose}></BoldCloseButton>

        <DialogTitle id="scroll-dialog-title" disableTypography>
          <div className="row padding-top--35 padding-bottom--10">
            <div className="col-12 no-padding fig-vertical-middle">
              <span className="modal-title">Next, add values to your Rubric</span>
            </div>
          </div>

          <div className="row">
            <div className="col-7 no-padding fig-modal-dialog-edit">
              <TextField
                // placeholder={this.props.values.rubrics[this.props.values.selectedRubricIdx].name}
                //placeholder={this.props.values.title}
                placeholder="Title"
                value={this.props.values.title}
                onChange={this.props.handleChange(AddRubricForm.TITLE)}
                fullWidth
                error={!this.props.values.title || !this.props.values.title.length}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton className="fig-edit-modal">
                        <PenIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="col-3 no-padding fig-vertical-middle fig-align-right">
              <div>
                <Typography variant="body2">Rubric Score</Typography>
              </div>
            </div>

            <div className="col-2">
              <TextField
                className="fig-score-container"
                placeholder="4"
                type="number"
                error={this.hasErrors()}
                inputProps={{ min: 4, max: 10 }}
                value={this.props.values.score}
                onChange={this.props.handleChange(AddRubricForm.SCORE)}
                fullWidth
              />
            </div>
          </div>
        </DialogTitle>
        <DialogContent dividers={false}>
          <div className="row">{this.renderSelectedRubric()}</div>
        </DialogContent>
        {/* <DialogActions /> */}
        <div className="row modal-dialog-footer-two-buttons">
          <div className="col-12 no-padding fig-space-between">
            <div className="modal-button-box padding-left--25">
              <Button className="fig-button in-modal-dialog fig-button__white" onClick={this.back}>
                Back
              </Button>
            </div>

            <div className="modal-button-box padding-right--25">
              <Button
                className={`fig-button in-modal-dialog ${!this.hasErrors() ? 'fig-button__grey' : 'fig-button__white'}`}
                onClick={this.continue}
              >
                Finish
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }

  renderSelectedRubric() {
    let rubrics = this.props.values.rubrics;
    let category = rubrics[this.props.values.selectedRubricIdx];
    let categories = category && category.template && category.template.categories ? category.template.categories : [];
    return (
      <div className="w-100">
        {categories.map((category, idxCategory) => {
          return <div key={idxCategory}>{this.renderCategory(category, idxCategory)}</div>;
        })}
      </div>
    );
  }
  renderCategory = (category: Category, idx: number) => {
    let rez = (
      <div className="modal-list-box">
        <div className="modal-list-item">
          <div className="category-name">{`${category.name}`}</div>
          <div>{category.questions.map((question, idxQuestion) => this.renderQuestion(question, idxQuestion))}</div>
        </div>
      </div>
    );
    return rez;
  };

  renderQuestion = (question: string, idxQuestion: number) => {
    let itemToRender = (
      <div className="content-text" key={idxQuestion}>
        <b>{1 + idxQuestion}.</b>
        {question}
      </div>
    );
    return itemToRender;
  };
}
export default SetRubricValuesForm;
