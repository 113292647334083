import { ThemeProvider } from '@material-ui/core';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import AppComponent from './app';
import initStore from './app/config/store';
import config from './app/demo/config/constants';
import './main.css';
import './styles/sass/mainScss.scss';

const store = initStore();
// registerLocale(store);

// loadIcons();

const rootEl = document.getElementById('root');

const render = (Component) =>
  // eslint-disable-next-line react/no-render-return-value
  ReactDOM.render(
    <Provider store={store}>
      <div>
        <ThemeProvider theme={config.theme.getMuiTheme()}>
          <Component />
        </ThemeProvider>
      </div>
    </Provider>,
    rootEl
  );

render(AppComponent);
