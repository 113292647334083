import { Button, TextField } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { getUnauthedHomePath } from '../../../../app';
import { setGlobalAlert } from '../../../actions/utils';
import { IRootState } from '../../../shared/reducers';
import { successNotification } from '../../../utils/utils';
import { registerUser } from '../../actions/figaroApiActions';

interface IProps extends PropsFromRedux {
  history: any;
  match: any;
}

interface IState {
  newPassword?: string;
  newPasswordRetry?: string;
  name: string;
  email: string;
  attempted: boolean;
}

class RegisterUserPanel extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    console.log('RegisterUserPanel ctor,  props ', props);
    this.state = {
      name: '',
      email: '',
      attempted: false,
      newPassword: '',
      newPasswordRetry: '',
    };
  }

  render() {
    return (
      <div className="login-wrapper">
        <Paper>
          <div className="login-wrapper__header">
            <div className="header-container no-margin text-center w-100">
              <p className="no-margin">Register a new user</p>
            </div>
          </div>

          <form className="login-wrapper__form">
            <TextField
              className="w-100"
              id="firstName"
              //label="Name"
              value={this.state.name}
              onChange={(e) =>
                this.setState({
                  name: e.target.value,
                })
              }
              margin="normal"
              variant="outlined"
              required
              InputLabelProps={{
                shrink: true,
              }}
              placeholder={'NAME'}
              error={this.state.attempted && (!this.state.name || this.state.name.length === 0)}
            />
            <TextField
              className="w-100"
              id="email"
              //label="Email"
              type="email"
              value={this.state.email}
              onChange={(e) =>
                this.setState({
                  email: e.target.value,
                })
              }
              InputLabelProps={{
                shrink: true,
              }}
              placeholder={'EMAIL'}
              margin="normal"
              variant="outlined"
              required
              error={this.state.attempted && (!this.state.email || this.state.email.length === 0)}
            />

            <TextField
              className="w-100"
              id="new-password"
              value={this.state.newPassword}
              onChange={(e) =>
                this.setState({
                  newPassword: e.target.value,
                })
              }
              type="password"
              //label="Password"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder={'PASSWORD'}
              margin="normal"
              variant="outlined"
              error={this.state.attempted && (!this.state.newPassword || this.state.newPassword.length === 0)}
              required
            />

            <TextField
              className="w-100"
              id="new-password-again"
              value={this.state.newPasswordRetry}
              onChange={(e) =>
                this.setState({
                  newPasswordRetry: e.target.value,
                })
              }
              type="password"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder={'PASSWORD AGAIN'}
              //label="Password Again"
              margin="normal"
              variant="outlined"
              required
              error={this.state.attempted && (!this.state.newPasswordRetry || this.state.newPasswordRetry.length === 0)}
            />

            <Button className="fig-button ghost-to-dark-blue margin-top--30" onClick={this.onRegisterUser}>
              Register
            </Button>
          </form>
        </Paper>
      </div>
    );
  }

  /*  
  private renderUserProfile() {
    return (
      <div className="row" style={{ margin: 20, textAlign: 'center', width: '100%' }}>
        <TextField
          id="firstName"
          //label="Name"
          value={this.state.name}
          onChange={(e) =>
            this.setState({
              name: e.target.value,
            })
          }
          InputLabelProps={{
            shrink: true,
          }}
          placeholder={'Enter Name'}
          margin="normal"
          variant="outlined"
          required
          error={this.state.attempted && (!this.state.name || this.state.name.length === 0)}
          style={{ margin: 20 }}
        />
        <TextField
          id="email"
          label="Email"
          type="email"
          value={this.state.email}
          onChange={(e) =>
            this.setState({
              email: e.target.value,
            })
          }
          margin="normal"
          variant="outlined"
          required
          error={this.state.attempted && (!this.state.email || this.state.email.length === 0)}
          style={{ margin: 20 }}
        />

        <Button
          variant="contained"
          color="primary"
          className="form-button"
          style={{ textTransform: 'capitalize', margin: 25 }}
          onClick={this.onRegisterUser}
        >
          Register
        </Button>
      </div>
    );
  }*/

  onSuccesfullRegister = () => {
    successNotification(
      'Successful registration! To complete the account creation, please check your e-mail and click the confirmation link.'
    );
    this.props.history.push(getUnauthedHomePath());
  };

  onRegisterUser = () => {
    if (this.state.newPassword !== this.state.newPasswordRetry) {
      this.props.setGlobalAlert("Passwords don't match")
    } else if (this.state.name && this.state.email && this.state.newPassword) {
      registerUser(this.state.name, this.state.email, this.state.newPassword).then(
        () => this.onSuccesfullRegister(),
        (err) => {
          console.log('register err ', err);
        }
      );
    } else {
      this.props.setGlobalAlert("Mandatory field missing")
    }
    this.setState({
      attempted: true,
    });
  };

  /*
  private renderUserPasswordReset() {
    return (
      <div className="row" style={{ margin: 20, width: '100%' }}>
        <TextField
          id="new-password"
          value={this.state.newPassword}
          onChange={(e) =>
            this.setState({
              newPassword: e.target.value,
            })
          }
          type="password"
          label="Password"
          margin="normal"
          variant="outlined"
          error={this.state.attempted && (!this.state.newPassword || this.state.newPassword.length === 0)}
          required
          style={{ margin: 20 }}
        />

        <TextField
          id="new-password-again"
          value={this.state.newPasswordRetry}
          onChange={(e) =>
            this.setState({
              newPasswordRetry: e.target.value,
            })
          }
          type="password"
          label="Password Again"
          margin="normal"
          variant="outlined"
          required
          error={this.state.attempted && (!this.state.newPasswordRetry || this.state.newPasswordRetry.length === 0)}
          style={{ margin: 20 }}
        />
      </div>
    );
  }*/
}

const mapStateToProps = (state: IRootState) => {
  return {};
};
const mapDispatchToProps = {
  setGlobalAlert,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(RegisterUserPanel);
