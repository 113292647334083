import { IconButton, SvgIcon } from '@material-ui/core';
import React from 'react';
import sprites from '../../../../icons/fig-icons.svg';

export default function BoldCloseButton(props) {
  let { right, top, width, height, handleClose } = props;
  return (
    <IconButton
      onClick={handleClose}
      size={'small'}
      style={{
        position: 'absolute',
        right,
        top,
      }}
    >
      <SvgIcon style={{ width, height }}>
        <svg onClick={handleClose}>
          <use href={sprites + '#close-icon'} />
        </svg>
      </SvgIcon>
    </IconButton>
  );
}
