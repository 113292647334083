import { Grid, Link, Tooltip } from '@material-ui/core';
import React from 'react';
import config from '../../config/constants';
import { Comment, Datum3, UterranceProps } from '../../model/Video';
import { seconds2mssmm } from '../../utils/utils';
import { IVideoPlayer } from '../video/VideoRenderer';

interface IProps extends UterranceProps {
  theVideoPlayer: IVideoPlayer;
  isPlaying: boolean;
  idx: number;
  onLinkClick: any;
  onLinkDoubleClick: any;
  onLinkMouseUp: any;
  utteranceIdToElementId: any;
}

class Utterance extends React.PureComponent<IProps, any> {
  render() {
    // console.log(' render AUtterace ' + this.props.idx);
    const style = {
      borderStyle: 'solid',
      borderWidth: '2px',
      borderColor:
        this.props.found && this.props.foundAndSeeked
          ? config.transcript.fluencySeekColor
          : this.props.highlighted && this.props.exceedsThreshold
          ? config.trigger.triggeredBorderColour
          : 'white',
      padding: '2px',
      backgroundColor: this.props.found
        ? config.transcript.fluencyColor
        : this.props.foundAndSeeked
        ? config.trigger.triggeredBackgroundColour
        : this.props.ofSelectedHighlight
        ? config.highlights.selectedHighlightBackground
        : this.props.highlighted
        ? config.highlights.highlightBackground
        : this.props.exceedsThreshold
        ? config.trigger.triggeredBackgroundColour
        : 'transparent',
    };

    return (
      <Grid key={this.props.idx} item style={style}>
        {/* <div style={style}> */}
        {this.renderTooltipLink(this.props.data, this.props.idx, this.props.isPlaying, this.props.commentsArray)}
        {/* </div> */}
      </Grid>
    );
  }

  renderTooltipLink(data: Datum3, idx: number, isCurrent: boolean, commentsArray: Comment[]) {
    const link = (
      <Link
        // href={`#${idx}_${data.start}_${data.end}`}
        // href={`#${data.start}_${data.end}____${idx}`}

        // href={`#${data.start}_${data.end}`}

        id={this.props.utteranceIdToElementId(idx)}
        //color={isCurrent ? 'primary' : 'inherit'}
        variant={isCurrent ? 'body2' : 'body2'}
        // variant="body2"
        // variant={'inherit'}
        onClick={this.props.onLinkClick}
        onDoubleClick={this.props.onLinkDoubleClick}
        onMouseUp={this.props.onLinkMouseUp}
        style={{
          textDecoration: commentsArray ? 'underline' : '',
          color: isCurrent ? 'red' : 'inherit',
        }}
      >
        {this.formatLinkText(data, idx)}
      </Link>
    );
    if (commentsArray) {
      return <Tooltip title={this.renderTooltipText(commentsArray)}>{link}</Tooltip>;
    } else {
      return link;
    }
  }

  formatLinkText(data, idx) {
    const rez = data.text ? data.text : config.transcript.gapFakeText;
    return config.dev.transcript ? seconds2mssmm(data.start) + '_' + rez + '_' + seconds2mssmm(data.end) : rez;
  }
  renderTooltipText(commentsArray: Comment[]) {
    const el = (
      <Grid container>
        {commentsArray.map((comment, index) => (
          <Grid item key={index} style={{ paddingRight: '5px', paddingBottom: '5px' }}>
            {`${comment.userID}:${comment.annotation ? '*' : ''} ${comment.text}`}
          </Grid>
        ))}
      </Grid>
    );
    return el;
  }
}

export default Utterance;

// export const FwdRefUtterance = React.forwardRef((props: IProps, ref) => {
//   const _props:IProps = { ...props };

//   return <Utterance ref={ref as RefObject<Utterance>} {..._props}></Utterance>;
// });
