import React, { CSSProperties } from 'react';
import { Annotation } from '../../model/Video';

interface IProps {
  annotation: Annotation;
  diameter: number;
  color: string;
  zIndex: number;
  filled: boolean;
}

const styleCircle = (x, y, diameter, color, zIndex, filled) => {
  const r = Math.round(diameter / 2);
  let rez = {
    width: diameter,
    height: diameter,
    borderRadius: '50%',
    margin: 0,
    top: `${y}%`,
    left: `${x}%`,
    zIndex,
    transform: `translate(-${r}px, -${r}px)`,
    pointerEvents: 'none',
    position: 'absolute',
  } as CSSProperties;
  if (filled) {
    rez.backgroundColor = color;
  } else {
    rez.color = color;
    rez.borderStyle = `solid`;
    rez.borderWidth = 2;
  }
  return rez;
};

class AnnotationRenderer extends React.PureComponent<IProps> {
  render() {
    return (
      <div
        style={styleCircle(
          this.props.annotation.x,
          this.props.annotation.y,
          this.props.diameter,
          this.props.color,
          this.props.zIndex,
          this.props.filled
        )}
      >
        {/* this.props.annotation.id*/}
      </div>
    );
  }
}

export default AnnotationRenderer;
