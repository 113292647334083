import { Button, IconButton, ListItem, ListItemAvatar, TextField, Tooltip, Typography } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Checkbox from '@material-ui/core/Checkbox';
import { Pageview } from '@material-ui/icons';
import ReportIcon from '@material-ui/icons/Attachment';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  ClipPath,
  Defs,
  Document,
  Font,
  G,
  Image,
  Page,
  Path,
  PDFDownloadLink,
  Rect,
  StyleSheet,
  Svg,
  Text,
  View
} from '@react-pdf/renderer';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { createSelector } from 'reselect';
import sprites from '../../../../icons/fig-icons.svg';
import { seekTimeWindow } from '../../../actions/utils';
import { IRootState } from '../../../shared/reducers';
import {
  deleteScreenshot,
  setClientName,
  setCoachEmail,
  setCoachName,
  setCoachRole,
  setCommentEnabled,
  setCompanyAddress,
  setCompanyName,
  setCompanyUrl,
  setCoverLetterEnabled,
  setCoverLetterIntro,
  setCoverLetterSalutation,
  setFaceEmotionsChart,
  setFaceEmotionsText,
  setHighlightChart,
  setHighlightEnabled,
  setHighlightPicture,
  setInitialEmotions,
  setInitialFaceEmotions,
  setScreenshotComment,
  setScreenshotTranscript,
  setSpeakerEmotionsEnabled,
  setSpeakerFaceEmotionsEnabled,
  setSpeakerVerbalEmotionsEnabled,
  setSummaryEnabled,
  setSummaryText,
  setVerbalChart,
  setVerbalEnabled,
  setVerbalText,
  toggleScreenshotEnabled
} from '../../actions/reportActions';
import { setPDFReport, setReportDrawerVisibility, setSelectedTextSideTab } from '../../actions/selectionActions';
import { highlightSelected } from '../../actions/videoActions';
import config from '../../config/constants';
import { AnalysisItemStatus } from '../../model/ApiTypes';
import {
  Comment,
  Datum3,
  getCommentsForTimeWindow,
  getTextOfUtterances,
  Highlight,
  StreamRef,
  TimeWindow,
  VERBAL_EMOTION_NONE
} from '../../model/Video';
import { CommentInfo, HLInfo, ScreenshotInfo } from '../../reducers/reportReducer';
import { seconds2mss, toDateTimeSSLocalInput } from '../../utils/utils';
import ChartsForSpeaker from '../echarts/ChartForSpeaker';
import { retrieveEmotionsRates } from '../echarts/Charts';
import UnifiedGraph, { BaseChartCfg } from '../echarts/UGraph';
import { getChartColors, streamRef2LegendId } from '../echarts/utils';
import { IVideoPlayer } from '../video/VideoRenderer';
import TabsComponent from './TabsComponent';

//https://fonts2u.com/open-sans-extrabold.font?ptext=Figaro+Analytics
//Open Sans Extrabold  large
//https://fonts.google.com/specimen/Arimo?preview.text=123%20Yongue%20Street%20Toronto,%20ONT%20M1M%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20ms.coach@coachingcompany.com%20https:%2F%2Fwww.coachingcompany.com&preview.text_type=custom#standard-styles
//Arimo
Font.register({
  family: 'OpenSans-ExtraBold',
  src: `fonts/OpenSans-ExtraBold.ttf`,
});

Font.register({
  family: 'Arimo-Regular',
  fonts: [
    { src: `fonts/Arimo-Regular.ttf` }, // font-style: normal, font-weight: normal
  ],
});
Font.register({
  family: 'Arimo-Bold',
  //fonts: [{ src: `fonts/Arimo-Medium.ttf` }],
  fonts: [{ src: `fonts/Arimo-Bold.ttf` }],
});

Font.register({
  family: 'Arimo',
  fonts: [{ src: `fonts/Arimo-Italic.ttf`, fontStyle: 'italic' }],
});

export const pdfGrey = '#D9D9D9';
const legendDiameter = 6;
const textColor = '#10254E';
const leftMargin = '47mm';
const footerWidth = '163mm';
const mainHLWidth = '153mm';
const transcriptWidth = '130mm';
const transcriptContainerWidth = '143mm';

const screenshotTranscriptWidth = 216;
const screenshotTranscriptFontSize = '10pt';
const screenshotTranscriptLineHeight = '1.5';

// Create styles
const styles = StyleSheet.create({
  figPdfBackgroundImage: { position: 'absolute', top: 0, left: 0, height: '297mm', width: '210mm' },
  figPage: { position: 'relative' },
  figPdfRoundLogoContainer: {
    position: 'absolute',
    width: '50mm',
    height: '50mm',
    borderRadius: '50%',
    backgroundColor: pdfGrey,
    left: '36mm',
    top: '60mm',
    textAlign: 'center',
    paddingTop: '2mm',
    //GGT
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12pt',
    fontFamily: 'Arimo-Regular',
  },
  figPdfRoundLogoImg: {
    width: '28mm',
    height: '42mm',
    //GGT
    color: 'black',
  },
  figPdfRendererTitle: {
    position: 'absolute',
    top: '25%',
    left: '20mm',
    right: '20mm',
    color: textColor,
    fontSize: '27',
    fontFamily: 'OpenSans-ExtraBold',
    textAlign: 'center',
  },

  figPdfCompanyAddress: {
    position: 'absolute',
    //GGT
    //top: '273mm',
    top: '283mm',
    left: leftMargin,
    //bottom: '15mm',
    color: textColor,
    //backgroundColor: 'lightgray',
    fontStyle: 'italic',
    fontFamily: 'Arimo',
    //width: '80mm',
    lineHeight: '1.3',
    fontSize: '10.5',
    //GGT
    //fontSize: '10',
  },
  figPdfCoachEmail: {
    position: 'absolute',
    //GGT
    //top: '273mm',
    //top: '280mm',
    //top: '283mm',
    top: '278mm',

    //bottom: '15mm',
    //GGT
    //right: 0,
    right: '5mm',
    color: textColor,
    //backgroundColor: 'lightgreen',
    fontStyle: 'italic',
    fontFamily: 'Arimo',
    //GGT
    lineHeight: '1.3',
    // fontWeight: 400,
    //width: '80mm',
    //fontSize: '10',
    fontSize: '10.5',
    textAlign: 'right',
  },

  figPdfCompanyUrl: {
    position: 'absolute',
    //GGT
    //top: '278.5mm',
    //right: 0,
    top: '285.5mm',
    right: '5mm',

    //bottom: '10mm',

    color: textColor,
    //backgroundColor: 'blue',
    //GGT
    lineHeight: '1.3',
    // fontWeight: 400,
    fontStyle: 'italic',
    fontFamily: 'Arimo',
    //width: '80mm',
    //fontSize: '10',
    fontSize: '10.5',
    textAlign: 'right',
  },
  figPageNumber: {
    position: 'absolute',
    //GGT
    //bottom: '5mm',
    bottom: '3mm', //5mm;
    right: 0,
    color: textColor,
    //color: 'brown',
    //backgroundColor: 'pink',
    width: '167mm',
    textAlign: 'center',
    //GGT
    fontFamily: 'Arimo-Regular',
    fontSize: '10',
  },
  figPdfCoverSalutation: {
    left: leftMargin,
    color: textColor,
    //GGT
    //position: 'absolute',
    //top: '20%',
    //fontWeight: 600,
    fontSize: '14pt',
    fontFamily: 'Arimo-Bold', //'Arimo-Regular',
    //color: 'red',
  },
  figPdfCoverText: {
    //position: 'absolute',
    //top: '80mm',
    fontSize: '14pt',
    //fontSize: '12pt',
    left: leftMargin,
    //right: '20mm',
    //width: '150mm',
    width: '147mm',
    fontFamily: 'Arimo-Regular',
    lineHeight: '1.3',
    color: textColor,
    fontWeight: 400,
    //textAlign: 'justify',
    textAlign: 'left',
    //backgroundColor: 'yellow',
    // display: 'flex',
    // flexDirection: 'row',
    //flexWrap: 'wrap',
    // justifyContent: 'flex-start',
  },
  figPdfCoverSignature: {
    fontSize: '14pt',
    left: leftMargin,
    fontFamily: 'Arimo-Bold', //'Arimo-Regular',
    color: textColor,
    //fontWeight: 800,
    width: '100%',
  },
  figPdfCoverAddress: {
    left: leftMargin,
    color: textColor,

    fontStyle: 'italic',
    fontFamily: 'Arimo',
    fontSize: '12pt',
    width: '100%',
  },

  figPdfImage: {
    width: '76.5mm', //0.5*mainHLWidth
    height: '43.22mm', // ratio width/height = 1.77
  },
  figPdfImage2: {
    width: '91.8mm',
    height: '51.86mm',
  },

  figPdfHighlightLegend: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '10.5pt',

    fontFamily: 'Arimo-Regular',
    //GGT
    left: leftMargin,
    width: mainHLWidth,
  },
  figPdfHighlightTranscript: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    marginTop: '22.5pt',
    fontStyle: 'italic',
    fontFamily: 'Arimo',
    color: textColor,
    //fontSize: '10.5pt',
    fontSize: '9pt',
    lineHeight: '1.3',

    //GGT
    left: leftMargin,
    width: transcriptContainerWidth,
    textAlign: 'left',
  },
  figPdfQuote: {
    width: '8.2mm',
    height: '6.3mm',
    marginRight: '15pt',
  },
  figPdfHighlightsCommentsMain: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    color: textColor,
    fontSize: '10.5pt',
    fontFamily: 'Arimo-Regular',
    // fontStyle: 'italic',
    // fontFamily: 'Arimo',
    marginTop: '22.5pt',
    //GGT
    left: leftMargin,
    width: transcriptContainerWidth,
    textAlign: 'left',
    //justifyContent: 'flex-start',
  },
});

interface IProps extends PropsFromRedux {
  theVideoPlayer: IVideoPlayer;
}
export type ReportFaceSizingDivMap = { [faceTag: string]: any };
export type ReportScreenshotTranscriptSizingDivMap = { [screenshotId: string]: any };

interface IState {
  document: JSX.Element;
  isSeeking: boolean;
  reportFaceTextMap: { [faceTag: string]: string }; //REP2 TODO FFS
  reportVerbalText: string; //REP2 TODO FFS
}

Font.registerHyphenationCallback((word) => [word]); // do not split words

class Report2 extends React.PureComponent<IProps, IState> {
  analysesChartsHandle = null;

  reportFaceSizingDivMap: ReportFaceSizingDivMap = {}; //REP2 TODO FFS
  reportVerbalSizingDiv: any = null; //REP2 TODO FFS
  reportScreenshotTranscriptSizingDivMap: ReportScreenshotTranscriptSizingDivMap = {};

  constructor(props: IProps) {
    super(props);
    this.state = { document: null, isSeeking: false, reportFaceTextMap: {}, reportVerbalText: null };
  }

  pt2pxRatio = 1.333;
  componentDidMount() {
    this.pt2pxRatio = pt2px();
    this.forceUpdate();
    if (this.props.speakersInfo) {
      this.props.setInitialEmotions(this.props.speakersInfo);
      this.props.setInitialFaceEmotions(this.props.speakersInfo);
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>): void {
    if (this.props.speakersInfo && prevProps.speakersInfo !== this.props.speakersInfo) {
      this.props.setInitialFaceEmotions(this.props.speakersInfo);
      this.props.setInitialEmotions(this.props.speakersInfo);
    }
  }

  renderHtmlLegend = (hl: HLInfo) => (
    <div className="fig-pdf-highlight-legend" key={`hl_legendp_${hl.id}`}>
      {this.renderFaceHtmlLegend(hl)}
      {this.renderVoiceHtmlLegend(hl)}
    </div>
  );

  generatePdfLegend = (hl: HLInfo) => (
    <View style={styles.figPdfHighlightLegend} key={`hl_legendp_${hl.id}`} /*debug*/>
      {this.generatePdfFaceLegend(hl)}
      {this.generatePdfVoiceLegend(hl)}
    </View>
  );
  renderFaceHtmlLegend = (hl: HLInfo) => {
    let speakersInfo: { [id: string]: StreamRef[] } = {};
    hl.faceSelections.forEach((faceStrem) => {
      if (!speakersInfo[faceStrem.tagFriendlyName]) {
        speakersInfo[faceStrem.tagFriendlyName] = [faceStrem];
      } else {
        speakersInfo[faceStrem.tagFriendlyName].push(faceStrem);
      }
    });
    return Object.keys(speakersInfo).map((speaker) => this.renderHtmlLegendCategory(speaker, speakersInfo[speaker], hl.seriesColors));
  };
  generatePdfScreenshotLegend = (screenshot: ScreenshotInfo) => (
    <View style={{ ...styles.figPdfHighlightLegend, left: 0 }} key={`hl_legendp_${screenshot.id}`} /*debug*/>
      {this.generatePdfFaceLegend2(screenshot.faceSelections, screenshot.seriesColors)}
      {this.generatePdfVoiceLegend2(screenshot.voiceSelections, screenshot.seriesColors)}
    </View>
  );
  generatePdfFaceLegend = (hl: HLInfo) => {
    let speakersInfo: { [id: string]: StreamRef[] } = {};
    hl.faceSelections.forEach((faceStrem) => {
      if (!speakersInfo[faceStrem.tagFriendlyName]) {
        speakersInfo[faceStrem.tagFriendlyName] = [faceStrem];
      } else {
        speakersInfo[faceStrem.tagFriendlyName].push(faceStrem);
      }
    });
    return Object.keys(speakersInfo).map((speaker) => this.generatePdfLegendCategory(speaker, speakersInfo[speaker], hl.seriesColors));
  };
  generatePdfFaceLegend2 = (
    faceSelections: StreamRef[],
    seriesColors: {
      [seriesName: string]: string;
    }
  ) => {
    let speakersInfo: { [id: string]: StreamRef[] } = {};
    faceSelections.forEach((faceStrem) => {
      if (!speakersInfo[faceStrem.tagFriendlyName]) {
        speakersInfo[faceStrem.tagFriendlyName] = [faceStrem];
      } else {
        speakersInfo[faceStrem.tagFriendlyName].push(faceStrem);
      }
    });
    return Object.keys(speakersInfo).map((speaker) => this.generatePdfLegendCategory(speaker, speakersInfo[speaker], seriesColors));
  };

  generatePdfVoiceLegend = (hl: HLInfo) => {
    return this.generatePdfLegendCategory('Voice', hl.voiceSelections, hl.seriesColors);
  };
  generatePdfVoiceLegend2 = (
    voiceSelections: StreamRef[],
    seriesColors: {
      [seriesName: string]: string;
    }
  ) => {
    return this.generatePdfLegendCategory('Voice', voiceSelections, seriesColors);
  };
  renderVoiceHtmlLegend = (hl: HLInfo) => {
    return this.renderHtmlLegendCategory('Voice', hl.voiceSelections, hl.seriesColors);
  };

  generatePdfLegendCategory = (category: string, selections: StreamRef[], seriesColors: any) => {
    return selections && selections.length ? (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'baseline',
          //GGT
          marginTop: '1mm',
        }}
        key={`hl_legend_${category}`}
      >
        <Text
          key={category}
          style={{ alignSelf: 'center', fontFamily: 'Arimo-Bold', fontSize: '12', paddingTop: '1.8mm', height: '100%', width: '16%' }}
        >
          {category}
        </Text>
        <View
          key={category + '_items'}
          style={{
            width: '84%',
            display: 'flex',
            margin: 0,
            padding: 0,
            flexDirection: 'row',
            alignItems: 'baseline',
            //GGT
            marginTop: '1.8mm',
            flexWrap: 'wrap',
          }}
          // debug
        >
          {Object.values(selections).map((stream) => {
            return (
              <View
                key={stream.stream + '_container'}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginRight: '7.5pt',
                  marginBottom: '1mm',
                  fontSize: '10.5pt',
                  fontFamily: 'Arimo-Regular',
                }}
              >
                <Text
                  key={stream.stream + '_circle'}
                  style={{
                    width: legendDiameter,
                    height: legendDiameter,
                    borderRadius: '50%',
                    margin: 0,
                    backgroundColor: seriesColors[streamRef2LegendId(stream)],
                  }}
                ></Text>
                <Text key={stream.stream} style={{ color: 'black', marginLeft: '3mm' }}>
                  {stream.stream}
                </Text>
              </View>
            );
          })}
        </View>
      </View>
    ) : null;
  };
  renderHtmlLegendCategory = (category: string, selections: StreamRef[], seriesColors: any) => {
    let diameter = 10;
    return selections && selections.length ? (
      <div
        style={{
          // position: 'absolute',
          // top: '45%',
          left: '20%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
        key={`hl_legend_${category}`}
      >
        <div key={category} style={{ color: 'black', fontWeight: 'bolder' }}>
          {category} &nbsp;
        </div>
        {Object.values(selections).map((stream) => {
          return (
            <div
              key={stream.stream + '_container'}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginRight: '7.5pt',
              }}
            >
              <div
                key={stream.stream + '_circle'}
                style={{
                  width: diameter,
                  height: diameter,
                  borderRadius: '50%',
                  margin: 0,
                  backgroundColor: seriesColors[streamRef2LegendId(stream)],
                }}
              ></div>
              <div key={stream.stream} style={{ color: 'black', marginLeft: 5 }}>
                {stream.stream}
              </div>
            </div>
          );
        })}
      </div>
    ) : null;
  };

  // analysisIdx: 0
  // analysisTag: "624197bf-b4d1-4675-b5df-f3fa7f0a2e64"
  // stream: "Attention"
  // tagFriendlyName: "trump"

  //Attention.624197bf-b4d1-4675-b5df-f3fa7f0a2e64: '#0051DF'
  //Pitch Mean: "#c9ab00"

  htmlReport = () => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ position: 'relative', height: ' 297mm', width: '210mm' }}>
        <img style={{ position: 'absolute', top: 0, left: 0, height: ' 297mm', width: '210mm' }} src="8_empty_page.png" />
        <div className="fig-pdf-round-logo">
          <img src="FigaroLogo.png" />
        </div>
        <div className="fig-pdf-renderer-title" key={'0'}>
          Figaro Analytics
        </div>
        <div key={'100'} className="fig-pdf-company-adress" style={{ whiteSpace: 'pre-wrap' }}>
          {this.props.companyAddress}
        </div>
        <div key={'102'} className="fig-pdf-coach-email">
          {this.props.coachEmail}
        </div>
        <div key={'103'} className="fig-pdf-company-url">
          {this.props.companyURL}
        </div>
      </div>
      {this.props.coverLetterEnabled && (
        <div style={{ position: 'relative', height: ' 297mm', width: '210mm' }}>
          <img style={{ position: 'absolute', top: 0, left: 0, height: ' 297mm', width: '210mm' }} src="7_left_logo_empty_page.png" />
          <div key={'0'} className="fig-pdf-cover-salutation">
            {this.props.coverSalutation}
          </div>
          <div key={'0'} className="fig-pdf-cover-intro">
            {this.props.coverIntro}
          </div>
          <div key={'50'} className="fig-pdf-coach-name">
            {this.props.coachName}
          </div>
          <div key={'60'} className="fig-pdf-coach-role">
            {this.props.coachRole}
          </div>
          <div key={'70'} className="fig-pdf-company-name">
            {this.props.companyName}
          </div>

          <div key={'100'} className="fig-pdf-company-adress" style={{ whiteSpace: 'pre-wrap' }}>
            {this.props.companyAddress}
          </div>

          <div key={'102'} className="fig-pdf-coach-email">
            {this.props.coachEmail}
          </div>
          <div key={'103'} className="fig-pdf-company-url">
            {this.props.companyURL}
          </div>
          <div key="final" className="fig-pdf-page-number">
            - 1 -
          </div>
        </div>
      )}
      {this.props.highlights &&
        Object.values(this.props.highlights)
          .sort((a, b) => a.index - b.index)
          .map((hl) =>
            hl.enabled ? (
              <div key={'hlpage_' + hl.id} style={{ position: 'relative', height: ' 297mm', width: '210mm' }}>
                <img
                  key={'-1'}
                  style={{ position: 'absolute', top: 0, left: 0, height: ' 297mm', width: '210mm' }}
                  src="7_left_logo_empty_page.png"
                />

                <div key={hl.id + '_b'} className="fig-pdf-highlight-mainbox">
                  <div key={hl.id + '_a'} className="fig-pdf-highlight-mainbox__div1">
                    Highlight
                  </div>
                  <div className="fig-space-between font-italic">
                    <div key={hl.id + '_c'}>{this.props.hlInfo[hl.id].highlight.name}</div>
                    <div key={hl.id + '_d'}>
                      {`${seconds2mss(this.props.hlInfo[hl.id].highlight.startTime)} - ${seconds2mss(
                        this.props.hlInfo[hl.id].highlight.endTime
                      )}`}
                    </div>
                  </div>

                  <div className="fig-pdf-chart-and-image">
                    <img className="fig-pdf-image1" src={hl.chart} key={`${hl.id}.chart`} />
                    <img className="fig-pdf-image2" src={hl.picture} key={`${hl.id}.picture`} />
                  </div>

                  {this.renderHtmlLegend(hl)}

                  <div className="fig-pdf-highlight-transcript" key={`${hl.id}.tr1`}>
                    <img className="fig-pdf-quote" src="quote.svg" />
                    {this.props.hlInfo[hl.id].transcript}
                  </div>

                  <div className="fig-pdf-highlights-comments-main">
                    <img className="fig-pdf-comment-svg" src="comment.svg" />
                    {this.renderHTMLComments(hl.id, this.props.hlInfo[hl.id].commentsDuringHL, hl.comments)}
                  </div>
                </div>

                <div key={'100'} className="fig-pdf-company-adress" style={{ whiteSpace: 'pre-wrap' }}>
                  {this.props.companyAddress}
                </div>

                <div key={'102'} className="fig-pdf-coach-email">
                  {this.props.coachEmail}
                </div>
                <div key={'103'} className="fig-pdf-company-url">
                  {this.props.companyURL}
                </div>
                <div key="final" className="fig-pdf-page-number">
                  - 3 -
                </div>
              </div>
            ) : null
          )}
      <div style={{ position: 'relative', height: ' 297mm', width: '210mm' }}>
        <img style={{ position: 'absolute', top: 0, left: 0, height: ' 297mm', width: '210mm' }} src="7_left_logo_empty_page.png" />
        <div
          key={'0'}
          style={{
            position: 'absolute',
            top: '20%',
            fontSize: 24,
            textAlign: 'center',
            fontFamily: 'Arimo-Regular',
            left: '30%',
          }}
        >
          Notes
        </div>
        <div
          key={'0'}
          style={{
            position: 'absolute',
            top: '25%',
            left: '29%',
            fontSize: 16,
            textAlign: 'justify',
            fontFamily: 'Arimo-Regular',
          }}
        >
          Intentionally Blank
        </div>
        <div key={'100'} className="fig-pdf-company-adress" style={{ whiteSpace: 'pre-wrap' }}>
          {this.props.companyAddress}
        </div>

        <div key={'102'} className="fig-pdf-coach-email">
          {this.props.coachEmail}
        </div>
        <div key={'103'} className="fig-pdf-company-url">
          {this.props.companyURL}
        </div>
        <div key="final" className="fig-pdf-page-number">
          - 2 -
        </div>
      </div>
    </div>
  );

  generatePdfFooterSpacer = (marginTop = '25mm') => (
    <View
      //debug
      key={'fs' + new Date().getTime()}
      style={{
        // display: 'flex',
        bottom: '0mm',
        // height: '30mm',
        //height: '23mm',
        //height: '17mm',

        //height: '12mm',
        //height: '17mm',
        height: '1mm', //POC_wkoverlap
        //marginBottom: '3mm',
        //marginTop: '12mm',
        marginTop: marginTop, //'20mm', //POC_wkoverlap
        //borderTop: '1pt solid red',

        left: leftMargin,
        width: footerWidth,
        // flexDirection: 'row',
        // justifyContent: 'space-between',
        // alignItems: 'baseline',
        // paddingTop: '5mm',
        // paddingBottom: '5mm',
        // marginTop: '5mm',
        // marginBottom: '5mm',
        //marginTop: '8mm',

        //backgroundColor: 'white',
        //backgroundColor: 'yellow',
      }}
      fixed
      //debug
    >
      <Text
        key={'100_f'}
        style={{
          // position: 'absolute',
          // top: '273mm',

          //bottom: '25mm',

          // left: leftMargin,
          color: textColor,
          // backgroundColor: 'lightgray',
          fontStyle: 'italic',
          fontFamily: 'Arimo',
          //GGT
          fontSize: '10',
        }}
      ></Text>
      <Text
        key={'102_F'}
        style={{
          //   position: 'absolute',
          //  top: '273mm',

          //bottom: '25mm',
          //    right: 0,
          color: textColor,
          // backgroundColor: 'green',
          fontStyle: 'italic',
          fontFamily: 'Arimo',
          fontSize: '10',

          // width: '167mm',
          // textAlign: 'right'
        }}
      ></Text>
    </View>
  );

  generatePdfAbsoluteFooter = () => (
    <>
      {/* <Text key={'200_f'} style={styles.figPdfCompanyAddress} fixed >
        {this.props.companyAddress}
      </Text> */}
      <Text key={'202_F' + new Date().getTime()} style={styles.figPdfCoachEmail} fixed /*debug*/>
        {`${this.props.coachEmail}\n${this.props.companyURL}`}
      </Text>
      {/* <Text key={'203_F'} style={styles.figPdfCompanyUrl} fixed>
        {this.props.companyURL}
      </Text> */}
      {/* {this.generatePageNumber()} */}
    </>
  );

  generatePdfPageNumber = () => (
    <Text
      key={'300_p' + new Date().getTime()}
      style={styles.figPageNumber}
      render={({ pageNumber }) => {
        return pageNumber > 1 ? `- ${pageNumber - 1} -` : null;
      }}
      fixed
    />
  );

  /* header curves placeholder*/
  generatePdfHeaderSpacer = (key) => (
    <Text
      key={key}
      style={{
        width: '100%',
        top: '0mm',
        left: '0mm',
        //height: '37mm',
        height: '40mm', //POC_wkoverlap
        backgroundColor: 'transparent',
        //backgroundColor: 'gray',
        //opacity: 0.3,
        fontSize: '12pt',
        fontFamily: 'Arimo-Regular',
      }}
      fixed
    ></Text>
  );

  /* positioner */
  generatePdfSpacer = (height: string | number, key) => (
    <Text
      //key={'h2'}
      key={key}
      style={{
        width: '100%',
        top: '0mm',
        left: '0mm',
        //height: '5%',
        height,
        backgroundColor: 'transparent',
        //backgroundColor: 'magenta',
        fontSize: '12pt',
        fontFamily: 'Arimo-Regular',
      }}
      fixed
      //debug
    ></Text>
  );

  generatePdfIntroPage = () => {
    //console.log('rendering intro page');
    return (
      <Page size="A4" key={'open'} style={styles.figPage} wrap>
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            width: '100%',
            height: '100%',
          }}
          key={'view_open'}
          wrap
        >
          <Image key={'-1'} style={styles.figPdfBackgroundImage} src="new_left_logo_empty_page.png" fixed />
          <View style={styles.figPdfRendererTitle}>
            <Text style={{ fontSize: '15' }} key={'00'}>
              Report to
            </Text>
            <Text style={{}} key={'01'}>
              {`${this.props.clientName}`}
            </Text>
            <Text style={{ fontSize: '15' }} key={'02'}>
              By
            </Text>
            <Text style={{}} key={'03'}>
              {`${this.props.coachName}`}
            </Text>
          </View>
          {this.generatePdfAbsoluteFooter()}
        </View>
      </Page>
    );
  };

  generatePdfCoverPage = () => {
    //console.log('rendering cover page');
    return (
      this.props.coverLetterEnabled && (
        <Page size="A4" key={'cover'} style={{ ...styles.figPage }} wrap>
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              minHeight: '297mm', //!IMPORTANT
            }}
            key={'view_open'}
          >
            <Image key={'-1c'} style={styles.figPdfBackgroundImage} src="new_left_logo_empty_page.png" fixed />
            {this.generatePdfHeaderSpacer('s1')}
            {this.generatePdfSpacer('21mm', 'h2')}
            <Text key={'0c'} style={styles.figPdfCoverSalutation}>
              {`${this.props.coverSalutation}\n\n`}
            </Text>
            <Text key={'01c'} style={styles.figPdfCoverText}>
              {this.props.coverIntro}
              <Text key={'02c'} style={{ ...styles.figPdfCoverSignature }}>
                {`\n${this.props.coachName}\n`}
              </Text>
              <Text key={'03c'} style={styles.figPdfCoverAddress}>
                {`${this.props.coachRole}\n`}
              </Text>
              <Text key={'04c'} style={styles.figPdfCoverAddress}>
                {`${this.props.companyName}`}
              </Text>
            </Text>
            {this.generatePdfFooterSpacer('50mm')}
            {this.generatePdfAbsoluteFooter()}
            {this.generatePdfPageNumber()}
          </View>
        </Page>
      )
    );
  };

  generatePdfSummaryPage = () => {
    return this.props.summaryEnabled ? (
      <Page size="A4" key={'summary'} style={{ ...styles.figPage }} wrap>
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            minHeight: '297mm', //!IMPORTANT
            justifyContent: 'flex-start',
          }}
          key={'view_open'}
        >
          <Image key={'-1c'} style={styles.figPdfBackgroundImage} src="new_left_logo_empty_page.png" fixed />
          {this.generatePdfHeaderSpacer('s2')}
          {this.props.summaryEnabled ? this.generatePdfSummary() : null}

          {this.generatePdfFooterSpacer('50mm')}
          {this.generatePdfAbsoluteFooter()}
          {this.generatePdfPageNumber()}
        </View>
      </Page>
    ) : null;
  };

  generatePdfQuotes = () => (
    <Svg
      key={'quote'}
      style={styles.figPdfQuote}
      width="214.998px"
      height="166.356px"
      viewBox="0 0 214.998 166.356"
      enable-background="new 0 0 214.998 166.356"
      fixed
    >
      <Path
        fill="#328BA3"
        d="M55.636,71.247c-10.17,0-19.527,3.452-27.094,9.2C29.287,56.448,47.496,32.44,83.429,8.428L78.096,0
C26.033,32.955,0,69.117,0,108.496c0,18.084,4.828,32.251,14.497,42.483c9.66,10.251,21.892,15.367,36.681,15.367
c14.794,0,26.719-4.114,35.797-12.354c9.069-8.229,13.608-18.183,13.608-29.832c0-0.126-0.022-0.226-0.022-0.36
c0.275-2.076,0.474-4.151,0.474-6.317C101.034,91.956,80.699,71.247,55.636,71.247z"
      />
      <Path
        fill="#328BA3"
        d="M202.41,149.409c0.596-0.686,1.3-1.354,1.841-2.075c6.687-8.049,10.747-18.453,10.747-29.851
c0-25.527-20.33-46.241-45.398-46.241c-11.478,0-21.927,4.376-29.922,11.541c0-0.284-0.045-0.523-0.045-0.816
c0-24.508,18.336-49.03,55.026-73.543L189.326,0c-52.067,32.955-78.101,69.117-78.101,108.496c0,18.084,4.828,32.251,14.501,42.483
c9.655,10.26,21.892,15.377,36.673,15.377c14.799,0,26.728-4.115,35.806-12.363c1.281-1.146,2.274-2.436,3.375-3.672
C201.869,150.023,202.131,149.717,202.41,149.409z"
      />
    </Svg>
  );

  generatePdfPop = () => (
    <Svg
      key={'quote'}
      style={styles.figPdfQuote}
      width="135.54px"
      height="113.678px"
      viewBox="0 0 135.54 113.678"
      enable-background="new 0 0 135.54 113.678"
      fixed
    >
      <G>
        <Defs>
          <ClipPath id="SVGID_2_">
            <Rect width="135.54" height="113.678" x="0" y="0" />
            {/* overflow="visible" */}
          </ClipPath>
        </Defs>
        <Path
          clip-path="url(#SVGID_2_)"
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#328BA3"
          d="M28.674,39.249v19.634L11.599,78.529
		L11.567,57.24C4.823,54.214,0.097,47.306,0.097,39.249V19.633C0.097,8.788,8.632,0,19.169,0h45.301
		c6.209,0,11.671,3.103,15.154,7.83H59.186C42.332,7.83,28.674,21.894,28.674,39.249L28.674,39.249z M121.546,88.357l-0.056,25.32
		l-20.074-23.38H58.934c-12.637,0-22.884-10.552-22.884-23.566V39.32c0-13.012,10.245-23.561,22.884-23.561h53.546
		c12.639,0,22.89,10.549,22.89,23.561v27.411C135.37,76.436,129.669,84.741,121.546,88.357z"
        />
      </G>
    </Svg>
  );

  generateHighlight = (hl: HLInfo) => (
    <Page key={'hlpage_' + hl.id} size="A4" style={styles.figPage} wrap>
      <View /* fig-pdf-highlight-mainbox*/
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '297mm', //!IMPORTANT
          color: textColor,
          //GGT
          fontSize: '12pt',
          fontFamily: 'Arimo-Regular',
          //left: leftMargin,
          //width: footerWidth,
          top: 0, //POC_wkoverlap
          left: 0,
          width: '100%',
          justifyContent: 'flex-start',
        }}
        key={'view_hl_' + hl.id}
      >
        <Image key={'-1'} style={styles.figPdfBackgroundImage} src="new_left_logo_empty_page.png" fixed />
        {this.generatePdfHeaderSpacer('s3')}

        {/*this.generateSpacer('1mm', 'h2') GGT  38 in loc de   top: '35mm */}
        <Text
          key={hl.id + '_a'}
          style={{
            ...{ fontSize: '14pt', fontFamily: 'Arimo-Bold', fontWeight: 'extrabold' },
            left: leftMargin /*,GGT left: '40mm' */,
          }}
        >
          Highlight
        </Text>
        <View
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            left: leftMargin,
            fontStyle: 'italic',
            fontFamily: 'Arimo',
            fontSize: '12pt',
            //GGT
            //width: 163mm;
            width: mainHLWidth,
          }}
          key={hl.id + '_b'}
        >
          <Text
            key={hl.id + '_c'}
            style={{
              textAlign: 'left',
              maxWidth: '84%',
            }}
          >
            {`${this.props.hlInfo[hl.id].highlight.name}`}
          </Text>
          <Text
            key={hl.id + '_d'}
            style={{
              textAlign: 'right',
            }}
          >
            {`${seconds2mss(this.props.hlInfo[hl.id].highlight.startTime)} - ${seconds2mss(this.props.hlInfo[hl.id].highlight.endTime)}`}
          </Text>
        </View>

        <View
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            left: leftMargin,
            width: mainHLWidth, //width: '160mm'
            marginTop: '7.5pt',
            height: styles.figPdfImage.height, //'43.22mm',
          }}
          // debug
          key={'view_hl_img' + hl.id}
        >
          {/* ratio 320/180 1280/720 1.77 160/90*/}
          <Image src={hl.chart} key={`${hl.id}.chart`} style={{ ...styles.figPdfImage, height: '100%' }} />
          <Image src={hl.picture} key={`${hl.id}.picture`} style={{ ...styles.figPdfImage, height: '100%' }} />
        </View>

        {this.generatePdfLegend(hl)}

        <View style={styles.figPdfHighlightTranscript} /*debug*/ key={'view_hl_tr_c' + hl.id}>
          {this.generatePdfQuotes()}
          {/* <Text style={{ width: transcriptWidth }} key={`${hl.id}.tr1`}>
            {this.props.hlInfo[hl.id].transcript}
          </Text> */}

          <View key={`enwV_${hl.id}.tr1`} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} /*debug*/>
            {this.splittedTextWordsCountBased(this.props.hlInfo[hl.id].transcript, `${hl.id}.tr1`)}
          </View>
        </View>

        <View style={styles.figPdfHighlightsCommentsMain} /*debug*/ key={'view_hl_comm_c' + hl.id}>
          {this.generatePdfPop()}
          {this.generatePdfComments(hl.id, this.props.hlInfo[hl.id].commentsDuringHL, hl.comments)}
        </View>

        {this.generatePdfFooterSpacer()}
        {this.generatePdfAbsoluteFooter()}
        {this.generatePdfPageNumber()}
      </View>
    </Page>
  );

  generatePdfSummary = () => {
    return (
      <View key={'sumary'} style={{ marginBottom: '10mm', marginTop: '5mm' }}>
        <Text key={'00c'} style={styles.figPdfCoverSalutation}>
          {`Summary\n\n`}
        </Text>
        <Text key={'0c'} style={styles.figPdfCoverText}>
          {`${this.props.summaryText}`}
        </Text>
        {/* {this.generatePdfSpacer('10mm', 'h2')} */}
      </View>
    );
  };

  splitIntoLines = (text2Split: string, element = this.reportVerbalSizingDiv) => {
    let rez = [] as string[];
    let text = text2Split;
    while (text.length) {
      let splitPos = truncate(element, text, 14 * 1.3 * this.pt2pxRatio); //fontSize*lineHeight*pt2pxRatio
      console.log('crt split pos = ', splitPos, '; parts: ', text.substring(0, splitPos), text.substring(splitPos));
      rez.push(text.substring(0, splitPos));
      text = text.substring(splitPos);
    }
    return rez;
  };
  generatePdfVerbalEmotions = () => {
    return (
      <View key={'verbalele'} style={{ width: '100%', marginBottom: '10mm', marginTop: '5mm' }} wrap={false}>
        {/* {this.generatePdfSpacer('10mm', 'h1')} */}
        <Text
          key={'00c'}
          style={{
            ...styles.figPdfCoverSalutation,
            minHeight: '16mm',
            height: '16mm',
            width: '100%',
            paddingTop: '5mm',
            paddingBottom: '4mm',
          }}
        >
          {`Verbal Emotions\n\n`}
        </Text>

        <View
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            height: '128', //'127' /*styles.figPdfImage.height*/,
            minHeight: '128',
          }}
          wrap={false}
        >
          <View
            key={'0c'}
            // debug
            style={{
              ...styles.figPdfCoverText,
              margin: 0,
              padding: 0,
              width: '241', //'85mm',
              maxWidth: '241',
              minWidth: '241',
              height: '128' /*styles.figPdfImage.height */,
              minHeight: '128',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',

              textAlign: 'left',
            }}
          >
            {/* {`${this.props.verbalText}`}  */}
            {this.props.verbalText
              ? this.splitIntoLines(this.props.verbalText, this.reportVerbalSizingDiv).map((line, idx) => <Text key={idx}>{line}</Text>)
              : null}
          </View>

          {/* {this.splittedTextWordsCountBased(this.props.faceEmotionsMap[speaker.analysisFriendlyTag].text, speaker.analysisFriendlyTag)}                 */}
          <Image
            /*debug*/
            src={this.props.verbalChart}
            key={`verbal.chart`}
            style={{ ...styles.figPdfImage, paddingBottom: 5 /*, height: '100%' */ }}
          />
        </View>
        {/* {this.props.verbalDetailedText ? this.generatePdfSpacer('10mm', 'h2') : null} */}
        {this.props.verbalDetailedText ? (
          <Text key={'0c1'} style={{ ...styles.figPdfCoverText, marginBottom: '10mm' }}>
            {`${this.props.verbalDetailedText}`}
          </Text>
        ) : null}
        {/* {this.generatePdfSpacer('10mm', 'h3')} */}
      </View>
    );
  };

  generatePdfSpeakerCharts = (speaker) => (
    <Page key={'chpage_' + speaker.analysisIndex} size="A4" style={styles.figPage} wrap>
      <View /* fig-pdf-highlight-mainbox*/
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '297mm', //!IMPORTANT
          color: textColor,
          //GGT
          fontSize: '12pt',
          fontFamily: 'Arimo-Regular',
          //left: leftMargin,
          //width: footerWidth,
          top: 0, //POC_wkoverlap
          left: 0,
          width: '100%',
          justifyContent: 'flex-start',
        }}
        key={'view_ch_' + speaker.analysisIndex}
      >
        <Image key={'-1'} style={styles.figPdfBackgroundImage} src="new_left_logo_empty_page.png" fixed />
        {this.generatePdfHeaderSpacer('s4')}
        {/* <View key={speaker.analysisIndex} style={{ width: '100%', marginBottom: '10mm', marginTop: '5mm' }} wrap={false}> */}
        {this.generatePdfSpacer('10mm', 'h3')}
        <Text
          key={'00c'}
          style={{
            minHeight: '12mm',
            height: '12mm',
            width: '50%',
            // paddingTop: '5mm',
            // paddingBottom: '4mm',
            textAlign: 'left',
            left: leftMargin,
            color: textColor,
            fontSize: '14pt',
            fontFamily: 'Arimo-Bold', //'Arimo-Regular',
          }}
        >
          {`Emotions - ${speaker.analysisFriendlyTag}`}
        </Text>
        <View
          //debug
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            left: leftMargin,
            //width: mainHLWidth, //width: '160mm'
            //marginTop: '7.5pt',
            //height: styles.figPdfImage.height, //'43.22mm',

            //justifyContent: 'space-between',
            width: '163mm', //'100%',
            height: '150',
            minHeight: '150', //'162',
          }}
          wrap={false}
          key={'view_charts_img' + speaker.analysisFriendlyTag}
        >
          <View
            //debug
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              //left: leftMargin,

              //width: mainHLWidth, //width: '160mm'
              //marginTop: '7.5pt',
              //height: styles.figPdfImage.height, //'43.22mm',

              justifyContent: 'flex-start',
              //width: '50%',
              height: '150',
              minHeight: '150', //'162',
              width: '81.5mm',
              minWidth: '81.5mm',

              padding: 0,
              margin: 0,
            }}
            wrap={false}
            key={'view_charts_imgvf' + speaker.analysisFriendlyTag}
          >
            {this.props.emotionsMap[speaker.analysisFriendlyTag].faceChartEnabled && (
              <Text
                key={'fe'}
                style={{
                  minHeight: '8mm',
                  height: '8mm',
                  width: '100%',
                  // paddingTop: '5mm',
                  // paddingBottom: '4mm',
                  textAlign: 'left',

                  color: textColor,
                  fontSize: '14pt',
                  fontFamily: 'Arimo-Bold', //'Arimo-Regular',
                }}
              >
                Face Emotions
              </Text>
            )}
            {this.props.emotionsMap[speaker.analysisFriendlyTag].faceChartEnabled && (
              <Image
                //debug
                src={this.props.emotionsMap[speaker.analysisFriendlyTag].faceChart}
                key={`${speaker.analysisFriendlyTag}.chartF`}
                // style={{ ...styles.figPdfImage }}
                style={{ /*height: '142mm' */ left: '-10mm' }}
              />
            )}
          </View>

          <View
            //debug
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              //left: leftMargin,
              //width: mainHLWidth, //width: '160mm'
              //marginTop: '7.5pt',
              //height: styles.figPdfImage.height, //'43.22mm',

              justifyContent: 'flex-start',
              width: '81.5mm',
              minWidth: '81.5mm',
              height: '150',
              minHeight: '150', //'162',
            }}
            wrap={false}
            key={'view_charts_imgvv' + speaker.analysisFriendlyTag}
          >
            {this.props.emotionsMap[speaker.analysisFriendlyTag].verbalChartEnabled && (
              <Text
                key={'ve'}
                style={{
                  minHeight: '8mm',
                  height: '8mm',
                  width: '100%',
                  // paddingTop: '5mm',
                  // paddingBottom: '4mm',
                  textAlign: 'left',

                  color: textColor,
                  fontSize: '14pt',
                  fontFamily: 'Arimo-Bold', //'Arimo-Regular',
                }}
              >
                Verbal Emotions
              </Text>
            )}

            {this.props.emotionsMap[speaker.analysisFriendlyTag].verbalChartEnabled && (
              <Image
                //debug
                src={this.props.emotionsMap[speaker.analysisFriendlyTag].verbalChart}
                key={`${speaker.analysisFriendlyTag}.chartV`}
                // style={{ ...styles.figPdfImage }}
                style={{ /*height: '142mm' */ left: '-10mm' }}
              />
            )}
          </View>
        </View>
        <Text key={'bl'} style={{ ...styles.figPdfCoverText, marginBottom: '6mm' }}></Text>
        {this.props.emotionsMap[speaker.analysisFriendlyTag].text ? (
          <Text key={'0c1'} style={{ ...styles.figPdfCoverText, marginBottom: '10mm' }}>
            {`${this.props.emotionsMap[speaker.analysisFriendlyTag].text}`}
          </Text>
        ) : null}
        {this.generatePdfFooterSpacer('50mm')}
        {this.generatePdfAbsoluteFooter()}
        {this.generatePdfPageNumber()}
      </View>
    </Page>
  );

  generatePdfEmotionCharts = () =>
    this.props.speakersInfo
      .filter((speaker) => !speaker.speakerHidden && this.props.emotionsMap[speaker.analysisFriendlyTag].speakerEnabled)
      .map((speaker) => this.generatePdfSpeakerCharts(speaker));

  generatePdfScreenshots = () =>
    //this.props.screenshotsInfo.filter((screenshot) => screenshot.enabled).map((screenshot) => this.generatePdfScreenshot(screenshot));
    Object.values(this.props.screenshotsInfo)
      .filter((screenshot) => screenshot.enabled)
      .sort((a, b) => a.timestamp - b.timestamp)
      .map((screenshot) => this.generatePdfScreenshot(screenshot));

  generatePdfScreenshot = (screenshot) => (
    <Page key={'shpage_' + screenshot.id} size="A4" style={styles.figPage} wrap>
      <View /* fig-pdf-highlight-mainbox*/
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '297mm', //!IMPORTANT
          color: textColor,
          fontSize: '12pt',
          fontFamily: 'Arimo-Regular',
          top: 0,
          left: 0,
          width: '100%',
          justifyContent: 'flex-start',
        }}
        key={'view_sh_' + screenshot.id}
      >
        <Image key={'-1'} style={styles.figPdfBackgroundImage} src="new_left_logo_empty_page.png" fixed />
        {this.generatePdfHeaderSpacer('s5')}
        {this.generatePdfSpacer('10mm', 'h4')}
        <View
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            height: '128',
            minHeight: '128',
            paddingRight: '30',
          }}
          wrap={false}
        >
          <View
            //debug
            key={'0c'}
            style={{
              ...styles.figPdfCoverText,
              width: screenshotTranscriptWidth, //'76.5mm',
              maxWidth: screenshotTranscriptWidth,
              minWidth: screenshotTranscriptWidth,

              height: '128',
              minHeight: '128',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              textAlign: 'left',
              margin: 0,
              padding: 0,
            }}
          >
            <Text
              key={'00d'}
              style={{
                minHeight: '7mm',
                height: '7mm',
                width: '100%',
                color: textColor,
                fontFamily: 'Arimo-Bold', //'Arimo-Regular',
              }}
            >
              {`Transcript @ ${seconds2mss(screenshot.timestamp)}`}
            </Text>
            {screenshot.transcriptPart1
              ? this.splitIntoLines(screenshot.transcriptPart1, this.reportScreenshotTranscriptSizingDivMap[screenshot.id]).map(
                  (line, idx) => (
                    <Text
                      key={idx}
                      style={{
                        fontStyle: 'italic',
                        fontFamily: 'Arimo',
                        fontSize: screenshotTranscriptFontSize,
                        padding: 0,
                        lineHeight: screenshotTranscriptLineHeight,
                      }}
                    >
                      {line}
                    </Text>
                  )
                )
              : null}
          </View>
          <Image
            //debug
            src={screenshot.picture}
            key={`${screenshot.id}.pic`}
            style={{ ...styles.figPdfImage }}
          />
        </View>
        {screenshot.transcriptPart2 ? (
          <Text //debug
            key={'0c1'}
            style={{
              ...styles.figPdfCoverText,
              width: mainHLWidth,
              padding: 0,
              fontStyle: 'italic',
              fontFamily: 'Arimo',
              fontSize: screenshotTranscriptFontSize,
              lineHeight: screenshotTranscriptLineHeight,
            }}
          >
            {screenshot.transcriptPart2}
          </Text>
        ) : null}

        <View
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            left: leftMargin,
            width: '163mm', //'100%',
            paddingBottom: '3mm',
          }}
          wrap={false}
          key={'chart_img' + screenshot.id}
        >
          <Image
            //debug
            src={screenshot.chart}
            key={`${screenshot.id}.chart`}
            style={{
              width: '100%',
              maxHeight: '92mm',
              height: '92mm',
              paddingBottom: '-10mm',
            }}
          />
          {this.generatePdfScreenshotLegend(screenshot)}
        </View>
        {screenshot.comment ? (
          <Text key={'0c1'} style={{ ...styles.figPdfCoverText, marginBottom: '10mm' }}>
            {`${screenshot.comment}`}
          </Text>
        ) : null}
        {this.generatePdfFooterSpacer('50mm')}
        {this.generatePdfAbsoluteFooter()}
        {this.generatePdfPageNumber()}
      </View>
    </Page>
  );

  generatePdfFinalPage = () => (
    <Page size="A4" key={'final'} style={{ ...styles.figPage, position: 'relative' }}>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          minHeight: '297mm', //!IMPORTANT
        }}
        key={'view_open'}
      >
        <Image key={'-1c'} style={styles.figPdfBackgroundImage} src="new_left_logo_empty_page.png" fixed />
        {this.generatePdfHeaderSpacer('s6')}
        {this.generatePdfSpacer('3mm', 'h5')}
        <Text key={'0fn'} style={styles.figPdfCoverSalutation}>
          Notes
        </Text>
        <Text key={'01'} style={{ ...styles.figPdfCoverAddress, color: 'lightgray' }}>
          {`\n\nIntentionally Blank`}
        </Text>
        {this.generatePdfFooterSpacer()}
        {this.generatePdfAbsoluteFooter()}

        {this.generatePdfPageNumber()}
      </View>
    </Page>
  );

  reportMeta = {
    title: config.report.reportTitle,
    author: config.report.application,
    subject: config.report.reportTitle,
    creator: config.report.application,
    producer: config.report.application,
  };

  makeReport = () => {
    //split verbal emotions text into verbal summary / verbal detail text, save to Redux
    this.pt2pxRatio = pt2px();
    if (this.state.reportVerbalText && this.reportVerbalSizingDiv) {
      let splitPos = truncate(this.reportVerbalSizingDiv, this.state.reportVerbalText, 128 * this.pt2pxRatio); //170.666); // /*'128pt'*/);
      console.log(
        'verbal pos split = ',
        splitPos,
        '; parts: ',
        this.state.reportVerbalText.substring(0, splitPos),
        this.state.reportVerbalText.substring(splitPos)
      );
      this.props.setSummaryVerbalText(this.state.reportVerbalText.substring(0, splitPos), false);
      this.props.setSummaryVerbalText(this.state.reportVerbalText.substring(splitPos), true);
    } else {
      this.props.setSummaryVerbalText('', false);
      this.props.setSummaryVerbalText('', true);
    }

    //split facial emotions text into facial summary / facial detail text, save to Redux
    Object.keys(this.state.reportFaceTextMap).forEach((faceTag) => {
      if (this.state.reportFaceTextMap[faceTag] && this.reportFaceSizingDivMap[faceTag]) {
        let splitPos = truncate(this.reportFaceSizingDivMap[faceTag], this.state.reportFaceTextMap[faceTag], 170.666); // /*'128pt'*/);
        console.log(
          `facial pos split for ${faceTag} = `,
          splitPos,
          '; parts: ',
          this.state.reportFaceTextMap[faceTag].substring(0, splitPos),
          this.state.reportFaceTextMap[faceTag].substring(splitPos)
        );
        this.props.setSummaryFaceText(this.state.reportFaceTextMap[faceTag].substring(0, splitPos), faceTag, false);
        this.props.setSummaryFaceText(this.state.reportFaceTextMap[faceTag].substring(splitPos), faceTag, true);
      } else {
        this.props.setSummaryFaceText('', faceTag, false);
        this.props.setSummaryFaceText('', faceTag, true);
      }
    });

    Object.values(this.props.screenshotsInfo)
      .filter((screenshot) => screenshot.enabled)
      .forEach((screenshot) => {
        if (!screenshot.transcriptPart1 || screenshot.transcriptPart1 === '') {
          const tr = getTextOfUtterances(
            this.props.transcript,
            Math.max(0, screenshot.timestamp - 10),
            Math.min(this.props.videoDuration, screenshot.timestamp + 30)
          );

          let splitPos = truncate(this.reportScreenshotTranscriptSizingDivMap[screenshot.id], tr, /*was 128*/ 124 * this.pt2pxRatio); //170.666); // /*'128pt'*/);

          console.log(
            `screenshot transcript  pos split for ${screenshot.id} = `,
            splitPos,
            '; parts: ',
            tr.substring(0, splitPos),
            tr.substring(splitPos)
          );
          this.props.setScreenshotTranscript(tr.substring(0, splitPos), tr.substring(splitPos), screenshot.id);
        }
      });
    setTimeout(this.generatePdfReportContents, 0);
  };
  generatePdfReportContents = () => {
    this.reportDocInstance = (
      <Document
        {...this.reportMeta}
        ref={(internalComp) => {
          console.log('setting reportDocRef to ', internalComp);
          this.reportDocRef = internalComp;
        }}
      >
        {this.generatePdfIntroPage()}
        {this.generatePdfCoverPage()}
        {this.generatePdfSummaryPage()}
        {this.generatePdfEmotionCharts()}
        {this.generatePdfScreenshots()}
        {this.props.highlights &&
          Object.values(this.props.highlights)
            .sort((a, b) => a.index - b.index)
            .map((hl) => (hl.enabled ? this.generateHighlight(hl) : null))}
        {this.generatePdfFinalPage()}
      </Document>
    );
    this.setState({ document: this.reportDocInstance }, () => {
      this.props.setPDFReport({
        report: this.state.document, //reportDocInstance,
        simulator: config.dev.simulateReportView ? this.htmlReport() : null,
      });
    });
    //return this.reportDocInstance;
  };

  reportDocRef = null;
  reportDocInstance = null;

  generatePdfComments(highlightId: string, allCommentsDuringThisHL: Comment[], selectedComments: CommentInfo) {
    return (
      <View key={'comm4_' + highlightId}>
        {allCommentsDuringThisHL.map((comment, indx) => {
          return selectedComments[comment.id] === true ? (
            <View
              key={'view4_' + comment.id}
              style={{
                marginBottom: '15pt',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                fontSize: '9pt',
                lineHeight: '1.3',
                fontFamily: 'Arimo-Regular',
              }} /*debug*/
            >
              <Text
                style={
                  {
                    /*color: 'blue'*/
                  }
                }
                key={'intro4_' + comment.id}
              >{`[ ${seconds2mss(comment.time)} - ${comment.userID} ]`}</Text>
              {this.splittedTextWordsCountBased(comment.text, comment.id)}
            </View>
          ) : null;
        })}
      </View>
    );
  }

  splittedTextCharsCountBased = (text: string, id: string, chunkSize = config.dev.reportChunkSize, width = transcriptWidth) =>
    [...Array(Math.floor(text.length / chunkSize) + 1).keys()].map((page) => (
      <Text style={{ width }} key={'_id' + id + 'text4_page' + page} /*debug*/>
        {text.slice(page * chunkSize, (page + 1) * chunkSize > text.length ? text.length : (page + 1) * chunkSize)}
      </Text>
    ));
  splittedTextWordsCountBased = (
    text: string,
    id,
    chunkSize = config.dev.reportChunkSize,
    width = transcriptWidth,
    marginView = '20pt',
    marginText = '10pt'
  ) => {
    const parts = text.split(' ');
    const multiplePages = parts.length > chunkSize;
    return [...Array(Math.floor(parts.length / chunkSize) + 1).keys()].map((page) => (
      <View
        style={{
          marginBottom: multiplePages ? marginView : 0, //'20pt' : 0, //POC_wkoverlap
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between' /*flex-start */,
        }}
        key={'V_id' + id + '_page' + page}
      >
        <Text
          style={{
            width,
            marginBottom: multiplePages ? marginText : 0, //'10pt' : 0, //POC_wkoverlap
            // border: '1 solid green',
          }}
          key={'_id' + id + 'text4_page' + page} /*debug*/
        >
          {parts
            .slice(page * chunkSize, (page + 1) * chunkSize > parts.length ? parts.length : (page + 1) * chunkSize)
            .map((word) => word + ' ')}
        </Text>
      </View>
    ));
  };

  renderHTMLComments(highlightId: string, allCommentsDuringThisHL: Comment[], selectedComments: CommentInfo) {
    return (
      <div>
        {allCommentsDuringThisHL.map((comment, indx) => {
          return selectedComments[comment.id] === true ? (
            <div>
              <div className="fig-pdf-comment-holder">
                <div>{`[ ${seconds2mss(comment.time)} - ${comment.userID} ]`}</div>
                <div>{comment.text}</div>
              </div>
            </div>
          ) : null;
        })}
      </div>
    );
  }

  render() {
    let height = window.innerHeight - 200; //TODO FIX 4 resize
    if (height < 0) height = 30;

    console.log(' render Reportingtab ', this.props);

    return (
      <div style={{ width: '100%', height, overflowY: 'auto' }}>
        {this.renderCoachInfo()}
        {this.renderCoverLetter()}
        {this.renderSummary()}
        {/* {this.renderVerbalEmotions()} */}
        {this.renderEmotions()}
        {this.renderScreenshots()}
        {this.renderHighlights()}

        {this.renderLinkToHLTab()}
        <div className="absolute-hide">
          <UnifiedGraph /* HIDDEN Analysis charts, for generating HLs charts and capturing charts images */
            theVideoPlayer={this.props.theVideoPlayer}
            isComplexityOnly={false}
            setChartsHandle={(e) => {
              // FFS POC hidden charts capture
              console.log('setChartsHandle ', e);
              this.analysesChartsHandle = e;
            }}
          />
        </div>
        {this.renderReportingButtons()}
      </div>
    );
  }

  renderLinkToHLTab() {
    return (
      <>
        {!config.dev.simulateReportView && (
          <div className="row margin-top--60 margin-bottom--20 fig-vertical-middle ">
            &nbsp; * Use
            <Tooltip title="Go to Highlights">
              <IconButton
                //variant="outlined"
                className="fig-horizontal-center"
                onClick={() => {
                  this.props.setReportDrawerVisibility(false);
                  this.props.setSelectedTextSideTab(TabsComponent.TAB_IDX_HLS);
                }}
              >
                <ReportIcon fontSize="small" color="secondary" />
              </IconButton>
            </Tooltip>{' '}
            on the Highlights tab to select the highlights available for reporting
          </div>
        )}
      </>
    );
  }
  renderReportingButtons() {
    return (
      <div className="row margin-top--40 margin-bottom--20 fig-vertical-middle ">
        {true /*this.hasAllHLImages()*/ && (
          <>
            <Button
              size="small"
              style={{
                // visibility: !this.hasAllHLImages() ? 'hidden' : 'inherit',
                textTransform: 'none',
                fontWeight: 'bold',
                fontSize: 16,
              }}
              onClick={this.makeReport}
              className="col-4 "
              // disabled={!this.hasAllHLImages()}
              disabled={!this.props.hasAllHLImages}
            >
              Generate Report
            </Button>

            <PDFDownloadLink
              className="col-4  fig-horizontal-center"
              //document={this.props.pdfReport && this.props.pdfReport.report ? this.props.pdfReport.report : null}
              // document={this.reportDocInstance}
              document={this.state.document}
              fileName={`${config.report.reportTitle}_${toDateTimeSSLocalInput(new Date())}.pdf`}
            >
              {({ blob, url, loading, error }) => (loading ? 'Processing ...' : 'Download the report')}
            </PDFDownloadLink>
            <Tooltip title={`Toggle Report Viewer`}>
              <Button
                size="small"
                style={{
                  // visibility: !this.hasAllHLImages() ? 'hidden' : 'inherit',
                  textTransform: 'none',
                  fontWeight: 'bold',
                  fontSize: 16,
                }}
                onClick={this.toggleReportDrawer}
                className="col-4 "
              >
                Toggle Preview <Pageview color={!this.props.reportDrawerOpened ? 'secondary' : 'inherit'} />
              </Button>
            </Tooltip>
          </>
        )}
      </div>
    );
  }
  toggleReportDrawer = () => {
    this.props.setReportDrawerVisibility(!this.props.reportDrawerOpened);
    /*
    if (!this.props.reportDrawerOpened && config.dev.simulateReportView && (!this.props.pdfReport || !this.props.pdfReport.simulator)) {
      if (!this.hasAllHLImages()) {
        this.props.setPDFReport(null);
      } else
        this.props.setPDFReport({
          report: this.props.pdfReport ? this.props.pdfReport.report : this.generateReportContents(),
          simulator: this.htmlReport(),
        });
    }*/
  };

  onToggleHighlightSelected = (highlight: Highlight) => {
    if (this.props.appSelectedHighlight && this.props.appSelectedHighlight.id === highlight.id) {
      this.props.highlightSelected(this.props.selectedVideoId, null);
    } else {
      //this.selectThenCaptureHL(highlight);
      this.props.highlightSelected(this.props.selectedVideoId, highlight);
    }
  };

  afterHLSeeked = (highlight) => () => {
    console.log('take picture after seek called');
    let image = this.props.theVideoPlayer.getCrtImage();
    this.props.setHighlightPicture(highlight.id, image).then((ok) => {
      this.setState({ isSeeking: false });
    });
  };
  selectThenCaptureHL = (highlight: Highlight) => {
    let initialSelected = this.props.appSelectedHighlight;
    let tw: TimeWindow = {
      startTime: Math.max(highlight.startTime - config.highlights.reportCaptureBorder, 0),
      endTime: Math.min(highlight.endTime + config.highlights.reportCaptureBorder, this.props.videoDuration),
    };
    this.setState({ isSeeking: true }, () => {
      this.props.theVideoPlayer.seek(
        Math.min(highlight.startTime, highlight.endTime),
        this.afterHLSeeked(highlight)
      ); /*seek video at HL's start time*/
    });
    seekTimeWindow(tw); //seek graph zoom at desired timewindow
    this.props.highlightSelected(this.props.selectedVideoId, highlight).then(
      (ok) => {
        //console.log('setting timer');
        setTimeout(() => {
          //console.log('timer called');
          if (this.analysesChartsHandle && this.analysesChartsHandle.getEchartsInstance()) {
            //get chart image
            let img = this.analysesChartsHandle.getEchartsInstance().getDataURL({
              pixelRatio: 1,
              backgroundColor: '#fff',
              type: 'png',
              excludeComponents: ['toolbox', 'dataZoom'],
            });
            console.log('adding chart image for hl ', highlight.id /*, img*/);
            //get chart legend(streams and colors)
            let chartCfg = this.analysesChartsHandle.getEchartsInstance().getOption() as BaseChartCfg;
            let filteredColors: typeof chartCfg.seriesColors = getChartColors(chartCfg);
            this.props.setHighlightChart(highlight.id, img, chartCfg.faceSelections, chartCfg.voiceSelections, filteredColors);
            //restore selection
          }
          this.props.highlightSelected(this.props.selectedVideoId, initialSelected);
        }, 0);
      },

      (err) => {}
    );
  };

  onEnableHL = (hl: HLInfo) => (event) => {
    event.stopPropagation();
    if (!hl.enabled) {
      this.props.setHighlightEnabled(hl.id, true).then((ok) => {
        this.selectThenCaptureHL(this.props.hlInfo[hl.id].highlight);
      });
    } else {
      this.props.setHighlightEnabled(hl.id, false).then((ok) => {
        this.props.setHighlightChart(hl.id, null, [], [], {});
      });
    }
  };

  onEnableScreenshot = (screenshot: ScreenshotInfo) => (event) => {
    event.stopPropagation();
    this.props.toggleScreenshotEnabled(screenshot.id, !screenshot.enabled);
  };
  isHiddenSpeaker = (faceTag: string) => {
    if (!this.props.speakersInfo) return true;
    const found = this.props.speakersInfo.find((speaker) => speaker.analysisFriendlyTag === faceTag);
    if (found) return found.speakerHidden;
    return true;
  };

  renderCoachInfo() {
    return (
      <Accordion key={'coach'} className="fig-accordion">
        <AccordionSummary
          className="row fig-vertical-middle"
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
          <Checkbox
            checked
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            color="primary"
            size="small"
          />
          <span className="row fig-vertical-middle" aria-label="Enable Coach Info">
            <b>Coach Info</b>
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <div className="col-12 fig-accordion-details">
            <div className="row">
              <TextField
                className="col-12 fig-modal-dialog-input"
                label="NAME"
                InputLabelProps={{
                  shrink: true,
                  required: false,
                }}
                // placeholder={'Enter Coach Name'}
                value={this.props.coachName}
                onChange={(event) => this.props.setCoachName(event.target.value)}
                required
                error={false /*!this.state.description || !this.state.description.length*/}
              />
            </div>
            <div className="row">
              <TextField
                className="col-12 fig-modal-dialog-input"
                label="ROLE (Optional)"
                InputLabelProps={{
                  shrink: true,
                  required: false,
                }}
                // placeholder={'Enter Coach Role'}
                value={this.props.coachRole}
                onChange={(event) => this.props.setCoachRole(event.target.value)}
                required
                error={false /*!this.state.description || !this.state.description.length*/}
              />
            </div>
            <div className="row">
              <TextField
                className="col-12 fig-modal-dialog-input"
                label="EMAIL"
                InputLabelProps={{
                  shrink: true,
                  required: false,
                }}
                // placeholder={'Enter Coach Email'}
                value={this.props.coachEmail}
                onChange={(event) => this.props.setCoachEmail(event.target.value)}
                required
                error={false /*!this.state.description || !this.state.description.length*/}
              />
            </div>
            <div className="row">
              <TextField
                className="col-12 fig-modal-dialog-input"
                label="COMPANY NAME"
                InputLabelProps={{
                  shrink: true,
                  required: false,
                }}
                // placeholder={'Enter Company Name'}
                value={this.props.companyName}
                onChange={(event) => this.props.setCompanyName(event.target.value)}
                required
                error={false /*!this.state.description || !this.state.description.length*/}
              />
            </div>
            <div className="row">
              <TextField
                className="col-12 fig-modal-dialog-input"
                label="COMPANY ADDRESS"
                InputLabelProps={{
                  shrink: true,
                  required: false,
                }}
                // placeholder={'Enter Company Address'}
                multiline={true}
                rows={2}
                value={this.props.companyAddress}
                onChange={(event) => this.props.setCompanyAddress(event.target.value)}
                required
                error={false /*!this.state.description || !this.state.description.length*/}
              />
            </div>
            <div className="row">
              <TextField
                className="col-12 fig-modal-dialog-input"
                label="COMPANY URL"
                InputLabelProps={{
                  shrink: true,
                  required: false,
                }}
                // placeholder={'Enter Company URL'}
                value={this.props.companyURL}
                onChange={(event) => this.props.setCompanyUrl(event.target.value)}
                required
                error={false /*!this.state.description || !this.state.description.length*/}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }
  renderCoverLetter() {
    return (
      <Accordion key={'cover'} className="fig-accordion">
        <AccordionSummary
          className="row fig-vertical-middle"
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
          <Checkbox
            checked={this.props.coverLetterEnabled}
            onClick={(event) => {
              event.stopPropagation();
              this.props.setCoverLetterEnabled(!this.props.coverLetterEnabled);
            }}
            onFocus={(event) => event.stopPropagation()}
            color="primary"
            size="small"
          />
          <span className="row fig-vertical-middle" aria-label="Enable Cover Letter">
            <b>Cover Letter</b>
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <div className="col-12 fig-accordion-details">
            <div className="row">
              <TextField
                className="col-3 fig-modal-dialog-input"
                label="SALUTATION"
                InputLabelProps={{
                  shrink: true,
                  required: false,
                }}
                // placeholder={'Enter Salutation '}
                value={this.props.coverSalutation}
                onChange={(event) => this.props.setCoverLetterSalutation(event.target.value)}
                required
                error={false /*!this.state.description || !this.state.description.length*/}
              />
              <TextField
                className="col-9 fig-modal-dialog-input"
                label="NAME"
                InputLabelProps={{
                  shrink: true,
                  required: false,
                }}
                // placeholder={'Enter Salutation '}
                value={this.props.clientName}
                onChange={(event) => this.props.setClientName(event.target.value)}
                required
                error={false /*!this.state.description || !this.state.description.length*/}
              />
            </div>
            <div className="row">
              <TextField
                className="col-12 fig-modal-dialog-input"
                multiline={true}
                rows={5}
                label="INTRO TEXT"
                InputLabelProps={{
                  shrink: true,
                  required: false,
                }}
                // placeholder={'Enter Intro'}
                value={this.props.coverIntro}
                onChange={(event) => this.props.setCoverLetterIntro(event.target.value)}
                required
                error={false /*!this.state.description || !this.state.description.length*/}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }

  renderSummary() {
    return (
      <Accordion key={'summary'} className="fig-accordion">
        <AccordionSummary
          className="row fig-vertical-middle"
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
          <Checkbox
            checked={this.props.summaryEnabled}
            onClick={(event) => {
              event.stopPropagation();
              this.props.setSummaryEnabled(!this.props.summaryEnabled);
            }}
            onFocus={(event) => event.stopPropagation()}
            color="primary"
            size="small"
          />
          <span className="row fig-vertical-middle" aria-label="Enable Summary">
            <b>Summary</b>
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <div className="col-12 fig-accordion-details">
            <div className="row">
              <TextField
                className="col-12 fig-modal-dialog-input"
                label="SUMMARY TEXT"
                multiline={true}
                rows={5}
                InputLabelProps={{
                  shrink: true,
                  required: false,
                }}
                // placeholder={'Enter Salutation '}
                value={this.props.summaryText}
                onChange={(event) => this.props.setSummaryText(event.target.value)}
                required
                error={false /*!this.state.description || !this.state.description.length*/}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }
  static HEIGHT_CHART = 600;
  renderVerbalEmotions() {
    return (
      <Accordion key={'verbal'} className="fig-accordion">
        <AccordionSummary
          className="row fig-vertical-middle"
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
          <Checkbox
            checked={this.props.verbalEnabled}
            onClick={(event) => {
              event.stopPropagation();
              this.props.setVerbalEnabled(!this.props.verbalEnabled);
            }}
            onFocus={(event) => event.stopPropagation()}
            color="primary"
            size="small"
          />
          <span className="row fig-vertical-middle" aria-label="Verbal Emotions">
            <b>Verbal Emotions</b>
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <div className="col-12 fig-accordion-details">
            {/* <div className="row">
              <TextField
                className="col-6 fig-modal-dialog-input"
                multiline={true}
                rows={5}
                label="VERBAL EMOTIONS SUMMARY"
                InputLabelProps={{
                  shrink: true,
                  required: false,
                }}
                placeholder={'Enter verbal emotions summary'}
                value={this.props.verbalText}
                onChange={(event) => this.props.setSummaryVerbalText(event.target.value, false)}
                required
                error={false}
              />
              <div className="col-6 ">{this.renderImage('VerbalEmotions', this.props.verbalChart , hlContainer)}</div>
            </div> */}
            <div className="row ">
              <TextField
                className="col-9 fig-modal-dialog-input"
                multiline={true}
                rows={10}
                label="VERBAL EMOTIONS DETAILS"
                InputLabelProps={{
                  shrink: true,
                  required: false,
                }}
                placeholder={'Enter verbal emotions details'}
                value={this.state.reportVerbalText}
                onChange={(event) => this.setState({ reportVerbalText: event.target.value })}
                required
                error={false /*!this.state.description || !this.state.description.length*/}
              />
              <div className="col-3 ">{this.renderImage('VerbalEmotions', this.props.verbalChart /*, hlContainer*/)}</div>
            </div>
            <div className={'absolute-hide'} style={{ height: Report2.HEIGHT_CHART, width: '100%' }}>
              <ChartsForSpeaker
                {...this.props.speakersEmotionsRates[0]} // {...speakerRates}
                speakersCount={1}
                key={'uniq'}
                height={Report2.HEIGHT_CHART}
                addImage={null}
                verbalOnly={true}
                setChartsHandle={(e) => {
                  console.log('setChartsHandle ', e);

                  if (e && e.getEchartsInstance() && !this.props.verbalChart) {
                    console.log('getting verbal emotions chart image ');
                    //get chart image
                    let img = e.getEchartsInstance().getDataURL({
                      pixelRatio: 1,
                      backgroundColor: '#fff',
                      type: 'png',
                      excludeComponents: ['toolbox', 'title', 'legend', 'xAxis', 'yAxis', 'grid', 'tooltip'],
                    });

                    console.log('setting verbal emotions chart image ');
                    this.props.setVerbalChart(img);
                  }
                }}
              />
            </div>
            {this.renderSizingDiv((internalComp) => {
              this.reportVerbalSizingDiv = internalComp;
            })}
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }
  renderEmotions() {
    return Object.values(this.props.emotionsMap).map((faceInfo, index) =>
      this.isHiddenSpeaker(faceInfo.id) ? null : (
        <Accordion key={`face_${faceInfo.id}`} className="fig-accordion">
          <AccordionSummary
            className="row fig-vertical-middle"
            expandIcon={<ExpandMoreIcon />}
            aria-label="Expand"
            aria-controls="additional-actions1-content"
            id="additional-actions1-header"
          >
            <Checkbox
              checked={faceInfo.speakerEnabled}
              onClick={(event) => {
                event.stopPropagation();
                this.props.setSpeakerEmotionsEnabled(!faceInfo.speakerEnabled, faceInfo.id);
              }}
              onFocus={(event) => event.stopPropagation()}
              color="primary"
              size="small"
            />
            <span className="row fig-vertical-middle" aria-label="Charts">
              <b>{`Charts - ${faceInfo.id}`}</b>
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <div className="col-12 fig-accordion-details">
              <div className="row" /*style={{ marginBottom: 0}} */>
                <div className="col-6 " style={{ margin: 0, padding: 0 }} key="f">
                  {this.renderImage(faceInfo.id, faceInfo.faceChart)}
                  <div>
                    {' '}
                    <Checkbox
                      checked={faceInfo.faceChartEnabled}
                      onClick={(event) => {
                        event.stopPropagation();
                        this.props.setSpeakerFaceEmotionsEnabled(!faceInfo.faceChartEnabled, faceInfo.id);
                      }}
                      onFocus={(event) => event.stopPropagation()}
                      color="primary"
                      size="small"
                    />
                    <span>Include Face Emotions</span>
                  </div>
                </div>
                <div className="col-6 " style={{ margin: 0, padding: 0 }} key="v">
                  {this.renderImage(faceInfo.id, faceInfo.verbalChart)}
                  <div>
                    {' '}
                    <Checkbox
                      checked={faceInfo.verbalChartEnabled}
                      onClick={(event) => {
                        event.stopPropagation();
                        this.props.setSpeakerVerbalEmotionsEnabled(!faceInfo.verbalChartEnabled, faceInfo.id);
                      }}
                      onFocus={(event) => event.stopPropagation()}
                      color="primary"
                      size="small"
                    />
                    <span>Include Verbal Emotions</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <TextField
                  className="col-12 fig-modal-dialog-input"
                  multiline={true}
                  rows={6}
                  label="COMMENTS"
                  InputLabelProps={{
                    shrink: true,
                    required: false,
                  }}
                  placeholder={'Enter comments'}
                  value={faceInfo.text}
                  onChange={(event) => this.props.setFaceEmotionsText(event.target.value, faceInfo.id, false)}
                  required
                  error={false}
                />
              </div>

              <div className={'absolute-hide'} style={{ height: Report2.HEIGHT_CHART, width: '100%' }} key="fc">
                <ChartsForSpeaker
                  {...{
                    ...this.props.speakersEmotionsRates.find((crt) => crt.analysisFriendlyTag === faceInfo.id),
                    verbalEmotionsRate: { [VERBAL_EMOTION_NONE]: 1 },
                  }} // {...speakerRates}
                  speakersCount={this.props.speakersEmotionsRates.length}
                  key={faceInfo.id + index}
                  height={Report2.HEIGHT_CHART}
                  addImage={null}
                  setChartsHandle={(e) => {
                    console.log('setFaceChartsHandle for ', faceInfo.id, e);

                    if (e && e.getEchartsInstance() && !faceInfo.faceChart) {
                      console.log('getting emotions chart image for face ', faceInfo.id /*, img*/);
                      //get chart image
                      let img = e.getEchartsInstance().getDataURL({
                        pixelRatio: 1,
                        backgroundColor: '#fff',
                        type: 'png',
                        excludeComponents: ['toolbox', 'title', 'legend', 'xAxis', 'yAxis', 'grid', 'tooltip'],
                      });

                      console.log('setting emotions chart image for face ', faceInfo.id /*, img*/);
                      this.props.setFaceEmotionsChart(img, faceInfo.id);
                    }
                  }}
                />
              </div>
              <div className={'absolute-hide'} style={{ height: Report2.HEIGHT_CHART, width: '100%' }} key="vc">
                <ChartsForSpeaker
                  {...{
                    ...this.props.speakersEmotionsRates.find((crt) => crt.analysisFriendlyTag === faceInfo.id),
                    //verbalEmotionsRate: { [VERBAL_EMOTION_NONE]: 1 },
                  }} // {...speakerRates}
                  speakersCount={this.props.speakersEmotionsRates.length}
                  key={faceInfo.id + index}
                  height={Report2.HEIGHT_CHART}
                  addImage={null}
                  verbalOnly={true}
                  setChartsHandle={(e) => {
                    console.log('setVerbalChartsHandle for ', faceInfo.id, e);

                    if (e && e.getEchartsInstance() && !faceInfo.verbalChart) {
                      console.log('getting emotions verbal image for  ', faceInfo.id /*, img*/);
                      //get chart image
                      let img = e.getEchartsInstance().getDataURL({
                        pixelRatio: 1,
                        backgroundColor: '#fff',
                        type: 'png',
                        excludeComponents: ['toolbox', 'title', 'legend', 'xAxis', 'yAxis', 'grid', 'tooltip'],
                      });

                      console.log('setting emotions verbal chart image for face ', faceInfo.id /*, img*/);
                      this.props.setVerbalChart(img, faceInfo.id);
                    }
                  }}
                />
              </div>

              {this.renderSizingDiv((internalComp) => {
                this.reportFaceSizingDivMap[faceInfo.id] = internalComp;
              })}
            </div>
          </AccordionDetails>
        </Accordion>
      )
    );
  }

  renderScreenshots() {
    return Object.values(this.props.screenshotsInfo)
      .sort((a, b) => a.timestamp - b.timestamp)
      .map((screenshot) => (
        <Accordion key={`HL_${screenshot.id}`} className="fig-accordion">
          <AccordionSummary
            className="fig-vertical-middle "
            expandIcon={<ExpandMoreIcon />}
            aria-label="Expand"
            aria-controls="additional-actions1-content"
            id="additional-actions1-header"
          >
            <div className="row col-8 no-padding">
              <Checkbox
                checked={screenshot.enabled}
                onClick={this.onEnableScreenshot(screenshot)}
                onFocus={(event) => event.stopPropagation()}
                size="small"
                color="primary"
              />
              <span className="row fig-vertical-middle" aria-label="Enable Screenshot">
                <b>{`@ ${seconds2mss(screenshot.timestamp)} `}</b>
              </span>
            </div>
            {/* <div className="col-4">
              <div className="row fig-vertical-middle ">{`${seconds2mss(screenshot.timestamp)}`}</div>
            </div> */}
          </AccordionSummary>
          <AccordionDetails>
            <div className="col-12 fig-accordion-details">
              <div>
                <div className="col-3 " style={{ float: 'right' /*, paddingRight: 0*/ /*, width: '25%', border: 5, borderRight: 0, */ }}>
                  <img alt="" style={{ width: '100%' }} src={screenshot.picture} onError={null} title={screenshot.id} />
                </div>
                <p>
                  {/* //TODO FFS BEST MOMENT NOT TO SLOW RENDER */}
                  {getTextOfUtterances(
                    this.props.transcript,
                    Math.max(0, screenshot.timestamp - 10),
                    Math.min(this.props.videoDuration, screenshot.timestamp + 30)
                  )}
                </p>
              </div>
              <div className="row col-12 " style={{ paddingLeft: 0 }}>
                <img
                  alt="" //to avoid the broken link image
                  style={{ width: '100%' }}
                  src={screenshot.chart}
                  onError={null}
                  title={screenshot.id}
                />
              </div>
              {/* //TODO ASK BUILD LEGEND */}
              <div className="row">
                <TextField
                  className="col-12 fig-modal-dialog-input"
                  multiline={true}
                  rows={6}
                  label="COMMENTS"
                  InputLabelProps={{
                    shrink: true,
                    required: false,
                  }}
                  placeholder={'Enter comments'}
                  value={screenshot.comment}
                  onChange={(event) => this.props.setScreenshotComment(screenshot.id, event.target.value)}
                  required
                  error={false}
                />
              </div>
              <div style={{ textAlign: 'center' }}>
                <Button
                  className="fig-button "
                  variant="outlined"
                  style={{
                    width: 250,
                    height: 25,
                    right: 10,
                  }}
                  onClick={() => {
                    this.props.deleteScreenshot(screenshot.id);
                  }}
                >
                  {`Delete @${seconds2mss(screenshot.timestamp)} from report`}
                </Button>
              </div>
              {this.renderSizingDiv2((internalComp) => {
                this.reportScreenshotTranscriptSizingDivMap[screenshot.id] = internalComp;
              })}
            </div>
          </AccordionDetails>
        </Accordion>
      ));
  }
  renderHighlights() {
    return Object.values(this.props.highlights)
      .sort((a, b) => a.index - b.index)
      .map((hl) => {
        return this.props.hlInfo[hl.id] ? (
          <Accordion key={`HL_${hl.id}`} className="fig-accordion">
            <AccordionSummary
              className="fig-vertical-middle "
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="additional-actions1-content"
              id="additional-actions1-header"
            >
              <div className="row col-8 no-padding">
                <Checkbox
                  checked={hl.enabled}
                  onClick={this.onEnableHL(hl)}
                  onFocus={(event) => event.stopPropagation()}
                  size="small"
                  color="primary"
                  disabled={this.state.isSeeking}
                />
                <span className="row fig-vertical-middle" aria-label="Enable Highlight">
                  <b>{`Highlights: ${this.props.hlInfo[hl.id].highlight.name} `}</b>
                </span>
              </div>
              <div className="col-4">
                <div className="row fig-vertical-middle ">
                  <Tooltip title={this.props.appSelectedHighlight && this.props.appSelectedHighlight.id === hl.id ? 'Unselect' : 'Select'}>
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        this.onToggleHighlightSelected(this.props.hlInfo[hl.id].highlight);
                      }}
                      style={
                        this.props.appSelectedHighlight && this.props.appSelectedHighlight.id === hl.id
                          ? { backgroundColor: config.highlights.selectedHighlightBackground }
                          : {}
                      }
                    >
                      <svg className="fig-edit-icon">
                        <use href={sprites + '#anotate-icon'} />
                      </svg>
                    </IconButton>
                  </Tooltip>

                  {`[${seconds2mss(this.props.hlInfo[hl.id].highlight.startTime)} - ${seconds2mss(
                    this.props.hlInfo[hl.id].highlight.endTime
                  )}]`}
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="row col-12 fig-accordion-details">
                <div className="col-6">{this.renderComments(hl.id, this.props.hlInfo[hl.id].commentsDuringHL, hl.comments)}</div>
                <div className="col-3 ">{this.renderImage(hl.id, hl.chart /*, hlContainer*/)}</div>
                <div className="col-3 ">{this.renderImage(hl.id, hl.picture /*, hlContainer*/)}</div>
              </div>
            </AccordionDetails>
          </Accordion>
        ) : null;
      });
  }

  renderComments(highlightId: string, allCommentsDuringThisHL: Comment[], selectedComments: CommentInfo) {
    return (
      <div
        className="fig-hl-comments-main-container"
        style={{
          //width: this.state.width,
          maxHeight: window.innerHeight - 120,
          overflowY: 'auto',
        }}
      >
        {allCommentsDuringThisHL.map((comment, indx) => {
          return (
            <div className="row no-gutters fig-vertical-middle" key={comment.id}>
              <div className="col-12 left-side padding-left--25">
                <Checkbox
                  checked={selectedComments[comment.id] === true}
                  onClick={(event) => {
                    event.stopPropagation();
                    this.props.setCommentEnabled(highlightId, comment.id, !selectedComments[comment.id]);
                  }}
                  onFocus={(event) => event.stopPropagation()}
                  color="secondary"
                  size="small"
                />
                <div>
                  <Typography color="textPrimary" variant="body1" className="fig-user">
                    {`${comment.userID} [${seconds2mss(comment.time)}]`}
                  </Typography>
                  <Typography color="textPrimary" variant="body2" className="username-adress">
                    {comment.text}
                  </Typography>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  renderImage = (name: string, url: string /*, container: any*/, big?: boolean) => {
    // let width = container ? container.getBoundingClientRect().width : 320;
    // console.log("width: ", width)
    return (
      url && (
        <ListItem
          className={`modal-list-item`}
          key={`itemrubric_${name}`}
          // button
        >
          {/* <ListItemText primary={name} style={{ width: '50%' }} /> */}
          <ListItemAvatar>
            <img
              alt="" //to avoid the broken link image
              //style={{ width: 320, height: 180 }}
              //1280/720 1.77
              style={{ width: big ? '100%' : 160, height: big ? 360 : 90 }}
              src={url}
              onError={null}
              title={name}
            />
          </ListItemAvatar>
        </ListItem>
      )
      // </div>
    );
  };

  hasAllHLImages = () => {
    if (!this.props.highlights) return true;
    let hls = Object.values(this.props.highlights);
    if (!hls.length) return true;
    return hls.findIndex((hl) => hl.enabled && (!hl.chart || !hl.picture)) === -1;
  };

  renderSizingDiv = (refHandler) => (
    <div
      ref={refHandler}
      className="absolute-hide"
      style={{
        whiteSpace: 'pre-wrap',
        backgroundColor: 'pink',
        margin: 0,
        padding: 0,
        fontSize: '14pt',
        fontFamily: 'Arimo, sans-serif',
        lineHeight: '1.3',
        fontWeight: 400,
        textAlign: 'left',

        textAlignLast: 'left',
        textIndent: 0,
        fontStretch: 'normal',
        width: 241 * this.pt2pxRatio,
        minWidth: 241 * this.pt2pxRatio,
        maxWidth: 241 * this.pt2pxRatio,

        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
      }}
    ></div>
  );

  renderSizingDiv2 = (refHandler) => (
    <div
      ref={refHandler}
      className="absolute-hide"
      style={{
        whiteSpace: 'pre-wrap',
        backgroundColor: 'pink',
        margin: 0,
        padding: 0,
        fontSize: screenshotTranscriptFontSize,
        fontFamily: 'Arimo, sans-serif',
        lineHeight: screenshotTranscriptLineHeight,
        fontWeight: 400,
        textAlign: 'left',

        textAlignLast: 'left',
        textIndent: 0,
        fontStretch: 'normal',
        width: screenshotTranscriptWidth * this.pt2pxRatio,
        minWidth: screenshotTranscriptWidth * this.pt2pxRatio,
        maxWidth: screenshotTranscriptWidth * this.pt2pxRatio,

        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
      }}
    ></div>
  );
}

function pt2px() {
  var e = document.createElement('div');
  e.className = 'pt_test';
  document.body.appendChild(e);
  var rect = e.getBoundingClientRect();
  document.body.removeChild(e);
  console.log('pt2px ', rect.width / 100);
  return rect.width / 100;
}
function truncate(element, content: string, height) {
  console.log('truncate cnt/height ', content, height);
  //console.log('after replace: ', content.replaceAll('\n', ' \n'));

  let words = content.replaceAll('\n', ' \n').split(' ');
  let pos = 0;
  console.log('truncate if needed, words: ', words);
  element.style.height = 'auto';
  element.textContent = content = '';

  function getHeight(elt) {
    const h = elt.getBoundingClientRect().height;
    console.log('text len/height: ', content.length, h);
    return h;
  }
  function inflate(str) {
    return str + words[pos] + ' ';
  }

  // inflate the string until it fully fits vertically.
  while (getHeight(element) <= height && pos <= words.length - 1) {
    element.textContent = content = inflate(content);
    pos++;
    console.log('after inflate: content/pos ', content, pos);
  }
  if (pos > 0) {
    //remove last space
    element.textContent = content = content.slice(0, -1);
    if (getHeight(element) > height) {
      //remove last word
      element.textContent = content = content.slice(0, -words[pos - 1].length);
    }
  }
  console.log('returning splitpoint/whole', content.length, content);
  return content.length;
}

const selectorHLs4Report = (state: IRootState) => state.report.highlights;

const retrieveHasAllHLImages = createSelector([selectorHLs4Report], (hls4Report) => {
  if (!hls4Report) return true;
  let hls = Object.values(hls4Report);
  if (!hls.length) return true;
  return hls.findIndex((hl) => hl.enabled && (!hl.chart || !hl.picture)) === -1;
});

const selectorAllCrtVideoHL = (state: IRootState) => {
  if (
    state.video.currentVideoId &&
    state.video.videosMap[state.video.currentVideoId] &&
    state.video.videosMap[state.video.currentVideoId].highlights
  ) {
    return state.video.videosMap[state.video.currentVideoId].highlights.highlights;
  } else return null;
};
const selectorAllCrtVideoComments = (state: IRootState) => {
  if (
    state.video.currentVideoId &&
    state.video.videosMap[state.video.currentVideoId] &&
    state.video.videosMap[state.video.currentVideoId].comments
  ) {
    return state.video.videosMap[state.video.currentVideoId].comments;
  } else return null;
};

const emptyTranscript: Datum3[] = [];
const selectorTranscript: (IRootState) => Datum3[] = (state: IRootState) => {
  //console.log('graph: SELECTOR transcript called with params ', state);
  return state.video.currentVideoId &&
    state.video.videosMap[state.video.currentVideoId] &&
    state.video.videosMap[state.video.currentVideoId].transcript &&
    state.video.videosMap[state.video.currentVideoId].transcript.streams &&
    state.video.videosMap[state.video.currentVideoId].transcript.streams.utterance &&
    state.video.videosMap[state.video.currentVideoId].transcript.streams.utterance.data
    ? state.video.videosMap[state.video.currentVideoId].transcript.streams.utterance.data
    : emptyTranscript;
};

const retrieveHLs4ReportInfo = createSelector(
  [selectorAllCrtVideoHL, selectorAllCrtVideoComments, selectorHLs4Report, selectorTranscript],
  (allHLs, allComments, hls4Report, transcript) => {
    let resMap = {} as { [id: string]: { highlight: Highlight; commentsDuringHL: Comment[]; transcript: string } };
    Object.keys(hls4Report).forEach((id) => {
      let hl = allHLs.find((crtHL) => crtHL.id === id);
      if (!hl) return;
      const timeSlot: TimeWindow = { startTime: hl.startTime, endTime: hl.endTime };
      let commentsDuringHL = getCommentsForTimeWindow(allComments, timeSlot);
      resMap[id] = {
        highlight: hl,
        commentsDuringHL: commentsDuringHL ? commentsDuringHL : [],
        transcript: getTextOfUtterances(transcript, hl.startTime, hl.endTime),
      };
    });
    return resMap;
  }
);

const selectorFaceAnalyses = (state: IRootState) => {
  if (state.video.currentVideoId && state.video.videosMap[state.video.currentVideoId]) {
    return state.video.videosMap[state.video.currentVideoId].faceAnalyses;
  } else return null;
};
const selectorMetaFaceAnalysesMap = (state: IRootState) => {
  if (state.video.currentVideoId && state.video.videosMap[state.video.currentVideoId]) {
    return state.video.videosMap[state.video.currentVideoId].metaFaceAnalysesMap;
  } else return null;
};

const retrieveSpeakersInfo = createSelector([selectorFaceAnalyses, selectorMetaFaceAnalysesMap], (analyses, metaFaceAnalysesMap) => {
  return analyses
    ? analyses.map((analysis, idx) => {
        return {
          analysisIndex: idx,
          analysisTag: analysis.tag,
          analysisFriendlyTag: metaFaceAnalysesMap[analysis.urlid].name,
          speakerHidden: metaFaceAnalysesMap[analysis.urlid].status === AnalysisItemStatus.hidden,
        };
      })
    : null;
});

const retrieveScreenshots = (state: IRootState) => state.report.screenshots;

/*

{
  /* <div className="row " style={{ height, overflowY: 'auto', width: '100%' }}>
<ChartsTabs
  containerHeight={height}
  speakersEmotionsRates={this.props.speakersEmotionsRates}
  setSelectedChartsTab={this.props.setSelectedChartsTab}
  selectedTab={this.props.selectedChartsTab}
  addImage={this.props.addImage}
></ChartsTabs>
</div> 
}

{
  /* <div className="row " style={{ height: this.props.containerHeight - 35 }}>
<div className="col no-padding " style={{ height: this.props.containerHeight - 35 }}>
  {this.props.speakersEmotionsRates.map((speakerRates, index) => (
    <TabPanel className="" key={speakerRates.analysisTag} index={index} value={this.props.selectedTab}>
      <ChartsForSpeaker
        {...speakerRates}
        speakersCount={this.props.speakersEmotionsRates.length}
        key={speakerRates.analysisTag + index}
        // added idx as a workaround for cases when bakend returns mutiple fae analyses having the same tag video=20
        height={this.props.containerHeight - 35}
        addImage={this.props.addImage}
      />
    </TabPanel>
  ))}
</div>
</div> 
}*/

const mapStateToProps = (state: IRootState) => {
  console.log('Report mapStateToProps, state ', state);
  return {
    coachName: state.report.coachName,
    coachRole: state.report.coachRole,
    coachEmail: state.report.coachEmail,

    companyName: state.report.companyName,
    companyAddress: state.report.companyAddress,
    companyURL: state.report.companyURL,

    coverLetterEnabled: state.report.coverLetterEnabled,
    coverSalutation: state.report.coverSalutation,
    coverIntro: state.report.coverIntro,

    clientName: state.report.clientName,

    summaryEnabled: state.report.summaryEnabled,
    summaryText: state.report.summaryText,
    faceEmotionsMap: state.report.faceEmotionsInfoMap,
    emotionsMap: state.report.emotionsInfoMap,
    speakersInfo: retrieveSpeakersInfo(state),
    screenshotsInfo: retrieveScreenshots(state),

    verbalEnabled: state.report.verbalEnabled,
    verbalText: state.report.verbalText,
    verbalDetailedText: state.report.verbalDetailedText,
    verbalChart: state.report.verbalChart,

    highlights: selectorHLs4Report(state),
    hlInfo: retrieveHLs4ReportInfo(state),
    //fullReportData: state.report,

    selectedVideoId: state.video.currentVideoId,
    videoDuration: state.video.videoDuration,
    appSelectedHighlight: state.video.currentHighlight,

    speakersEmotionsRates: retrieveEmotionsRates(state),
    reportDrawerOpened: state.selections.reportDrawerOpened,
    //pdfReport: state.selections.pdfDoc,
    hasAllHLImages: retrieveHasAllHLImages(state),
    transcript: selectorTranscript(state), // needed for getting  HL text
  };
};

const mapDispatchToProps = {
  setSummaryText,
  setCoachEmail,
  setCoachName,
  setCoachRole,
  setCompanyAddress,
  setCompanyName,
  setCompanyUrl,
  setCoverLetterEnabled,
  setCoverLetterIntro,
  setCoverLetterSalutation,
  setClientName,
  setSummaryEnabled,
  setSummaryFaceText: setFaceEmotionsText,
  setSummaryVerbalText: setVerbalText,
  setScreenshotTranscript,
  setVerbalEnabled,
  setVerbalChart,
  setInitialEmotions,
  setInitialFaceEmotions,
  setFaceEmotionsChart,
  setFaceEmotionsText,
  setSpeakerEmotionsEnabled: setSpeakerEmotionsEnabled,
  setSpeakerFaceEmotionsEnabled,
  setSpeakerVerbalEmotionsEnabled,
  highlightSelected,
  setHighlightEnabled,
  setHighlightChart,
  setHighlightPicture,
  setCommentEnabled,
  setSelectedTextSideTab,
  setReportDrawerVisibility,
  setPDFReport,
  toggleScreenshotEnabled,
  setScreenshotComment,
  deleteScreenshot,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Report2);

// cand refresh report?
// freeze pkg?
