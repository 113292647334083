/* eslint-disable no-console */
import ReactEcharts from 'echarts-for-react';
import React from 'react';
import config, { ENV_VALUE_ENABLED, getColor } from '../../config/constants';
import { EmotionsRate, hasEmotions } from '../../model/Video';
import { applyOpacity } from '../../utils/utils';
import { EChartOption } from './UGraph';

export interface SpeakerEmotionsRates {
  analysisIndex: number;
  analysisTag: string;
  analysisFriendlyTag: string;
  facialEmotionsRate: EmotionsRate;
  reducedFacialEmotionsRate: EmotionsRate;
  verbalEmotionsRate: EmotionsRate;
  emotionColors: { [seriesName: string]: string };
  speakerHidden: boolean;
}

interface IProps extends SpeakerEmotionsRates {
  height: number;
  speakersCount: number;
  addImage: any;
  setChartsHandle?: any; // not null for Reporting Charts, not real Charts
  verbalOnly?: boolean; //workaround to generate just the Verbal Emotions pie chart, used for Reports
}
interface IState {}

const NO_NEW_OPTIONS = {};

class ChartsForSpeaker extends React.PureComponent<IProps, IState> {
  echartsReact: ReactEcharts;
  graphContainer: any = null;

  baseGraphStructure: EChartOption = null;
  pieKeys: string[] = [];

  onEvents = {};

  legendContainer: HTMLDivElement;

  constructor(props: IProps) {
    super(props);
    this.state = {};
    console.log(' ChartsForSpeaker constructor called, props ', props);
  }

  getChartComputedOptions = () => {
    if (this.baseGraphStructure && this.echartsReact && this.echartsReact.getEchartsInstance()) {
      return this.echartsReact.getEchartsInstance().getOption();
    } else {
      return null;
    }
  };

  getVarGraphStructure = () => {
    return NO_NEW_OPTIONS;
  };

  getValuesArray = (emotions: EmotionsRate) => {
    let rez: number[] = [];
    Object.keys(emotions)
      .sort()
      .filter((emotion) => emotion !== 'Other')
      .forEach((key) => {
        const val = emotions[key] * 100;
        rez.push(parseFloat(val.toFixed(2)));
      });
    return rez;
  };

  getCrossColor = (emotionsColor) => (params) => {
    //console.log(`crossColor for  params: and emotions:, pieKeys:   `, params, emotionsColor, this.pieKeys);
    const rez = params.percent ? emotionsColor[this.pieKeys[params.dataIndex]] : 'white';
    return applyOpacity(rez, this.props.speakerHidden ? 0.5 : 1);
  };

  CENTER_LEFT = '50%';
  // this should be called when both analysesData and Duration became available for the video
  initBaseGraphStructure() {
    console.log('$$$$$$$$$$$initBaseChratsStructure ChartForSpeaker called');
    const source = [];
    const series = [];
    const titles: any[] = []; //No title needed since we use tabs for speakers
    // this.props.speakersCount > 1
    //   ? [
    //       {
    //         textAlign: 'center',
    //         text: `Statistics for ${this.props.analysisFriendlyTag ? this.props.analysisFriendlyTag : this.props.analysisTag} ${
    //           config.dev.realLegendId ? '(' + this.props.analysisTag + ')' : ''
    //         }`,
    //         textStyle: {
    //           fontSize: 16,
    //           fontWeight: 'bold',
    //           color: primaryColor,
    //         },
    //         left: this.CENTER_LEFT,
    //         top: '2%',
    //       },
    //     ]
    //   : [];
    let legendSelected = null;
    source.push(Object.keys(this.props.facialEmotionsRate).sort());

    legendSelected = {};
    const withVerbalEmotions =
      process.env.REACT_APP_VERBAL_EMOTIONS &&
      process.env.REACT_APP_VERBAL_EMOTIONS === ENV_VALUE_ENABLED &&
      hasEmotions(this.props.verbalEmotionsRate);

    let analysesData: EmotionsRate[] = withVerbalEmotions
      ? [this.props.facialEmotionsRate, { ...this.props.verbalEmotionsRate, Disgust: 0, Contempt: 0 }]
      : [this.props.facialEmotionsRate];
    let analysesInfo: string[] = withVerbalEmotions ? ['face_pie', 'verbal_pie'] : ['face_pie'];
    let analysesInfoTitles: string[] = withVerbalEmotions ? ['Face Emotions (all)', 'Verbal Emotions'] : ['Face Emotions (all)'];

    analysesData.forEach((streamInfo, index) => {
      let valuesArray = this.getValuesArray(streamInfo);
      titles.push({
        textAlign: 'center',
        text: analysesInfoTitles[index],
        textStyle: {
          fontSize: 12,
          fontWeight: 'bold',
          color: this.props.speakerHidden ? 'gray' : 'black', //secondaryColor,
        },
        left: withVerbalEmotions ? `${index * 50 + 25}%` : this.CENTER_LEFT,
        top: withVerbalEmotions ? '5%' : '8%',
        z: 1,
      });
      if (this.props.speakerHidden) {
        titles.push({
          textAlign: 'center',
          text: analysesInfoTitles[index].replaceAll(/./gi, '-') + '--',
          //text: '------------------------------',
          textStyle: {
            fontSize: 14,
            fontWeight: 'bold',
            color: 'gray', //secondaryColor,
          },
          left: withVerbalEmotions ? `${index * 50 + 25}%` : this.CENTER_LEFT,
          top: withVerbalEmotions ? '5%' : '8%',
          z: 2,
        });
      }

      if (!this.props.verbalOnly || index) source.push(valuesArray); //create just verbal series
      if (!this.props.verbalOnly || index)
        series.push({
          name: analysesInfo[index],
          id: analysesInfo[index],
          type: 'pie',
          animation: false,
          seriesLayoutBy: 'row',
          data: valuesArray,

          radius: this.props.verbalOnly ? '70%' : withVerbalEmotions ? '40%' : '70%',
          center: [`${50}%`, this.props.verbalOnly ? '55%' : withVerbalEmotions ? '30%' : '55%'],
          left: this.props.verbalOnly ? '0%' : `${index * 50}%`,
          right: this.props.verbalOnly ? '0%' : withVerbalEmotions ? `${50 - index * 50}%` : '0%',
          top: 0,
          bottom: 0,
          labelLine: {
            show: true,
            length: 15,
            length2: 15,
          },
          label: {
            alignTo: 'labelLine', //'edge',
            margin: 20,
            fontSize: this.props.setChartsHandle ? 24 : undefined,
            formatter: (params) => {
              //console.log('format label for  params: ', params);
              return `${this.pieKeys[params.dataIndex]}: ${params.data}%`;
            },
          },
          itemStyle: {
            color: this.getCrossColor(this.props.emotionColors),
          },
          tooltip: {},
        });
      legendSelected[analysesInfo[index]] = true;
    });

    if (!this.props.verbalOnly && withVerbalEmotions) {
      analysesData = [this.props.reducedFacialEmotionsRate, this.props.verbalEmotionsRate];
      analysesInfo = ['Face Emotions (reduced)', 'Verbal Emotions'];

      titles.push({
        textAlign: 'center',
        text: `Comparison`,
        textStyle: {
          fontSize: 12,
          fontWeight: 'bold',
          color: this.props.speakerHidden ? 'gray' : 'black', //secondaryColor,
        },
        left: `45%`,
        top: '55%',
      });
      if (this.props.speakerHidden) {
        titles.push({
          textAlign: 'center',
          text: 'Comparison'.replaceAll(/./gi, '-') + '---',
          //text: '------------------------------',
          textStyle: {
            fontSize: 14,
            fontWeight: 'bold',
            color: 'gray', //secondaryColor,
          },
          left: `45%`,
          top: '55%',
          z: 2,
        });
      }

      analysesData.forEach((streamInfo, index) => {
        let valuesArray = this.getValuesArray(streamInfo);
        let color = getColor(index);
        series.push({
          name: analysesInfo[index],
          id: analysesInfo[index],
          type: 'bar',
          seriesLayoutBy: 'row',
          emphasis: { focus: 'series' /*label: { show: true }*/ },
          tooltip: {},
          data: valuesArray,
          sampling: 'none',
          itemStyle: {
            color: applyOpacity(color, this.props.speakerHidden ? 0.5 : 1),
          },
          animation: false,
        });

        legendSelected[analysesInfo[index]] = true;
      });
    }

    //   formatter: [
    //     '{a|Style "a" is applied to this snippet}'
    //     '{b|Style "b" is applied to this snippet}This snippet use default style{x|use style "x"}'
    // ].join('\n'),

    // rich: {
    //     a: {
    //         color: 'red',
    //         lineHeight: 10
    //     },

    let rez: EChartOption = {
      legend: {},
      title: titles,
      dataset: {
        source,
        seriesLayoutBy: 'row',
      },
      series,
    };
    if (!this.props.verbalOnly && withVerbalEmotions) {
      rez = {
        ...rez,
        tooltip: {
          trigger: 'axis',
          showContent: true,
          extraCssText: this.props.speakerHidden ? 'text-decoration: line-through' : null,
        },
        xAxis: {
          type: 'value',
          // boundaryGap: [0, 0.01],
          axisLabel: {
            show: true,
            color: this.props.speakerHidden ? 'gray' : 'black',
            // fontWeight: 'bold',
            fontSize: 12,
          },
        },
        yAxis: {
          type: 'category',
          data: Object.keys(this.props.reducedFacialEmotionsRate).sort(),
          axisLabel: {
            show: true,
            color: this.props.speakerHidden ? 'gray' : 'black',
            // fontWeight: 'bold',
            fontSize: 12,
          },
        },

        grid: { top: '60%', left: 60 },
      };
    }

    console.log('set legend');
    // select one serie at initial render - if valid trigger config , the one configured for triggering , otherwise, the first serie (zero based)
    if (!this.props.verbalOnly) rez.legend = this.initLegend(legendSelected);

    if (!this.props.verbalOnly)
      rez.toolbox = {
        top: 0,
        right: '15',
        z: 40,
        orient: 'horizontal',
        show: true,
        emphasis: {
          iconStyle: {
            // borderColor: this.state.highlightEditing ? config.highlights.highlightDraftBorder : config.graph.toolBoxSelectedColor,
            textPosition: 'bottom',
          },
        },
      };

    console.log('$$$$$$$$$$$initBaseGraphStructure returned ', rez);
    this.baseGraphStructure = rez;
    this.pieKeys = rez.dataset.source[0];
    return this.baseGraphStructure;
  }

  componentDidMount() {}
  componentWillUnmount() {
    console.log('WILL UNmount', this.echartsReact);
  }

  componentDidUpdate(prevProps: SpeakerEmotionsRates, prevState: IState) {}

  render() {
    console.log('*****ChartForSpeaker RENDER*********** ');
    if (
      !this.props.facialEmotionsRate ||
      !this.props.reducedFacialEmotionsRate ||
      !this.props.verbalEmotionsRate ||
      !this.props.analysisTag
    ) {
      console.log('*****RENDER*********** ChartForSpeaker intoarce div kior');
      return <div />;
    }
    const start = new Date().getTime();
    console.log('*****RENDER*********** Charts options: ', this.getChartComputedOptions());

    const rez = (
      <div className="row style={width: '100%'}">
        <div
          className={'col no-padding NUfig-video-details-left-side__chartContainer '}
          ref={(internalComp) => {
            this.graphContainer = internalComp;
          }}
        >
          <ReactEcharts
            ref={(e) => {
              this.echartsReact = e;
              if (this.props.setChartsHandle) {
                this.props.setChartsHandle(e);
              }
            }}
            option={this.baseGraphStructure ? this.getVarGraphStructure() : this.initBaseGraphStructure()}
            style={{ height: this.props.height, width: '100%' }}
            onEvents={this.onEvents}
            theme={config.theme.name}
          />
        </div>
      </div>
    );
    console.log('*****RENDER*********** Charts took ', new Date().getTime() - start);
    return rez;
  }

  initLegend(legendSelected) {
    return {
      selected: legendSelected,
      left: 30,
      show: true,
      bottom: 0,
      z: 30,
      textStyle: {
        fontSize: 12,
        fontWeight: 'bold',
        color: this.props.speakerHidden ? 'gray' : 'black',
      },
    };
  }

  static MAX_LEGEND_HEIGHT = 75; //percentage
}
export default ChartsForSpeaker;
