import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../shared/reducers';
import Header from './header/Header';

interface IProps {
  history: any;
}

class Settings extends React.Component<IProps, any> {
  render() {
    let className = 'figaro-content';
    return (
      <div className="figaro-container">
        <Header selectedMenu="settings" history={this.props.history} />
        <div className={className}>Settings</div>
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => {
  return {};
};

export default connect(mapStateToProps, {})(Settings);
