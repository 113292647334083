import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import OneRubricPanel from '../../demo/components/rubric/OneRubricPanel';
import { IRootState } from '../../shared/reducers';
import Header from './header/Header';

type IProps = PropsFromRedux & { history: any; match: any }; // TODO FFS RouteComponentProps<P>

class OneRubric extends React.Component<IProps, any> {
  componentDidMount = () => {
    console.log('OneRubric did mount,  props ', this.props);
  };
  componentWillUnmount = () => {
    console.log('OneRubric will unmount ', this.props);
  };

  render() {
    console.log('OneRubric render ', this.props);
    let className = 'figaro-content';
    return (
      <div className="figaro-container">
        <Header selectedMenu="rubrics" history={this.props.history} />
        <div className={className}>
          {this.props.match && this.props.match.params && this.props.match.params.id ? (
            <OneRubricPanel match={this.props.match} history={this.props.history} />
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => {
  console.log('OneRubric mapState2Props ', state);
  return {
  };
};

const mapDispatchToProps = {};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(OneRubric);
