import { Button, Typography } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import React from 'react';

interface IProps {
  categoryName: string;
  allStreams: string[];
  selectedStreams: string[];
  selectedStreamsChanged: (category: string, newSelections: string[]) => void;
}

export default class CategoryOptions extends React.PureComponent<IProps, any> {
  render() {
    // console.log('CategoryOptions render  props: ', this.props);
    return (
      <div className="fig-options-pannel-category">
        <div className="fig-options-pannel-category__row">
          <div className="category-title">
            {this.props.categoryName}
          </div>
          <div>
            <Button className="fig-button" onClick={this.handleAllSelected}>
              ALL
            </Button>
            <Button className="fig-button" onClick={this.handleNoneSelected}>
              NONE
            </Button>
          </div>
        </div>
        {this.props.allStreams.map((stream, indx) => (
          <div className="fig-options-pannel-category__row" key={stream}>
            <div className="subcategory-title">
              <Typography color="textPrimary" variant="body1">
                {stream}
              </Typography>
            </div>
            <div>
              <Typography color="textPrimary" variant="body1">
                <Switch size="small" checked={this.props.selectedStreams.includes(stream)} onChange={this.handleChange} name={stream} />
              </Typography>
            </div>
          </div>
        ))}
      </div>
    );
  }

  handleAllSelected = (event) => {
    console.log(' selectedStreams ALL', this.props.allStreams);
    this.props.selectedStreamsChanged(this.props.categoryName, [...this.props.allStreams]);
  };

  handleNoneSelected = (event) => {
    console.log(' selectedStreams NONE', []);
    this.props.selectedStreamsChanged(this.props.categoryName, []);
  };

  handleChange = (event) => {
    let newStreams = [...this.props.selectedStreams];
    if (event.target.checked && !newStreams.includes(event.target.name)) {
      newStreams.push(event.target.name);
    } else if (!event.target.checked && newStreams.includes(event.target.name)) {
      newStreams = newStreams.filter((stream) => stream !== event.target.name);
    }
    console.log(' selectedStreams ', newStreams);
    this.props.selectedStreamsChanged(this.props.categoryName, newStreams);
  };
}
