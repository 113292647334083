import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { setGlobalAlert } from '../../../../actions/utils';
import { IRootState } from '../../../../shared/reducers';
import { createSubmissionRubric, getGlobalRubrics, getRubrics } from '../../../actions/figaroApiActions';
import { Rubric, SubmissionRubric } from '../../../model/ApiTypes';
import ChooseRubricForm from './ChooseRubricForm';
import SetRubricValuesForm from './SetRubricValuesForm';

type IProps = PropsFromRedux & {
  onClose: () => void;
  submission: number;
  addRubric: (rubric: SubmissionRubric) => void;
};

export interface IState {
  step?: number;
  rubrics: Rubric[];
  selectedRubricIdx: number;
  title: string;
  score: number;
}
export interface IChildProps {
  values: IState;
  setGlobalAlert: any;
  nextStep: () => void;
  prevStep?: () => void;

  handleChange: (input: any) => (e: any) => void;
  onClose: () => void;
}

class AddRubricForm extends React.PureComponent<IProps, IState> {
  public static TITLE = 'title';
  public static SCORE = 'score';
  public static SELECTED_IDX = 'selectedRubricIdx';

  constructor(props: IProps) {
    super(props);
    this.state = {
      rubrics: [],
      selectedRubricIdx: null,
      title: null,
      score: undefined,
      step: 1,
    };
  }

  buildRubricsArray = () =>
    Object.keys(this.props.rubrics)
      .map((rubricId) => this.props.rubrics[rubricId])
      .concat(Object.keys(this.props.globalRubrics).map((rubricId) => this.props.globalRubrics[rubricId]));

  buildSubmissionRubric = () => {
    let rubric: SubmissionRubric = {
      submission: this.props.submission,
      name: this.state.title,
      state: 'available',
      rubric: { ...this.state.rubrics[this.state.selectedRubricIdx].template, score: this.state.score },
    };
    return rubric;
  };

  // Proceed to next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
    if (step === 2) {
      let rubric = this.buildSubmissionRubric();
      // this.props.createSubmissionRubric(rubric, this.props.authentication.token);
      this.props.addRubric(rubric);
      this.props.onClose();
    }
  };

  // Go back to prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  // Handle fields change
  handleChange = (input: any) => (e: { target: { value: string } }) => {
    console.log('handleChange called, input/e ', input, e);
    switch (input) {
      case AddRubricForm.TITLE: {
        this.setState({ title: e.target.value });
        break;
      }
      case AddRubricForm.SCORE: {
        this.setState({ score: Number.parseInt(e.target.value, 10) });
        break;
      }
    }
  };
  setSelectedIdx = (idx: string) => {
    let rubric = this.state.rubrics[Number.parseInt(idx, 10)];
    this.setState({ selectedRubricIdx: Number.parseInt(idx, 10), title: rubric.name });
  };
  componentDidMount = () => {
    console.log('AddRubricForm did mount, loading Rubrics,  props ', this.props);
    this.loadRubrics();
  };
  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.rubrics !== this.props.rubrics || this.props.globalRubrics !== prevProps.globalRubrics) {
      this.setState({
        rubrics: this.buildRubricsArray(),
      });
    }
  }

  render() {
    const { title, score, selectedRubricIdx, step, rubrics } = this.state;
    const values = { title, score, selectedRubricIdx, rubrics };

    switch (step) {
      case 1:
        return (
          <ChooseRubricForm
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
            onClose={this.props.onClose}
            setSelectedIdx={this.setSelectedIdx}
            setGlobalAlert={this.props.setGlobalAlert}
          />
        );
      case 2:
        return (
          <SetRubricValuesForm
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            values={values}
            onClose={this.props.onClose}
            setGlobalAlert={this.props.setGlobalAlert}
          />
        );
      default:
        return null;
    }
  }

  loadRubrics() {
    this.props.getRubrics(this.props.authentication.token);
    if (!this.props.globalRubricsLoaded) this.props.getGlobalRubrics(this.props.authentication.token);
  }
}
const mapStateToProps = (state: IRootState) => {
  console.log('Rubric4Video mapStateToProps, state ', state);
  return {
    authentication: state.authentication,
    rubrics: state.rubrics.rubricsMap,
    globalRubrics: state.settings.globalRubricsMap ? state.settings.globalRubricsMap : {},
    globalRubricsLoaded: state.settings.globalRubricsMap && true,
  };
};

const mapDispatchToProps = { getRubrics, getGlobalRubrics, createSubmissionRubric, setGlobalAlert };

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(AddRubricForm);
