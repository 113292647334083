import { Popover } from '@material-ui/core';
import React from 'react';

export interface IKeyValueItem {
  key: string;
  displayedValue: string;
  object?: any;
}

export interface ISelectableItem extends IKeyValueItem {
  selected: boolean;
}

export interface IBorderedSelectorProps {
  anchorRef: any;
  items: ISelectableItem[];
  toggleSelection: (key, object?) => void;
  width?: number;
  height?: number;
  widthString?: string;
  heightString?: string;
  onClose: () => void;
  position?: 'left' | 'right';
  marginLeft?: number;
  inline?: boolean;
  noBorder?: boolean;
}
interface IProps extends IBorderedSelectorProps {
  svgChecked: JSX.Element;
  svgNotChecked: JSX.Element;
}

export default class BorderedSelector extends React.PureComponent<IProps, any> {
  render() {
    let marginProps = this.props.marginLeft ? { marginLeft: this.props.marginLeft } : {};
    let borderProps = this.props.noBorder ? { border: '0px solid white', padding: '0px' } : {};
    if (this.props.inline) {
      return this.generateItems(marginProps, borderProps);
    } else
      return this.props.anchorRef ? (
        <Popover
          open={true}
          anchorEl={this.props.anchorRef}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: this.props.position ? this.props.position : 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: this.props.position ? this.props.position : 'right',
          }}
          PaperProps={{ elevation: 0 }}
          onClose={this.props.onClose}

          //these were for using Popper, but there is an issue with closing it -could not prevent closing on checkbox click when it was rendered within a Select
          // <Popper
          // open
          // anchorEl={this.props.anchorRef}
          // placement="bottom-end"
          // modifiers={{
          //   flip: {
          //     enabled: false,
          //   },
          //   preventOverflow: {
          //     enabled: true,
          //     //boundariesElement: 'scrollParent',
          //     boundariesElement: this.props.anchorRef.parent,
          //     padding: 0,
          //   },
          // }}
          // popperOptions={{}}
        >
          {this.generateItems(marginProps, borderProps)}
        </Popover>
      ) : null;
  }

  generateItems = (marginProps: any, borderProps: any) => {
    return (
      <div
        className="fig-bordered-selector-container"
        style={{
          ...marginProps,
          ...borderProps,
          width: this.props.widthString ? this.props.widthString : this.props.width,
          height: this.props.heightString ? this.props.heightString : this.props.height,
        }}
      >
        {this.props.items.map((item, indx) => (
          <div className="row no-gutters fig-vertical-middle" key={item.key}>
            <div className="col-12 fig-bordered-selector-textbox">
              <label htmlFor={item.key}> {item.displayedValue}</label>
              <div className="fig-bordered-selector-icon">
                {item.selected ? this.props.svgChecked : this.props.svgNotChecked}
                <input
                  type="checkbox"
                  //size="small" for <Checkbox>
                  checked={item.selected}
                  onChange={this.handleChange(item.object)}
                  name={item.displayedValue}
                  id={item.key}
                  // onClick={this.handleClick} /*for Popper*/
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  handleChange = (object) => (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.toggleSelection(event.target.id, object);
  };
  // handleClick = (event) => {
  //   event.preventDefault();
  //   event.stopPropagation();
  // };
}
