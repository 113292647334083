import axios from 'axios';


const baseURL = process.env.REACT_APP_BASE_URL
export const s3URL = process.env.REACT_APP_BASE_S3
export const submissionCommentsURL = 'submissioncomments'
export const submissionHighlightsURL = 'submissionHighlights'
export const submissionsURL = 'submissions'
export const submissionSharesURL = 'submissionshares'
export const settingsURL = 'settings'
export const rubricsURL = 'rubrics'
export const submissionRubricsURL = 'submissionrubrics'
export const submissionEvaluationsURL = 'submissionevaluations'
export const foldersURL = 'folders'
export const folderTagsURL = 'submissionTags'
export const registerURL = 'auth/local/register'
export const confirmEMailURL = 'auth/email-confirmation'
export const forgotPasswordURL = 'auth/forgot-password'
export const resetPasswordURL = 'auth/reset-password'
export const resendConfirmationEMailURL = 'auth/send-email-confirmation'

export const usersURL = 'users'
export default axios.create({
    baseURL
});
