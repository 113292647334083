import { Box, Button, FormControl, IconButton, Select, Tooltip } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import React, { ChangeEvent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { createSelector } from 'reselect';
import sprites from '../../../../icons/fig-icons.svg';
import { IRootState } from '../../../shared/reducers';
import {
  createSubmissionEvaluation,
  createSubmissionRubric,
  deleteSubmissionEvaluation,
  deleteSubmissionRubric,
  updateSubmissionEvaluation,
} from '../../actions/figaroApiActions';
import { Evaluation } from '../../actions/figaroApiActions/types';
import { Category, EvaluationResponse, Score, SubmissionRubric } from '../../model/ApiTypes';
import { computeOwnEvaluations, SubmissionRubricsMap } from '../../model/Video';
import DeleteConfirmationDialog from '../shared/DeleteConfirmationDialog';
import AddRubricForm from './addrubric/AddRubricForm';
interface IProps extends PropsFromRedux {}
interface IState {
  selectedRubricIdx: number;
  selectedRubricId: number;
  addRubricDialogOpened: boolean;
  selectedRubricEvaluation: EvaluationResponse;
  deleteRubricDialogOpened: boolean;
  deleteEvaluationDialogOpened: boolean;
}

class Rubric4Video extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    console.log('Rubric4Video c-tor');
    const hasEvaluations = props.ownEvaluations && props.ownEvaluations.length > 0;
    this.state = {
      selectedRubricIdx: hasEvaluations ? 0 : undefined,
      selectedRubricEvaluation: hasEvaluations ? this.cloneOrMakeEmptyEvaluation(props.ownEvaluations[0]) : null,
      selectedRubricId: hasEvaluations ? props.ownEvaluations[0].rubricInfo.id : null,
      deleteRubricDialogOpened: false,
      addRubricDialogOpened: false,
      deleteEvaluationDialogOpened: false,
    };
  }
  componentDidMount() {
    console.log('Rubric4Video did mount');
  }
  componentDidUpdate(prevProps: IProps, prevState: IState) {
    console.log('Rubric4Video did update: prev props, state: ', prevProps, prevState, 'crt props, state: ', this.props, this.state);
    let evaluationsChanged = prevProps.ownEvaluations !== this.props.ownEvaluations;
    if (evaluationsChanged) {
      console.log('evaluations changed');
      if (this.state.selectedRubricId) {
        this.selectRubricById(this.state.selectedRubricId);
      } else {
        this.selectRubricByIdx(0);
      }
    }
  }

  cloneOrMakeEmptyEvaluation = (ownEvaluation: { rubricInfo: SubmissionRubric; evaluation: Evaluation }) => {
    if (ownEvaluation && ownEvaluation.evaluation && ownEvaluation.evaluation.evaluation) {
      return this.cloneEvaluation(ownEvaluation.evaluation.evaluation);
    } else {
      return this.makeEmptyEvaluation(ownEvaluation.rubricInfo);
    }
  };
  cloneEvaluation = (evaluation: EvaluationResponse) => {
    let rez = { categories: [] } as EvaluationResponse;
    evaluation.categories.forEach((category: Score, idx) => {
      rez.categories.push({ response: [...category.response] });
    });
    return rez;
  };
  makeEmptyEvaluation = (rubricInfo: SubmissionRubric) => {
    let rez = { categories: [] } as EvaluationResponse;
    rubricInfo.rubric.categories.forEach((category: Category) => {
      let responses = Array.from(Array(category.questions.length).keys()).map((pos) => 0);
      rez.categories.push({ response: responses });
    });
    return rez;
  };

  setQuestionRating = (idxquestion: number, idxcategory: number) => (event: ChangeEvent<{}>, newValue: number) => {
    // console.log('fn of setQuestionRating q/Cat/val: ', idxquestion, idxcategory, newValue);
    let existingCategories = this.state.selectedRubricEvaluation.categories;
    let newCategories = [...existingCategories];
    newCategories[idxcategory] = { response: [...existingCategories[idxcategory].response] };
    newCategories[idxcategory].response[idxquestion] = !isNaN(newValue) ? newValue : 0;
    this.setState({ selectedRubricEvaluation: { categories: newCategories } });
  };

  onAddRubric = () => {
    this.setState({ addRubricDialogOpened: true });
  };
  onDeleteRubric = () => {
    if (this.hasConsistentSelection()) {
      if (!isNaN(this.state.selectedRubricIdx)) {
        this.setState({ deleteRubricDialogOpened: true });
      }
    }
  };
  onDeleteRubricConfirmed = () => {
    this.closeDeleteRubricConfirmationDialog();
    this.props.deleteSubmissionRubric(
      this.props.ownEvaluations[this.state.selectedRubricIdx].rubricInfo.id,
      this.props.authentication.token
    );
  };
  closeDeleteRubricConfirmationDialog = () => {
    this.setState({ deleteRubricDialogOpened: false });
  };

  onSaveEvaluation = () => {
    if (this.props.ownEvaluations[this.state.selectedRubricIdx].evaluation) {
      this.props.updateSubmissionEvaluation(
        { evaluation: this.cloneEvaluation(this.state.selectedRubricEvaluation), state: 'available' },
        this.props.ownEvaluations[this.state.selectedRubricIdx].rubricInfo.id,
        this.props.authentication.token
      );
    } else {
      this.props.createSubmissionEvaluation(
        { evaluation: this.cloneEvaluation(this.state.selectedRubricEvaluation), state: 'available' },
        this.props.ownEvaluations[this.state.selectedRubricIdx].rubricInfo.id,
        this.props.authentication.token
      );
    }
  };
  onDeleteEvaluation = () => {
    if (this.props.ownEvaluations[this.state.selectedRubricIdx].evaluation) {
      this.setState({ deleteEvaluationDialogOpened: true });
    }
  };
  onDeleteEvaluationConfirmed = () => {
    this.closeDeleteEvaluationConfirmationDialog();
    if (this.props.ownEvaluations[this.state.selectedRubricIdx].evaluation) {
      this.props.deleteSubmissionEvaluation(
        this.props.ownEvaluations[this.state.selectedRubricIdx].evaluation.id,
        this.props.ownEvaluations[this.state.selectedRubricIdx].rubricInfo.id,
        this.props.authentication.token
      );
    }
  };
  closeDeleteEvaluationConfirmationDialog = () => {
    this.setState({ deleteEvaluationDialogOpened: false });
  };

  selectRubricNone = () => {
    this.setState({
      selectedRubricIdx: undefined,
      selectedRubricId: null,
      selectedRubricEvaluation: null,
    });
  };
  selectRubricByIdx = (idx: number) => {
    const hasEvaluations = this.props.ownEvaluations && this.props.ownEvaluations.length > idx;
    if (hasEvaluations && !isNaN(idx) && idx >= 0) {
      this.setState({
        selectedRubricIdx: idx,
        selectedRubricId: this.props.ownEvaluations[idx].rubricInfo.id,
        selectedRubricEvaluation: this.cloneOrMakeEmptyEvaluation(this.props.ownEvaluations[idx]),
      });
    } else {
      this.selectRubricNone();
    }
  };
  selectRubricById = (id: number) => {
    const hasEvaluations = this.props.ownEvaluations && this.props.ownEvaluations.length > 0;
    if (hasEvaluations && !isNaN(id)) {
      let idx = this.props.ownEvaluations.findIndex((evaluation) => evaluation.rubricInfo.id === id);
      if (idx >= 0) {
        this.selectRubricByIdx(idx);
        return;
      }
    }
    this.selectRubricNone();
  };

  hasConsistentSelection = () => {
    let rez =
      !isNaN(this.state.selectedRubricIdx) &&
      this.props.ownEvaluations.length > this.state.selectedRubricIdx &&
      this.state.selectedRubricIdx >= 0 &&
      this.state.selectedRubricId === this.props.ownEvaluations[this.state.selectedRubricIdx].rubricInfo.id;
    return rez;
  };
  render() {
    console.log(' render Rubric4Video ', this.props, this.state);

    let style = {} as React.CSSProperties;
    if (this.props.playerHeight) {
      style.height = this.computePanelHeight(this.props.playerHeight); //+ 'px';
    } else {
      console.log(' render Rubric4Video NO HEIGHT AVAIL');
    }
    return this.props.currentVideoId ? (
      <div className="row fig-video-details-rubric-main-container">
        <div className="col-12 no-padding">
          <div className="row">
            <FormControl className="col-6 fig-rubric-input fig-horizontal-center">
              <Select
                native
                labelId="select-rubric-label"
                id="select-rubric"
                value={this.hasConsistentSelection() ? this.state.selectedRubricIdx : -1}
                onChange={(event) => this.selectRubricByIdx(event.target.value as number)}
                style={{ fontSize: 'smaller', background: 'white' }}
              >
                {/* Select will display the first option anyway when the value is not one of the options
                so we make a fake first option whose text will be displayed in this case, but we hide it from the options */}
                {!this.hasConsistentSelection() && (
                  <option value="-1" disabled style={{ display: 'none' }} key={`_${-1}`}>
                    (No rubric selected)
                  </option>
                )}
                {this.props.ownEvaluations.map((evaluation, idx) => (
                  <option value={idx} key={`${evaluation.rubricInfo.id}_${idx}`}>
                    {evaluation.rubricInfo.name}
                  </option>
                ))}
              </Select>
            </FormControl>

            <Tooltip title="Delete RUBRIC">
              <IconButton onClick={this.onDeleteRubric} className="fig-button fig-delete-rubric col-2">
                <svg>
                  <use href={sprites + '#delete-rubric-icon'} />
                </svg>
              </IconButton>
            </Tooltip>
            <Button className="col-4 fig-button rubric" onClick={this.onAddRubric}>
              ADD RUBRIC
            </Button>
          </div>
          <div className="row" style={style}>
            {this.hasConsistentSelection() ? (
              <>{this.renderEvaluationForRubric(this.props.ownEvaluations[this.state.selectedRubricIdx])}</>
            ) : null}
          </div>
          <div className="row">
            <div className="col-12 no-padding w-100 fig-space-between margin-top--20">
              <Button className="fig-button" onClick={this.onSaveEvaluation} disabled={!this.hasConsistentSelection()}>
                Save Evaluation
              </Button>
              <Tooltip title="This will remove your evaluation, not the questions or the rubric">
                <Button
                  className="fig-button "
                  onClick={this.onDeleteEvaluation}
                  disabled={!this.hasConsistentSelection() || !this.props.ownEvaluations[this.state.selectedRubricIdx].evaluation}
                >
                  Delete Evaluation
                </Button>
              </Tooltip>
            </div>
          </div>
        </div>
        {this.renderAddRubricDialog()}
        {this.renderDeleteSubmissionRubricConfirmation()}
        {this.renderDeleteEvaluationConfirmation()}
      </div>
    ) : null;
  }
  renderDeleteSubmissionRubricConfirmation() {
    return this.state.deleteRubricDialogOpened ? (
      <DeleteConfirmationDialog
        handleDelete={this.onDeleteRubricConfirmed}
        handleClose={this.closeDeleteRubricConfirmationDialog}
        title={'Delete Submission Rubric'}
        text={`Are you sure you want to delete the rubric "${
          this.props.ownEvaluations[this.state.selectedRubricIdx].rubricInfo.name
        }" for this video?`}
      />
    ) : null;
  }
  renderDeleteEvaluationConfirmation() {
    return this.state.deleteEvaluationDialogOpened ? (
      <DeleteConfirmationDialog
        handleDelete={this.onDeleteEvaluationConfirmed}
        handleClose={this.closeDeleteEvaluationConfirmationDialog}
        title={'Delete Evaluation'}
        text={`Are you sure you want to delete your evaluation for rubric "${
          this.props.ownEvaluations[this.state.selectedRubricIdx].rubricInfo.name
        }" for this video? This will remove your evaluation, not the questions or the rubric`}
      />
    ) : null;
  }

  renderAddRubricDialog = () => {
    return this.state.addRubricDialogOpened ? (
      <AddRubricForm onClose={this.onCloseAddRubricDialog} submission={this.props.currentVideoId} addRubric={this.addRubric} />
    ) : null;
  };

  onCloseAddRubricDialog = () => {
    this.setState({ addRubricDialogOpened: false });
  };

  addRubric = (rubric: SubmissionRubric) => {
    this.props.createSubmissionRubric(rubric, this.props.authentication.token).then(
      (okId) => this.selectRubricById(okId),
      (err) => {
        console.log('create submissionrubric  err ', err);
      }
    );
  };

  renderEvaluationForRubric = (info: { rubricInfo: SubmissionRubric; evaluation: Evaluation }) => {
    let { rubricInfo, evaluation } = info;
    let rez = (
      <div className="w-100">
        {rubricInfo.rubric.categories.map((category, idxCategory) => {
          return (
            <div key={`${idxCategory}_${category.name}`}>
              {this.renderCategory(
                category,
                rubricInfo.rubric.score,
                evaluation ? evaluation.evaluation.categories[idxCategory] : null,
                idxCategory
              )}
            </div>
          );
        })}
      </div>
    );
    return rez;
  };

  renderCategory = (category: Category, rubricScore: number, score: Score, idxCategory: number) => {
    let rez = (
      <div>
        <div className="fig-rubric-category-container">
          <b>
            <svg className="fig-rubric-icon">
              <use href={sprites + '#rubric-icon'} />
            </svg>
            {`  ${category.name}`}
          </b>
          <div>
            {category.questions.map((question, idxQuestion) =>
              this.renderQuestionWScore(question, rubricScore, score, idxQuestion, idxCategory)
            )}
          </div>
        </div>
      </div>
    );
    return rez;
  };
  renderQuestionWScore = (question: string, rubricScore: number, score: Score, idxQuestion: number, idxCategory: number) => {
    let scoreFromState = this.state.selectedRubricEvaluation ? this.state.selectedRubricEvaluation.categories[idxCategory] : null;
    console.log(
      'renderQuestionWScore: question/idxCategory/idxQuestion ',
      question,
      idxCategory,
      idxQuestion,
      ' , scoreFromState: ',
      scoreFromState
    );

    let itemToRender = (
      <div className="fig-rubric-category-container__box" key={`${idxCategory}_${idxQuestion}_${question}`}>
        {/* //_${score}`}> */}
        <div>
          <b>{1 + idxQuestion}.</b>
          {question}
        </div>
        <Box borderColor="transparent" className="fig-rating-box">
          <Rating
            name={`rating_${idxCategory}_${idxQuestion}_${question}`}
            //_${score}`}
            max={rubricScore}
            value={
              scoreFromState && scoreFromState.response && scoreFromState.response[idxQuestion] ? scoreFromState.response[idxQuestion] : 0
            }
            onChange={this.setQuestionRating(idxQuestion, idxCategory)}
            size="small"
          />
          <div>
            {scoreFromState && scoreFromState.response && scoreFromState.response[idxQuestion]
              ? `${scoreFromState.response[idxQuestion]}/${rubricScore}`
              : ''}
          </div>
          {/* <b>(was: {score && score.response && score.response[idxQuestion] ? `${score.response[idxQuestion]}/${rubricScore}` : 0})</b> */}
        </Box>
      </div>
    );
    return itemToRender;
  };

  //height: calc(100vh - 337px - 30px - 41px - 72px);
  computePanelHeight = (videoHeight: number) => {
    //TODO FFS only if player is docked, otherwise consider videoHeight=0
    //console.log('compute RubricTab height for video=', videoHeight);

    // return `calc(100vh - ${videoHeight}px - 30px - 41px - 72px)`;
    let rez = window.innerHeight - videoHeight - 30 - 10 - 41 - 10 - 40 - 20 - 45 - 30;
    console.log('compute RubricTab height for videoH=', videoHeight, ' rez = ', rez, ' window: ', window.innerHeight);
    return rez > 0 ? rez : 0;
  };
}

const emptySubmissionRubricsMap = {} as SubmissionRubricsMap;
const selectorEvaluations: (IRootState) => SubmissionRubricsMap = (state: IRootState) => {
  console.log('Rubric4Video: SELECTOR selectorEvaluations called with params ', state);
  let rez =
    state.video.currentVideoId && state.video.videosMap[state.video.currentVideoId]
      ? state.video.videosMap[state.video.currentVideoId].submissionRubricsMap
      : emptySubmissionRubricsMap;
  console.log('Rubric4Video: SELECTOR selectorEvaluations returns  ', rez);
  return rez;
};
const selectorOwnUser: (IRootState) => number = (state: IRootState) => {
  console.log('Rubric4Video: SELECTOR selectorOwnUser called with params ', state);
  let rez = state.authentication ? state.authentication.user.id : null;
  console.log('Rubric4Video: SELECTOR selectorOwnUser returns  ', rez);
  return rez;
};

const retrieveOwnEvaluations = createSelector([selectorEvaluations, selectorOwnUser], (submissionRubricsMap, user) =>
  computeOwnEvaluations(submissionRubricsMap, user)
);

const mapStateToProps = (state: IRootState) => {
  console.log('Rubric4Video mapStateToProps, state ', state);
  return {
    playerHeight: state.utils.playerHeight,
    windowHeight: state.utils.windowHeight,
    authentication: state.authentication,
    currentVideoId: state.video.currentVideoId,
    ownEvaluations: retrieveOwnEvaluations(state) as {
      rubricInfo: SubmissionRubric;
      evaluation: Evaluation;
    }[],
  };
};

const mapDispatchToProps = {
  deleteSubmissionRubric,
  createSubmissionEvaluation,
  deleteSubmissionEvaluation,
  updateSubmissionEvaluation,
  createSubmissionRubric,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Rubric4Video);
