/* eslint-disable import/no-anonymous-default-export */
import {
  AuthClearAction,
  AUTH_CLEAR,
  SubmissionDeletedAction,
  SubmissionEnhancedInfoAction,
  SubmissionMetaAction,
  SubmissionRubricEvaluationsActionTypes,
  SubmissionRubricsActionTypes,
  SUBMISSIONS,
  SubmissionsAction,
  SubmissionsAskedToOthersAction,
  SubmissionShareActionTypes,
  SubmissionsToUploadAction,
  SUBMISSIONS_ASKED_TO_OTHERS,
  SUBMISSIONS_TO_UPLOAD,
  SUBMISSION_DELETED,
  SUBMISSION_ENHANCED_INFO,
  SUBMISSION_META,
  SUBMISSION_RUBRIC_ADDED,
  SUBMISSION_RUBRIC_DELETED,
  SUBMISSION_RUBRIC_EVALUATIONS,
  SUBMISSION_RUBRIC_EVALUATION_ADDED,
  SUBMISSION_RUBRIC_EVALUATION_DELETED,
  SUBMISSION_RUBRIC_EVALUATION_UPDATED,
  SUBMISSION_RUBRIC_UPDATED,
  SUBMISSION_SHARE_ADDED,
  SUBMISSION_SHARE_DELETED,
} from '../actions/figaroApiActions/types';
import {
  VideoActionTypes,
  VIDEO_ANNOTATION_ADDED,
  VIDEO_ANNOTATION_DELETED,
  VIDEO_ANNOTATION_DRAFT,
  VIDEO_ANNOTATION_EDITING,
  VIDEO_ANNOTATION_EDITING_NONE,
  VIDEO_ANNOTATION_UPDATED,
  VIDEO_COMMENT_ADDED,
  VIDEO_COMMENT_DELETED,
  VIDEO_COMMENT_PRIVACY_CHANGED,
  VIDEO_COMMENT_UPDATED,
  VIDEO_DURATION_COMPUTED,
  VIDEO_HIGHLIGHT_ADDED,
  VIDEO_HIGHLIGHT_DELETED,
  // VIDEO_HIGHLIGHT_SELECTED,
  VIDEO_HIGHLIGHT_PRIVACY_CHANGED,
  VIDEO_HIGHLIGHT_SELECTED,
  VIDEO_HIGHLIGHT_UPDATED,
  VIDEO_PAUSED,
  VIDEO_SELECTED,
  VIDEO_TAG_ADDED,
  VIDEO_TAG_CREATION,
  VIDEO_TAG_CREATION_DRAFT,
  VIDEO_TAG_CREATION_NONE,
  VIDEO_TAG_DELETED,
  VIDEO_TAG_DRAFT,
  VIDEO_TAG_EDITING,
  VIDEO_TAG_EDITING_NONE,
  VIDEO_TAG_UPDATED,
  VIDEO_TIME_REACHED,
} from '../actions/videoActions/types';
import { Category, FaceAnalysisItem } from '../model/ApiTypes';
import { Annotation, Comment, Coords, emptyEvaluationsMap, Highlight, makeEvaluationsMap, Video } from '../model/Video';

export interface AnnotationStatus {
  video: Video;
  comment: Comment;
  draftAnnotation: Annotation;
}
export const defaultAnnotatingStatus: Readonly<AnnotationStatus> = null;

export interface TaggingStatus {
  video: Video;
  tag: FaceAnalysisItem;
  draftTagCoords: Coords;
}
export interface TagCreationStatus {
  video: Video;
  name: string;
  draftTagCoords: Coords;
  status: string;
}

export const defaultTaggingStatus: Readonly<TaggingStatus> = null;
export const defaultTagCreationStatus: Readonly<TagCreationStatus> = null;
const initialVideosMap = (): { [id: number]: Video } => {
  return {};
};

export const DURATION_UNKNOWN = 0;
export const TIME_INITIAL = 0;
export const ANNOTATION_COORD_NONE = -1;
export const DRAFT_TAG_COORDS_NONE = {
  x1: 0,
  y1: 0,
  x2: 0,
  y2: 0,
};

const initialState = {
  videosMap: initialVideosMap(),
  currentVideoId: null,
  currentTime: TIME_INITIAL,
  videoDuration: DURATION_UNKNOWN,
  currentAnnotatingStatus: defaultAnnotatingStatus, // info about the annotation that is being edited
  currentTaggingStatus: defaultTaggingStatus, // info about the faceTag that is being edited
  currentTagCreationStatus: defaultTagCreationStatus, //info about the face tag that is being created
  isPaused: true,
  currentHighlight: null as Highlight,
};

export type FigaroVideoState = Readonly<typeof initialState>;

// Reducers
// eslint-disable-next-line complexity
export default function (
  state: FigaroVideoState = initialState,
  action:
    | VideoActionTypes
    | SubmissionEnhancedInfoAction
    | SubmissionMetaAction
    | SubmissionsAskedToOthersAction
    | SubmissionsToUploadAction
    | SubmissionsAction
    | AuthClearAction
    | SubmissionRubricsActionTypes
    | SubmissionRubricEvaluationsActionTypes
    | SubmissionShareActionTypes
    | SubmissionDeletedAction
): FigaroVideoState {
  switch (action.type) {
    case AUTH_CLEAR: {
      return { ...initialState };
    }

    // TODO FFS HOW TO UPDATE HL SEL AT  VIDEO SELECT
    case VIDEO_HIGHLIGHT_SELECTED: {
      let theUpdatedVideo: Video = state.videosMap[action.payload.videoId];
      computeAnalysisId(theUpdatedVideo, action.payload.highlight);
      return {
        ...state,
        currentHighlight: action.payload.highlight,
      };
    }
    case VIDEO_HIGHLIGHT_DELETED: {
      let theUpdatedVideo: Video = state.videosMap[action.payload.videoId];
      theUpdatedVideo = {
        ...theUpdatedVideo,
        highlights: {
          highlights: [...theUpdatedVideo.highlights.highlights].filter((highlight) => highlight.id !== action.payload.highlightId),
        },
      };
      return {
        ...state,
        videosMap: {
          ...state.videosMap,
          [action.payload.videoId]: theUpdatedVideo,
        },
        currentHighlight:
          state.currentHighlight && state.currentHighlight.id === action.payload.highlightId ? null : state.currentHighlight,
      };
    }
    case VIDEO_HIGHLIGHT_ADDED: {
      let theUpdatedVideo: Video = state.videosMap[action.payload.videoId];
      //update idx for action.payload.highlight, theUpdatedVideo
      computeAnalysisId(theUpdatedVideo, action.payload.highlight);
      theUpdatedVideo = {
        ...theUpdatedVideo,
        highlights: {
          highlights: [...theUpdatedVideo.highlights.highlights, action.payload.highlight].sort((a: Highlight, b: Highlight) => {
            if (a.startTime < b.startTime) return -1;
            if (a.startTime > b.startTime) return 1;
            return a.id < b.id ? -1 : 1;
          }),
        },
      };
      return {
        ...state,
        videosMap: {
          ...state.videosMap,
          [action.payload.videoId]: theUpdatedVideo,
        },
      };
    }
    case VIDEO_HIGHLIGHT_UPDATED: {
      let theUpdatedVideo: Video = state.videosMap[action.payload.videoId];
      computeAnalysisId(theUpdatedVideo, action.payload.highlight);
      theUpdatedVideo = {
        ...theUpdatedVideo,
        highlights: {
          highlights: theUpdatedVideo.highlights.highlights.map((oldHighlight) =>
            oldHighlight.id === action.payload.highlightId
              ? {
                  ...action.payload.highlight,
                }
              : oldHighlight
          ),
        },
      };

      return {
        ...state,
        videosMap: {
          ...state.videosMap,
          [action.payload.videoId]: theUpdatedVideo,
        },
        currentHighlight:
          state.currentHighlight && state.currentHighlight.id === action.payload.highlightId
            ? action.payload.highlight
            : state.currentHighlight,
      };
    }
    case VIDEO_HIGHLIGHT_PRIVACY_CHANGED: {
      let theUpdatedVideo: Video = state.videosMap[action.payload.videoId];
      computeAnalysisId(theUpdatedVideo, action.payload.highlight);
      theUpdatedVideo = {
        ...theUpdatedVideo,
        highlights: {
          highlights: theUpdatedVideo.highlights.highlights.map((oldHighlight) =>
            oldHighlight.id === action.payload.highlightId ? { ...action.payload.highlight } : oldHighlight
          ),
        },
      };
      return {
        ...state,
        videosMap: {
          ...state.videosMap,
          [action.payload.videoId]: theUpdatedVideo,
        },
        currentHighlight:
          state.currentHighlight && state.currentHighlight.id === action.payload.highlightId
            ? action.payload.highlight
            : state.currentHighlight,
      };
    }
    //FFS POC evaluations: evaluations might remain empty/1 because of rights, but averages might have changed on server
    //should somehow re-get rubric averages at evaluation update/delete, but this came with all rubric info, including changes in evaluations etc
    //FFS POC evaluations: also maybe when changing Shares, averages are recomputed by server and we should re-get?

    case SUBMISSION_RUBRIC_EVALUATIONS: {
      // console.log('SUBMISSION_RUBRIC_EVALUATIONS payload', action.payload);
      let theUpdatedVideo: Video = state.videosMap[action.payload.videoId];
      let evalutionsRemainedEmpty = false;
      if (
        action.payload.submissionRubricEvaluations.length ===
          0 /*Object.keys(theUpdatedVideo.submissionRubricsMap[action.payload.submissionRubricId].evaluationsMap).length === 0*/ &&
        (!theUpdatedVideo.submissionRubricsMap[action.payload.submissionRubricId].evaluationsMap ||
          emptyEvaluationsMap === theUpdatedVideo.submissionRubricsMap[action.payload.submissionRubricId].evaluationsMap)
      ) {
        evalutionsRemainedEmpty = true;
      } else {
        theUpdatedVideo = {
          ...theUpdatedVideo,
          submissionRubricsMap: {
            ...theUpdatedVideo.submissionRubricsMap,
            [action.payload.submissionRubricId]: {
              rubricInfo: theUpdatedVideo.submissionRubricsMap[action.payload.submissionRubricId].rubricInfo,
              evaluationsMap: makeEvaluationsMap(action.payload.submissionRubricEvaluations),
            },
          },
        };
      }
      // console.log('SUBMISSION_RUBRIC_EVALUATIONS returns ');
      return evalutionsRemainedEmpty
        ? state
        : {
            ...state,
            videosMap: {
              ...state.videosMap,
              [action.payload.videoId]: theUpdatedVideo,
            },
          };
    }
    case SUBMISSION_RUBRIC_EVALUATION_DELETED: {
      // console.log('SUBMISSION_RUBRIC_EVALUATION_DELETED payload', action.payload);
      let theUpdatedVideo: Video = state.videosMap[action.payload.videoId];
      let updated = { ...theUpdatedVideo.submissionRubricsMap[action.payload.submissionRubricId] };
      updated.evaluationsMap = { ...updated.evaluationsMap };
      delete updated.evaluationsMap[action.payload.evaluationId];

      //FFS POC evaluations - show server-computed averages for regular users
      updated.rubricInfo = {
        ...updated.rubricInfo,
        rubric: {
          ...updated.rubricInfo.rubric,
          categories: updated.rubricInfo.rubric.categories.map((category, idx) => {
            return { ...category, averages: action.payload.evaluationResponse.categories[idx].averages } as Category;
          }),
        },
      };

      theUpdatedVideo = {
        ...theUpdatedVideo,
        submissionRubricsMap: {
          ...theUpdatedVideo.submissionRubricsMap,
          [action.payload.submissionRubricId]: updated,
        },
      };
      // console.log('SUBMISSION_RUBRIC_EVALUATION_DELETED returns');
      return {
        ...state,
        videosMap: {
          ...state.videosMap,
          [action.payload.videoId]: theUpdatedVideo,
        },
      };
    }
    case SUBMISSION_RUBRIC_EVALUATION_UPDATED:
    case SUBMISSION_RUBRIC_EVALUATION_ADDED: {
      // console.log('SUBMISSION_RUBRIC_EVALUATION_UPDATED/ADDED payload', action.payload);
      let theUpdatedVideo: Video = state.videosMap[action.payload.videoId];
      let updated = { ...theUpdatedVideo.submissionRubricsMap[action.payload.submissionRubricId] };
      updated.evaluationsMap = { ...updated.evaluationsMap, [action.payload.evaluation.id]: action.payload.evaluation };
      //FFS POC evaluations - show server-computed averages for regular users
      updated.rubricInfo = {
        ...updated.rubricInfo,
        rubric: {
          ...updated.rubricInfo.rubric,
          categories: updated.rubricInfo.rubric.categories.map((category, idx) => {
            return { ...category, averages: action.payload.evaluation.evaluation.categories[idx].averages } as Category;
          }),
        },
      };
      theUpdatedVideo = {
        ...theUpdatedVideo,
        submissionRubricsMap: {
          ...theUpdatedVideo.submissionRubricsMap,
          [action.payload.submissionRubricId]: updated,
        },
      };
      // console.log('SUBMISSION_RUBRIC_EVALUATION_UPDATED/ADDED return');
      return {
        ...state,
        videosMap: {
          ...state.videosMap,
          [action.payload.videoId]: theUpdatedVideo,
        },
      };
    }
    case SUBMISSION_RUBRIC_UPDATED: {
      let theUpdatedVideo: Video = state.videosMap[action.payload.videoId];
      theUpdatedVideo = {
        ...theUpdatedVideo,
        submissionRubricsMap: {
          ...theUpdatedVideo.submissionRubricsMap,
          [action.payload.submissionRubricId]: {
            rubricInfo: {
              ...theUpdatedVideo.submissionRubricsMap[action.payload.submissionRubricId].rubricInfo,
              name: action.payload.name,
            },
            evaluationsMap: theUpdatedVideo.submissionRubricsMap[action.payload.submissionRubricId].evaluationsMap,
          },
        },
      };
      return {
        ...state,
        videosMap: {
          ...state.videosMap,
          [action.payload.videoId]: theUpdatedVideo,
        },
      };
    }
    case SUBMISSION_RUBRIC_ADDED: {
      let theUpdatedVideo: Video = state.videosMap[action.payload.videoId];
      theUpdatedVideo = {
        ...theUpdatedVideo,
        submissionRubricsMap: {
          ...theUpdatedVideo.submissionRubricsMap,
          [action.payload.submissionRubricId]: { rubricInfo: action.payload.submissionRubric, evaluationsMap: emptyEvaluationsMap },
        },
      };
      return {
        ...state,
        videosMap: {
          ...state.videosMap,
          [action.payload.videoId]: theUpdatedVideo,
        },
      };
    }
    case SUBMISSION_RUBRIC_DELETED: {
      let theUpdatedVideo: Video = state.videosMap[action.payload.videoId];
      theUpdatedVideo = {
        ...theUpdatedVideo,
        submissionRubricsMap: {
          ...theUpdatedVideo.submissionRubricsMap,
        },
      };
      delete theUpdatedVideo.submissionRubricsMap[action.payload.submissionRubricId];
      return {
        ...state,
        videosMap: {
          ...state.videosMap,
          [action.payload.videoId]: theUpdatedVideo,
        },
      };
    }
    case SUBMISSION_SHARE_DELETED: {
      let theUpdatedVideo: Video = state.videosMap[action.payload.videoId];
      theUpdatedVideo = {
        ...theUpdatedVideo,
        submissionSharesMap: {
          ...theUpdatedVideo.submissionSharesMap,
        },
      };
      delete theUpdatedVideo.submissionSharesMap[action.payload.shareId];
      return {
        ...state,
        videosMap: {
          ...state.videosMap,
          [action.payload.videoId]: theUpdatedVideo,
        },
      };
    }
    case SUBMISSION_SHARE_ADDED: {
      let theUpdatedVideo: Video = state.videosMap[action.payload.videoId];
      theUpdatedVideo = {
        ...theUpdatedVideo,
        submissionSharesMap: {
          ...theUpdatedVideo.submissionSharesMap,
          [action.payload.shareId]: action.payload.share,
        },
      };
      return {
        ...state,
        videosMap: {
          ...state.videosMap,
          [action.payload.videoId]: theUpdatedVideo,
        },
      };
    }
    case SUBMISSION_DELETED: {
      let newVideosMap = { ...state.videosMap };
      if (newVideosMap[action.payload.submission.id]) {
        delete newVideosMap[action.payload.submission.id];
      }
      return {
        ...state,
        videosMap: newVideosMap,
      };
    }
    case VIDEO_ANNOTATION_UPDATED: {
      let theUpdatedVideo: Video = state.videosMap[action.payload.videoId];
      theUpdatedVideo = {
        ...theUpdatedVideo,
        comments: {
          comments: theUpdatedVideo.comments.comments.map((oldComment) =>
            oldComment.id === action.payload.commentId ? { ...oldComment, annotation: action.payload.annotation } : oldComment
          ),
        },
      };
      return {
        ...state,
        videosMap: {
          ...state.videosMap,
          [action.payload.videoId]: theUpdatedVideo,
        },
      };
    }

    case VIDEO_ANNOTATION_ADDED: {
      let theUpdatedVideo: Video = state.videosMap[action.payload.videoId];
      theUpdatedVideo = {
        ...theUpdatedVideo,
        comments: {
          comments: theUpdatedVideo.comments.comments.map((comment) =>
            comment.id !== action.payload.commentId ? comment : { ...comment, annotation: action.payload.annotation }
          ),
        },
      };
      return {
        ...state,
        videosMap: {
          ...state.videosMap,
          [action.payload.videoId]: theUpdatedVideo,
        },
      };
    }

    case VIDEO_ANNOTATION_DELETED: {
      let theUpdatedVideo: Video = state.videosMap[action.payload.videoId];
      theUpdatedVideo = {
        ...theUpdatedVideo,
        comments: {
          comments: [...theUpdatedVideo.comments.comments].map((comment) =>
            comment.id !== action.payload.commentId ? comment : { ...comment, annotation: null }
          ),
        },
      };
      return {
        ...state,
        videosMap: {
          ...state.videosMap,
          [action.payload.videoId]: theUpdatedVideo,
        },
      };
    }
    case VIDEO_COMMENT_DELETED: {
      let theUpdatedVideo: Video = state.videosMap[action.payload.videoId];
      theUpdatedVideo = {
        ...theUpdatedVideo,
        comments: {
          comments: [...theUpdatedVideo.comments.comments].filter((element) => element.id !== action.payload.commentId),
        },
      };

      return {
        ...state,
        videosMap: {
          ...state.videosMap,
          [action.payload.videoId]: theUpdatedVideo,
        },
      };
    }
    case VIDEO_COMMENT_ADDED: {
      let theUpdatedVideo: Video = state.videosMap[action.payload.videoId];
      theUpdatedVideo = {
        ...theUpdatedVideo,
        comments: {
          comments: [...theUpdatedVideo.comments.comments, action.payload.comment].sort((a: Comment, b: Comment) => {
            if (a.time < b.time) return -1;
            if (a.time > b.time) return 1;
            return a.id < b.id ? -1 : 1;
          }),
        },
      };
      return {
        ...state,
        videosMap: {
          ...state.videosMap,
          [action.payload.videoId]: theUpdatedVideo,
        },
      };
    }
    case VIDEO_COMMENT_UPDATED: {
      let theUpdatedVideo: Video = state.videosMap[action.payload.videoId];
      theUpdatedVideo = {
        ...theUpdatedVideo,
        comments: {
          comments: theUpdatedVideo.comments.comments.map((oldComment) =>
            oldComment.id === action.payload.comment.id
              ? {
                  ...oldComment,
                  privacy: action.payload.comment.privacy,
                  time: action.payload.comment.time,
                  text: action.payload.comment.text,
                  updated_at: action.payload.comment.updated_at,
                }
              : oldComment
          ),
        },
      };
      return {
        ...state,
        videosMap: {
          ...state.videosMap,
          [action.payload.videoId]: theUpdatedVideo,
        },
      };
    }
    case VIDEO_COMMENT_PRIVACY_CHANGED: {
      let theUpdatedVideo: Video = state.videosMap[action.payload.videoId];
      theUpdatedVideo = {
        ...theUpdatedVideo,
        comments: {
          comments: theUpdatedVideo.comments.comments.map((oldComment) =>
            oldComment.id === action.payload.commentId ? { ...oldComment, privacy: action.payload.privacy } : oldComment
          ),
        },
      };
      return {
        ...state,
        videosMap: {
          ...state.videosMap,
          [action.payload.videoId]: theUpdatedVideo,
        },
      };
    }

    case SUBMISSIONS:
    case SUBMISSIONS_TO_UPLOAD:
    case SUBMISSIONS_ASKED_TO_OTHERS: {
      const map = {};
      action.payload.videos.forEach((video) => {
        map[video.id] = video;
      });
      return {
        ...state,
        videosMap: map,
        // note: the analysis , transcript, comments etc, i.e. the enhanced video info is not fully retrieved, just empty arrays
        // later, if a given video is selected, its "enhaced" info will be downloaded and made available via the SUBMISSION_ENHANCED_INFO event
      };
    }
    // TODO FFS update thresholds at SUBMISSION_DISFLUENCIES_UPDATED
    case SUBMISSION_META:
      return {
        ...state,
        videosMap: {
          ...state.videosMap,
          [action.payload.video.id]: action.payload.video,
        },
      };
    case SUBMISSION_ENHANCED_INFO:
      let theUpdatedVideo = action.payload.video;
      if (theUpdatedVideo.highlights && theUpdatedVideo.highlights.highlights) {
        theUpdatedVideo.highlights.highlights.forEach((highlight) => computeAnalysisId(theUpdatedVideo, highlight));
      }
      return {
        ...state,
        videosMap: {
          ...state.videosMap,
          [action.payload.video.id]: action.payload.video,
        },
      };
    // todo ffs cancel old axios promises ? that axios API is deprecated
    // todo ffs reset slected highlight here or at higher level
    case VIDEO_SELECTED:
      return {
        ...state,
        currentVideoId: action.payload.id,
        currentTime: TIME_INITIAL,
        videoDuration: DURATION_UNKNOWN,
        isPaused: true,
      };
    case VIDEO_TIME_REACHED:
      return { ...state, currentTime: action.payload.time };
    case VIDEO_DURATION_COMPUTED:
      return { ...state, videoDuration: action.payload.duration };
    case VIDEO_PAUSED:
      return { ...state, isPaused: action.payload.paused };
    case VIDEO_ANNOTATION_DRAFT: {
      if (state.currentAnnotatingStatus) {
        return {
          ...state,
          currentAnnotatingStatus: {
            ...state.currentAnnotatingStatus,
            draftAnnotation: {
              ...state.currentAnnotatingStatus.draftAnnotation,
              x: action.payload.x,
              y: action.payload.y,
            },
          },
        };
      } else {
        return state;
      }
    }
    case VIDEO_ANNOTATION_EDITING_NONE: {
      return {
        ...state,
        currentAnnotatingStatus: null,
      };
    }
    case VIDEO_ANNOTATION_EDITING: {
      let acomment: Comment = null;
      const avideo = state.videosMap[action.payload.videoId];
      // console.log('redux found video ', avideo);
      if (avideo && avideo.comments) {
        acomment = avideo.comments.comments.find((comment) => comment.id === action.payload.commentId);
      }
      if (acomment && avideo) {
        return {
          ...state,
          currentAnnotatingStatus: {
            video: avideo,
            comment: acomment,
            draftAnnotation: acomment.annotation ? { ...acomment.annotation } : null,
          },
        };
      } else {
        return {
          ...state,
          currentAnnotatingStatus: null,
        };
      }
    }
    case VIDEO_TAG_CREATION_DRAFT: {
      if (state.currentTagCreationStatus) {
        return {
          ...state,
          currentTagCreationStatus: {
            ...state.currentTagCreationStatus,
            draftTagCoords: {
              ...state.currentTagCreationStatus.draftTagCoords,
              x1: action.payload.x1,
              y1: action.payload.y1,
              x2: action.payload.x2,
              y2: action.payload.y2,
            },
          },
        };
      } else {
        return state;
      }
    }
    case VIDEO_TAG_DRAFT: {
      if (state.currentTaggingStatus) {
        return {
          ...state,
          currentTaggingStatus: {
            ...state.currentTaggingStatus,
            draftTagCoords: {
              ...state.currentTaggingStatus.draftTagCoords,
              x1: action.payload.x1,
              y1: action.payload.y1,
              x2: action.payload.x2,
              y2: action.payload.y2,
            },
          },
        };
      } else {
        return state;
      }
    }
    case VIDEO_TAG_EDITING_NONE: {
      return {
        ...state,
        currentTaggingStatus: null,
      };
    }
    case VIDEO_TAG_CREATION_NONE: {
      return {
        ...state,
        currentTagCreationStatus: null,
      };
    }
    case VIDEO_TAG_CREATION: {
      const avideo = state.videosMap[action.payload.videoId] as Video;
      if (avideo) {
        return {
          ...state,
          currentTagCreationStatus: {
            video: avideo,
            status: 'draft',
            name: action.payload.tagId,
            draftTagCoords: DRAFT_TAG_COORDS_NONE,
          },
        };
      } else {
        return {
          ...state,
          currentTagCreationStatus: null,
        };
      }
    }
    case VIDEO_TAG_EDITING: {
      let atag: FaceAnalysisItem = null;
      const avideo = state.videosMap[action.payload.videoId] as Video;
      if (avideo) {
        atag = avideo.metaFaceAnalysesMap[action.payload.tagId];
      }
      if (atag && avideo) {
        return {
          ...state,
          currentTaggingStatus: {
            video: avideo,
            tag: atag,
            draftTagCoords: atag.tag ? { ...atag.tag } : null,
          },
        };
      } else {
        return {
          ...state,
          currentTaggingStatus: null,
        };
      }
    }

    case VIDEO_TAG_DELETED: {
      let theUpdatedVideo: Video = state.videosMap[action.payload.videoId];
      let theUpdatedFaceAnalysesMap = { ...theUpdatedVideo.metaFaceAnalysesMap };
      delete theUpdatedFaceAnalysesMap[action.payload.tagId];
      theUpdatedVideo = {
        ...theUpdatedVideo,
        metaFaceAnalysesMap: theUpdatedFaceAnalysesMap,
      };
      return {
        ...state,
        videosMap: {
          ...state.videosMap,
          [action.payload.videoId]: theUpdatedVideo,
        },
      };
    }
    case VIDEO_TAG_ADDED: {
      let theUpdatedVideo: Video = state.videosMap[action.payload.videoId];
      theUpdatedVideo = {
        ...theUpdatedVideo,
        metaFaceAnalysesMap: { ...theUpdatedVideo.metaFaceAnalysesMap, [action.payload.tagId]: action.payload.tag },
        // todo tag2FaceAnalysisIdx: Tag2FaceAnalysisIdx;
        // tag2FriendlyName: Tag2FriendlyName;
      };
      return {
        ...state,
        videosMap: {
          ...state.videosMap,
          [action.payload.videoId]: theUpdatedVideo,
        },
      };
    }
    case VIDEO_TAG_UPDATED: {
      let theUpdatedVideo: Video = state.videosMap[action.payload.videoId];
      theUpdatedVideo = {
        ...theUpdatedVideo,
        metaFaceAnalysesMap: { ...theUpdatedVideo.metaFaceAnalysesMap, [action.payload.tagId]: { ...action.payload.tag } },
        // todo tag2FaceAnalysisIdx: Tag2FaceAnalysisIdx;
        // tag2FriendlyName: Tag2FriendlyName;
      };
      return {
        ...state,
        videosMap: {
          ...state.videosMap,
          [action.payload.videoId]: theUpdatedVideo,
        },
      };
    }
    default:
      return state;
  }
}

const computeAnalysisId = (theUpdatedVideo: Video, hlToFixIdx: Highlight) => {
  if (hlToFixIdx && hlToFixIdx.selectedStreamsFace) {
    for (let i = 0; i < hlToFixIdx.selectedStreamsFace.length; i++) {
      if (!hlToFixIdx.selectedStreamsFace[i].analysisIdx) {
        hlToFixIdx.selectedStreamsFace[i].analysisIdx = theUpdatedVideo.tag2FaceAnalysisIdx[hlToFixIdx.selectedStreamsFace[i].analysisTag];
        hlToFixIdx.selectedStreamsFace[i].tagFriendlyName = theUpdatedVideo.tag2FriendlyName[hlToFixIdx.selectedStreamsFace[i].analysisTag];
      }
    }
  }
};
