import { Button, IconButton, Typography } from '@material-ui/core';
import Popper from '@material-ui/core/Popper/Popper';
import React from 'react';
import sprites from '../../../../icons/fig-icons.svg';
import { getEmailAddress, ShareBase, Submission } from '../../model/ApiTypes';
import { SubmissionSharesMap } from '../../model/Video';
import DeleteConfirmationDialog from '../shared/DeleteConfirmationDialog';
import { EllipseAvatarIcon } from '../shared/EllipseAvatarIcon';
import ShareVideo from './ShareVideo';
interface IProps {
  submission: Submission;
  anchorRef: any;
  sharesMap: SubmissionSharesMap;
  token: string;
  removeShare: (id) => void;
  addShare: (addresses: string[]) => void;
  setGlobalAlert: any;
}
interface IState {
  shareSubmissionDialogOpened: boolean;
  width: number;
  height: number;

  deleteShareDialogOpened: boolean;
  idShareToDelete: string;
}

export default class SharedWith extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      shareSubmissionDialogOpened: false,
      width: this.props.anchorRef ? this.props.anchorRef.getBoundingClientRect().width : 350,
      height: this.props.anchorRef ? this.props.anchorRef.getBoundingClientRect().height : 350,
      deleteShareDialogOpened: false,
      idShareToDelete: null,
    };
  }

  componentDidMount() {
    // window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    // window.removeEventListener('resize', this.handleResize);
  }

  // handleResize = () => {
  //   this.setState({
  //     width: this.props.anchorRef ? 0.75 * this.props.anchorRef.getBoundingClientRect().width : 400,
  //     height: this.props.anchorRef ? 0.75 * this.props.anchorRef.getBoundingClientRect().height : 350,
  //   });
  // };

  render() {
    return this.props.anchorRef ? (
      <>
        <Popper
          className="z-index-lvl1"
          open={true}
          anchorEl={this.props.anchorRef}
          placement="bottom-end"
          modifiers={{
            flip: {
              enabled: false,
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: this.props.anchorRef.parent, //'scrollParent',
              padding: 0,
            },
          }}
        >
          <div
            className="fig-share-with-main-container"
            style={{
              //width: 0.75 * this.props.anchorRef.getBoundingClientRect().width,
              width: this.state.width,
              maxHeight: window.innerHeight - 120,
              overflowY: 'auto',
              // zIndex: 300,
            }}
          >
            {Object.keys(this.props.sharesMap).map((shareId, indx) => {
              const share = this.props.sharesMap[shareId] as ShareBase;
              const user = share.user;
              return (
                <div className="row no-gutters fig-vertical-middle" key={shareId}>
                  <div className="col-8 left-side">
                    <EllipseAvatarIcon viewBox="0 0 36 35" />
                    <div>
                      <Typography color="textPrimary" variant="body1">
                        {user ? user.username : ''}
                      </Typography>
                      <Typography color="textPrimary" variant="body2" className="username-adress">
                        {user && user.email ? user.email : share.email}
                      </Typography>
                    </div>
                  </div>
                  <div className="col-4 right-side">
                    <Button
                      className="fig-button-contained"
                      disabled
                      onClick={null}
                      id={`${user ? user.id : share.email}`}
                      style={{
                        visibility: user && user.role ? 'visible' : 'hidden',
                      }}
                    >
                      {user && user.role ? user.role.name : null}
                    </Button>
                    <IconButton className="margin-left--10" size="small" /*edge="end"*/ onClick={this.onRemoveShare} id={shareId}>
                      <svg className="fig-close-icon">
                        <use href={sprites + '#close-icon'} />
                      </svg>
                    </IconButton>
                  </div>
                </div>
              );
            })}
            <div className="row no-gutters" key="shareVideo">
              <div className="col-12">
                <Button variant="text" onClick={this.openShareDialog} className="fig-share-video">
                  Share Video
                </Button>
              </div>
            </div>
          </div>
        </Popper>
        {this.renderShareDialog()}
        {this.renderDeleteSubmissionConfirmation()}
      </>
    ) : null;
  }
  openShareDialog = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ shareSubmissionDialogOpened: true });
  };

  renderShareDialog = () => {
    return this.state.shareSubmissionDialogOpened ? (
      <ShareVideo
        onClose={this.onCloseShareDialog}
        onShare={this.onShareSubmission}
        submission={this.props.submission.id}
        token={this.props.token} /*addRubric={this.addRubric}*/
        alreadySharedTo={Object.values(this.props.sharesMap).map((share) => getEmailAddress(share))}
        setGlobalAlert= {this.props.setGlobalAlert}
      />
    ) : null;
  };

  onCloseShareDialog = () => {
    this.setState({ shareSubmissionDialogOpened: false });
  };

  renderDeleteSubmissionConfirmation() {
    if (this.state.deleteShareDialogOpened && this.state.idShareToDelete) {
      const share = this.props.sharesMap[this.state.idShareToDelete] as ShareBase;
      const user = share.user;

      return (
        <DeleteConfirmationDialog
          handleDelete={this.onDeleteShareConfirmed}
          handleClose={this.closeDeleteShareConfirmationDialog}
          title={'Delete Share'}
          text={`Are you sure you want to delete the share to "${user && user.email ? user.email : share.email}"?`}
        />
      );
    } else {
      return null;
    }
  }

  onRemoveShare = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ deleteShareDialogOpened: true, idShareToDelete: event.currentTarget.id });
  };
  onDeleteShareConfirmed = () => {
    this.props.removeShare(this.state.idShareToDelete);
    this.closeDeleteShareConfirmationDialog();
  };
  closeDeleteShareConfirmationDialog = () => {
    this.setState({ deleteShareDialogOpened: false, idShareToDelete: null });
  };

  onShareSubmission = (addresses: string[]) => {
    this.props.addShare(addresses);
    this.onCloseShareDialog();
  };
}
