import React from 'react';
import sprites from '../../../../icons/fig-icons.svg';
import BorderedSelector, { IBorderedSelectorProps } from './BorderedSelector';

export default class BorderedSelectorTicks extends React.PureComponent<IBorderedSelectorProps> {
  static svgChecked = (
    <svg className="fig-check-icon">
      <use href={sprites + '#check-icon'} />
    </svg>
  );
  static svgNotChecked = null as JSX.Element;
  render() {
    return (
      <BorderedSelector {...this.props} svgChecked={BorderedSelectorTicks.svgChecked} svgNotChecked={BorderedSelectorTicks.svgNotChecked} />
    );
  }
}
