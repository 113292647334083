import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import React from 'react';
import BoldCloseButton from './BoldCloseButton';

interface IProps {
  handleDelete: () => void;
  handleClose: () => void;
  title: string;
  text: string;
}

export default class DeleteConfirmationDialog extends React.PureComponent<IProps> {
  render() {
    return (
      <Dialog className="modal-confirmation-dialog"
        open
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
        scroll="paper"
      >
        <BoldCloseButton width={14} height={14} top={15} right={15} handleClose={this.props.handleClose}></BoldCloseButton>

        <div className="row fig-horizontal-center">
          <DialogActions>
            <ErrorIcon fontSize="large" className="attention-icon" />
          </DialogActions>
        </div>
        <DialogTitle className="modal-confirmation-dialog__title" id="alert-dialog-title">{this.props.title}</DialogTitle>
        <DialogContent className="modal-confirmation-dialog__content">
          <DialogContentText id="alert-dialog-description">{this.props.text}</DialogContentText>
        </DialogContent>
        <DialogActions className="modal-confirmation-dialog__buttons">
          <Button onClick={this.props.handleClose} color="primary" className="fig-button in-modal-dialog">
            Cancel
          </Button>
          <Button onClick={this.props.handleDelete} color="primary" className="fig-button in-modal-dialog delete">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
