import { CHANGE_SETTINGS } from "../actions/types";

const INITIAL_STATE = {
  users: [],
  coaches: [],
  admins: [],
};

export type FigaroUsersProfileState = Readonly<typeof INITIAL_STATE>;


// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state: FigaroUsersProfileState = INITIAL_STATE,
  action: any
): FigaroUsersProfileState => {
  switch (action.type) {
    case CHANGE_SETTINGS: {
      return state;
    }
    default:
      return state;
  }
};
