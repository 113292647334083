import React from 'react';
import { connect } from 'react-redux';
import RubricsPanel from '../../demo/components/rubric/RubricsPanel';
import { IRootState } from '../../shared/reducers';
import Header from './header/Header';
interface IProps {
  history: any;
}

class Rubrics extends React.Component<IProps, any> {
  render() {
    let className = 'figaro-content';
    return (
      <div className="figaro-container">
        <Header selectedMenu="rubrics" history={this.props.history} />
        <div className={className}>
          <RubricsPanel history={this.props.history} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => {
  return {};
};

export default connect(mapStateToProps, {})(Rubrics);
