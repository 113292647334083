import { AuthActionTypes, AUTH_CLEAR, AUTH_USER } from '../actions/figaroApiActions/types';
import {
  ReportActionTypes,
  REPORT_CLIENT_NAME,
  REPORT_COACH_EMAIL,
  REPORT_COACH_NAME,
  REPORT_COACH_ROLE,
  REPORT_COMPANY_ADDRESS,
  REPORT_COMPANY_NAME,
  REPORT_COMPANY_URL,
  REPORT_COVER_LETTER_ENABLED,
  REPORT_COVER_LETTER_INTRO,
  REPORT_COVER_LETTER_SALUTATION,
  REPORT_EMOTIONS_INITIAL,
  REPORT_FACES_INITIAL,
  REPORT_FACE_EMOTIONS_CHART,
  REPORT_FACE_EMOTIONS_TEXT,
  REPORT_HL_ADDED,
  REPORT_HL_CHART,
  REPORT_HL_COMMENT_ENABLED,
  REPORT_HL_ENABLED,
  REPORT_HL_PICTURE,
  REPORT_HL_TOGGLED,
  REPORT_SCREENSHOT_ADDED,
  REPORT_SCREENSHOT_COMMENT,
  REPORT_SCREENSHOT_DELETED,
  REPORT_SCREENSHOT_TOGGLE,
  REPORT_SCREENSHOT_TRANSCRIPT,
  REPORT_SPEAKER_ENABLED,
  REPORT_SPEAKER_FACE_ENABLED,
  REPORT_SPEAKER_VERBAL_ENABLED,
  REPORT_SUMMARY_ENABLED,
  REPORT_SUMMARY_TEXT,
  REPORT_VERBAL_EMOTIONS_CHART,
  REPORT_VERBAL_EMOTIONS_TEXT,
  REPORT_VERBAL_ENABLED,
} from '../actions/reportActions/types';
import { VideoActionTypes, VIDEO_HIGHLIGHT_UPDATED, VIDEO_SELECTED } from '../actions/videoActions/types';
import { LoginResponse } from '../model/ApiTypes';
import { StreamRef } from '../model/Video';
import { userValue } from './authReducer';

const initialUser = userValue();

const initialState = {
  // coachName: initialUser ? initialUser.username : 'Ms. Coach',
  // coachRole: initialUser ? initialUser.role.description : 'Coaching Consultant',
  // coachEmail: initialUser ? initialUser.email : 'ms.coach@coachingcompany.com',
  coachName: 'Ms. Coach',
  coachRole: 'Coaching Consultant',
  coachEmail: 'ms.coach@mycompany.com',

  companyName: 'My Company Inc.',
  companyAddress: '123 Yongue Street\nToronto, ONT M1M 1M1',
  companyURL: 'https://www.mycompany.com',

  coverLetterEnabled: true,
  coverSalutation: 'Dear Mr. Client',
  coverIntro:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed in mauris pharetra, aliquam mauris ac, rhoncus justo. Vivamus tristique pretium facilisis. Nullam egestas libero ut vulputate laoreet. Curabitur justo sapien, malesuada ut consequat vel,bibendum et metus. Maecenas id sapien quis felis congue interdum. In dapibus odio leo, vel iaculis nisi facilisis nec. \n\nMauris eget molestie neque. Ut rhoncus lacus purus, vitae   blandit libero mattis non. Duis sodales maximus facilisis. Duis quis ullamcorper lorem. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.\n\n Phasellus in augue a leo fringilla sodales tempor sed massa. Sed consectetur convallis elit, ut gravida lorem gravida a. Mauris ac eros purus. Vivamus consequat sagittis posuere. Donec tincidunt lectus sem, vel vestibulum enim pulvinar in. Praesent finibus tincidunt enim, vel dictum tortor iaculis in. Vestibulum ac feugiat magna. Duis porta sit amet odio at auctor. Vivamus convallis turpis sed gravida imperdiet.\n\nSincerely,\n\n',

  summaryEnabled: false,
  summaryText: 'Sample summary text',

  faceEmotionsInfoMap: {} as FaceEmotionsMap, //REP1
  emotionsInfoMap: {} as V2EmotionsMap, //REP2
  verbalText: '', //REP1
  verbalDetailedText: '', //REP1
  verbalEnabled: false, //REP1
  verbalChart: null, //REP1, REP2
  clientName: 'Mr. Client',

  highlights: {} as HLMap, //REP1 only? TODO ASK
  screenshots: {} as ScreenshotMap, //REP2
};

export type ReportState = Readonly<typeof initialState>;
export type ScreenshotMap = { [id: string]: ScreenshotInfo };
export type ScreenshotInfo = {
  id: string;
  comment: string;
  enabled: boolean;
  //index: number;
  timestamp: number;
  chart: any;
  picture: any;
  faceSelections: StreamRef[];
  voiceSelections: StreamRef[];
  seriesColors: { [seriesName: string]: string };

  transcriptPart1: string;
  transcriptPart2: string;
};

export type HLMap = { [id: string]: HLInfo };
export type HLInfo = {
  id: string;
  comments: CommentInfo;
  enabled: boolean;
  index: number;
  chart: any;
  picture: any;
  faceSelections: StreamRef[];
  voiceSelections: StreamRef[];
  seriesColors: { [seriesName: string]: string };
};
export type CommentInfo = { [id: string]: boolean };

export type FaceEmotionsInfo = {
  id: string;
  enabled: boolean;
  chart: any;
  text: string;
  detailedText: string;
};

export type FaceEmotionsMap = { [faceTag: string]: FaceEmotionsInfo };

export type V2EmotionsInfo = {
  id: string;
  speakerEnabled: boolean;
  faceChart: any;
  verbalChart: any;
  text: string;
  verbalChartEnabled: boolean;
  faceChartEnabled: boolean;
};
export type V2EmotionsMap = { [faceTag: string]: V2EmotionsInfo };
// Reducers
// eslint-disable-next-line import/no-anonymous-default-export
export default (state: ReportState = initialState, action: ReportActionTypes | AuthActionTypes | VideoActionTypes): ReportState => {
  switch (action.type) {
    case AUTH_CLEAR: {
      return { ...initialState };
    }
    case AUTH_USER: {
      const loginResponse: LoginResponse = action.payload;
      return {
        ...state,
        // coachName: loginResponse.user.username,
        // coachRole: loginResponse.user.role && loginResponse.user.role.description ? loginResponse.user.role.description : 'Sample Role',
        // coachEmail: loginResponse.user.email ? loginResponse.user.email : 'Sample email',
      };
    }
    case VIDEO_SELECTED: {
      //do not reset categories ,  fake streams etc, since they  are not dependencies of video
      return {
        ...state,
        highlights: {} as HLMap,
        faceEmotionsInfoMap: {} as FaceEmotionsMap,
        verbalChart: null,
        verbalText: '',
        verbalDetailedText: '',
      };
    }

    case REPORT_COACH_EMAIL: {
      return { ...state, coachEmail: action.payload.coachEmail };
    }
    case REPORT_COACH_NAME: {
      return { ...state, coachName: action.payload.coachName };
    }
    case REPORT_COACH_ROLE: {
      return { ...state, coachRole: action.payload.coachRole };
    }

    case REPORT_COMPANY_ADDRESS: {
      return { ...state, companyAddress: action.payload.companyAddress };
    }
    case REPORT_COMPANY_NAME: {
      return { ...state, companyName: action.payload.companyName };
    }
    case REPORT_COMPANY_URL: {
      return { ...state, companyURL: action.payload.companyUrl };
    }

    case REPORT_COVER_LETTER_ENABLED: {
      return { ...state, coverLetterEnabled: action.payload.enabled };
    }
    case REPORT_COVER_LETTER_INTRO: {
      return { ...state, coverIntro: action.payload.intro };
    }
    case REPORT_COVER_LETTER_SALUTATION: {
      return { ...state, coverSalutation: action.payload.salutation };
    }
    case REPORT_CLIENT_NAME: {
      return { ...state, clientName: action.payload.clientName };
    }
    case REPORT_SUMMARY_ENABLED: {
      return { ...state, summaryEnabled: action.payload.enabled };
    }
    case REPORT_SUMMARY_TEXT: {
      return { ...state, summaryText: action.payload.text };
    }
    case REPORT_FACES_INITIAL: {
      let nfaceEmotionsInfoMap = { ...state.faceEmotionsInfoMap };
      Object.keys(action.payload.map).forEach((frienndlyName) => {
        if (nfaceEmotionsInfoMap[frienndlyName]) {
        } else {
          nfaceEmotionsInfoMap[frienndlyName] = action.payload.map[frienndlyName];
        }
      });
      return {
        ...state,
        faceEmotionsInfoMap: nfaceEmotionsInfoMap,
      };
    }
    case REPORT_EMOTIONS_INITIAL: {
      let nfaceEmotionsInfoMap = { ...state.emotionsInfoMap };
      Object.keys(action.payload.map).forEach((frienndlyName) => {
        if (nfaceEmotionsInfoMap[frienndlyName]) {
        } else {
          nfaceEmotionsInfoMap[frienndlyName] = action.payload.map[frienndlyName];
        }
      });
      return {
        ...state,
        emotionsInfoMap: nfaceEmotionsInfoMap,
      };
    }
    case REPORT_SPEAKER_ENABLED: {
      if (!state.faceEmotionsInfoMap[action.payload.faceTag] || !state.emotionsInfoMap[action.payload.faceTag]) {
        return state;
      }
      let oldFaceEmotions = state.faceEmotionsInfoMap[action.payload.faceTag];
      let oldEmotions = state.emotionsInfoMap[action.payload.faceTag];
      return {
        ...state,
        faceEmotionsInfoMap: {
          ...state.faceEmotionsInfoMap,
          [action.payload.faceTag]: { ...oldFaceEmotions, enabled: action.payload.enabled },
        },
        emotionsInfoMap: { ...state.emotionsInfoMap, [action.payload.faceTag]: { ...oldEmotions, speakerEnabled: action.payload.enabled } },
      };
    }
    case REPORT_SPEAKER_FACE_ENABLED: {
      if (!state.emotionsInfoMap[action.payload.faceTag]) {
        return state;
      }
      let oldEmotions = state.emotionsInfoMap[action.payload.faceTag];
      return {
        ...state,
        emotionsInfoMap: {
          ...state.emotionsInfoMap,
          [action.payload.faceTag]: { ...oldEmotions, faceChartEnabled: action.payload.enabled },
        },
      };
    }
    case REPORT_SPEAKER_VERBAL_ENABLED: {
      if (!state.emotionsInfoMap[action.payload.faceTag]) {
        return state;
      }
      let oldEmotions = state.emotionsInfoMap[action.payload.faceTag];
      return {
        ...state,
        emotionsInfoMap: {
          ...state.emotionsInfoMap,
          [action.payload.faceTag]: { ...oldEmotions, verbalChartEnabled: action.payload.enabled },
        },
      };
    }
    case REPORT_FACE_EMOTIONS_TEXT: {
      if (!state.faceEmotionsInfoMap[action.payload.faceTag] || !state.emotionsInfoMap[action.payload.faceTag]) {
        return state;
      }
      let oldFaceEmotions = state.faceEmotionsInfoMap[action.payload.faceTag];
      let oldEmotions = state.emotionsInfoMap[action.payload.faceTag];
      return {
        ...state,
        faceEmotionsInfoMap: {
          ...state.faceEmotionsInfoMap,
          [action.payload.faceTag]: action.payload.detail
            ? { ...oldFaceEmotions, detailedText: action.payload.text }
            : { ...oldFaceEmotions, text: action.payload.text },
        },
        emotionsInfoMap: {
          ...state.emotionsInfoMap,
          [action.payload.faceTag]: { ...oldEmotions, text: action.payload.text },
        },
      };
    }
    case REPORT_FACE_EMOTIONS_CHART: {
      if (!state.faceEmotionsInfoMap[action.payload.faceTag] || !state.emotionsInfoMap[action.payload.faceTag]) {
        return state;
      }
      let oldFaceEmotions = state.faceEmotionsInfoMap[action.payload.faceTag];
      let oldEmotions = state.emotionsInfoMap[action.payload.faceTag];
      return {
        ...state,
        faceEmotionsInfoMap: {
          ...state.faceEmotionsInfoMap,
          [action.payload.faceTag]: { ...oldFaceEmotions, chart: action.payload.chart },
        },
        emotionsInfoMap: { ...state.emotionsInfoMap, [action.payload.faceTag]: { ...oldEmotions, faceChart: action.payload.chart } },
      };
    }
    case REPORT_VERBAL_EMOTIONS_TEXT: {
      return action.payload.detail ? { ...state, verbalDetailedText: action.payload.text } : { ...state, verbalText: action.payload.text };
    }
    case REPORT_VERBAL_ENABLED: {
      return { ...state, verbalEnabled: action.payload.enabled };
    }
    case REPORT_VERBAL_EMOTIONS_CHART: {
      let oldEmotions = action.payload.faceTag ? state.emotionsInfoMap[action.payload.faceTag] : null;

      return {
        ...state,
        verbalChart: action.payload.chart,
        emotionsInfoMap: oldEmotions
          ? { ...state.emotionsInfoMap, [action.payload.faceTag]: { ...oldEmotions, verbalChart: action.payload.chart } }
          : state.emotionsInfoMap,
      };
    }

    // case OWN_FOLDERS: {
    //   const map = {};
    //   action.payload.folders.forEach((folder) => {
    //     map[folder.id] = folder;
    //   });
    //   return {
    //     ...state,
    //     foldersMap: map,
    //   };
    // }
    // case FOLDER_DELETED: {
    // let newState = { ...state.foldersMap };
    // delete newState[action.payload.folderId];
    // let folder2SubmissionsMap = { ...state.folder2SubmissionsMap };
    // let submission2FoldersMap = { ...state.submission2FoldersMap };
    // if (folder2SubmissionsMap[action.payload.folder.name]) {
    //   folder2SubmissionsMap[action.payload.folder.name].forEach((folderSubmission) => {
    //     submission2FoldersMap[folderSubmission.submission.id] = submission2FoldersMap[folderSubmission.submission.id].filter(
    //       (crtt) => crtt.name !== action.payload.folder.name
    //     );
    //     if (submission2FoldersMap[folderSubmission.submission.id].length === 0) {
    //       delete submission2FoldersMap[folderSubmission.submission.id];
    //     }
    //   });
    //   delete folder2SubmissionsMap[action.payload.folder.name];
    // }

    // return { ...state, foldersMap: newState, folder2SubmissionsMap, submission2FoldersMap };
    //   return { ...state };
    // }
    // case FOLDER_ADDED:
    // case FOLDER_UPDATED:
    //   //return { ...state, foldersMap: { ...state.foldersMap, [action.payload.folderId]: action.payload.folder } };
    //   return { ...state };

    case REPORT_SCREENSHOT_ADDED: {
      return {
        ...state,
        screenshots: {
          ...state.screenshots,
          [action.payload.screenshot.id]: action.payload.screenshot,
        },
      };
    }
    case REPORT_SCREENSHOT_DELETED: {
      let newState = { ...state.screenshots };
      delete newState[action.payload.id];
      return {
        ...state,
        screenshots: newState,
      };
    }
    case REPORT_SCREENSHOT_COMMENT: {
      if (!state.screenshots[action.payload.id]) {
        return state;
      }
      let old = state.screenshots[action.payload.id];
      return {
        ...state,
        screenshots: {
          ...state.screenshots,
          [action.payload.id]: {
            ...old,
            comment: action.payload.comment,
          },
        },
      };
    }
    case REPORT_SCREENSHOT_TRANSCRIPT: {
      if (!state.screenshots[action.payload.id]) {
        return state;
      }
      let old = state.screenshots[action.payload.id];
      return {
        ...state,
        screenshots: {
          ...state.screenshots,
          [action.payload.id]: {
            ...old,
            transcriptPart1: action.payload.part1,
            transcriptPart2: action.payload.part2,
          },
        },
      };
    }
    case REPORT_SCREENSHOT_TOGGLE: {
      if (!state.screenshots[action.payload.id]) {
        return state;
      }
      let old = state.screenshots[action.payload.id];
      return {
        ...state,
        screenshots: {
          ...state.screenshots,
          [action.payload.id]: {
            ...old,
            enabled: action.payload.enabled,
          },
        },
      };
    }

    case REPORT_HL_ADDED: {
      return {
        ...state,
        highlights: {
          ...state.highlights,
          [action.payload.id]: {
            id: action.payload.id,
            enabled: false,
            comments: {},
            index: Object.keys(state.highlights).length,
            chart: null,
            picture: null,
            seriesColors: {},
            faceSelections: [],
            voiceSelections: [],
          },
        },
      };
    }
    case REPORT_HL_COMMENT_ENABLED: {
      if (!state.highlights[action.payload.highlightId]) {
        return state;
      }
      let newHlComments = { ...state.highlights[action.payload.highlightId].comments };
      if (!action.payload.enabled) {
        delete newHlComments[action.payload.commentId];
      } else {
        newHlComments[action.payload.commentId] = true;
      }
      return {
        ...state,
        highlights: {
          ...state.highlights,
          [action.payload.highlightId]: { ...state.highlights[action.payload.highlightId], comments: newHlComments },
        },
      };
    }
    case REPORT_HL_ENABLED: {
      if (!state.highlights[action.payload.id]) {
        return state;
      }
      let oldHl = state.highlights[action.payload.id];
      return {
        ...state,
        highlights: {
          ...state.highlights,
          [action.payload.id]: {
            ...oldHl,
            enabled: action.payload.enabled,
            chart: action.payload.enabled ? oldHl.chart : null,
            picture: action.payload.enabled ? oldHl.picture : null,
            seriesColors: action.payload.enabled ? oldHl.seriesColors : {},
            faceSelections: action.payload.enabled ? oldHl.faceSelections : [],
            voiceSelections: action.payload.enabled ? oldHl.voiceSelections : [],
          },
        },
      };
    }
    case VIDEO_HIGHLIGHT_UPDATED: {
      if (!state.highlights[action.payload.highlightId]) {
        return state;
      }
      let oldHl = state.highlights[action.payload.highlightId];
      return {
        ...state,
        highlights: {
          ...state.highlights,
          [action.payload.highlightId]: {
            ...oldHl,
            chart: null,
            picture: null,
            enabled: false /*, comments: {} */,
            seriesColors: {},
            faceSelections: [],
            voiceSelections: [],
          },
        },
      };
    }
    case REPORT_HL_CHART: {
      if (!state.highlights[action.payload.id]) {
        return state;
      }
      let oldHl = state.highlights[action.payload.id];
      return {
        ...state,
        highlights: {
          ...state.highlights,
          [action.payload.id]: {
            ...oldHl,
            chart: action.payload.chart,
            seriesColors: action.payload.seriesColors,
            faceSelections: action.payload.faceSelections,
            voiceSelections: action.payload.voiceSelections,
          },
        },
      };
    }
    case REPORT_HL_PICTURE: {
      if (!state.highlights[action.payload.id]) {
        return state;
      }
      let oldHl = state.highlights[action.payload.id];
      return {
        ...state,
        highlights: { ...state.highlights, [action.payload.id]: { ...oldHl, picture: action.payload.picture } },
      };
    }
    case REPORT_HL_TOGGLED: {
      let wasPresent =
        state.highlights && state.highlights[action.payload.id] !== null && state.highlights[action.payload.id] !== undefined;
      let newState = { ...state.highlights };
      if (wasPresent) {
        let oldEntry = state.highlights[action.payload.id];
        delete newState[action.payload.id];
        Object.values(newState)
          .filter((entry) => entry.index > oldEntry.index)
          .forEach((entry) => (entry.index = entry.index - 1));
      } else {
        newState[action.payload.id] = {
          id: action.payload.id,
          enabled: false,
          comments: {},
          index: Object.keys(state.highlights).length,
          chart: null,
          picture: null,
          seriesColors: {},
          faceSelections: [],
          voiceSelections: [],
        };
      }

      return {
        ...state,
        highlights: newState,
      };
    }
    default:
      return state;
  }
};
