import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-css-effects/jelly.css';
import 'react-s-alert/dist/s-alert-default.css';
import './app.scss';
import Clients from './app/components/common/Clients';
import LoginContainer from './app/components/common/loginContainer/LoginContainer';
import OneRubric from './app/components/common/OneRubric';
import PrivateRoute from './app/components/common/privateRoute/PrivateRoute';
import Profile from './app/components/common/Profile';
import Rubrics from './app/components/common/Rubrics';
import Settings from './app/components/common/Settings';
import OneVideo from './app/components/common/video/OneVideo';
import Videos from './app/components/common/video/Videos';
import VideosRequest from './app/components/common/video/VideosRequest';
import VideosPanel from './app/demo/components/submission/VideosPanel';
import ForgotPasswordPanel from './app/demo/components/user/ForgotPasswordPanel';
import RegisterEmailConfirmationPanel from './app/demo/components/user/RegisterEmailConfirmationPanel';
import RegisterUserPanel from './app/demo/components/user/RegisterUserPanel';
import RequestRegistrationConfirmationPanel from './app/demo/components/user/RequestRegistrationConfirmationPanel';
import ResetPasswordPanel from './app/demo/components/user/ResetPasswordPanel';
import { API_ROLE_COACH, API_ROLE_USER } from './app/demo/model/ApiTypes';
import ModalAlert from './ModalAlert';

const cond = document && document.querySelector('base') ? document.querySelector('base') : null;

let abaseHref = cond && cond.getAttribute('href') ? cond.getAttribute('href') : '';

let baseHref = abaseHref ? abaseHref.replace(/\/$/, '') : '';

export interface IAppProps extends StateProps, DispatchProps {}
export const getUnauthedHomePath = () => '/login';
export const getAuthedHomePath = () => getVideosPath();
export const getVideosPath = (folderId?: number, pageNo?: number) =>
  !isNaN(folderId) && !isNaN(pageNo)
    ? `/folders/${folderId}/${pageNo}`
    : `/folders/${VideosPanel.FAKE_FOLDER_ID_ALL}/${VideosPanel.INITIAL_PAGE}`;

export const App = (props: IAppProps) => {
  useEffect(() => {}, []);
  const allAccessRole = ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_COACH', API_ROLE_USER, API_ROLE_COACH];

  return (
    <div>
      <HashRouter basename={baseHref} hashType={'slash'}>
        <div>
          <Switch>
            <Route path="/register" exact component={RegisterUserPanel} />
            <Route path="/emailconfirmation" exact component={RegisterEmailConfirmationPanel} />
            <Route path="/passwordreset" exact component={ResetPasswordPanel} />
            <Route path="/forgotpassword" exact component={ForgotPasswordPanel} />
            <Route path="/requestconfirmation" exact component={RequestRegistrationConfirmationPanel} />
            <PrivateRoute path="/video/:id" exact component={OneVideo} authed={true} userRole={props.userRole} accessRole={allAccessRole} />
            {/* <PrivateRoute path="/add-video" component={AddVideo} authed={true} userRole={props.userRole} accessRole={allAccessRole} /> */}
            <PrivateRoute path="/profile" component={Profile} authed={true} userRole={props.userRole} accessRole={allAccessRole} />
            <PrivateRoute
              path="/rubrics/:id"
              exact
              component={OneRubric}
              authed={true}
              userRole={props.userRole}
              accessRole={allAccessRole}
            />
            <PrivateRoute path="/rubrics" exact component={Rubrics} authed={true} userRole={props.userRole} accessRole={allAccessRole} />
            <PrivateRoute path="/settings" exact component={Settings} authed={true} userRole={props.userRole} accessRole={allAccessRole} />
            <PrivateRoute path="/clients" exact component={Clients} authed={true} userRole={props.userRole} accessRole={allAccessRole} />

            <PrivateRoute
              path="/requestvideo"
              exact
              component={VideosRequest}
              authed={true}
              userRole={props.userRole}
              accessRole={allAccessRole}
            />
            <PrivateRoute
              path="/folders/:folder/:page"
              exact
              component={Videos}
              authed={true}
              userRole={props.userRole}
              accessRole={allAccessRole}
            />
            <PrivateRoute exact path="/" component={Videos} authed={true} userRole={props.userRole} accessRole={allAccessRole} />
            <Route exact path={getUnauthedHomePath()} component={LoginContainer} />
            {/* <Route
            path="/"
            render={(props) => {
              console.log('DEFAULT ROUTE');
              return <div>{`DEFAULT ROUTE ${Date.now().toLocaleString()}`}</div>;
            }}
          /> */}
            <PrivateRoute path="/" component={Videos} authed={true} userRole={props.userRole} accessRole={allAccessRole} />
          </Switch>
        </div>
      </HashRouter>
      <Alert stack={{ limit: 10 }} html={true} />
      <ModalAlert />
    </div>
  );
};

//

const mapStateToProps = ({ /*locale,*/ authentication }: any) => ({
  // currentLocale: locale.currentLocale,

  // isAuthenticated: authentication.isAuthenticated,
  // isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ADMIN]),
  // isInProduction: applicationProfile.inProduction,
  userRole: authentication.user ? authentication.user.role.name : null,
});

const mapDispatchToProps = {
  //setLocale
  // clearGlobalAlert,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(App));
