import { IconButton, TextField } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import React, { FormEvent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { createSelector } from 'reselect';
import { IRootState } from '../../../shared/reducers';
import RubricsTree from './RubricsTree';

interface IProps extends PropsFromRedux {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IState {
  term: string;
  searchKeywords: string[];
}

class RubricTemplates extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { term: '', searchKeywords: [] };
  }
  componentDidMount = () => {};
  componentWillUnmount = () => {
    console.log('RubricTemplates will unmount ', this.props);
  };

  render() {
    console.log('RubricTemplates render ', this.props);
    return (
      <div className="fig-rubrics-left-side">
        <div className="fig-rubrics-left-side-title">
          <span>Your Rubrics</span>
        </div>
        <div className="fig-rubrics-left-side-search">{this.renderSearch()}</div>
        <div className="fig-rubrics-left-side-list">
          <RubricsTree searchKeywords={this.state.searchKeywords} rubrics={this.props.allRubrics}></RubricsTree>
        </div>
      </div>
    );
  }

  onClearSearch = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.resetState();
  };

  private resetState(): void {
    this.setState({
      term: '',
      searchKeywords: [],
    });
  }

  handleInputChange = (event) => {
    this.setState({ term: event.target.value });
  };

  onEnterKey = (e: any) => {
    if (e.which === 13) {
      this.onFormSubmit(e);
    }
  };

  separators = new RegExp([',', ' '].join('|'));
  onFormSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.setState({
      searchKeywords: this.state.term.split(this.separators),
    });
  };

  renderSearch() {
    return (
      <form onSubmit={this.onFormSubmit}>
        <div className="fig-space-between fig-vertical-middle">
          <TextField value={this.state.term} onChange={this.handleInputChange} label="Search rubrics" onKeyPress={this.onEnterKey} />
          <IconButton aria-label="clear" size="small" onClick={this.onClearSearch}>
            <ClearIcon fontSize="inherit" />
          </IconButton>
        </div>
      </form>
    );
  }
}

const GLOBAL_RUBRICS_NONE = {};
const selectorOwnRubrics = (state: IRootState) => state.rubrics.rubricsMap;
const selectorGlobalRubrics = (state: IRootState) =>
  state.settings.globalRubricsMap ? state.settings.globalRubricsMap : GLOBAL_RUBRICS_NONE;

const selectorAllRubrics = createSelector([selectorGlobalRubrics, selectorOwnRubrics], (globals, privates) =>
  Object.values(privates).concat(Object.values(globals))
);

const mapStateToProps = (state: IRootState) => {
  console.log('RubricTemplates mapStateToProps ', state);
  return {
    authentication: state.authentication,
    // rubrics: selectorOwnRubrics(state),
    // globalRubrics: selectorGlobalRubrics(state),
    allRubrics: selectorAllRubrics(state),
  };
};

const mapDispatchToProps = {};
const connector = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true });
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(RubricTemplates);
