import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React from 'react';
import '../tabs/Tab.css';
import { TabPanel } from '../tabs/TabPanel';
import ChartsForSpeaker, { SpeakerEmotionsRates } from './ChartForSpeaker';

interface IProps {
  containerHeight: any;
  speakersEmotionsRates: SpeakerEmotionsRates[];
  setSelectedChartsTab: any;
  selectedTab: number;
  addImage: any;
}
class ChartsTabs extends React.PureComponent<IProps> {
  render() {
    console.log(' render ChartsTabs ', this.props);
    return (
      <div className="fig-direction-column w-100" style={{ height: this.props.containerHeight }}>
        <div
          className="row"
          style={{
            height: 35,
          }}
        >
          <div className="col no-padding ">
            <Tabs
              className="fig-video-details-left-side__chartTabs"
              style={{
                height: 35,
              }}
              value={this.props.selectedTab}
              textColor="primary"
              indicatorColor="primary"
              onChange={(event, newValue) => {
                this.props.setSelectedChartsTab(newValue);
              }}
              variant={'scrollable'}
              scrollButtons={'on'}
            >
              {this.props.speakersEmotionsRates.map((speakerRates, ispeaker) => (
                <Tab
                  key={speakerRates.analysisTag + ispeaker}
                  label={`${speakerRates.speakerHidden ? '(' : ''} ${speakerRates.analysisFriendlyTag} ${
                    speakerRates.speakerHidden ? ')' : ''
                  }`}
                  style={speakerRates.speakerHidden ? { color: 'gray', textDecoration: 'line-through' } : {}}
                />
              ))}
            </Tabs>
          </div>
        </div>
        <div className="row " style={{ height: this.props.containerHeight - 35 }}>
          <div className="col no-padding " style={{ height: this.props.containerHeight - 35 }}>
            {this.props.speakersEmotionsRates.map((speakerRates, index) => (
              <TabPanel className="" key={speakerRates.analysisTag} index={index} value={this.props.selectedTab}>
                <ChartsForSpeaker
                  {...speakerRates}
                  speakersCount={this.props.speakersEmotionsRates.length}
                  key={speakerRates.analysisTag + index}
                  // added idx as a workaround for cases when bakend returns mutiple fae analyses having the same tag video=20
                  height={this.props.containerHeight - 35}
                  addImage={this.props.addImage}
                />
              </TabPanel>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
export default ChartsTabs;
