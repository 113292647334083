import React from 'react';
import { Coords } from '../../model/Video';
interface IProps {
  color: string;
  zIndex: number;
  tagCoords: Coords;
  solid: boolean;
  name: string;
  isResizingBottom: boolean;
  isResizingTop: boolean;
  isResizingLeft: boolean;
  isResizingRight: boolean;
}

class FacetagRenderer extends React.PureComponent<IProps> {
  render() {
    if (
      !this.props.tagCoords ||
      isNaN(this.props.tagCoords.x1) ||
      isNaN(this.props.tagCoords.x2) ||
      isNaN(this.props.tagCoords.y1) ||
      isNaN(this.props.tagCoords.y2)
    ) {
      return null;
    }
    // console.log('render AFace, ', this.format(this.props.tagCoords));
    return (
      <div
        id={`FACE4_${this.props.name}`}
        style={{
          color: this.props.color,
          borderWidth: this.props.solid ? 2 : 3,
          borderTopStyle: this.props.isResizingTop ? 'solid' : this.props.solid ? 'solid' : 'dashed',
          borderLeftStyle: this.props.isResizingLeft ? 'solid' : this.props.solid ? 'solid' : 'dashed',
          borderRightStyle: this.props.isResizingRight ? 'solid' : this.props.solid ? 'solid' : 'dashed',
          borderBottomStyle: this.props.isResizingBottom ? 'solid' : this.props.solid ? 'solid' : 'dashed',
          margin: 0,
          padding: 0,
          position: 'absolute',
          top: `${Math.min(this.props.tagCoords.y1, this.props.tagCoords.y2)}%`,
          left: `${Math.min(this.props.tagCoords.x1, this.props.tagCoords.x2)}%`,
          width: `${Math.abs(this.props.tagCoords.x1 - this.props.tagCoords.x2)}%`,
          height: `${Math.abs(this.props.tagCoords.y1 - this.props.tagCoords.y2)}%`,
          pointerEvents: 'none',
          zIndex: this.props.zIndex,
          display: 'flex',
          flexDirection: 'column-reverse',
        }}
      >
        <span>{this.props.name}</span>
      </div>
    );
  }

  format(coords: Coords) {
    if (coords) {
      return `(${this.formatC(coords.x1)}, 
    ${this.formatC(coords.y1)}), 
    (${this.formatC(coords.x2)},
    ${this.formatC(coords.y2)})`;
    }
    return '';
  }

  formatC(coord: number) {
    return coord ? coord.toFixed(2) : '';
  }
}

export default FacetagRenderer;
