import { FaceAnalysisItem } from '../../model/ApiTypes';
import { Annotation, Comment, Highlight } from '../../model/Video';

export const VIDEO_SELECTED = 'VIDEO_SELECTED';
export const VIDEO_TIME_REACHED = 'VIDEO_TIME_REACHED';
export const VIDEO_DURATION_COMPUTED = 'VIDEO_DURATION_COMPUTED';
export const VIDEO_PAUSED = 'VIDEO_PAUSED';
export const VIDEO_THRESHOLD_UPDATED = 'VIDEO_THRESHOLD_UPDATED';

export interface VideoSelectedAction {
  type: typeof VIDEO_SELECTED;
  payload: { id: number };
}
interface VideoTimeReachedAction {
  type: typeof VIDEO_TIME_REACHED;
  payload: { time: number };
}
interface VideoDurationComputedAction {
  type: typeof VIDEO_DURATION_COMPUTED;
  payload: { duration: number };
}
interface VideoPausedAction {
  type: typeof VIDEO_PAUSED;
  payload: { paused: boolean };
}
interface VideoThresholdUpdatedAction {
  type: typeof VIDEO_THRESHOLD_UPDATED;
  payload: {
    videoId: string;
    threshold: number;
  };
}

export type CurrentVideoActionTypes =
  | VideoSelectedAction
  | VideoTimeReachedAction
  | VideoDurationComputedAction
  | VideoPausedAction
  | VideoThresholdUpdatedAction;

export const VIDEO_COMMENT_ADDED = 'VIDEO_COMMENT_ADDED';
export const VIDEO_COMMENT_DELETED = 'VIDEO_COMMENT_DELETED';
export const VIDEO_COMMENT_PRIVACY_CHANGED = 'VIDEO_COMMENT_PRIVACY_CHANGED';
export const VIDEO_COMMENT_UPDATED = 'VIDEO_COMMENT_UPDATED';
interface AddCommentAction {
  type: typeof VIDEO_COMMENT_ADDED;
  payload: { videoId: string; comment: Comment };
}
interface DeleteCommentAction {
  type: typeof VIDEO_COMMENT_DELETED;
  payload: { videoId: string; commentId: string };
}
interface UpdateCommentPrivacyAction {
  type: typeof VIDEO_COMMENT_PRIVACY_CHANGED;
  payload: { videoId: string; commentId: string; privacy: string };
}
interface UpdateCommentAction {
  type: typeof VIDEO_COMMENT_UPDATED;
  payload: { videoId: string; comment: Comment };
}

export type VideoCommentActionTypes = AddCommentAction | DeleteCommentAction | UpdateCommentPrivacyAction | UpdateCommentAction;

export const VIDEO_ANNOTATION_ADDED = 'VIDEO_ANNOTATION_ADDED';
export const VIDEO_ANNOTATION_DELETED = 'VIDEO_ANNOTATION_DELETED';
export const VIDEO_ANNOTATION_UPDATED = 'VIDEO_ANNOTATION_UPDATED';
export const VIDEO_ANNOTATION_EDITING = 'VIDEO_ANNOTATION_EDITING';
export const VIDEO_ANNOTATION_EDITING_NONE = 'VIDEO_ANNOTATION_EDITING_NONE';
export const VIDEO_ANNOTATION_DRAFT = 'VIDEO_ANNOTATION_DRAFT';
interface AddAnnotationAction {
  type: typeof VIDEO_ANNOTATION_ADDED;
  payload: { videoId: string; commentId: string; annotation: Annotation };
}
interface DeleteAnnotationAction {
  type: typeof VIDEO_ANNOTATION_DELETED;
  payload: { videoId: string; commentId: string };
}
interface UpdateAnnotationAction {
  type: typeof VIDEO_ANNOTATION_UPDATED;
  payload: { videoId: string; commentId: string; annotation: Annotation };
}
interface EditingAnnotationAction {
  type: typeof VIDEO_ANNOTATION_EDITING;
  payload: { videoId: string; commentId: string };
}
interface NoneEditingAnnotationAction {
  type: typeof VIDEO_ANNOTATION_EDITING_NONE;
  payload: {};
}
interface DraftAnnotationAction {
  type: typeof VIDEO_ANNOTATION_DRAFT;
  payload: { x: number; y: number };
}
export type VideoAnnotationActionTypes =
  | AddAnnotationAction
  | DeleteAnnotationAction
  | UpdateAnnotationAction
  | EditingAnnotationAction
  | NoneEditingAnnotationAction
  | DraftAnnotationAction;

export const VIDEO_TAG_ADDED = 'VIDEO_TAG_ADDED';
export const VIDEO_TAG_DELETED = 'VIDEO_TAG_DELETED';
export const VIDEO_TAG_UPDATED = 'VIDEO_TAG_UPDATED';
export const VIDEO_TAG_EDITING = 'VIDEO_TAG_EDITING';
export const VIDEO_TAG_EDITING_NONE = 'VIDEO_TAG_EDITING_NONE';

export const VIDEO_TAG_CREATION = 'VIDEO_TAG_CREATION';
export const VIDEO_TAG_CREATION_NONE = 'VIDEO_TAG_CREATION_NONE';

export const VIDEO_TAG_DRAFT = 'VIDEO_TAG_DRAFT';
export const VIDEO_TAG_CREATION_DRAFT = 'VIDEO_TAG_CREATION_DRAFT';
interface AddTagAction {
  type: typeof VIDEO_TAG_ADDED;
  payload: { videoId: string; tagId: string; tag: FaceAnalysisItem };
}
interface DeleteTagAction {
  type: typeof VIDEO_TAG_DELETED;
  payload: { videoId: string; tagId: string };
}
interface UpdateTagAction {
  type: typeof VIDEO_TAG_UPDATED;
  payload: { videoId: string; tagId: string; tag: FaceAnalysisItem };
}
interface EditingTagAction {
  type: typeof VIDEO_TAG_EDITING;
  payload: { videoId: string; tagId: string };
}
interface NoneEditingTagAction {
  type: typeof VIDEO_TAG_EDITING_NONE;
  payload: {};
}

interface CreatingTagAction {
  type: typeof VIDEO_TAG_CREATION;
  payload: { videoId: string; tagId: string };
}
interface NoneCreatingTagAction {
  type: typeof VIDEO_TAG_CREATION_NONE;
  payload: {};
}

interface DraftTagAction {
  type: typeof VIDEO_TAG_DRAFT;
  payload: { x1: number; y1: number; x2: number; y2: number };
}

interface DraftCreationTagAction {
  type: typeof VIDEO_TAG_CREATION_DRAFT;
  payload: { x1: number; y1: number; x2: number; y2: number };
}

export type VideoTagActionTypes =
  | AddTagAction
  | DeleteTagAction
  | UpdateTagAction
  | EditingTagAction
  | NoneEditingTagAction
  | DraftTagAction
  | CreatingTagAction
  | NoneCreatingTagAction
  | DraftCreationTagAction;

export const VIDEO_HIGHLIGHT_ADDED = 'VIDEO_HIGHLIGHT_ADDED';
export const VIDEO_HIGHLIGHT_DELETED = 'VIDEO_HIGHLIGHT_DELETED';
export const VIDEO_HIGHLIGHT_UPDATED = 'VIDEO_HIGHLIGHT_UPDATED';
// export const VIDEO_HIGHLIGHT_SELECTED = 'VIDEO_HIGHLIGHT_SELECTED';
export const VIDEO_HIGHLIGHT_PRIVACY_CHANGED = 'VIDEO_HIGHLIGHT_PRIVACY_CHANGED';
interface AddHighlightAction {
  type: typeof VIDEO_HIGHLIGHT_ADDED;
  payload: { videoId: string; highlight: Highlight };
}
interface DeleteHighlightAction {
  type: typeof VIDEO_HIGHLIGHT_DELETED;
  payload: { videoId: string; highlightId: string };
}
interface UpdateHighlightAction {
  type: typeof VIDEO_HIGHLIGHT_UPDATED;
  payload: { videoId: string; highlightId: string; highlight: Highlight };
}
// TOD FFS unite with UpdateHighlightAction
interface UpdateHighlightPrivacyAction {
  type: typeof VIDEO_HIGHLIGHT_PRIVACY_CHANGED;
  payload: { videoId: string; highlightId: string; /* privacy: string;*/ highlight: Highlight };
}
export const VIDEO_HIGHLIGHT_SELECTED = 'HIGHLIGHT_SELECTED';
export interface HighlightSelectedAction {
  type: typeof VIDEO_HIGHLIGHT_SELECTED;
  payload: { highlight: Highlight; videoId: string };
}

export type VideoHighlightActionTypes =
  | AddHighlightAction
  | DeleteHighlightAction
  | UpdateHighlightAction
  | UpdateHighlightPrivacyAction
  | HighlightSelectedAction;

export type VideoActionTypes =
  | VideoHighlightActionTypes
  | VideoTagActionTypes
  | VideoCommentActionTypes
  | VideoAnnotationActionTypes
  | CurrentVideoActionTypes;
