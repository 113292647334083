/* eslint-disable no-console */
/* eslint-disable no-console */
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import { addVideoTag, updateTagName, updateTagStatus, updateVideoTag } from '../../actions/figaroApiActions';
import { noTagSelected, tagSelected, videoTagDeleted } from '../../actions/videoActions';
import { AnalysisItemStatus, FaceAnalysisItem } from '../../model/ApiTypes';
import { toStringId } from '../../model/Video';
import { generateFakeId } from '../../utils/utils';
import { IVideoPlayer } from '../video/VideoRenderer';
import AddTag, { IAddTagState } from './AddTag';
import TagsTable from './TagsTable';

interface IProps extends PropsFromRedux {
  theVideoPlayer: IVideoPlayer;
}

class Tags extends React.PureComponent<IProps, any> {
  //deprecated - tags are not updatable/deletable anymore
  /*
  handleTagPositioning = (tag: FaceAnalysisItem) => {
    this.props.theVideoPlayer.pause();
    // this.props.theVideoPlayer.seek(tag.time);
    if (this.props.currentTaggingStatus) {
      // we were already editing a tag
      if (this.props.currentTaggingStatus.video === this.props.selectedVideo) {
        if (this.props.currentTaggingStatus.tag === tag) {
          //  we were editing THIS one, update it
          if (this.props.currentTaggingStatus.draftTagCoords) {
            // commit the draft coords
            this.props.updateVideoTag(this.props.selectedVideo.id, tag.url, {
              ...tag,
              tag: this.props.currentTaggingStatus.draftTagCoords,
            } as FaceAnalysisItem);
          } else {
            console.log(' unexpected Tag set coord', tag, this.props.currentTaggingStatus);
          }
          this.props.noTagSelected();
        } else {
          // while editing another tag, we clicked SetCoords on this tag
          this.props.tagSelected(this.props.selectedVideo.id, tag.url);
        }
      } else {
        // another, previosly selected  video, has a tag in editing mode  - should not happen... drop that one and select this one
        console.log(' unexpected Tag set coord', this.props.selectedVideo.id, tag, this.props.currentTaggingStatus.video.id);
        this.props.tagSelected(this.props.selectedVideo.id, tag.url);
      }
    } else {
      // enter edit tag coords mode
      this.props.tagSelected(this.props.selectedVideo.id, tag.url);
    }
  };

  handleDeleteTag = (tagId: string) => {
    // console.log('ATags handle delete tag with  params', tagId);
    this.props.videoTagDeleted(this.props.selectedVideo.id, tagId);
  };
  */
  handleAddTag = (form: IAddTagState) => {
    // console.log('ATags handle add tag with form params', form);
    const newTag: FaceAnalysisItem = {
      url: generateFakeId(),
      name: form.name,
      status: AnalysisItemStatus.processing,
      message: null,
      tag: form.coords,
    };
    this.props.addVideoTag(this.props.selectedVideoIdString, newTag, this.props.token);
  };

  render() {
    let height = window.innerHeight - 200;
    if (height < 0) height = 30;

    if (!this.props.selectedVideoIdString) {
      return <div />;
    }
    return (
      <div className="col-12" style={{ height, overflowY: 'auto' }}>
        {this.renderNewTagSection()}
        {this.renderListOfTags()}
      </div>
    );
  }

  renderNewTagSection() {
    return (
      <>
        <div className="row fig-video-details-left-side__face-tagging margin-bottom--20">
          <div className="col-12">
            <div className="row">
              <div className="new-face-tag-title">New Face Tag</div>
            </div>
            <AddTag handleFormSubmit={this.handleAddTag} theVideoPlayer={this.props.theVideoPlayer}/>
          </div>
        </div>
      </>
    );
  }
  renderListOfTags() {
    return (
      <div className="row fig-rubrics fig-video-details-left-side__face-tagging">
        <div className="col-12">
          <div className="row">
            <div className="new-face-tag-title">Face Tags</div>
          </div>
          <div className="fig-rubrics-table-holder margin-top--20 no-padding">
            <TagsTable
              tags={this.props.tags}
              updateTagStatus={(id, tag) => {
                this.props.updateTagStatus(id, tag, this.props.token);
              }}
              selectedVideoId={this.props.selectedVideoIdString}
              updateTagName={(id, tag) => {
                this.props.updateTagName(id, tag, this.props.token);
              }}
            ></TagsTable>
          </div>
        </div>
      </div>
    );
  }
}

const ARRAY_FACES_NONE = [] as FaceAnalysisItem[];
const selectFaceAnalyses = (state: IRootState) => {
  if (
    state.video.currentVideoId &&
    state.video.videosMap[state.video.currentVideoId] &&
    state.video.videosMap[state.video.currentVideoId].metaFaceAnalysesMap
  ) {
    return Object.values(state.video.videosMap[state.video.currentVideoId].metaFaceAnalysesMap);
  } else return ARRAY_FACES_NONE;
};

const mapStateToProps = (state: IRootState) => {
  return {
    selectedVideoIdString: state.video.currentVideoId ? toStringId(state.video.currentVideoId) : null,
    //currentTaggingStatus: state.video.currentTaggingStatus, deprecated, tags are no longer updatable
    tags: selectFaceAnalyses(state),
    token: state.authentication.token,
  };
};
const mapDispatchToProps = {
  addVideoTag,
  videoTagDeleted,
  updateTagName,
  updateTagStatus,
  updateVideoTag,
  tagSelected,
  noTagSelected,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Tags);
