import { successNotification, warningNotification } from '../../../utils/utils';
import { FaceAnalysisItem } from '../../model/ApiTypes';
import { Highlight } from '../../model/Video';
import { logErrorResponse } from '../figaroApiActions';
import {
  CurrentVideoActionTypes,
  VideoAnnotationActionTypes,
  VideoHighlightActionTypes,
  VideoTagActionTypes,
  VIDEO_ANNOTATION_DRAFT,
  VIDEO_ANNOTATION_EDITING,
  VIDEO_ANNOTATION_EDITING_NONE,
  VIDEO_DURATION_COMPUTED,
  VIDEO_HIGHLIGHT_SELECTED,
  VIDEO_PAUSED,
  VIDEO_SELECTED,
  VIDEO_TAG_ADDED,
  VIDEO_TAG_CREATION,
  VIDEO_TAG_CREATION_DRAFT,
  VIDEO_TAG_CREATION_NONE,
  VIDEO_TAG_DELETED,
  VIDEO_TAG_DRAFT,
  VIDEO_TAG_EDITING,
  VIDEO_TAG_EDITING_NONE,
  VIDEO_TAG_UPDATED,
  VIDEO_TIME_REACHED,
} from './types';

export const timeReached = (time: number) => (dispatch: (action: CurrentVideoActionTypes) => void) => {
  dispatch({
    type: VIDEO_TIME_REACHED,
    payload: { time },
  });
};

export const durationComputed = (duration: number) => (dispatch: (action: CurrentVideoActionTypes) => void) => {
  dispatch({
    type: VIDEO_DURATION_COMPUTED,
    payload: { duration },
  });
};

export const videoSelected = (videoId: number) => (dispatch: (action: CurrentVideoActionTypes) => void) => {
  dispatch({
    type: VIDEO_SELECTED,
    payload: { id: videoId },
  });
};
export const noVideoSelected = () => (dispatch: (action: CurrentVideoActionTypes) => void) => {
  dispatch({
    type: VIDEO_SELECTED,
    payload: { id: null },
  });
};

export const videoPaused = (paused: boolean) => (dispatch: (action: CurrentVideoActionTypes) => void) => {
  dispatch({
    type: VIDEO_PAUSED,
    payload: { paused },
  });
};

export const noAnnotationSelected = () => (dispatch: (action: VideoAnnotationActionTypes) => void) => {
  dispatch({
    type: VIDEO_ANNOTATION_EDITING_NONE,
    payload: {},
  });
};

export const annotationSelected = (videoId: string, commentId: string) => (dispatch: (action: VideoAnnotationActionTypes) => void) => {
  dispatch({
    type: VIDEO_ANNOTATION_EDITING,
    payload: { videoId, commentId },
  });
};
export const annotationDrafted = (x: number, y: number) => (dispatch: (action: VideoAnnotationActionTypes) => void) => {
  dispatch({
    type: VIDEO_ANNOTATION_DRAFT,
    payload: { x, y },
  });
};
export const noTagSelected = () => (dispatch: (action: VideoTagActionTypes) => void) => {
  dispatch({
    type: VIDEO_TAG_EDITING_NONE,
    payload: {},
  });
};

export const tagCreationStarted = (videoId: string, tagId: string) => (dispatch: (action: VideoTagActionTypes) => void) => {
  dispatch({
    type: VIDEO_TAG_CREATION,
    payload: { videoId, tagId },
  });
};
export const noTagCreation = () => (dispatch: (action: VideoTagActionTypes) => void) => {
  dispatch({
    type: VIDEO_TAG_CREATION_NONE,
    payload: {},
  });
};
export const tagCreationDrafted = (x1: number, y1: number, x2: number, y2: number) => (dispatch: (action: VideoTagActionTypes) => void) => {
  dispatch({
    type: VIDEO_TAG_CREATION_DRAFT,
    payload: { x1, y1, x2, y2 },
  });
};

export const tagSelected = (videoId: string, tagId: string) => (dispatch: (action: VideoTagActionTypes) => void) => {
  dispatch({
    type: VIDEO_TAG_EDITING,
    payload: { videoId, tagId },
  });
};
export const tagDrafted = (x1: number, y1: number, x2: number, y2: number) => (dispatch: (action: VideoTagActionTypes) => void) => {
  dispatch({
    type: VIDEO_TAG_DRAFT,
    payload: { x1, y1, x2, y2 },
  });
};
export const videoTagAdded =
  (videoId: string, tagId: string, tag: FaceAnalysisItem) => (dispatch: (action: VideoTagActionTypes) => void) => {
    dispatch({
      type: VIDEO_TAG_ADDED,
      payload: { videoId, tagId, tag },
    });
    successNotification('FAKE Video tag added! (NOT SENT TO THE BACKEND)');
  };
export const videoTagDeleted = (videoId: string, tagId: string) => (dispatch: (action: VideoTagActionTypes) => void) => {
  dispatch({
    type: VIDEO_TAG_DELETED,
    payload: { videoId, tagId },
  });
  warningNotification('FAKE Video tag deleted! (NOT SENT TO THE BACKEND)');
};
export const videoTagUpdated =
  (videoId: string, tagId: string, tag: FaceAnalysisItem) => (dispatch: (action: VideoTagActionTypes) => void) => {
    dispatch({
      type: VIDEO_TAG_UPDATED,
      payload: { videoId, tagId, tag },
    });
    successNotification('Video tag updated! (NOT SENT TO THE BACKEND)');
  };
export const highlightSelected =
  (videoId: string, highlight: Highlight) => async (dispatch: (action: VideoHighlightActionTypes) => void) => {
    try {
      dispatch({
        type: VIDEO_HIGHLIGHT_SELECTED,
        payload: { videoId, highlight },
      });
      return Promise.resolve();
    } catch (err) {
      logErrorResponse(err, 'FAILED SET SELECTED HL ');
      return Promise.reject(err);
    }
  };
