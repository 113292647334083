import { Link } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import PersonOutline from '@material-ui/icons/PersonOutline';
import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { getAuthedHomePath, getUnauthedHomePath } from '../../../../app';
import { login } from '../../../demo/actions/figaroApiActions';
import { API_ROLE_COACH, API_ROLE_USER } from '../../../demo/model/ApiTypes';
import { IRootState } from '../../../shared/reducers';

interface IState {
  username: string;
  password: string;
  userNameRequired: boolean;
  passwordRequired: boolean;
}

class LoginContainer extends Component<PropsFromRedux & { history: any }, IState> {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      userNameRequired: false,
      passwordRequired: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log('login container componentDidUpdate : prev/crt props ', prevProps, this.props);
  }
  private checkUserName(): boolean {
    this.setState({ userNameRequired: this.state.username === '' });
    return !(this.state.username === '');
  }

  private checkPassword(): boolean {
    this.setState({ passwordRequired: this.state.password === '' });
    return !(this.state.password === '');
  }

  getHomeFor = (userRole) => {
    switch (userRole) {
      case 'ROLE_ADMIN':
      case 'ROLE_USER':
      case 'ROLE_COACH':
      case API_ROLE_USER:
      case API_ROLE_COACH: {
        return getAuthedHomePath();
      }
      default:
        return getUnauthedHomePath();
    }
  };

  onSuccesfullLogin = () => {
    // console.log('login container after login() '); // FFS back/fwd // history.push(this.getHomeFor(this.props.userRole)); // // window.location.reload();
    /*  import history from '../../../history'; */ this.props.history.push(this.getHomeFor(this.props.userRole));
  };

  private onFormSubmit(): void {
    if (this.checkUserName() === false || this.checkPassword() === false) {
      return;
    }
    this.props
      .login({
        identifier: this.state.username,
        password: this.state.password,
      })
      .then(
        () => this.onSuccesfullLogin(),
        (err) => {
          // console.log('login container login err ', err);
          // window.location.reload();
        }
      );
  }

  onEnterKey(e: any) {
    if (e.which === 13) {
      this.onFormSubmit();
    }
  }

  private renderLoginFailed() {
    if (this.props.loginFailed) {
      return (
        <>
          <div className="fig-login-failed">
            <span>Login failed!</span>
            <span>Can't access your account?</span>
          </div>
          <div className="fig-login-reset-resend">
            <Link onClick={this.onForgotPassword}>Reset your password</Link>
            <Link onClick={this.onAskRegistrationConfirmation}>Re-send registration confirmation code</Link>
          </div>
        </>
      );
    }
    return <div />;
  }

  onAskRegistrationConfirmation = () => {
    this.props.history.push(`/requestconfirmation?email=${this.state.username}`);
  };
  onForgotPassword = () => {
    this.props.history.push(`/forgotpassword?email=${this.state.username}`);
  };
  onCreateAccount = () => {
    this.props.history.push(`/register?email=${this.state.username}`);
  };

  render() {
    return (
      <div className="login-wrapper">
        <Paper>
          <div className="login-wrapper__header">
            <Avatar className="header-avatar" color="secondary">
              <PersonOutline color="secondary" />
            </Avatar>
            <div className="header-container">
              <p className="no-margin">Welcome to</p>
              <h1 className="no-margin">Figaro </h1>
            </div>
            <div className="header-container" style={{ color: 'inherit', width: 50 }} />
          </div>
          <form className="login-wrapper__form">
            <TextField
              className="w-100"
              id="email"
              value={this.state.username}
              onChange={(e) =>
                this.setState({
                  username: e.target.value,
                  userNameRequired: !e.target.value,
                })
              }
              onKeyPress={(e) => this.onEnterKey(e)}
              type="text"
              //label="Username"
              variant="outlined"
              margin="normal"
              // className={styles.textField}
              required
              error={this.state.userNameRequired}
              InputLabelProps={{
                shrink: true,
              }}
              placeholder={'USERNAME'}
            />
            <TextField
              className="w-100"
              id="password"
              value={this.state.password}
              onChange={(e) =>
                this.setState({
                  password: e.target.value,
                  passwordRequired: !e.target.value,
                })
              }
              InputLabelProps={{
                shrink: true,
              }}
              placeholder={'PASSWORD'}
              onKeyPress={(e) => this.onEnterKey(e)}
              type="password"
              //label="Password"
              margin="normal"
              variant="outlined"
              required
              error={this.state.passwordRequired}
            />
            <div className="login-wrapper__buttons">
              <Button className="fig-button ghost-to-dark-blue" onClick={() => this.onFormSubmit()}>
                Log In
              </Button>
              {/* <Link onClick={this.onCreateAccount}>Create account</Link> */}
              <Link onClick={this.onForgotPassword}>Reset Password</Link>
            </div>
          </form>
          {this.renderLoginFailed()}
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => {
  // console.log('login container map state, ', state);
  return {
    loginFailed: state.authentication.loginError,
    userRole: state.authentication.user ? state.authentication.user.role.name : null,
  };
};

const mapDispatchToProps = { login };
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(LoginContainer);
