import {
  CLEAR_ALERT,
  CONFIG_UPDATED_DURATION_THRESHOLD,
  SET_ALERT,
  UPDATE_GRAPHICS_SIDE_WIDTH,
  UPDATE_LEGEND_HEIGHT,
  UPDATE_PLAYER_HEIGHT,
  UPDATE_WINDOW_HEIGHT,
} from '../actions/types';
import cfg from '../demo/config/constants';

const INITIAL_STATE = {
  //TODO FFS what size initially ? match it to default layout
  playerHeight: 337,
  windowHeight: window.innerHeight,

  //legend height percentage
  legendHeight: 25,
  //percentage
  graphicsSideWidth: 66,
  durationThreshold: cfg.graph.downSamplingTrigger,
  alertTitle: null,
};

export type FigaroUtilsState = Readonly<typeof INITIAL_STATE>;

// Reducers
// eslint-disable-next-line import/no-anonymous-default-export
export default (state: FigaroUtilsState = INITIAL_STATE, action: any): FigaroUtilsState => {
  switch (action.type) {
    case UPDATE_PLAYER_HEIGHT: {
      return { ...state, playerHeight: action.payload.playerHeight, windowHeight: window.innerHeight };
    }
    case UPDATE_WINDOW_HEIGHT: {
      //return { ...state, windowHeight: action.payload.windowHeight };
      return { ...state, windowHeight: window.innerHeight };
    }
    case UPDATE_LEGEND_HEIGHT: {
      return { ...state, legendHeight: action.payload.height };
    }
    case UPDATE_GRAPHICS_SIDE_WIDTH: {
      return { ...state, graphicsSideWidth: action.payload.width };
    }
    case CONFIG_UPDATED_DURATION_THRESHOLD: {
      return { ...state, durationThreshold: action.payload.durationThreshold };
    }
    case SET_ALERT: {
      return { ...state, alertTitle: action.payload.title };
    }
    case CLEAR_ALERT: {
      return { ...state, alertTitle: null };
    }
    default:
      return state;
  }
};
