import { Button, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, TextField } from '@material-ui/core';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { getVideosPath } from '../../../../app';
import sprites from '../../../../icons/fig-icons.svg';
import { setGlobalAlert, windowHeightUpdated } from '../../../actions/utils';
import { IRootState } from '../../../shared/reducers';
import { getDatagroups, getDisfluencies, getGlobalRubrics, requestSubmissionFromUser } from '../../actions/figaroApiActions';
import { IUser } from '../../model/ApiTypes';
import { toDateTimeLocalInput } from '../../utils/utils';
import { EllipseAvatarIcon } from '../shared/EllipseAvatarIcon';
import SearchUsersPanel from './SearchUsersPanel';
type IProps = PropsFromRedux & {
  history: any;
};

interface IState {
  selectedUsers: { [userId: string]: IUser };
  emailsOfSelectedUsers: string[]; //emails of selectedUsers:
  selectedUncheckedEMails: { [address: string]: string }; //raw emails which have no IUSer
  clearedSearch: boolean; //search users box is closed
}

class RequestVideoPanel extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedUsers: {},
      selectedUncheckedEMails: {},
      emailsOfSelectedUsers: [],
      clearedSearch: true,
    };
    console.log('RequestVideoPanel ctor,  props ', props);
  }

  componentDidMount = () => {
    console.log('RequestVideoPanel did mount  props ', this.props);
    this.loadSettings();
    window.addEventListener('resize', this.handleGlobalWindowResize);
    this.handleGlobalWindowResize();
  };
  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleGlobalWindowResize);
  };
  handleGlobalWindowResize = () => {
    this.props.windowHeightUpdated(window.innerHeight);
  };

  //TODO FFS WHERE TO LOAD STUFF WHEN LOGIN IS SKIPPED ??
  loadSettings() {
    if (!this.props.disfluenciesLoaded) this.props.getDisfluencies(this.props.authentication.token);
    if (!this.props.dataGroupsLoaded) this.props.getDatagroups(this.props.authentication.token);
    if (!this.props.globalRubricsLoaded) this.props.getGlobalRubrics(this.props.authentication.token);
  }
  render() {
    //height = 140 + 110 = 250
    let usefullHeight = window.innerHeight - 250;
    console.log('RequestVideoPanel render ', this.props);
    return (
      <div className="row no-gutters fig-request-a-video">
        <div className="col-12">
          <div className="row no-gutters">
            <div className="col-12">
              <div className="fig-request-a-video-header">
                <span>Request a Video</span>
                <Button
                  className="fig-button rubric-primary"
                  onClick={() => {
                    this.props.history.push(getVideosPath());
                  }}
                >
                  Back to Videos
                </Button>
              </div>
            </div>
          </div>

          <div className="row no-gutters">
            <div className="col-4">
              <div className="fig-request-a-video-left-side" style={{ height: usefullHeight + 110 }}>
                <div className="fig-request-a-video-left-side__title">
                  <span>Search for users or enter email address</span>
                </div>
                <SearchUsersPanel
                  onAddUncheckedAddress={this.onAddUncheckedAddress}
                  onToggleSelectUser={this.onToggleSelectUser}
                  token={this.props.authentication.token}
                  eMailsOfSelectedUsers={this.state.emailsOfSelectedUsers}
                  selectedUncheckedEMails={this.state.selectedUncheckedEMails}
                  selectedUsers={this.state.selectedUsers}
                  maxHeight={0.5 * usefullHeight}
                  onSearchBoxChanged={(cleared) => {
                    this.setState({ clearedSearch: cleared }); //to force rerender
                  }}
                />
                <div className="fig-request-a-video-left-side__title">Selected Users:</div>

                <div
                  className="fig-request-a-video-left-side__selected-users"
                  style={{
                    height: (!this.state.clearedSearch ? 0.5 * usefullHeight : usefullHeight) - 40,
                  }}
                >
                  {this.renderSelection()}
                </div>
              </div>
            </div>

            <div className="col-8">
              <div className="fig-request-a-video-right-side" style={{ height: usefullHeight + 110 }}>
                <div className="fig-request-a-video-right-side__title">Second, add request information</div>
                <RequestForm onRequestVideo={this.onRequestVideo} enabled={this.hasSelectedRecipients()} maxHeight={usefullHeight - 20} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSelection() {
    return (
      <List dense>
        {Object.keys(this.state.selectedUsers).map((userId, idx) => (
          <ListItem className="selected-user-box" key={userId}>
            <EllipseAvatarIcon viewBox="0 0 36 35" />
            <ListItemText
              className="selected-user-names-box"
              primary={this.state.selectedUsers[userId].username}
              secondary={this.state.selectedUsers[userId].email}
            ></ListItemText>
            <ListItemSecondaryAction>
              <IconButton size="small" edge="end" aria-label="delete" onClick={this.onToggleSelectUser(this.state.selectedUsers[userId])}>
                <svg className="fig-close-icon">
                  <use href={sprites + '#close-icon'} />
                </svg>
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
        {Object.keys(this.state.selectedUncheckedEMails).map((address, idx) => (
          <ListItem className="selected-user-box" key={address}>
            <EllipseAvatarIcon viewBox="0 0 36 35" />
            <ListItemText className="selected-user-names-box" primary={address}></ListItemText>

            <ListItemSecondaryAction>
              <IconButton
                size="small"
                edge="end"
                aria-label="delete"
                style={{ fontWeight: 'bolder', color: 'black' }}
                onClick={this.onRemoveUncheckedAddress(address)}
              >
                <svg className="fig-close-icon">
                  <use href={sprites + '#close-icon'} />
                </svg>
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    );
  }

  hasSelectedRecipients() {
    return (
      (this.state.selectedUsers && Object.keys(this.state.selectedUsers).length > 0) ||
      (this.state.selectedUncheckedEMails && Object.keys(this.state.selectedUncheckedEMails).length > 0)
    );
  }
  onRequestVideo = (videoDetails: IRequestFormState) => {
    Object.values(this.state.selectedUsers).forEach((user) =>
      this.props.requestSubmissionFromUser(
        videoDetails.requestTitle,
        videoDetails.instructions,
        videoDetails.deadline.toISOString(),
        this.props.authentication.token,
        user.id,
        user.email
      )
    );
    Object.values(this.state.selectedUncheckedEMails).forEach((address) =>
      this.props.requestSubmissionFromUser(
        videoDetails.requestTitle,
        videoDetails.instructions,
        videoDetails.deadline.toISOString(),
        this.props.authentication.token,
        null,
        address
      )
    );
    //todo FFS which screen to go to and how to refresh /make sure it was refreshed ? .then la fiecare?!
    //this.props.history.push('/videos');
  };

  onToggleSelectUser = (user: IUser) => (event) => {
    let newSelectedUsers = { ...this.state.selectedUsers };
    let newAlreadySelectedEMails = [...this.state.emailsOfSelectedUsers];
    if (newSelectedUsers[user.id]) {
      delete newSelectedUsers[user.id];
      newAlreadySelectedEMails.splice(newAlreadySelectedEMails.indexOf(user.email));
    } else {
      if (!this.state.selectedUncheckedEMails[user.email]) {
        newSelectedUsers[user.id] = user;
        newAlreadySelectedEMails.push(user.email);
      } else {
        this.props.setGlobalAlert('The e-mail of this user was already included');
      }
    }
    this.setState({ selectedUsers: newSelectedUsers, emailsOfSelectedUsers: newAlreadySelectedEMails });
  };

  onAddUncheckedAddress = (term: string) => {
    // let { searchedTerm: term } = this.state;
    if (
      term &&
      term.length &&
      term.includes('@') &&
      !this.state.selectedUncheckedEMails[term] &&
      !this.state.emailsOfSelectedUsers.includes(term)
    ) {
      let newAddresses = { ...this.state.selectedUncheckedEMails };
      newAddresses[term] = term;
      this.setState({ selectedUncheckedEMails: newAddresses });
    } else if (this.state.emailsOfSelectedUsers.includes(term) || this.state.selectedUncheckedEMails[term]) {
      this.props.setGlobalAlert('This user was already included')
    }
  };

  onRemoveUncheckedAddress = (address: string) => () => {
    let newState = { ...this.state.selectedUncheckedEMails };
    if (newState[address]) {
      delete newState[address];
      this.setState({ selectedUncheckedEMails: newState });
    }
  };
}

interface IRequestFormProps {
  onRequestVideo: (videoDetails: IRequestFormState) => void;
  enabled: boolean;
  maxHeight: number;
}
interface IRequestFormState {
  requestTitle: string;
  instructions: string;
  deadline: Date;
}

class RequestForm extends React.PureComponent<IRequestFormProps, IRequestFormState> {
  constructor(props: IRequestFormProps) {
    super(props);
    this.state = {
      requestTitle: '',
      instructions: '',
      deadline: getDefaultDeadline(),
    };
    console.log('RequestForm ctor,  props ', props);
  }

  render() {
    return (
      <div>
        <div className="fig-request-a-video-right-side__contentbox" style={{ maxHeight: this.props.maxHeight, overflowY: 'auto' }}>
          <div className="video-title">
            <TextField
              className="w-100"
              label={'Video Title'}
              value={this.state.requestTitle}
              onChange={(event) => {
                this.setState({ requestTitle: event.target.value });
              }}
            />
          </div>
          <div className="textarea-box">
            <TextField
              className="w-100"
              label="Instructions"
              multiline={true}
              rows={10}
              value={this.state.instructions}
              variant="outlined"
              onChange={(event) => this.setState({ instructions: event.target.value })}
            />

            <form noValidate>
              <TextField
                className="datepicker"
                id="date"
                label="Deadline:"
                type="datetime-local"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: toDateTimeLocalInput(new Date()),
                  step: 60,
                }}
                value={toDateTimeLocalInput(this.state.deadline)}
                onChange={(date) => {
                  console.log('target ', date.target.value);
                  let nDate = new Date(date.target.value);
                  console.log('new Date: ', nDate);
                  if (new Date().getTime() <= nDate.getTime()) this.setState({ deadline: new Date(date.target.value) });
                }}
              />
            </form>
          </div>
        </div>
        <div className="button-holder">
          <Button className="fig-button in-modal-dialog" onClick={this.onRequestVideo} disabled={!this.props.enabled}>
            Request Video From Selected Users
          </Button>
        </div>
      </div>
    );
  }
  onRequestVideo = () => {
    this.props.onRequestVideo(this.state);
    this.clearFormState();
  };
  clearFormState = () => {
    this.setState({
      requestTitle: '',
      instructions: '',
      deadline: getDefaultDeadline(),
    });
  };
}

const mapStateToProps = (state: IRootState) => {
  console.log('RequestVideoPanel mapStateToProps ', state);
  return {
    windowHeight: state.utils.windowHeight,
    authentication: state.authentication,
    disfluenciesLoaded: state.settings.disfluencies && true,
    globalRubricsLoaded: state.settings.globalRubricsMap && true,
    dataGroupsLoaded:
      state.settings.allFaceCategories &&
      state.settings.allVoiceCategories &&
      Object.keys(state.settings.allFaceCategories).length > 0 &&
      Object.keys(state.settings.allVoiceCategories).length > 0,
  };
};

const mapDispatchToProps = {
  getDisfluencies,
  getDatagroups,
  getGlobalRubrics,
  requestSubmissionFromUser,
  windowHeightUpdated,
  setGlobalAlert,
};
const connector = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true });
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(RequestVideoPanel);

export const getDefaultDeadline = () => {
  return new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
};
