// {
//   analysisIdx: 0,
//   stream: 'joy',
//   analysisTag: 'Biden',

import {
  AuthClearAction,
  AUTH_CLEAR,
  SettingsActionTypes,
  SETTINGS_DATAGROUPS,
  SETTINGS_DISFLUENCIES,
  SETTINGS_GLOBAL_RUBRICS,
} from '../actions/figaroApiActions/types';
import { fakeRubric, SettingsDisfluencies, StreamCategories } from '../model/ApiTypes';
import { RubricsMap } from './rubricsReducer';

// },
const initialState = {
  disfluencies: null as SettingsDisfluencies,
  // all categories defined in datagroups
  allFaceCategories: {} as StreamCategories,
  allVoiceCategories: {} as StreamCategories,

  globalRubricsMap: null as RubricsMap,
};

export type SettingsState = Readonly<typeof initialState>;

// Reducers
// eslint-disable-next-line import/no-anonymous-default-export
export default (state: SettingsState = initialState, action: SettingsActionTypes | AuthClearAction): SettingsState => {
  switch (action.type) {
    case AUTH_CLEAR: {
      return { ...initialState };
    }
    case SETTINGS_DISFLUENCIES:
      return {
        ...state,
        disfluencies: action.payload.disfluencies,
      };
    case SETTINGS_DATAGROUPS:
      return {
        ...state,
        allFaceCategories: action.payload.allFaceCategories,
        allVoiceCategories: action.payload.allVoiceCategories,
      };

    case SETTINGS_GLOBAL_RUBRICS: {
      const map = {};
      action.payload.rubrics.forEach((rubric, idx) => {
        let id = generateFakeRubricId(idx);
        map[id] = fakeRubric(rubric, id);
      });
      return {
        ...state,
        globalRubricsMap: map,
      };
    }

    default:
      return state;
  }
};
export const generateFakeRubricId = (idx: number) => {
  return -1 - idx;
};
export const isGlobalRubric = (rubricId: number) => {
  return rubricId < 0;
};
