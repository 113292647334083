import { StreamRef } from '../../model/Video';
import { FaceEmotionsMap, ScreenshotInfo, V2EmotionsMap } from '../../reducers/reportReducer';

export const REPORT_HL_ADDED = 'HL_ADDED';
export const REPORT_HL_DELETED = 'HL_DELETED';
export const REPORT_HL_UPDATED = 'HL_UPDATED';
export const REPORT_HL_TOGGLED = 'HL_TOGGLED';
export const REPORT_HL_ENABLED = 'REPORT_HL_ENABLED';
export const REPORT_HL_CHART = 'REPORT_HL_CHART';
export const REPORT_HL_PICTURE = 'REPORT_HL_PICTURE';
export const REPORT_HL_COMMENT_ENABLED = 'REPORT_HL_COMMENT_ENABLED';

export const REPORT_SCREENSHOT_ADDED = 'REPORT_SCREENSHOT_ADDED';
export const REPORT_SCREENSHOT_DELETED = 'REPORT_SCREENSHOT_DELETED';
export const REPORT_SCREENSHOT_COMMENT = 'REPORT_SCREENSHOT_COMMENT';
export const REPORT_SCREENSHOT_TOGGLE = 'REPORT_SCREENSHOT_TOGGLE';

interface AddHLAction {
  type: typeof REPORT_HL_ADDED;
  payload: { id: string };
}
interface ToggleHLSelection4ReportsAction {
  type: typeof REPORT_HL_TOGGLED;
  payload: { id: string };
}
interface SetHLEnabledAction {
  type: typeof REPORT_HL_ENABLED;
  payload: { id: string; enabled: boolean };
}
interface SetHLChartAction {
  type: typeof REPORT_HL_CHART;
  payload: {
    id: string;
    chart: any;
    faceSelections: StreamRef[];
    voiceSelections: StreamRef[];
    seriesColors: { [seriesName: string]: string };
  };
}
interface SetHLPictureAction {
  type: typeof REPORT_HL_PICTURE;
  payload: { id: string; picture: any };
}
interface SetHLCommentEnabledAction {
  type: typeof REPORT_HL_COMMENT_ENABLED;
  payload: { highlightId: string; commentId: string; enabled: boolean };
}

interface DeleteHLAction {
  type: typeof REPORT_HL_DELETED;
  payload: { id: string };
}
interface UpdateHLAction {
  type: typeof REPORT_HL_UPDATED;
  payload: { videoId: string; comment: Comment };
}

export const REPORT_COACH_NAME = 'REPORT_COACH_NAME';
export const REPORT_COACH_ROLE = 'REPORT_COACH_ROLE';
export const REPORT_COACH_EMAIL = 'REPORT_COACH_EMAIL';
export const REPORT_COMPANY_ADDRESS = 'REPORT_COMPANY_ADDRESS';
export const REPORT_COMPANY_NAME = 'REPORT_COMPANY_NAME';
export const REPORT_COMPANY_URL = 'REPORT_COMPANY_URL';

export const REPORT_COVER_LETTER_ENABLED = 'REPORT_COVER_LETTER_ENABLED';
export const REPORT_COVER_LETTER_SALUTATION = 'REPORT_COVER_LETTER_SALUTATION';
export const REPORT_COVER_LETTER_INTRO = 'REPORT_COVER_LETTER_INTRO';
export const REPORT_SUMMARY_ENABLED = 'REPORT_SUMMARY_ENABLED';
export const REPORT_SUMMARY_TEXT = 'REPORT_SUMMARY_TEXT';
export const REPORT_FACES_INITIAL = 'REPORT_FACES_INITIAL';
export const REPORT_EMOTIONS_INITIAL = 'REPORT_EMOTIONS_INITIAL';

export const REPORT_SPEAKER_ENABLED = 'REPORT_SPEAKER_ENABLED';
export const REPORT_SPEAKER_VERBAL_ENABLED = 'REPORT_SPEAKER_VERBAL_ENABLED';
export const REPORT_SPEAKER_FACE_ENABLED = 'REPORT_SPEAKER_FACE_ENABLED';

export const REPORT_FACE_EMOTIONS_TEXT = 'REPORT_FACE_EMOTIONS_TEXT';
export const REPORT_FACE_EMOTIONS_CHART = 'REPORT_FACE_EMOTIONS_CHART';
export const REPORT_VERBAL_ENABLED = 'REPORT_VERBAL_ENABLED';
export const REPORT_VERBAL_EMOTIONS_TEXT = 'REPORT_VERBAL_EMOTIONS_TEXT';
export const REPORT_VERBAL_EMOTIONS_CHART = 'REPORT_VERBAL_EMOTIONS_CHART';
export const REPORT_CLIENT_NAME = 'REPORT_CLIENT_NAME';
export const REPORT_SCREENSHOT_TRANSCRIPT = 'REPORT_SCREENSHOT_TRANSCRIPT';

interface SetScreenshotTranscriptAction {
  type: typeof REPORT_SCREENSHOT_TRANSCRIPT;
  payload: {
    part1: string;
    part2: string;
    id: string;
  };
}

interface SetCoachNameAction {
  type: typeof REPORT_COACH_NAME;
  payload: { coachName: string };
}
interface SetCoachNameAction {
  type: typeof REPORT_COACH_NAME;
  payload: { coachName: string };
}
interface SetCoachRoleAction {
  type: typeof REPORT_COACH_ROLE;
  payload: { coachRole: string };
}
interface SetCoachEmailAction {
  type: typeof REPORT_COACH_EMAIL;
  payload: { coachEmail: string };
}
interface SetCompanyNameAction {
  type: typeof REPORT_COMPANY_NAME;
  payload: { companyName: string };
}
interface SetCompanyAddressAction {
  type: typeof REPORT_COMPANY_ADDRESS;
  payload: { companyAddress: string };
}
interface SetCompanyUrlAction {
  type: typeof REPORT_COMPANY_URL;
  payload: { companyUrl: string };
}

interface SetCoverLetterEnabledAction {
  type: typeof REPORT_COVER_LETTER_ENABLED;
  payload: { enabled: boolean };
}
interface SetCoverLetterSalutationAction {
  type: typeof REPORT_COVER_LETTER_SALUTATION;
  payload: { salutation: string };
}
interface SetCoverLetterIntroAction {
  type: typeof REPORT_COVER_LETTER_INTRO;
  payload: { intro: string };
}

interface SetSummaryEnabledAction {
  type: typeof REPORT_SUMMARY_ENABLED;
  payload: { enabled: boolean };
}
interface SetSummaryTextAction {
  type: typeof REPORT_SUMMARY_TEXT;
  payload: { text: string };
}

interface SetInitialFaceEmotionAction {
  type: typeof REPORT_FACES_INITIAL;
  payload: { map: FaceEmotionsMap };
}
interface SetInitialEmotionsAction {
  type: typeof REPORT_EMOTIONS_INITIAL;
  payload: { map: V2EmotionsMap };
}

interface SetFaceEmotionEnabledAction {
  type: typeof REPORT_SPEAKER_ENABLED;
  payload: { enabled: boolean; faceTag: string };
}
interface SetSpeakerFaceEmotionEnabledAction {
  type: typeof REPORT_SPEAKER_FACE_ENABLED;
  payload: { enabled: boolean; faceTag: string };
}
interface SetSpeakerVerbalEmotionEnabledAction {
  type: typeof REPORT_SPEAKER_VERBAL_ENABLED;
  payload: { enabled: boolean; faceTag: string };
}

interface SetFaceTextAction {
  type: typeof REPORT_FACE_EMOTIONS_TEXT;
  payload: { text: string; faceTag: string; detail: boolean };
}
interface SetFaceChartAction {
  type: typeof REPORT_FACE_EMOTIONS_CHART;
  payload: { chart: any; faceTag: string };
}

interface SetVerbalEmotionEnabledAction {
  type: typeof REPORT_VERBAL_ENABLED;
  payload: { enabled: boolean };
}
interface SetVerbalTextAction {
  type: typeof REPORT_VERBAL_EMOTIONS_TEXT;
  payload: { text: string; detail: boolean };
}
interface SetVerbalChartAction {
  type: typeof REPORT_VERBAL_EMOTIONS_CHART;
  payload: { chart: any; faceTag?: string };
}

interface SetClientNameAction {
  type: typeof REPORT_CLIENT_NAME;
  payload: { clientName: string };
}

interface AddScreenshotAction {
  type: typeof REPORT_SCREENSHOT_ADDED;
  payload: { screenshot: ScreenshotInfo };
}
interface DeleteScreenshotAction {
  type: typeof REPORT_SCREENSHOT_DELETED;
  payload: { id: string };
}
interface ToggleScreenshotAction {
  type: typeof REPORT_SCREENSHOT_TOGGLE;
  payload: { id: string; enabled: boolean };
}
interface CommentScreenshotAction {
  type: typeof REPORT_SCREENSHOT_COMMENT;
  payload: { id: string; comment: string };
}

export type ReportActionTypes =
  | AddHLAction
  | ToggleHLSelection4ReportsAction
  | SetHLEnabledAction
  | SetHLChartAction
  | SetHLPictureAction
  | DeleteHLAction
  | UpdateHLAction
  | SetCoachEmailAction
  | SetCoachNameAction
  | SetCoachRoleAction
  | SetCompanyAddressAction
  | SetCompanyNameAction
  | SetCompanyUrlAction
  | SetCoverLetterEnabledAction
  | SetCoverLetterIntroAction
  | SetCoverLetterSalutationAction
  | SetSummaryEnabledAction
  | SetVerbalEmotionEnabledAction
  | SetInitialFaceEmotionAction
  | SetInitialEmotionsAction
  | SetFaceTextAction
  | SetFaceChartAction
  | SetSummaryTextAction
  | SetVerbalTextAction
  | SetVerbalChartAction
  | SetFaceEmotionEnabledAction
  | SetSpeakerFaceEmotionEnabledAction
  | SetSpeakerVerbalEmotionEnabledAction
  | SetHLCommentEnabledAction
  | SetClientNameAction
  | AddScreenshotAction
  | DeleteScreenshotAction
  | ToggleScreenshotAction
  | CommentScreenshotAction
  | SetScreenshotTranscriptAction;
