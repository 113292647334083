import {
  AuthClearAction,
  AUTH_CLEAR,
  SUBMISSIONS,
  SubmissionsActionTypes,
  SubmissionShareActionTypes,
  SUBMISSIONS_ASKED_TO_OTHERS,
  SUBMISSIONS_TO_UPLOAD,
  SUBMISSION_DELETED,
  SUBMISSION_ENHANCED_INFO,
  SUBMISSION_META,
  SUBMISSION_SHARE_ADDED,
  SUBMISSION_SHARE_DELETED,
  SUBMISSION_UPDATED_DETAILS,
  SUBMISSION_UPDATED_DISFLUENCIES,
  SUBMISSION_UPLOAD_INFO,
} from '../actions/figaroApiActions/types';
import { differs, Submission, SubmissionState, SubmissionUploadInfo } from '../model/ApiTypes';

const initialSubmissionsMap = (): { [id: number]: Submission } => {
  return {};
};
const initialSubmissionsUploadInfoMap = (): { [id: number]: SubmissionUploadInfo } => {
  return {};
};

const initialState = {
  submissionsMap: initialSubmissionsMap(),
  // todo pagination
  submissionsUploadInfosMap: initialSubmissionsUploadInfoMap(),
};

export type SubmissionsState = Readonly<typeof initialState>;

// Reducers
// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state: SubmissionsState = initialState,
  action: SubmissionsActionTypes | AuthClearAction | SubmissionShareActionTypes
): SubmissionsState => {
  switch (action.type) {
    case AUTH_CLEAR: {
      return { ...initialState };
    }
    case SUBMISSIONS:
    case SUBMISSIONS_TO_UPLOAD:
    case SUBMISSIONS_ASKED_TO_OTHERS: {
      const newMap = {};
      action.payload.submissions.forEach((submission) => {
        newMap[submission.id] = submission;
      });
      return {
        ...state,
        submissionsMap: newMap,
      };
    }
    case SUBMISSION_META:
      let newUploadInfoMap = { ...state.submissionsUploadInfosMap };
      if (newUploadInfoMap[action.payload.submission.id]) {
        delete newUploadInfoMap[action.payload.submission.id];
      }
      return {
        ...state,
        submissionsMap: {
          ...state.submissionsMap,
          [action.payload.submission.id]: action.payload.submission,
        },
        submissionsUploadInfosMap: newUploadInfoMap,
      };
    case SUBMISSION_ENHANCED_INFO: {
      return {
        ...state,
        submissionsMap: {
          ...state.submissionsMap,
          [action.payload.submission.id]: action.payload.submission,
        },
      };
    }
    case SUBMISSION_UPLOAD_INFO: {
      return {
        ...state,
        submissionsUploadInfosMap: {
          ...state.submissionsUploadInfosMap,
          [action.payload.submissionId]: action.payload.submissionUploadInfo,
        },
      };
    }

    // TODO FFS update thresholds at SUBMISSION_DISFLUENCIES_UPDATED
    case SUBMISSION_UPDATED_DISFLUENCIES: {
      let oldS = state.submissionsMap[action.payload.submission.id];
      return {
        ...state,
        submissionsMap: {
          ...state.submissionsMap,
          [action.payload.submission.id]: { ...oldS, options: action.payload.submission.options },
        },
      };
    }
    case SUBMISSION_SHARE_DELETED: //FFS study refresh submission info
    case SUBMISSION_SHARE_ADDED: {
      let oldS = state.submissionsMap[action.payload.videoId];
      let updatedVarSubmissionInfo = action.payload.updatedSubmissionInfo;
      if (updatedVarSubmissionInfo && differs(oldS, updatedVarSubmissionInfo)) {
        let {
          name: oldname,
          details: olddetails,
          state: oldstatus,
          options: oldoptions,
          deadline: olddeadline,
          submitdate: oldsubmitdate,
          updated_at: oldupdateat,
          ...rest
        } = oldS;
        let { name, details, state: newstatus, options, deadline, submitdate, updated_at } = updatedVarSubmissionInfo;
        return {
          ...state,
          submissionsMap: {
            ...state.submissionsMap,
            [action.payload.videoId]: { ...rest, name, details, state: newstatus, options, deadline, submitdate, updated_at },
          },
        };
      } else {
        return state;
      }
    }

    case SUBMISSION_UPDATED_DETAILS: {
      console.log('reducer, SUBMISSION_UPDATED_DETAILS: state: ', state, 'action: ', action.payload);

      let newUploadMap = { ...state.submissionsUploadInfosMap };
      if (newUploadMap[action.payload.submission.id]) {
        //update all subscription info, apart from upload info
        let temp = { ...action.payload.submission, analysis: state.submissionsUploadInfosMap[action.payload.submission.id].analysis };
        newUploadMap[action.payload.submission.id] = temp;
      }

      return {
        ...state,
        submissionsMap: {
          ...state.submissionsMap,
          //let oldS = state.submissionsMap[action.payload.submission.id];
          //[action.payload.submission.id]: { ...oldS, details: action.payload.submission.details, name: action.payload.submission.name },
          [action.payload.submission.id]: { ...action.payload.submission }, //update all info, nu just name/details
        },
        submissionsUploadInfosMap: newUploadMap,
      };
    }
    case SUBMISSION_DELETED: {
      if (action.payload.submission.state === SubmissionState.requested) {
        //submission deleted for real, remove it from our structures
        let newMap = { ...state.submissionsMap };
        if (newMap[action.payload.submission.id]) delete newMap[action.payload.submission.id];
        let newUploadMap = { ...state.submissionsUploadInfosMap };
        if (newUploadMap[action.payload.submission.id]) delete newUploadMap[action.payload.submission.id];
        return {
          ...state,
          submissionsMap: newMap,
          submissionsUploadInfosMap: newUploadMap,
        };
      } else {
        //submission state marked as deleted
        return {
          ...state,
          submissionsMap: {
            ...state.submissionsMap,
            [action.payload.submission.id]: { ...action.payload.submission },
          },
        };
      }
    }

    default:
      return state;
  }
};
