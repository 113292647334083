import {
  AuthClearAction,
  AUTH_CLEAR,
  OWN_RUBRICS,
  RubricsActionTypes,
  RUBRIC_ADDED,
  RUBRIC_DELETED,
  RUBRIC_UPDATED,
} from '../actions/figaroApiActions/types';
import { Rubric } from '../model/ApiTypes';

const initialRubricsMap = (): RubricsMap => {
  return {};
};

const initialState = {
  rubricsMap: initialRubricsMap(),
};

export type RubricsState = Readonly<typeof initialState>;
export type RubricsMap = { [id: string]: Rubric };

// Reducers
// eslint-disable-next-line import/no-anonymous-default-export
export default (state: RubricsState = initialState, action: RubricsActionTypes | AuthClearAction): RubricsState => {
  switch (action.type) {
    case AUTH_CLEAR: {
      return { ...initialState };
    }
    case OWN_RUBRICS: {
      const map = {};
      action.payload.rubrics.forEach((rubric) => {
        map[rubric.id] = rubric;
      });
      return {
        ...state,
        rubricsMap: map,
      };
    }
    case RUBRIC_DELETED: {
      let newState = { ...state.rubricsMap };
      delete newState[action.payload.rubricId];
      return { ...state, rubricsMap: newState };
    }
    case RUBRIC_ADDED:
    case RUBRIC_UPDATED:
      return { ...state, rubricsMap: { ...state.rubricsMap, [action.payload.rubricId]: action.payload.rubric } };

    default:
      return state;
  }
};
