import { Grid } from '@material-ui/core';
import React from 'react';
import { isStreamTagIncluded } from '../../model/utils';
import { CATEGORY_ANALYSIS, FAKE_EMOTION_COMPLEXITY, StreamRef } from '../../model/Video';
import { streamRef2LegendId } from './utils';

interface IProps {
  tag: string;
  friendlyName: string;
  categoryName: string;
  streams: string[];
  streamsSelected4Graph: StreamRef[];
  toggleSelectedStream: (category: string, tag: string, stream: string, friendlyName: string) => void;
  seriesColors: { [seriesName: string]: string };
  alwaysShowEmotionsColors: boolean; // if true, show circle's color even if the item is unchecked

  isPseudo?: boolean;
  isComplexityOnly: boolean;
  isHidden?: boolean; //mandatory for categories of face analisys
}

class CategoryLegend extends React.PureComponent<IProps, any> {
  public static LEGEND_ITEM_WIDTH = 80;
  public static LEGEND_ITEM_HEIGHT = 35;
  public static MAX_LEGEND_ITEM_WIDTH_I = 124 + 40;
  public static MAX_LEGEND_ITEM_WIDTH_II = 161 + 40;

  render() {
    let itemCount = 0;
    let container = (
      <Grid container>
        {(!this.props.isComplexityOnly && this.props.categoryName.includes(CATEGORY_ANALYSIS)
          ? this.props.streams.filter((stream) => stream !== FAKE_EMOTION_COMPLEXITY)
          : this.props.streams
        ).map((stream, idx) => {
          const isSelected = isStreamTagIncluded(this.props.tag, stream, this.props.streamsSelected4Graph);
          if (this.props.isPseudo && !isSelected) {
            return null;
          }
          itemCount++;
          return (
            <Grid key={stream} item>
              <div
                className="fig-legend-property-box"
                id={stream}
                style={{
                  color: isSelected ? 'black' : '#c0c0c0',
                  fontWeight: isSelected ? 'bold' : 'inherit',
                  minWidth: CategoryLegend.LEGEND_ITEM_WIDTH,
                  cursor: !this.props.isPseudo ? 'pointer' : 'inherit',
                }}
                onClick={this.onClick}
              >
                <div>{stream}</div>
                <div
                  style={{
                    backgroundColor:
                      isSelected || this.props.alwaysShowEmotionsColors
                        ? this.getLegendColor(this.props.tag, stream, this.props.friendlyName)
                        : '#c0c0c0',
                    width: 12,
                    height: 12,
                    borderRadius: '50%',
                  }}
                ></div>
              </div>
            </Grid>
          );
        })}
      </Grid>
    );
    return itemCount ? (
      <div className="fig-container" style={this.props.isHidden /*TODO ASK WING*/ ? { color: 'gray', textDecoration: 'line-through' } : {}}>
        <div style={this.props.isHidden ? { color: 'gray', textDecoration: 'line-through' } : {}}>
          {!this.props.isComplexityOnly ? <span>{this.props.categoryName}</span> : null}
          <span>{this.props.friendlyName}</span>
        </div>
        {container}
      </div>
    ) : null;
  }

  getLegendColor = (analysisTag, stream, tagFriendlyName) => {
    const ref: StreamRef = { analysisTag, stream, tagFriendlyName };
    const legendId = streamRef2LegendId(ref);
    return this.props.seriesColors && this.props.seriesColors[legendId] ? this.props.seriesColors[legendId] : 'pink';
  };
  onClick = (event: any) => {
    // console.log('click event ', event);
    event.preventDefault();
    if (!this.props.isPseudo) {
      this.props.toggleSelectedStream(this.props.categoryName, this.props.tag, event.currentTarget.id, this.props.friendlyName);
    }
  };
}

export default CategoryLegend;
