import {
  AuthClearAction,
  AUTH_CLEAR,
  SettingsActionTypes,
  SETTINGS_DATAGROUPS,
  SubmissionsActionTypes,
  SUBMISSION_ENHANCED_INFO,
  SUBMISSION_META,
} from '../actions/figaroApiActions/types';
import {
  FACEANALYSIS_PERSONS_SELECTED,
  FACE_ANALYSIS_OPTIONS_DRAWER,
  FACE_CATEGORIES_AVAILABLE,
  FACE_STREAMS_SELECTED,
  FACE_THRESHOLD,
  IMAGE_4_REPORT_ADDED,
  REPORT_DRAWER,
  REPORT_PDF,
  SELECTED_CARDS_FILTERING_SORTING,
  SELECTED_CHARTS_TAB,
  SELECTED_GRAPHICS_SIDE_TAB,
  SELECTED_LEGEND_TAB,
  SELECTED_TEXT_SIDE_TAB,
  SelectionsActionTypes,
  VOICE_ANALYSIS_OPTIONS_DRAWER,
  VOICE_CATEGORIES_AVAILABLE,
  VOICE_STREAMS_SELECTED,
  VOICE_THRESHOLD,
} from '../actions/selectionActions/types';
import { VideoActionTypes, VIDEO_HIGHLIGHT_SELECTED, VIDEO_SELECTED } from '../actions/videoActions/types';
import { ISelectableItem } from '../components/shared/BorderedSelector';
import { ARRAY_NONE, defaultSortByItems } from '../components/submission/FilteringSortingDialog';
import { StreamCategories } from '../model/ApiTypes';
import { getCategory, isStreamRefIncluded } from '../model/utils';
import { StreamRef, StreamsState } from '../model/Video';

const HIGHLIGHTS_SERIE_ID = 'All Highlights';
const PLAYING_SERIE_ID = 'Playing';
const THRESHOLD_SERIE_ID = 'Threshold';
const CURRENT_HIGHLIGHT_SERIE_ID = 'Selected Highlight';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FAKE_SERIES = [PLAYING_SERIE_ID, THRESHOLD_SERIE_ID, CURRENT_HIGHLIGHT_SERIE_ID, HIGHLIGHTS_SERIE_ID];
const FAKE_SERIES_MINUS_ALLHL = [PLAYING_SERIE_ID, THRESHOLD_SERIE_ID, CURRENT_HIGHLIGHT_SERIE_ID];

// e.g. {
//   analysisIdx: 0,
//   stream: 'joy',
//   analysisTag: 'Biden',
// },
const initialState: SelectionsState = {
  voiceThreshold: null as number,
  faceThreshold: null as number,
  selectedStreamsVoice: [] as StreamRef[],
  selectedStreamsFace: [] as StreamRef[],

  // selectedFakeStreamsVoice: FAKE_SERIES,
  // selectedFakeStreamsFace: FAKE_SERIES,
  //selectedFakeStreamsVoice: FAKE_SERIES_MINUS_ALLHL,
  selectedFakeStreamsFace: FAKE_SERIES_MINUS_ALLHL,

  availableFaceCategories: {},
  availableVoiceCategories: {},

  allFaceCategories: {} as StreamCategories,
  allVoiceCategories: {} as StreamCategories,

  faceAnalysisOptionsDrawerOpened: false,
  voiceAnalysisOptionsDrawerOpened: false,
  reportDrawerOpened: false,

  allPersonsTags: [] as string[],
  selectedPersonsTags: [] as string[],

  selectedGraphicsSideTab: 0,
  selectedTextSideTab: 0,
  selectedLegendTab: 0,
  selectedChartsTab: 0,
  filtersState: { State: ARRAY_NONE, Coaches: ARRAY_NONE, Presenter: ARRAY_NONE },
  sortByItems: defaultSortByItems,

  imagesMap: {},
  pdfDoc: null,
};

export interface SelectionsState extends StreamsState {
  //selectedFakeStreamsVoice: string[]; //
  selectedFakeStreamsFace: string[];

  // which categories will appear in the streams selection panel, a subset of all datagroups
  availableFaceCategories: StreamCategories;
  availableVoiceCategories: StreamCategories;

  //TODO FFS this duplicates Settings state, but how else could we access Settings state ?!
  allFaceCategories: StreamCategories;
  allVoiceCategories: StreamCategories;

  faceAnalysisOptionsDrawerOpened: boolean;
  voiceAnalysisOptionsDrawerOpened: boolean;
  reportDrawerOpened: boolean;

  allPersonsTags: string[];
  selectedPersonsTags: string[];

  selectedGraphicsSideTab: number;
  selectedTextSideTab: number;
  selectedLegendTab: number;
  selectedChartsTab: number;

  filtersState: { State: string[]; Coaches: string[]; Presenter: string[] };
  sortByItems: ISelectableItem[];
  imagesMap: { [id: string]: string }; //id 2 url
  pdfDoc: any;
}

// Reducers
// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state: SelectionsState = initialState,
  action: SubmissionsActionTypes | AuthClearAction | SelectionsActionTypes | VideoActionTypes | SettingsActionTypes
): SelectionsState => {
  switch (action.type) {
    case AUTH_CLEAR: {
      return { ...initialState };
    }
    // TODO FFS update thresholds at SUBMISSION_DISFLUENCIES_UPDATED

    case SUBMISSION_META: {
      const names = action.payload.video.tag2FaceAnalysisIdx ? Object.keys(action.payload.video.tag2FaceAnalysisIdx) : [];
      return {
        ...state,
        voiceThreshold: action.payload.video.threshold,
        faceThreshold: action.payload.video.threshold,

        allPersonsTags: [...names],
        selectedPersonsTags: [...names],
      };
    }
    case SUBMISSION_ENHANCED_INFO: {
      const names = action.payload.video.tag2FaceAnalysisIdx ? Object.keys(action.payload.video.tag2FaceAnalysisIdx) : [];
      return {
        ...state,
        voiceThreshold: action.payload.video.threshold,
        faceThreshold: action.payload.video.threshold,

        allPersonsTags: [...names],
        selectedPersonsTags: [...names],
      };
    }
    case VIDEO_SELECTED: {
      //do not reset categories ,  fake streams etc, since they  are not dependencies of video
      return {
        ...initialState,
        //selectedFakeStreamsVoice: state.selectedFakeStreamsVoice,
        selectedFakeStreamsFace: state.selectedFakeStreamsFace,
        availableFaceCategories: state.availableFaceCategories,
        availableVoiceCategories: state.availableVoiceCategories,
        allFaceCategories: state.allFaceCategories,
        allVoiceCategories: state.allVoiceCategories,
        voiceAnalysisOptionsDrawerOpened: state.voiceAnalysisOptionsDrawerOpened,
        faceAnalysisOptionsDrawerOpened: state.faceAnalysisOptionsDrawerOpened,
        // TODO FFS allPersonsTags , selectedPersonsTags

        selectedGraphicsSideTab: state.selectedGraphicsSideTab,
        selectedTextSideTab: state.selectedTextSideTab,
        // TODO FFS  selectedLegendTab: what if old legend was showing face options for the 3rd speaker, but now we have just one spaekr, etc
        sortByItems: state.sortByItems,
        filtersState: state.filtersState,
      };
    }
    case FACEANALYSIS_PERSONS_SELECTED: {
      return {
        ...state,
        selectedPersonsTags: [...action.payload.selectedPersons],
        selectedStreamsFace: state.selectedStreamsFace.filter((streamRef) =>
          action.payload.selectedPersons.includes(streamRef.analysisTag)
        ),
      };
    }
    case VOICE_STREAMS_SELECTED: {
      return {
        ...state,
        selectedStreamsVoice: action.payload.selectedStreamsVoice ? action.payload.selectedStreamsVoice : state.selectedStreamsVoice,
        //selectedFakeStreamsVoice: action.payload.selectedFakeStreams ? action.payload.selectedFakeStreams : state.selectedFakeStreamsVoice,
        selectedFakeStreamsFace: action.payload.selectedFakeStreams ? action.payload.selectedFakeStreams : state.selectedFakeStreamsFace,
      };
    }
    case FACE_STREAMS_SELECTED: {
      return {
        ...state,
        selectedStreamsFace: action.payload.selectedStreamsFace ? action.payload.selectedStreamsFace : state.selectedStreamsFace,
        selectedFakeStreamsFace: action.payload.selectedFakeStreams ? action.payload.selectedFakeStreams : state.selectedFakeStreamsFace,
      };
    }
    case VOICE_CATEGORIES_AVAILABLE: {
      return {
        ...state,
        availableVoiceCategories: action.payload.availableVoiceCategories,
        // kept selected just the existing selected streams that belong to available categories
        // todo ffs: what to do with the streams of the newly selected categories ? for now, do not select them
        selectedStreamsVoice: state.selectedStreamsVoice.filter((streamRef) =>
          isStreamRefIncluded(streamRef, action.payload.availableVoiceCategories)
        ),
      };
    }
    case FACE_CATEGORIES_AVAILABLE: {
      return {
        ...state,
        availableFaceCategories: action.payload.availableFaceCategories,
        // kept selected just the existing selected streams that belong to available categories
        // todo ffs: what to do with the streams of the newly selected categories ? for now, do not select them
        selectedStreamsFace: state.selectedStreamsFace.filter((streamRef) =>
          isStreamRefIncluded(streamRef, action.payload.availableFaceCategories)
        ),
      };
    }
    case SETTINGS_DATAGROUPS: {
      return {
        ...state,
        availableFaceCategories: allStreamsPerCategory(action.payload.allFaceCategories),
        availableVoiceCategories: allStreamsPerCategory(action.payload.allVoiceCategories),
        allFaceCategories: { ...action.payload.allFaceCategories },
        allVoiceCategories: { ...action.payload.allVoiceCategories },
      };
    }
    case FACE_THRESHOLD: {
      return {
        ...state,
        faceThreshold: action.payload.threshold,
      };
    }
    case VOICE_THRESHOLD: {
      return {
        ...state,
        voiceThreshold: action.payload.threshold,
      };
    }
    case VIDEO_HIGHLIGHT_SELECTED: {
      const hl = action.payload.highlight;
      let changedFaceCategories = false;
      let changedVoiceCategories = false;
      let newAvailableFaceCategories = { ...state.availableFaceCategories };
      let newAvailableVoiceCategories = { ...state.availableVoiceCategories };

      let newSelectedPersons = [...state.selectedPersonsTags];

      // add category of each HL's stream to the list of available categories, if not already available
      if (hl && hl.selectedStreamsFace) {
        hl.selectedStreamsFace.forEach((stream) => {
          if (stream.analysisTag && !newSelectedPersons.includes(stream.analysisTag)) {
            newSelectedPersons.push(stream.analysisTag);
          }
          const category = getCategory(stream, state.allFaceCategories);
          if (!category) {
            console.error('No category found for selected Face stream ', stream);
          } else {
            if (!newAvailableFaceCategories[category]) {
              newAvailableFaceCategories[category] = [stream.stream];
              changedFaceCategories = true;
            } else if (!newAvailableFaceCategories[category].includes(stream.stream)) {
              newAvailableFaceCategories[category] = [...newAvailableFaceCategories[category], stream.stream];
              changedFaceCategories = true;
            }
          }
        });
      }
      if (hl && hl.selectedStreamsVoice) {
        hl.selectedStreamsVoice.forEach((stream) => {
          const category = getCategory(stream, state.allVoiceCategories);
          if (!category) {
            console.error('No category found for selected Voice stream ', stream);
          } else {
            if (!newAvailableVoiceCategories[category]) {
              newAvailableVoiceCategories[category] = [stream.stream];
              changedVoiceCategories = true;
            } else if (!newAvailableVoiceCategories[category].includes(stream.stream)) {
              newAvailableVoiceCategories[category] = [...newAvailableVoiceCategories[category], stream.stream];
              changedVoiceCategories = true;
            }
          }
        });
      }
      return {
        ...state,
        voiceThreshold: hl ? hl.voiceThreshold : 100,
        faceThreshold: hl ? hl.faceThreshold : 100,
        selectedStreamsFace: hl ? hl.selectedStreamsFace : [],
        selectedStreamsVoice: hl ? hl.selectedStreamsVoice : [],
        // TODO ASK preference at HL unselect: clear all (as above) or preserve all (as below)
        // voiceThreshold: hl ? hl.voiceThreshold : state.voiceThreshold,
        // faceThreshold: hl ? hl.faceThreshold : state.faceThreshold,
        // selectedStreamsFace: hl ? hl.selectedStreamsFace : state.selectedStreamsFace,
        // selectedStreamsVoice: hl ? hl.selectedStreamsVoice : state.selectedStreamsVoice,

        availableFaceCategories: changedFaceCategories ? newAvailableFaceCategories : state.availableFaceCategories,
        availableVoiceCategories: changedVoiceCategories ? newAvailableVoiceCategories : state.availableVoiceCategories,
        selectedPersonsTags:
          state.selectedPersonsTags.length !== newSelectedPersons.length ? newSelectedPersons : state.selectedPersonsTags,
      };
    }
    case FACE_ANALYSIS_OPTIONS_DRAWER: {
      return {
        ...state,
        faceAnalysisOptionsDrawerOpened: action.payload.faceAnalysisOptionsDrawerOpened,
      };
    }
    case VOICE_ANALYSIS_OPTIONS_DRAWER: {
      return {
        ...state,
        voiceAnalysisOptionsDrawerOpened: action.payload.voiceAnalysisOptionsDrawerOpened,
      };
    }
    case REPORT_DRAWER: {
      return {
        ...state,
        reportDrawerOpened: action.payload.reportDrawerOpened,
      };
    }
    case REPORT_PDF: {
      return {
        ...state,
        pdfDoc: action.payload.pdfDoc,
      };
    }
    case SELECTED_GRAPHICS_SIDE_TAB: {
      return {
        ...state,
        selectedGraphicsSideTab: action.payload.selectedTabIdx,
      };
    }
    case SELECTED_TEXT_SIDE_TAB: {
      return {
        ...state,
        selectedTextSideTab: action.payload.selectedTabIdx,
      };
    }
    case SELECTED_LEGEND_TAB: {
      return {
        ...state,
        selectedLegendTab: action.payload.selectedTabIdx,
      };
    }
    case SELECTED_CHARTS_TAB: {
      return {
        ...state,
        selectedChartsTab: action.payload.selectedTabIdx,
      };
    }
    case SELECTED_CARDS_FILTERING_SORTING: {
      return {
        ...state,
        sortByItems: action.payload.sortByItems,
        filtersState: action.payload.filtersState,
      };
    }
    case IMAGE_4_REPORT_ADDED: {
      return {
        ...state,
        imagesMap: { ...state.imagesMap, [action.payload.name]: action.payload.url },
      };
    }
    default:
      return state;
  }
};

/** build a new object having all streams in each category */
const allStreamsPerCategory = (streamsPerCategory: StreamCategories) => {
  let rez = { ...streamsPerCategory };
  return rez;
};
