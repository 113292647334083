import {
  EvaluationResponse,
  Folder,
  FolderSubmission,
  LoginResponse,
  Rubric,
  RubricBase,
  SettingsDisfluencies,
  ShareBase,
  StreamCategories,
  Submission,
  SubmissionRubric,
  SubmissionUploadInfo,
  SUser,
  VarSubmissionInfo,
} from '../../model/ApiTypes';
import { Video } from '../../model/Video';

export const AUTH_USER = 'AUTH_USER';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_CLEAR = 'AUTH_CLEAR';

// logged in
interface AuthUserAction {
  type: typeof AUTH_USER;
  payload: LoginResponse;
}

// log in failed
interface AuthErrorAction {
  type: typeof AUTH_ERROR;
  payload: { message: string; error: any };
}

// logged out
export interface AuthClearAction {
  type: typeof AUTH_CLEAR;
  // payload: string;
}
export type AuthActionTypes = AuthUserAction | AuthClearAction | AuthErrorAction;

export const SUBMISSIONS = 'SUBMISSIONS';
export const SUBMISSIONS_TO_UPLOAD = 'SUBMISSIONS_TO_UPLOAD';
export const SUBMISSIONS_ASKED_TO_OTHERS = 'SUBMISSIONS_ASKED_TO_OTHERS';
export const SUBMISSION_ENHANCED_INFO = 'SUBMISSION_ENHANCED_INFO';
export const SUBMISSION_META = 'SUBMISSION_META';

export const SUBMISSION_UPLOAD_INFO = 'SUBMISSION_UPLOAD_INFO';
// export const SUBMISSION_OPTIONS_UPDATED = 'SUBMISSION_OPTIONS_UPDATED';
export const SUBMISSION_UPDATED_DISFLUENCIES = 'SUBMISSION_DISFLUENCIES_UPDATED';
export const SUBMISSION_UPDATED_DETAILS = 'SUBMISSION_UPDATED_DETAILS';
export const SUBMISSION_DELETED = 'SUBMISSION_DELETED';
// submissions?i.e. submission user has access to
export interface SubmissionsAction {
  type: typeof SUBMISSIONS;
  payload: { submissions: Submission[]; videos: Video[] };
}

// submissions?user=${userId} i.e. submission answered/to be answered by the given user ?
export interface SubmissionsToUploadAction {
  type: typeof SUBMISSIONS_TO_UPLOAD;
  payload: { submissions: Submission[]; videos: Video[] };
}

// submissions?createdby=${userId}   i.e. submission requests created by the given coach ?
export interface SubmissionsAskedToOthersAction {
  type: typeof SUBMISSIONS_ASKED_TO_OTHERS;
  payload: { submissions: Submission[]; videos: Video[] };
}

// submissions/${Id}
export interface SubmissionMetaAction {
  type: typeof SUBMISSION_META;
  payload: { submission: Submission; video: Video };
}

// voice, transcript, faces, etc  jsons for the submission convert to my data format ( Video)
export interface SubmissionEnhancedInfoAction {
  type: typeof SUBMISSION_ENHANCED_INFO;
  payload: { submission: Submission; video: Video };
}
// export interface SubmissionsOptionsUpdateAction {
//   type: typeof SUBMISSION_OPTIONS_UPDATED;
//   payload: { submission: Submission };
// }

export interface SubmissionUploadInfoAction {
  type: typeof SUBMISSION_UPLOAD_INFO;
  payload: { submissionId: number; submissionUploadInfo: SubmissionUploadInfo };
}

export interface SubmissionsDisfluenciesUpdateAction {
  type: typeof SUBMISSION_UPDATED_DISFLUENCIES;
  payload: { disfluencies: string[]; submission: Submission };
}
export interface SubmissionsDetailsUpdateAction {
  type: typeof SUBMISSION_UPDATED_DETAILS;
  payload: { submission: Submission };
}
export interface SubmissionDeletedAction {
  type: typeof SUBMISSION_DELETED;
  payload: { submission: Submission };
}

export type SubmissionsActionTypes =
  | SubmissionsAction
  | SubmissionsToUploadAction
  | SubmissionsAskedToOthersAction
  | SubmissionEnhancedInfoAction
  | SubmissionMetaAction
  // | SubmissionsOptionsUpdateAction
  | SubmissionsDisfluenciesUpdateAction
  | SubmissionUploadInfoAction
  | SubmissionsDetailsUpdateAction
  | SubmissionDeletedAction;

export const SETTINGS_DISFLUENCIES = 'SETTINGS_DISFLUENCIES';
export interface SettingsDisfluenciesAction {
  type: typeof SETTINGS_DISFLUENCIES;
  payload: { disfluencies: SettingsDisfluencies };
}

export const SETTINGS_DATAGROUPS = 'SETTINGS_DATAGROUPS';
export interface SettingsDatagroupsAction {
  type: typeof SETTINGS_DATAGROUPS;
  payload: {
    allFaceCategories: StreamCategories;
    allVoiceCategories: StreamCategories;
  };
}

export const SETTINGS_GLOBAL_RUBRICS = 'GLOBAL_RUBRICS';
export interface GlobalRubricsAction {
  type: typeof SETTINGS_GLOBAL_RUBRICS;
  payload: { rubrics: RubricBase[] };
}

export type SettingsActionTypes = SettingsDisfluenciesAction | SettingsDatagroupsAction | GlobalRubricsAction;

export const OWN_RUBRICS = 'OWN_RUBRICS';
export const RUBRIC_DELETED = 'RUBRIC_DELETED';
export const RUBRIC_UPDATED = 'RUBRIC_UPDATED';
export const RUBRIC_ADDED = 'RUBRIC_ADDED';

export interface RubricsAction {
  type: typeof OWN_RUBRICS;
  payload: { rubrics: Rubric[] };
}
export interface RubricDeletedAction {
  type: typeof RUBRIC_DELETED;
  payload: { rubricId: number };
}
export interface RubricUpdatedAction {
  type: typeof RUBRIC_UPDATED;
  payload: { rubricId: number; rubric: Rubric };
}
export interface RubricAddedAction {
  type: typeof RUBRIC_ADDED;
  payload: { rubricId: number; rubric: Rubric };
}

export type RubricsActionTypes = RubricsAction | RubricDeletedAction | RubricUpdatedAction | RubricAddedAction;

export const SUBMISSION_RUBRICS = 'SUBMISSION_RUBRICS';
export const SUBMISSION_RUBRIC_DELETED = 'SUBMISSION_RUBRIC_DELETED';
export const SUBMISSION_RUBRIC_UPDATED = 'SUBMISSION_RUBRIC_UPDATED';
export const SUBMISSION_RUBRIC_ADDED = 'SUBMISSION_RUBRIC_ADDED';

export interface SubmissionRubricsAction {
  type: typeof SUBMISSION_RUBRICS;
  payload: { videoId: number; submissionRubrics: SubmissionRubric[] };
}
export interface SubmissionRubricDeletedAction {
  type: typeof SUBMISSION_RUBRIC_DELETED;
  payload: { videoId: number; submissionRubricId: number };
}
export interface SubmissionRubricUpdatedAction {
  type: typeof SUBMISSION_RUBRIC_UPDATED;
  payload: { videoId: number; submissionRubricId: number; name: string };
}
export interface SubmissionRubricAddedAction {
  type: typeof SUBMISSION_RUBRIC_ADDED;
  payload: { videoId: number; submissionRubricId: number; submissionRubric: SubmissionRubric };
}

export type SubmissionRubricsActionTypes =
  | SubmissionRubricsAction
  | SubmissionRubricDeletedAction
  | SubmissionRubricUpdatedAction
  | SubmissionRubricAddedAction;

export const SUBMISSION_RUBRIC_EVALUATIONS = 'SUBMISSION_RUBRIC_EVALUATIONS';
export const SUBMISSION_RUBRIC_EVALUATION_DELETED = 'SUBMISSION_RUBRIC_EVALUATION_DELETED';
export const SUBMISSION_RUBRIC_EVALUATION_UPDATED = 'SUBMISSION_RUBRIC_EVALUATION_UPDATED';
export const SUBMISSION_RUBRIC_EVALUATION_ADDED = 'SUBMISSION_RUBRIC_EVALUATION_ADDED';

export interface Evaluation {
  id?: number; //no id when creating evaluation
  evaluation: EvaluationResponse;
  state: string;
  createdBy?: SUser; //not needed when creating evaluation
}

export interface SubmissionRubricEvaluationsAction {
  type: typeof SUBMISSION_RUBRIC_EVALUATIONS;
  payload: {
    videoId: number;
    submissionRubricId: number;
    submissionRubricEvaluations: Evaluation[];
  };
}
export interface SubmissionRubricEvaluationDeletedAction {
  type: typeof SUBMISSION_RUBRIC_EVALUATION_DELETED;
  payload: { evaluationId: number; submissionRubricId: number; videoId: number; evaluationResponse: EvaluationResponse };
}
export interface SubmissionRubricEvaluationUpdatedAction {
  type: typeof SUBMISSION_RUBRIC_EVALUATION_UPDATED;
  payload: { submissionRubricId: number; evaluation: Evaluation; videoId: number };
}
export interface SubmissionRubricEvaluationAddedAction {
  type: typeof SUBMISSION_RUBRIC_EVALUATION_ADDED;
  payload: { submissionRubricId: number; evaluation: Evaluation; videoId: number };
}

export type SubmissionRubricEvaluationsActionTypes =
  | SubmissionRubricEvaluationsAction
  | SubmissionRubricEvaluationDeletedAction
  | SubmissionRubricEvaluationUpdatedAction
  | SubmissionRubricEvaluationAddedAction;

export const SUBMISSION_SHARE_DELETED = 'SUBMISSION_SHARE_DELETED';
export const SUBMISSION_SHARE_ADDED = 'SUBMISSION_SHARE_ADDED';
export interface SubmissionShareDeletedAction {
  type: typeof SUBMISSION_SHARE_DELETED;
  payload: { videoId: number; shareId: number; updatedSubmissionInfo: VarSubmissionInfo };//FFS study refresh submission info
}
export interface SubmissionShareAddedAction {
  type: typeof SUBMISSION_SHARE_ADDED;
  payload: { videoId: number; shareId: number; share: ShareBase; updatedSubmissionInfo: VarSubmissionInfo };//FFS study refresh submission info
}

export type SubmissionShareActionTypes = SubmissionShareDeletedAction | SubmissionShareAddedAction;

export const OWN_FOLDERS = 'OWN_FOLDERS';
export const FOLDER_DELETED = 'FOLDER_DELETED';
export const FOLDER_UPDATED = 'FOLDER_UPDATED';
export const FOLDER_ADDED = 'FOLDER_ADDED';

export interface FoldersAction {
  type: typeof OWN_FOLDERS;
  payload: { folders: Folder[] };
}
export interface FolderDeletedAction {
  type: typeof FOLDER_DELETED;
  payload: { folderId: number; folder: Folder };
}
export interface FolderUpdatedAction {
  type: typeof FOLDER_UPDATED;
  payload: { folderId: number; folder: Folder };
}
export interface FolderAddedAction {
  type: typeof FOLDER_ADDED;
  payload: { folderId: number; folder: Folder };
}

export type FoldersActionTypes = FoldersAction | FolderDeletedAction | FolderUpdatedAction | FolderAddedAction;

export const ALLFOLDERS_SUBMISSIONS = 'ALLFOLDER_SUBMISSIONS';
export const FOLDER_SUBMISSION_DELETED = 'FOLDER_SUBMISSION_DELETED';
export const FOLDER_SUBMISSION_ADDED = 'FOLDER_SUBMISSION_ADDED';

export interface FoldersSubmissionsAction {
  type: typeof ALLFOLDERS_SUBMISSIONS;
  payload: { foldersSubmissions: FolderSubmission[] };
}
export interface FolderSubmissionAddedAction {
  type: typeof FOLDER_SUBMISSION_ADDED;
  payload: { folderSubmission: FolderSubmission };
}
export interface FolderSubmissionDeletedAction {
  type: typeof FOLDER_SUBMISSION_DELETED;
  payload: { folderSubmission: FolderSubmission };
}

export type FolderSubmissionActionTypes = FoldersSubmissionsAction | FolderSubmissionAddedAction | FolderSubmissionDeletedAction;
