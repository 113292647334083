import { IVideoPlayer } from '../../demo/components/video/VideoRenderer';
import { events, TIME_WINDOW_SELECTED } from '../../demo/event/CrossEvents';
import { TimeWindow } from '../../demo/model/Video';
import {
  CLEAR_ALERT,
  CONFIG_UPDATED_DURATION_THRESHOLD,
  SET_ALERT,
  UPDATE_GRAPHICS_SIDE_WIDTH,
  UPDATE_LEGEND_HEIGHT,
  UPDATE_PLAYER_HEIGHT as PLAYER_HEIGHT_UPDATED,
  UPDATE_WINDOW_HEIGHT as WINDOW_HEIGHT_UPDATED,
} from '../types';
export const playerHeightUpdated = (playerHeight) => async (dispatch) => {
  console.log('playerHeightUpdated: ', playerHeight);
  dispatch({
    type: PLAYER_HEIGHT_UPDATED,
    payload: {
      playerHeight,
    },
  });
  return Promise.resolve();
};
export const windowHeightUpdated = (windowHeight) => async (dispatch) => {
  console.log('windowHeightUpdated: ', windowHeight);
  dispatch({
    type: WINDOW_HEIGHT_UPDATED,
    payload: {
      windowHeight: windowHeight,
    },
  });
  return Promise.resolve();
};
export const legendHeightUpdated = (height) => async (dispatch) => {
  console.log('legendHeightUpdated: ', height);
  dispatch({
    type: UPDATE_LEGEND_HEIGHT,
    payload: {
      height: height,
    },
  });
  return Promise.resolve();
};
export const graphicsSideWidthUpdated = (width) => async (dispatch) => {
  console.log('graphicsSideWidthUpdated: ', width);
  dispatch({
    type: UPDATE_GRAPHICS_SIDE_WIDTH,
    payload: {
      width: width,
    },
  });
  return Promise.resolve();
};

export const durationThresholdUpdated = (durationThreshold) => async (dispatch) => {
  console.log('durationThresholdUpdated: ', durationThreshold);
  dispatch({
    type: CONFIG_UPDATED_DURATION_THRESHOLD,
    payload: {
      durationThreshold,
    },
  });
  return Promise.resolve();
};

export const setGlobalAlert = (title: string) => async (dispatch) => {
  console.log('setGlobalAlert: ', title);
  dispatch({
    type: SET_ALERT,
    payload: {
      title,
    },
  });
  return Promise.resolve();
};
export const clearGlobalAlert = () => async (dispatch) => {
  console.log('clearGlobalAlert: ');
  dispatch({
    type: CLEAR_ALERT,
    payload: {},
  });
  return Promise.resolve();
};

// inform Graphs/etc about the new time origin and span, optionally seek the player at start time
export const seekTimeWindow = (timeWindow: TimeWindow, theVideoPlayer?: IVideoPlayer) => {
  console.log('seeking time window ', timeWindow.startTime, timeWindow.endTime);
  const { startTime, endTime } = timeWindow;
  if (theVideoPlayer) {
    theVideoPlayer.seek(Math.min(startTime, endTime));
  }
  events.emit({
    type: TIME_WINDOW_SELECTED,
    payload: { timeWindow: { startTime, endTime } },
  });
};
