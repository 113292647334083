import { ISelectableItem } from '../../components/shared/BorderedSelector';
import { StreamCategories } from '../../model/ApiTypes';
import { StreamRef } from '../../model/Video';

export const VOICE_THRESHOLD = 'VOICE_THRESHOLD';
export const FACE_THRESHOLD = 'FACE_THRESHOLD';
export const FACE_STREAMS_SELECTED = 'FACE_STREAMS_SELECTED';
export const VOICE_STREAMS_SELECTED = 'VOICE_STREAMS_SELECTED';

export const FACEANALYSIS_PERSONS_SELECTED = 'FACEANALYSIS_PERSONS_SELECTED';

export const areTheSame = (sel1: StreamRef[], sel2: StreamRef[]) => {
  if ((sel1 && !sel2) || (sel2 && !sel1)) return false;
  if (sel1 && sel2) {
    if (sel1.length !== sel2.length) return false;
    for (let i = 0; i < sel1.length; i++) {
      if (!sel2.some((streamRef2) => streamRef2.stream === sel1[i].stream && streamRef2.analysisTag === sel1[i].analysisTag)) return false;
    }
  }
  return true;
};

export interface VoiceThresholdAction {
  type: typeof VOICE_THRESHOLD;
  payload: { threshold: number };
}

export interface FaceThresholdAction {
  type: typeof FACE_THRESHOLD;
  payload: { threshold: number };
}

export interface VoiceStreamsSelectedAction {
  type: typeof VOICE_STREAMS_SELECTED;
  payload: {
    selectedStreamsVoice: StreamRef[];
    selectedFakeStreams: string[];
  };
}
export interface FaceStreamsSelectedAction {
  type: typeof FACE_STREAMS_SELECTED;
  payload: {
    selectedStreamsFace: StreamRef[];
    selectedFakeStreams: string[];
  };
}

export const FACE_CATEGORIES_AVAILABLE = 'FACE_CATEGORIES_AVAILABLE';
export const VOICE_CATEGORIES_AVAILABLE = 'VOICE_CATEGORIES_AVAILABLE';

export interface VoiceCategoriesAvailableAction {
  type: typeof VOICE_CATEGORIES_AVAILABLE;
  payload: {
    availableVoiceCategories: StreamCategories;
  };
}
export interface FaceCategoriesAvailableAction {
  type: typeof FACE_CATEGORIES_AVAILABLE;
  payload: {
    availableFaceCategories: StreamCategories;
  };
}
export type StreamCategoriesActionTypes = VoiceCategoriesAvailableAction | FaceCategoriesAvailableAction;

export const FACE_ANALYSIS_OPTIONS_DRAWER = 'FACE_ANALYSIS_OPTIONS_DRAWER';
export const VOICE_ANALYSIS_OPTIONS_DRAWER = 'VOICE_ANALYSIS_OPTIONS_DRAWER';
export const REPORT_DRAWER = 'REPORT_DRAWER';
export const REPORT_PDF = 'REPORT_PDF';

export interface PdfReportAction {
  type: typeof REPORT_PDF;
  payload: {
    pdfDoc: any;
  };
}

export interface ReportDrawerAction {
  type: typeof REPORT_DRAWER;
  payload: {
    reportDrawerOpened: boolean;
  };
}

export interface VoiceAnalysisOptionsDrawerAction {
  type: typeof VOICE_ANALYSIS_OPTIONS_DRAWER;
  payload: {
    voiceAnalysisOptionsDrawerOpened: boolean;
  };
}
export interface FaceAnalysisOptionsDrawerAction {
  type: typeof FACE_ANALYSIS_OPTIONS_DRAWER;
  payload: {
    faceAnalysisOptionsDrawerOpened: boolean;
  };
}
export type AnalysisOptionsDrawerActionTypes = VoiceAnalysisOptionsDrawerAction | FaceAnalysisOptionsDrawerAction;

export interface FaceAnalysisPersonsSelectedAction {
  type: typeof FACEANALYSIS_PERSONS_SELECTED;
  payload: {
    selectedPersons: string[];
  };
}

export type SelectionsActionTypes =
  | VoiceThresholdAction
  | FaceThresholdAction
  | VoiceStreamsSelectedAction
  | FaceStreamsSelectedAction
  | StreamCategoriesActionTypes
  | AnalysisOptionsDrawerActionTypes
  | ReportDrawerAction|PdfReportAction
  | FaceAnalysisPersonsSelectedAction
  | TabsActionTypes
  | CardsFilteringAndSortingAction;

// export type SubmissionsActionTypes =
//   | SubmissionsToUploadAction
//   | SubmissionsAskedToOthersAction
//   | SubmissionEnhancedInfoAction
//   | SubmissionMetaAction;

export const SELECTED_GRAPHICS_SIDE_TAB = 'SELECTED_GRAPHICS_SIDE_TAB';
export const SELECTED_TEXT_SIDE_TAB = 'SELECTED_TEXT_SIDE_TAB';
export const SELECTED_LEGEND_TAB = 'SELECTED_LEGEND_TAB';
export const SELECTED_CHARTS_TAB = 'SELECTED_CHARTS_TAB';

export interface GraphicsSideTabsAction {
  type: typeof SELECTED_GRAPHICS_SIDE_TAB;
  payload: {
    selectedTabIdx: number;
  };
}
export interface TextSideTabsAction {
  type: typeof SELECTED_TEXT_SIDE_TAB;
  payload: {
    selectedTabIdx: number;
  };
}
export interface LegendTabsAction {
  type: typeof SELECTED_LEGEND_TAB;
  payload: {
    selectedTabIdx: number;
  };
}

export interface ChartsTabsAction {
  type: typeof SELECTED_CHARTS_TAB;
  payload: {
    selectedTabIdx: number;
  };
}

export const IMAGE_4_REPORT_ADDED = 'IMAGE_4_REPORT';
export interface Image4ReportAction {
  type: typeof IMAGE_4_REPORT_ADDED;
  payload: {
    name: string;
    url: string;
  };
}

export const SELECTED_CARDS_FILTERING_SORTING = 'SELECTED_CARDS_FILTERING_SORTING';
export interface CardsFilteringAndSortingAction {
  type: typeof SELECTED_CARDS_FILTERING_SORTING;
  payload: {
    filtersState: { State: string[]; Coaches: string[]; Presenter: string[] };
    sortByItems: ISelectableItem[];
  };
}

export type TabsActionTypes = GraphicsSideTabsAction | TextSideTabsAction | LegendTabsAction | ChartsTabsAction | Image4ReportAction;
