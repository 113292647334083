import { Button, IconButton, TextField } from '@material-ui/core';
import React from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import { connect } from 'react-redux';
import sprites from '../../../../icons/fig-icons.svg';
import { IRootState } from '../../../shared/reducers';
import { noTagCreation, tagCreationStarted } from '../../actions/videoActions';
import { Coords } from '../../model/Video';
import { DRAFT_TAG_COORDS_NONE } from '../../reducers/videoReducer';
import { IVideoPlayer } from '../video/VideoRenderer';

interface IProps extends StateProps, DispatchProps {
  handleFormSubmit: (form: IAddTagState) => void;
  theVideoPlayer: IVideoPlayer;
}

export interface IAddTagState {
  name: string;
  nameRequired: boolean;
  tagTime: number;
  coords: Coords;
}

class AddTag extends React.Component<IProps, IAddTagState> {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      nameRequired: false,
      tagTime: 0,
      coords: null,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    // return shallowCompare(this, nextProps, nextState);
    const nextPropsExceptCurentTime: IProps = { ...nextProps, currentTime: this.props.currentTime };
    if (shallowCompare(this, nextPropsExceptCurentTime, nextState)) return true;
    return false;
  }

  componentWillUnmount() {
    this.tagPositionEnd(null);
  }
  handleNameChange = (event) => {
    this.setState({ name: event.target.value, nameRequired: true });
  };

  onTagPosition = (event) => {
    //console.log('player', this.props.theVideoPlayer);
    const hasStarted = (this.props.theVideoPlayer as any).hasStarted();
    if (!hasStarted) {
      // let oldMuted = (this.props.theVideoPlayer as any).muted
      // (this.props.theVideoPlayer as any).muted = true;
      this.props.theVideoPlayer.play();
    }
    this.props.tagCreationStarted(this.props.selectedVideoId, this.state.name);
    // (this.props.theVideoPlayer as any).muted = oldMuted;
    if (!hasStarted) this.props.theVideoPlayer.pause();
  };
  tagPositionEnd = (event) => {
    this.props.noTagCreation();
  };

  onFormSubmit = (event) => {
    event.preventDefault();
    // console.log('onFormSubmit', this.state);
    if (this.state.name.length === 0) {
      this.setState({ nameRequired: true });
      return;
    }
    this.props.handleFormSubmit({ ...this.state, coords: { ...this.props.currentTagCreationStatus.draftTagCoords } });
    this.resetState();
  };

  resetState = () => {
    this.setState({
      name: '',
      nameRequired: false,
    });
    this.tagPositionEnd(null);
  };

  render() {
    //  console.log('render add tag');
    return (
      <div className="row " onSubmit={this.onFormSubmit}>
        <TextField
          className="col-8 margin-top--20"
          InputLabelProps={{
            shrink: true,
            required: false,
          }}
          placeholder={'Enter Speaker Name'}
          value={this.state.name}
          onChange={this.handleNameChange}
          required
          error={this.state.name.length === 0 && this.state.nameRequired}
          disabled={this.props.currentTagCreationStatus !== null}
        />
        <div className="col-4">
          {this.state.name &&
          this.state.name.length > 0 &&
          // (!this.props.currentTagCreationStatus /*|| this.props.currentTagCreationStatus.draftTagCoords !== DRAFT_TAG_COORDS_NONE*/) ? (
          true ? (
            <Button
              size="small"
              onClick={this.props.currentTagCreationStatus ? this.onFormSubmit : this.onTagPosition}
              className="col-8 text-lowercase text-capitalize fig-button"
              disabled={
                !(this.state.name && this.state.name.length > 0) ||
                (this.props.currentTagCreationStatus && this.props.currentTagCreationStatus.draftTagCoords === DRAFT_TAG_COORDS_NONE)
              }
            >
              {!this.props.currentTagCreationStatus ? `Tag speaker's face ` : `Create Face Tag`}
            </Button>
          ) : null}
          {this.props.currentTagCreationStatus ? (
            <IconButton
              aria-label="delete"
              className="fig-button fig-delete-rubric col-4 "
              size="small"
              onClick={this.resetState}
              style={{
                margin: 10,
              }}
            >
              <svg className="fig-icon-delete-rubric-row">
                <use href={sprites + '#delete-icon'} />
              </svg>
            </IconButton>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => {
  return {
    currentTime: state.video.currentTime,
    currentTagCreationStatus: state.video.currentTagCreationStatus,
    selectedVideoId: state.video.currentVideoId,
  };
};
const mapDispatchToProps = { noTagCreation, tagCreationStarted };
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AddTag);
