import { Icon, IconButton, ListItem, Menu, MenuItem } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import IconZoomIn from '@material-ui/icons/ZoomIn';
import IconZoomOut from '@material-ui/icons/ZoomOut';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  BigPlayButton,
  ControlBar,
  CurrentTimeDisplay,
  ForwardControl,
  PlaybackRateMenuButton,
  Player,
  ReplayControl,
  Shortcut,
  TimeDivider,
  VolumeMenuButton
} from 'video-react';
import 'video-react/dist/video-react.css';
import { durationThresholdUpdated, playerHeightUpdated } from '../../../actions/utils';
import { IRootState } from '../../../shared/reducers';
import { addImage } from '../../actions/selectionActions';
import { annotationDrafted, durationComputed, tagCreationDrafted, tagDrafted, timeReached, videoPaused } from '../../actions/videoActions';
import config, { ENV_VALUE_ENABLED } from '../../config/constants';
import { AnalysisItemStatus } from '../../model/ApiTypes';
import {
  Annotation,
  Comment,
  Comments,
  Coords,
  insideCoords,
  onBottomBorder,
  onLeftBorder,
  onRightBorder,
  onTopBorder,
  pan,
  resizeBottom,
  resizeLeft,
  resizeRight,
  resizeTop,
  toStringId
} from '../../model/Video';
import { ANNOTATION_COORD_NONE, DURATION_UNKNOWN } from '../../reducers/videoReducer';
import TabsComponent from '../tabs/TabsComponent';
import AnnotationRenderer from './AnnotationRenderer';
// import DownloadButton from './DownloadButton';
import FacetagRenderer from './FacetagRenderer';

type IProps = PropsFromRedux & {
  ref: React.RefObject<any>;
  hidden: boolean; //TODO FFS used for hiding controls when GraphOptions should cover the entire right side
};

export interface IVideoPlayer {
  seek(seconds: number, cb?: any): void;
  pause(): void;
  getBoundingClientRect(): any;
  play(): void;
  hasStarted(): boolean;
  getCrtImage(): any;
}

interface IState {
  tagCoords: Coords;
  isPanning: boolean;
  isLeftResizing: boolean;
  isRightResizing: boolean;
  isBottomResizing: boolean;
  isTopResizing: boolean;

  mightPan: boolean;
  mightLeftResize: boolean;
  mightRightResize: boolean;
  mightBottomResize: boolean;
  mightTopResize: boolean;

  videoWidthLimit: number;
  contextMenuX: number;
  contextMenuY: number;

  withAnnotateOverlay: boolean;
  withFaceTaggingOverlay: boolean;
  hasStarted: boolean;

  internalVideoWidth: number;
  internalVideoHeight: number;
  seekedCallback: any;
}

// https://video-react.js.org/

// renders the video which is current in ReduxState,
// and updates crtTime in ReduxState while playing
// updates videoDuration in ReduxState
class VideoRenderer extends React.PureComponent<IProps, IState> implements IVideoPlayer {
  player: any = null;
  annotationsContainer: any = null;
  controlBar: any = null;

  constructor(props) {
    super(props);
    this.state = {
      // playerState: null,
      tagCoords: null,
      isPanning: false,
      isLeftResizing: false,
      isRightResizing: false,
      isBottomResizing: false,
      isTopResizing: false,

      mightPan: false,
      mightLeftResize: false,
      mightRightResize: false,
      mightBottomResize: false,
      mightTopResize: false,

      videoWidthLimit: 100,
      contextMenuX: null,
      contextMenuY: null,
      withAnnotateOverlay: false,
      withFaceTaggingOverlay: false,

      hasStarted: false,
      internalVideoHeight: 0,
      internalVideoWidth: 0,
      seekedCallback: null,
    };
  }

  getCrtImage = () => {
    //console.log('getCrtImage called, props: ', this.props);
    let canvasEl = document.createElement('canvas');
    canvasEl.width = this.state.internalVideoWidth;
    canvasEl.height = this.state.internalVideoHeight;
    canvasEl.id = 'canvas4report';
    let ctx1 = canvasEl.getContext('2d');
    ctx1.drawImage(this.player.video.video, 0, 0, canvasEl.width, canvasEl.height);

    let rez = canvasEl.toDataURL('image/png', 1.0);
    canvasEl.remove();
    return rez;
  };
  getBoundingClientRect = () => {
    if (this.annotationsContainer !== null) {
      console.log('REAL annotationsContainer getBoundingClientRect');
      return this.annotationsContainer.getBoundingClientRect();
    }
    return null;
  };
  seek = (seconds, cb?) => {
    // console.log('got command to seek to: ', seconds);
    if (cb) {
      this.setState({ seekedCallback: cb }, () => {
        this.player !== null && this.player.seek(seconds);
      });
    } else {
      if (this.player !== null) this.player.seek(seconds);
    }
  };
  pause = () => {
    this.player !== null && this.player.pause();
  };
  play = () => {
    this.player !== null && this.player.play();
  };
  hasStarted = () => {
    //console.log('VideoRenderer player hase started ? player ', this.player, this.player.state);
    return this.player !== null && this.state.hasStarted;
  };
  onContextMenu = (event) => {
    event.preventDefault();
    if (isEventFromVideoElement(event)) {
      this.setState({ contextMenuX: event.clientX - 2, contextMenuY: event.clientY - 2 });
    }
  };

  onClick = (e: React.MouseEvent) => {
    const rel = relativeCoords(e);
    //console.log('click ', e, e.target);
    if (
      this.props.currentAnnotatingStatus &&
      this.props.currentAnnotatingStatus.video &&
      this.props.currentAnnotatingStatus.video.id === this.props.selectedVideoIdString
    ) {
      if (this.state.withAnnotateOverlay) {
        this.setState({ withAnnotateOverlay: false });
      }
      this.props.annotationDrafted(rel.x, rel.y);
    }
    if (this.state.withFaceTaggingOverlay) {
      this.setState({ withFaceTaggingOverlay: false });
    }
  };

  onDoubleClick = (e) => {
    //    console.log('dblclick ', e, e.target);
    if (this.state.withFaceTaggingOverlay) {
      this.setState({ withFaceTaggingOverlay: false });
    }
    if (
      this.props.currentAnnotatingStatus &&
      this.props.currentAnnotatingStatus.video &&
      this.props.currentAnnotatingStatus.video.id === this.props.selectedVideoIdString
    ) {
      if (this.state.withAnnotateOverlay) {
        this.setState({ withAnnotateOverlay: false });
      }
      this.props.annotationDrafted(ANNOTATION_COORD_NONE, ANNOTATION_COORD_NONE);
    }
    if (this.state.withFaceTaggingOverlay) {
      this.setState({ withFaceTaggingOverlay: false });
    }
  };

  onMouseDown = (e) => {
    //console.log('mousedown ', e.target.nodeName, e, e.target /*, this.relativeCoords(e)*/);
    const rel = relativeCoords(e);
    if (this.state.withFaceTaggingOverlay) {
      this.setState({ withFaceTaggingOverlay: false });
    }
    // if (this.props.selectedVideo && this.props.selectedVideo.metaFaceAnalysesMap) {
    //   containingTag = Object.values(this.props.selectedVideo.metaFaceAnalysesMap).find(
    //     (tag) => tag.tag && insideCoords(rel.x, rel.y, tag.tag)
    //   );
    //   if (containingTag) console.log(`${rel.x} ${rel.y} inside ${containingTag.name} `, containingTag.tag);
    // }

    if (
      (isEventFromVideoElement(e) || /*isEventFromBigPlayButtonElement ||*/ isEventFromFaceTaggingOverlayElement(e)) &&
      ((this.props.currentTaggingStatus &&
        this.props.currentTaggingStatus.video &&
        this.props.currentTaggingStatus.video.id === this.props.selectedVideoIdString) ||
        (this.props.currentTagCreationStatus &&
          this.props.currentTagCreationStatus.video &&
          this.props.currentTagCreationStatus.video.id === this.props.selectedVideoIdString))
    ) {
      let isPanning = false,
        isLeftResizing = false,
        isRightResizing = false,
        isTopResizing = false,
        isBottomResizing = false;

      isPanning =
        this.props.currentTagCreationStatus.draftTagCoords &&
        insideCoords(rel.x, rel.y, this.props.currentTagCreationStatus.draftTagCoords);

      isLeftResizing =
        this.props.currentTagCreationStatus.draftTagCoords &&
        onLeftBorder(rel.x, rel.y, this.props.currentTagCreationStatus.draftTagCoords);
      isRightResizing =
        this.props.currentTagCreationStatus.draftTagCoords &&
        onRightBorder(rel.x, rel.y, this.props.currentTagCreationStatus.draftTagCoords);
      isBottomResizing =
        this.props.currentTagCreationStatus.draftTagCoords &&
        onBottomBorder(rel.x, rel.y, this.props.currentTagCreationStatus.draftTagCoords);
      isTopResizing =
        this.props.currentTagCreationStatus.draftTagCoords && onTopBorder(rel.x, rel.y, this.props.currentTagCreationStatus.draftTagCoords);

      this.setState({
        tagCoords: { x1: rel.x, y1: rel.y, x2: rel.x, y2: rel.y },
        isPanning,
        isLeftResizing,
        isRightResizing,
        isBottomResizing,
        isTopResizing,

        mightBottomResize: false,
        mightLeftResize: false,
        mightPan: false,
        mightRightResize: false,
        mightTopResize: false,
      });
    }
  };
  onMouseMove = (e) => {
    //console.log('mousemove ', e.target.nodeName, e, e.target);
    const rel = relativeCoords(e);
    if (
      isEventFromVideoElement(e) /*|| isEventFromBigPlayButtonElement(e)*/ &&
      ((this.props.currentTaggingStatus &&
        this.props.currentTaggingStatus.video &&
        this.props.currentTaggingStatus.video.id === this.props.selectedVideoIdString) ||
        (this.props.currentTagCreationStatus &&
          this.props.currentTagCreationStatus.video &&
          this.props.currentTagCreationStatus.video.id === this.props.selectedVideoIdString))
    ) {
      if (this.state.tagCoords) {
        this.setState((state) => {
          return { tagCoords: { x1: state.tagCoords.x1, y1: state.tagCoords.y1, x2: rel.x, y2: rel.y } };
        });
      } else if (this.props.currentTagCreationStatus.draftTagCoords) {
        let isPanning = false,
          isLeftResizing = false,
          isRightResizing = false,
          isTopResizing = false,
          isBottomResizing = false;

        isPanning = insideCoords(rel.x, rel.y, this.props.currentTagCreationStatus.draftTagCoords);

        isLeftResizing = onLeftBorder(rel.x, rel.y, this.props.currentTagCreationStatus.draftTagCoords);
        isRightResizing = onRightBorder(rel.x, rel.y, this.props.currentTagCreationStatus.draftTagCoords);
        isBottomResizing = onBottomBorder(rel.x, rel.y, this.props.currentTagCreationStatus.draftTagCoords);
        isTopResizing = onTopBorder(rel.x, rel.y, this.props.currentTagCreationStatus.draftTagCoords);
        this.setState((state) => {
          return {
            mightPan: isPanning,
            mightLeftResize: isLeftResizing,
            mightRightResize: isRightResizing,
            mightBottomResize: isBottomResizing,
            mightTopResize: isTopResizing,
          };
        });
      }
    }
  };

  onMouseUp = (e) => {
    //console.log('mouseup ', e, e.target);
    const rel = relativeCoords(e);
    if (this.state.withFaceTaggingOverlay) {
      this.setState({ withFaceTaggingOverlay: false });
    }
    if (
      isEventFromVideoElement(e) /*|| isEventFromBigPlayButtonElement(e)*/ &&
      this.props.currentTaggingStatus &&
      this.props.currentTaggingStatus.video &&
      this.props.currentTaggingStatus.video.id === this.props.selectedVideoIdString &&
      this.state.tagCoords
    ) {
      this.props.tagDrafted(this.state.tagCoords.x1, this.state.tagCoords.y1, rel.x, rel.y);
      this.setState((state, props) => {
        return {
          tagCoords: null,
          isPanning: false,
          isBottomResizing: false,
          isLeftResizing: false,
          isRightResizing: false,
          isTopResizing: false,
        };
      });
    } else if (
      isEventFromVideoElement(e) /*|| isEventFromBigPlayButtonElement(e)*/ &&
      this.props.currentTagCreationStatus &&
      this.props.currentTagCreationStatus.video &&
      this.props.currentTagCreationStatus.video.id === this.props.selectedVideoIdString &&
      this.state.tagCoords
    ) {
      let newCoords = null;
      if (this.state.isPanning) {
        //panning mode
        //console.log('controlBar clientHeight:', this.controlBar, this.controlBar.button.offsetHeigh);
        //console.log('player videoHeight:', this.player, this.player.video.video.clientHeight);
        newCoords = pan(
          this.props.currentTagCreationStatus.draftTagCoords,
          { ...this.state.tagCoords, x2: rel.x, y2: rel.y },
          (100 * this.controlBar ? this.controlBar.button.offsetHeight : 0.1) / this.player.video.video.clientHeight
        );
      } else if (this.state.isBottomResizing) {
        newCoords = resizeBottom(this.props.currentTagCreationStatus.draftTagCoords, {
          ...this.state.tagCoords,
          x2: rel.x,
          y2: rel.y,
        });
      } else if (this.state.isTopResizing) {
        newCoords = resizeTop(this.props.currentTagCreationStatus.draftTagCoords, {
          ...this.state.tagCoords,
          x2: rel.x,
          y2: rel.y,
        });
      } else if (this.state.isLeftResizing) {
        newCoords = resizeLeft(this.props.currentTagCreationStatus.draftTagCoords, {
          ...this.state.tagCoords,
          x2: rel.x,
          y2: rel.y,
        });
      } else if (this.state.isRightResizing) {
        newCoords = resizeRight(this.props.currentTagCreationStatus.draftTagCoords, {
          ...this.state.tagCoords,
          x2: rel.x,
          y2: rel.y,
        });
      } else {
        //drawing mode
        newCoords = this.state.tagCoords;
      }
      this.props.tagCreationDrafted(newCoords.x1, newCoords.y1, newCoords.x2, newCoords.y2);
      this.setState((state, props) => {
        return {
          tagCoords: null,
          isPanning: false,
          isBottomResizing: false,
          isLeftResizing: false,
          isRightResizing: false,
          isTopResizing: false,
        };
      });
    } else if (this.props.currentTagCreationStatus && this.props.currentTagCreationStatus.draftTagCoords) {
      //if mouseUp on ctrlbar
      let { x1, x2, y1, y2 } = this.props.currentTagCreationStatus.draftTagCoords;
      this.props.tagCreationDrafted(x1, y1, x2, y2);
      this.setState((state, props) => {
        return {
          tagCoords: null,
          isPanning: false,
          isBottomResizing: false,
          isLeftResizing: false,
          isRightResizing: false,
          isTopResizing: false,
        };
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.videoWidthLimit !== prevState.videoWidthLimit) {
      console.log('VideoRenderer did update width');
      const playerRelCoords = this.getBoundingClientRect();
      if (playerRelCoords) {
        console.log('after videoWidthLimit updated,  videoComponentCoords/height: ', playerRelCoords, playerRelCoords.height);
        this.props.playerHeightUpdated(playerRelCoords.height);
      }
    }
    if (
      (this.props.currentAnnotatingStatus && !prevProps.currentAnnotatingStatus) ||
      (this.props.currentAnnotatingStatus && this.props.currentAnnotatingStatus.comment.id !== prevProps.currentAnnotatingStatus.comment.id)
    ) {
      if (
        this.props.currentAnnotatingStatus &&
        this.props.currentAnnotatingStatus.video &&
        this.props.currentAnnotatingStatus.video.id === this.props.selectedVideoIdString
      ) {
        this.setState({ withAnnotateOverlay: true });
      }
    }
    /*else*/ if (this.props.currentTagCreationStatus && !prevProps.currentTagCreationStatus) {
      if (
        this.props.currentTagCreationStatus &&
        this.props.currentTagCreationStatus.video &&
        this.props.currentTagCreationStatus.video.id === this.props.selectedVideoIdString
      ) {
        this.setState({ withFaceTaggingOverlay: true });
      }
    } else if (!this.props.currentTagCreationStatus && prevProps.currentTagCreationStatus) {
      this.setState({
        isBottomResizing: false,
        isLeftResizing: false,
        isPanning: false,
        isRightResizing: false,
        isTopResizing: false,
        mightBottomResize: false,
        mightLeftResize: false,
        mightPan: false,
        mightRightResize: false,
        mightTopResize: false,
        withFaceTaggingOverlay: false,
      });
    }
  }
  componentDidMount() {
    // eslint-disable-next-line no-console
    // console.log('Avideo mounted ', this, ' player: ', this.player);
    // we need to see the state of the player - i.e. currentTime, etc
    this.player !== null && this.player.subscribeToStateChange(this.handlePlayerStateChange);
  }

  // TODO how to unsubscribe ?

  // called by the internal player when its state changed; params have nothing todo with our this.state or our redux.state
  handlePlayerStateChange = (internalPlayerState, prevInternalPlayerState) => {
    // eslint-disable-next-line no-console
    // console.log(
    //   'statechange, with prevInternalPlayerState.duration: ',
    //   prevInternalPlayerState.duration,
    //   ' newDuration',
    //   internalPlayerState.duration
    // );

    console.log('statechange, with prevInternalPlayerState ', prevInternalPlayerState, ' new', internalPlayerState);
    // console.log('statechange, currenttime: ', state.currentTime, ' duration', state.duration);
    // copy player state to this component's state, just in case we need it
    // this.setState({
    //   playerState: internalPlayerState,
    // });
    if (prevInternalPlayerState.paused !== internalPlayerState.paused) {
      console.log(' player paused changed to : ' + internalPlayerState.paused);
      // update video time in Redux state
      this.props.videoPaused(internalPlayerState.paused);
    }
    if (prevInternalPlayerState.currentTime !== internalPlayerState.currentTime) {
      // eslint-disable-next-line no-console
      console.log(' currenttime: ' + internalPlayerState.currentTime);
      // update video time in Redux state
      this.props.timeReached(internalPlayerState.currentTime);
    }
    if (
      (this.props.videoDuration === DURATION_UNKNOWN || prevInternalPlayerState.duration !== internalPlayerState.duration) &&
      internalPlayerState.duration >= 0
    ) {
      console.log('statechange, announcing DURATION***');
      // eslint-disable-next-line no-console
      // console.log(prevState.duration + ' => ' + state.duration);
      // update video duration in Redux state
      this.props.durationComputed(internalPlayerState.duration);
    }
    this.setState({
      hasStarted: internalPlayerState.hasStarted,
      internalVideoHeight: internalPlayerState.videoHeight,
      internalVideoWidth: internalPlayerState.videoWidth,
    });
  };
  render() {
    const start = new Date().getTime();
    console.log('VideoRenderer render at ', start);
    let annotationsContainerWidth = this.annotationsContainer ? this.annotationsContainer.getBoundingClientRect().width : 0;
    let rez = (
      <div className="fig-video-details-right-side__video">
        <div style={{ maxWidth: `${this.state.videoWidthLimit}%`, margin: 'auto' }}>
          <div
            style={{
              position: 'relative',
              zIndex: config.video.videoContainerZIndex,
              cursor:
                this.state.mightPan || this.state.isPanning
                  ? 'move'
                  : this.state.mightBottomResize || this.state.isBottomResizing
                  ? 's-resize '
                  : this.state.mightTopResize || this.state.isTopResizing
                  ? 'n-resize '
                  : this.state.mightLeftResize || this.state.isLeftResizing
                  ? 'w-resize '
                  : this.state.mightRightResize || this.state.isRightResizing
                  ? 'e-resize '
                  : this.props.currentAnnotatingStatus || this.props.currentTaggingStatus || this.props.currentTagCreationStatus
                  ? 'crosshair'
                  : 'default',
            }}
            ref={(internalComp) => {
              this.annotationsContainer = internalComp;
            }}
            id="CONTAINER"
            onClick={this.onClick}
            onDoubleClick={this.onDoubleClick}
            onMouseDown={this.onMouseDown}
            onMouseUp={this.onMouseUp}
            onMouseMove={this.onMouseMove}
            onContextMenu={this.onContextMenu}
          >
            {this.renderVideoPlayer()}
            {this.renderOverlayOnAnnotatingStart()}
            {this.renderOverlayOnTaggingStart()}
            {this.renderDraftAnnotation(annotationsContainerWidth)}
            {this.renderNonDraftAnnotations(annotationsContainerWidth)}
            {this.renderDraftFaceTag()}
            {this.renderNonDraftFaceTags(annotationsContainerWidth)}
            {this.renderDraftFaceTagAtCreation()}
            {this.debug()}
            {this.renderZoom()}
            {this.renderContextMenu()}
          </div>
        </div>
      </div>
    );
    console.log('VideoRenderer returned ', rez, ', took: ms ', new Date().getTime() - start);
    return rez;
  }

  //TODO move autoscroll cfg and handling to redux and state
  closeContextMenu = () => {
    this.setState({ contextMenuX: null, contextMenuY: null });
  };
  renderContextMenu() {
    const menuItemStyle = {
      dense: true,
      style: { color: config.theme.getPopupMenuColor() },
    };

    return (
      <Menu
        keepMounted
        open={this.state.contextMenuY !== null}
        onClose={this.closeContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          this.state.contextMenuY !== null && this.state.contextMenuX !== null
            ? { top: this.state.contextMenuY, left: this.state.contextMenuX }
            : undefined
        }
      >
        <MenuItem
          onClick={() => {
            this.increaseVideoWidthLimit();
            this.closeContextMenu();
          }}
          onDoubleClick={() => {
            this.setState({ videoWidthLimit: 100 });
            this.closeContextMenu();
          }}
          {...menuItemStyle}
          disabled={this.state.videoWidthLimit >= 100}
        >
          <IconZoomIn />
          Enlarge Player
        </MenuItem>
        <MenuItem
          onClick={() => {
            this.decreaseVideoWidthLimit();
            this.closeContextMenu();
          }}
          onDoubleClick={() => {
            this.setState({ videoWidthLimit: 20 });
            this.closeContextMenu();
          }}
          {...menuItemStyle}
          disabled={this.state.videoWidthLimit <= 20}
          divider
        >
          <IconZoomOut />
          Reduce Player
        </MenuItem>

        <MenuItem
          onClick={() => {
            config.comments.autoscroll = !config.comments.autoscroll;
            this.closeContextMenu();
          }}
          {...menuItemStyle}
        >
          {config.comments.autoscroll ? <Icon>block</Icon> : <Icon>check</Icon>} Autoscroll Comments
        </MenuItem>
        <MenuItem
          onClick={() => {
            config.highlights.autoscroll = !config.highlights.autoscroll;
            this.closeContextMenu();
          }}
          {...menuItemStyle}
          divider
        >
          {config.highlights.autoscroll ? <Icon>block</Icon> : <Icon>check</Icon>} Autoscroll Highlights
        </MenuItem>
        {false && <MenuItem
          onClick={() => {
            config.dev.simulateReportView = !config.dev.simulateReportView;
            this.closeContextMenu();
          }}
          {...menuItemStyle}
          divider
        >
          {config.dev.simulateReportView ? <Icon>block</Icon> : <Icon>check</Icon>} HTML Report Viewer
        </MenuItem>}

        {process.env.REACT_APP_MENU_DURATION_THRESHOLD && process.env.REACT_APP_MENU_DURATION_THRESHOLD === ENV_VALUE_ENABLED ? (
          <div>
            <ListItem
              style={{
                fontFamily: 'Inter, sans-serif',
                fontSize: '0.875rem',
                fontWeight: 600,
              }}
            >{`Duration Threshold: ${config.graph.downSamplingTrigger}`}</ListItem>
            <MenuItem
              onClick={() => {
                config.graph.downSamplingTrigger += 30;
                this.props.durationThresholdUpdated(config.graph.downSamplingTrigger);
                this.closeContextMenu();
              }}
              {...menuItemStyle}
            >
              <AddIcon fontSize="small" />

              {`Increase `}
            </MenuItem>
            <MenuItem
              onClick={() => {
                config.graph.downSamplingTrigger -= 30;
                this.props.durationThresholdUpdated(config.graph.downSamplingTrigger);
                this.closeContextMenu();
              }}
              {...menuItemStyle}
              disabled={config.graph.downSamplingTrigger < 30}
              divider
            >
              <RemoveIcon fontSize="small" />

              {`Decrease  `}
            </MenuItem>
          </div>
        ) : null}
        {process.env.REACT_APP_MENU_REPAINT && process.env.REACT_APP_MENU_REPAINT === ENV_VALUE_ENABLED ? (
          <div>
            <ListItem
              style={{
                fontFamily: 'Inter, sans-serif',
                fontSize: '0.875rem',
                fontWeight: 600,
              }}
            >{`Analysis Repaint Interval:  ${config.graph.refreshRate}`}</ListItem>
            <MenuItem
              onClick={() => {
                config.graph.refreshRate++;
                this.closeContextMenu();
              }}
              {...menuItemStyle}
              disabled={config.graph.refreshRate >= config.graph.currentPlayingPointsWidth}
            >
              <AddIcon fontSize="small" />

              {`Increase  `}
            </MenuItem>
            <MenuItem
              onClick={() => {
                config.graph.refreshRate--;
                this.closeContextMenu();
              }}
              {...menuItemStyle}
              disabled={config.graph.refreshRate <= 1}
            >
              <RemoveIcon fontSize="small" />

              {`Decrease  `}
            </MenuItem>
          </div>
        ) : null}
      </Menu>
    );
  }

  renderZoom = () => {
    return config.dev.zoomVideo ? (
      <>
        <IconButton
          onClick={this.increaseVideoWidthLimit}
          onDoubleClick={() => {
            this.setState({ videoWidthLimit: 100 });
          }}
          // size="small"
          style={{ margin: 0, padding: 0, position: 'absolute', top: 40, right: '1%', color: 'red' }}
        >
          <IconZoomIn />
        </IconButton>
        <IconButton
          onClick={this.decreaseVideoWidthLimit}
          onDoubleClick={() => {
            this.setState({ videoWidthLimit: 20 });
          }}
          // size="small"
          style={{ margin: 0, padding: 0, position: 'absolute', top: 60, right: '1%', color: 'red' }}
        >
          <IconZoomOut />
        </IconButton>
        <h6 style={{ margin: 0, padding: 0, position: 'absolute', top: 80, right: '2%', color: 'red' }}>{this.state.videoWidthLimit}%</h6>
      </>
    ) : null;
  };

  increaseVideoWidthLimit = () => {
    const limit = this.state.videoWidthLimit;
    let newValue = limit + 33 > 100 ? 100 : limit + 33;
    this.setState({ videoWidthLimit: newValue });
  };
  decreaseVideoWidthLimit = () => {
    const limit = this.state.videoWidthLimit;
    let newValue = limit - 33 < 20 ? 20 : limit - 33;
    this.setState({ videoWidthLimit: newValue });
  };

  renderOverlayOnTaggingStart = () => {
    if (
      this.state.withFaceTaggingOverlay
      // this.props.currentTagCreationStatus &&
      // this.props.currentTagCreationStatus.video &&
      // this.props.currentTagCreationStatus.video === this.props.selectedVideo &&
      // this.props.currentTagCreationStatus.draftTagCoords === null
    ) {
      return (
        <div className="fig-overlay-on-video" style={{ zIndex: config.video.overlaysZIndex }}>
          <div>Now highlight the speaker's face with your crosshair</div>
        </div>
      );
    } else {
      return null;
    }
  };

  renderOverlayOnAnnotatingStart = () => {
    if (
      this.state.withAnnotateOverlay &&
      this.props.currentAnnotatingStatus &&
      this.props.currentAnnotatingStatus.video &&
      this.props.currentAnnotatingStatus.video.id === this.props.selectedVideoIdString
    ) {
      let hasAnnotation = this.props.currentAnnotatingStatus.draftAnnotation !== null;
      return (
        <div className="fig-overlay-on-video" style={{ zIndex: config.video.overlaysZIndex }}>
          <div>{hasAnnotation ? `Click on video to move the annotation ` : 'Click on video to annotate'}</div>
          {hasAnnotation ? <div>Double-click the green circle to remove it</div> : null}
        </div>
      );
    } else {
      return null;
    }
    //TODO ASK "THEN click the anotate button again, to commit")
  };

  onSeeked = () => {
    if (this.state.seekedCallback) {
      let cb = this.state.seekedCallback;
      this.setState({ seekedCallback: null }, () => cb());
    }
  };
  renderVideoPlayer() {
    const url = this.props.selectedVideoUrl;
    const startTime = this.props.curentTime;
    return (
      <Player
        ref={(internalComp) => {
          this.player = internalComp;
        }}
        playsInline
        crossOrigin="anonymous"
        src={url}
        startTime={startTime}
        fluid={true}
        onSeeked={this.onSeeked}
      >
        {!this.props.currentAnnotatingStatus &&
        !this.props.currentTaggingStatus &&
        /*allow playing video while setting tag coordinates, to check if the tag's rectangle fits the speaker all the time
        !this.props.currentTagCreationStatus &&*/
        !this.props.hidden ? (
          <ControlBar autoHide={false}>
            <ReplayControl
              seconds={30}
              order={1.1}
              ref={(el) => {
                this.controlBar = el;
              }}
            />
            <ForwardControl seconds={30} order={1.2} />
            <CurrentTimeDisplay order={4.1} />
            <TimeDivider order={4.2} />
            <PlaybackRateMenuButton rates={[2, 1.9, 1.8, 1.7, 1.6, 1.5, 1.4, 1.3, 1.2, 1.1, 1, 0.9, 0.8, 0.7, 0.6, 0.5, 0.4]} order={7.1} />
            <VolumeMenuButton />
            {/* <DownloadButton  POC report - used for testing video capture
              order={8}
              addImage={this.props.addImage}
              videoCoords={{ height: this.state.internalVideoHeight, width: this.state.internalVideoWidth }}
            /> */}
          </ControlBar>
        ) : (
          <ControlBar disableCompletely={true} disableDefaultControls={true} />
        )}
        {this.props.currentAnnotatingStatus || this.props.currentTaggingStatus || this.props.currentTagCreationStatus ? (
          /*allow playing video while setting tag coordinates, to check if the tag's rectangle fits the speaker all the time
        this.props.currentTagCreationStatus*/ <BigPlayButton disabled />
        ) : null}
        <Shortcut
          clickable={!this.props.currentAnnotatingStatus && !this.props.currentTaggingStatus && !this.props.currentTagCreationStatus}
          dblclickable={!this.props.currentAnnotatingStatus && !this.props.currentTaggingStatus && !this.props.currentTagCreationStatus}
        />
      </Player>
    );
  }

  debug() {
    return config.dev.video ? (
      <>
        <h3 style={{ margin: 0, padding: 0, position: 'absolute', top: '1%', right: '1%', color: 'red' }}>
          {this.props.curentTime.toFixed(2)}
        </h3>
        {/* <h3 style={{ margin: 0, padding: 0, position: 'absolute', top: '10%', right: '1%' }}>
          {this.state.tagCoords ? format(this.state.tagCoords) : ''}
        </h3> */}
      </>
    ) : null;
  }

  /** render all face tags, apart from the current editing one*/
  renderNonDraftFaceTags(containerWidth: number) {
    const isntEditingVideo =
      !this.props.currentTaggingStatus || !(this.props.currentTaggingStatus.video.id === this.props.selectedVideoIdString);
    if (this.props.isFaceTagsTabActive && this.props.selectedVideoIdString && this.props.metaFaceAnalysesMap) {
      return Object.values(this.props.metaFaceAnalysesMap).map((tag, index) => {
        const isntDraftTag = isntEditingVideo || !(this.props.currentTaggingStatus.tag === tag);
        return isntDraftTag && tag.status !== AnalysisItemStatus.hidden ? (
          <FacetagRenderer
            key={tag.url}
            name={tag.name}
            tagCoords={tag.tag}
            color={config.video.faceTagColor}
            zIndex={config.video.faceTagZIndex}
            solid
            isResizingBottom={false}
            isResizingLeft={false}
            isResizingRight={false}
            isResizingTop={false}
          />
        ) : // </div>
        null;
      });
    }
    return null;
  }

  renderDraftFaceTagAtCreation() {
    if (
      this.props.currentTagCreationStatus &&
      this.props.currentTagCreationStatus.video &&
      this.props.currentTagCreationStatus.video.id === this.props.selectedVideoIdString
    ) {
      // console.log(' render draft face tag : has redux tagging status');
      let tagCoords = null;
      if (this.state.isPanning) {
        //panning mode
        // console.log('controlBar clientHeight:', this.controlBar, this.controlBar.button.offsetHeight);
        // console.log('player videoHeight:', this.player, this.player.video.video.clientHeight);
        tagCoords = pan(
          this.props.currentTagCreationStatus.draftTagCoords,
          this.state.tagCoords,
          (100 * this.controlBar ? this.controlBar.button.offsetHeight : 0.1) / this.player.video.video.clientHeight
        );
      } else if (this.state.isBottomResizing) {
        tagCoords = resizeBottom(this.props.currentTagCreationStatus.draftTagCoords, this.state.tagCoords);
      } else if (this.state.isTopResizing) {
        tagCoords = resizeTop(this.props.currentTagCreationStatus.draftTagCoords, this.state.tagCoords);
      } else if (this.state.isLeftResizing) {
        tagCoords = resizeLeft(this.props.currentTagCreationStatus.draftTagCoords, this.state.tagCoords);
      } else if (this.state.isRightResizing) {
        tagCoords = resizeRight(this.props.currentTagCreationStatus.draftTagCoords, this.state.tagCoords);
      } else {
        //drawing mode
        if (
          this.state.tagCoords &&
          !isNaN(this.state.tagCoords.x1) &&
          !isNaN(this.state.tagCoords.x2) &&
          !isNaN(this.state.tagCoords.y1) &&
          !isNaN(this.state.tagCoords.y2)
        ) {
          // console.log(' render face tag : has redux tagging status AND internal state');
          tagCoords = this.state.tagCoords;
        } else {
          // console.log(' render face tag : has redux tagging status and NO internal state');
          tagCoords = this.props.currentTagCreationStatus.draftTagCoords;
        }
      }
      return (
        <FacetagRenderer
          key={'DraftTag'}
          tagCoords={tagCoords}
          color={this.state.withFaceTaggingOverlay ? 'transparent' : config.video.faceTagColor} //to avoid displaying the tag initially
          name={this.state.withFaceTaggingOverlay ? '' : this.props.currentTagCreationStatus.name}
          zIndex={config.video.faceTagZIndexDraft}
          solid={false}
          isResizingBottom={this.state.mightBottomResize || this.state.isBottomResizing}
          isResizingLeft={this.state.mightLeftResize || this.state.isLeftResizing}
          isResizingRight={this.state.mightRightResize || this.state.isRightResizing}
          isResizingTop={this.state.mightTopResize || this.state.isTopResizing}
        />
      );
    } else {
      // console.log(' render face tag : has NO redux tagging status');
      return null;
    }
  }

  renderDraftFaceTag() {
    if (
      this.props.currentTaggingStatus &&
      this.props.currentTaggingStatus.video &&
      this.props.currentTaggingStatus.video.id === this.props.selectedVideoIdString
    ) {
      // console.log(' render draft face tag : has redux tagging status');
      if (
        this.state.tagCoords &&
        !isNaN(this.state.tagCoords.x1) &&
        !isNaN(this.state.tagCoords.x2) &&
        !isNaN(this.state.tagCoords.y1) &&
        !isNaN(this.state.tagCoords.y2)
      ) {
        // console.log(' render face tag : has redux tagging status AND internal state');
        return (
          <FacetagRenderer
            key={'DraftTag'}
            tagCoords={this.state.tagCoords}
            color={config.video.faceTagColor}
            zIndex={config.video.faceTagZIndexDraft}
            name={this.props.currentTaggingStatus.tag.name}
            solid={false}
            isResizingBottom={false}
            isResizingLeft={false}
            isResizingRight={false}
            isResizingTop={false}
          ></FacetagRenderer>
        );
      } else {
        // console.log(' render face tag : has redux tagging status and NO internal state');
        return (
          <FacetagRenderer
            tagCoords={this.props.currentTaggingStatus.draftTagCoords}
            color={config.video.faceTagColor}
            zIndex={config.video.faceTagZIndexDraft}
            solid={false}
            name={this.props.currentTaggingStatus.tag.name}
            isResizingBottom={false}
            isResizingLeft={false}
            isResizingRight={false}
            isResizingTop={false}
          ></FacetagRenderer>
        );
      }
    } else {
      // console.log(' render face tag : has NO redux tagging status');
      return null;
    }
  }

  renderNonDraftAnnotations(annotationsContainerWidth: number) {
    if (this.annotationsContainer === null) return null;
    const allAnnotations: Annotation[] = this.getAnnotations(this.props.curentTime);
    let draftComment: Comment = null;
    if (
      this.props.currentAnnotatingStatus &&
      this.props.currentAnnotatingStatus.video.id === this.props.selectedVideoIdString &&
      this.props.currentAnnotatingStatus.draftAnnotation
    ) {
      draftComment = this.props.currentAnnotatingStatus.comment;
    }
    return allAnnotations.map((annotation, index) =>
      !draftComment || annotation !== draftComment.annotation ? (
        <AnnotationRenderer
          key={`${annotation.id}.${annotation.x}.${annotation.y}`}
          annotation={annotation}
          diameter={config.video.annotationCircle2VideoRatio * annotationsContainerWidth}
          color={config.video.annotationColor}
          zIndex={config.video.annotationZIndex}
          filled={false}
        />
      ) : null
    );
    // we eliminated the annotation that is currently in editing mode
  }
  renderDraftAnnotation(annotationsContainerWidth: number) {
    if (this.annotationsContainer === null) return null;
    let draftAnnotationEl = null;
    // let draftComment: Comment = null;
    if (
      this.props.currentAnnotatingStatus &&
      this.props.currentAnnotatingStatus.video.id === this.props.selectedVideoIdString &&
      this.props.currentAnnotatingStatus.draftAnnotation
    ) {
      // draftComment = this.props.currentAnnotatingStatus.comment;
      draftAnnotationEl =
        this.props.currentAnnotatingStatus.draftAnnotation.x !== ANNOTATION_COORD_NONE ? (
          <AnnotationRenderer
            key={-1}
            annotation={this.props.currentAnnotatingStatus.draftAnnotation}
            diameter={config.video.annotationCircle2VideoRatio * annotationsContainerWidth}
            color={config.video.annotationColorDraft}
            zIndex={config.video.annotattionZIndexDraft}
            filled
          />
        ) : null;
    }
    return draftAnnotationEl;
  }

  // get all annotations that should be visible at the current time
  // FFS todo consider comment privacy settings vs the current user
  getAnnotations = (time: number): Annotation[] => {
    const comments: Comments = this.props.comments;
    let rez: Annotation[] = [];
    if (comments && comments.comments) {
      rez = comments.comments
        .filter((comment) => comment.annotation && comment.time <= time && comment.time >= time - config.video.annotationVisibilityTime)
        .map((relevantComment) => relevantComment.annotation);
      // eslint-disable-next-line no-console
      // if (rez.length > 0) console.log('at time', time, 'relevants:', rez);
    }
    return rez;
  };
}
export const relativeCoords = (event) => {
  let bounds = event.target.getBoundingClientRect();
  // console.log(
  //   'relativeCoords: event.clientx: ',
  //   event.clientX,
  //   ' event.clientY: ',
  //   event.clientY,
  //   ' nativeevent.offsetX: ',
  //   event.nativeEvent.offsetX,
  //   ' nativeevent.offsetY: ',
  //   event.nativeEvent.offsetY,
  //   'target bounds: ',
  //   bounds
  // );

  // if (isEventFromVideoElement(event)) {
  bounds = event.target.getBoundingClientRect();
  const x = (event.clientX - bounds.left) / bounds.width;
  const y = (event.clientY - bounds.top) / bounds.height;
  return { x: 100 * x, y: 100 * y };
  //   // also e.nativeEvent.offsetX, e.nativeEvent.offsety + boundsleft etc
  // } else {
};
export const format = (coords: Coords) => {
  return `(${formatC(coords.x1)}, 
  ${formatC(coords.y1)}), 
  (${formatC(coords.x2)},
  ${formatC(coords.y2)})`;
};

export const formatC = (coord: number) => {
  return coord ? coord.toFixed(2) : '';
};

const selectorMetaFaceAnalysesMap = (state: IRootState) => {
  if (state.video.currentVideoId && state.video.videosMap[state.video.currentVideoId]) {
    return state.video.videosMap[state.video.currentVideoId].metaFaceAnalysesMap;
  } else return null;
};
const selectorCrtVideoComments = (state: IRootState) =>
  state.video.videosMap[state.video.currentVideoId] ? state.video.videosMap[state.video.currentVideoId].comments : null;

const mapStateToProps = (state: IRootState) => {
  return {
    //selectedVideo: state.video.currentVideoId ? state.video.videosMap[state.video.currentVideoId] : null,
    //selectedVideoId: state.video.currentVideoId,
    selectedVideoIdString: state.video.currentVideoId ? toStringId(state.video.currentVideoId) : null,
    selectedVideoUrl:
      state.video.currentVideoId && state.video.videosMap[state.video.currentVideoId]
        ? state.video.videosMap[state.video.currentVideoId].url
        : null,
    comments: selectorCrtVideoComments(state),
    metaFaceAnalysesMap: selectorMetaFaceAnalysesMap(state),
    videoDuration: state.video.videoDuration,
    curentTime: state.video.currentTime,

    //playerHeight: state.utils.playerHeight,
    currentAnnotatingStatus: state.video.currentAnnotatingStatus,
    currentTaggingStatus: state.video.currentTaggingStatus,
    currentTagCreationStatus: state.video.currentTagCreationStatus,
    isFaceTagsTabActive: state.selections.selectedGraphicsSideTab === TabsComponent.TAB_IDX_TAGS,
  };
};
const mapDispatchToProps = {
  timeReached,
  durationComputed,
  annotationDrafted,
  tagDrafted,
  tagCreationDrafted,
  videoPaused,
  playerHeightUpdated,
  durationThresholdUpdated,
  addImage,
};

const connector = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true });
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(VideoRenderer);

// const isEventFromBigPlayButtonElement = (event) => {
//   let target = event.target as Element;
//   let rez =
//     target.nodeName && target.nodeName === 'BUTTON' && target.className && -1 !== target.className.indexOf('video-react-big-play-button');
//   if (rez) console.log('isEventFromBigPlayButtonElement');
//   return rez;
// };
const isEventFromVideoElement = (event) => {
  let target = event.target as Element;
  // console.log('class: ', target.className);
  return target.nodeName && target.nodeName === 'VIDEO';
};
const isEventFromFaceTaggingOverlayElement = (event) => {
  let target = event.target as Element;
  return target.nodeName && target.nodeName === 'DIV' && target.className && target.className === 'fig-overlay-on-video';
};

/*
interface IState {
  tagCoords: Coords;
  x?: number;
  y?: number;
  clientX?: number;
  clientY?: number;
  pageX?: number;
  pageY?: number;
  screenX?: number;
  screenY?: number;
  relX?: number;
  relY?: number;
  playerState;
}

  onMouseMove = e => {
    // eslint-disable-next-line no-console
    console.log(e, e.relatedTarget);
    const rel = this.relativeCoords(e);
    this.setState({
      x: e.nativeEvent.offsetX,
      y: e.nativeEvent.offsetY,
      clientX: e.nativeEvent.clientX,
      clientY: e.nativeEvent.clientY,

      pageX: e.nativeEvent.pageX,
      pageY: e.nativeEvent.pageY,

      screenX: e.nativeEvent.screenX,
      screenY: e.nativeEvent.screenY,

      relX: rel.x,
      relY: rel.y,
    });
  };

    // this is just for debugging issues related to click coords , it is not really used
    // this.state = {
    //   playerState: null,
    //   x: 0,
    //   y: 0,

    //   clientX: 0,
    //   clientY: 0,

    //   pageX: 0,
    //   pageY: 0,

    //   screenX: 0,
    //   screenY: 0,

    //   relX: 0,
    //   relY: 0,
    // };

        <div>
          <p style={{ color: 'red', position: 'absolute', top: '65%', left: '29%', margin: 0, zIndex: 100 }} id="placeHolder">
            //{/*'anotarea' + this.ann1[Math.floor(this.props.curentTime)] + 'POS: ' + this.state.x + ',' + this.state.y*}
            {` x,y: ${this.state.x},${this.state.y};   clx,cly: ${this.state.clientX},${this.state.clientY}; px,py: ${this.state.pageX},${this.state.pageY};
             sx,sy: ${this.state.screenX},${this.state.screenY};  rx,ry: ${this.state.relX},${this.state.relY};
            `}
          </p>
        </div>
//deci tb sa-i pun top si left ce mi=a intors state.relX/relY la click

      const placeHolder = document.getElementById('placeholder');

*/
