import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../shared/reducers';
import Header from './header/Header';

interface IProps {
  history: any;
}

class Clients extends React.Component<IProps, any> {
  render() {
    let className = 'figaro-content';
    return (
      <div className="figaro-container">
        <Header selectedMenu="clients" history={this.props.history} />
        <div className={className}>
          <div className="row no-gutters fig-rubrics">
            <div className="col-12">
              <div className="fig-upload-form-header">
                <span>Clients</span>
              </div>
              {/* <UsersPanel history={this.props.history} /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => {
  return {};
};

export default connect(mapStateToProps, {})(Clients);
