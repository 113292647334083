/* eslint-disable no-console */
import { TextField } from '@material-ui/core';
import React, { FormEvent } from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import config from '../../config/constants';
import { ISelectableItem } from '../shared/BorderedSelector';
import { SelectionList } from '../shared/SelectionList';

interface IProps extends StateProps, DispatchProps {
  handleFormSubmit: (form: IAddCommentState) => void;
}

export interface IAddCommentState {
  privacyOptions: ISelectableItem[];
  comment: string;
  commentRequired: boolean;
  commentTime: number;
}

class AddComment extends React.PureComponent<IProps, IAddCommentState> {
  privacyListAnchor = null;
  constructor(props: IProps) {
    super(props);
    this.state = {
      privacyOptions: [...config.comments.defaultPrivacyItems],
      comment: '',
      commentRequired: false,
      commentTime: 0,
    };
  }

  handlePrivacyChange = (privacyOptions: ISelectableItem[]) => {
    this.setState({ privacyOptions });
  };

  handleCommentChange = (event) => {
    this.setState({ comment: event.target.value, commentRequired: true });
  };

  onFormSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log('onFormSubmit', this.state);
    if (this.state.comment.length === 0) {
      this.setState({ commentRequired: true });
      return;
    }
    this.props.handleFormSubmit({ ...this.state, commentTime: this.props.currentTime });
    this.resetState();
  };

  private resetState(): void {
    this.setState({
      //allow adding further comms with the same privacy
      privacyOptions: this.state && this.state.privacyOptions ? this.state.privacyOptions : [...config.comments.defaultPrivacyItems],
      comment: '',
      commentRequired: false,
    });
  }

  render() {
    return (
      <form className="row fig-vertical-middle" onSubmit={this.onFormSubmit}>
        <TextField
          className="col-12 fig-add-comment-input"
          label={`COMMENT @ ${this.props.currentTime}`}
          InputLabelProps={{
            shrink: true,
            required: false,
          }}
          placeholder={'Enter Comment'}
          value={this.state.comment}
          onChange={this.handleCommentChange}
          error={this.state.comment.length === 0 && this.state.commentRequired}
          onKeyPress={(e) => this.onEnterKey(e)}
          required
        />
        <div className="fig-select-status ">
          {/* <PrivacyButtons privacy={this.state.privacy} handlePrivacyChange={this.handlePrivacyChange} /> */}
          <SelectionList listItems={this.state.privacyOptions} selectionChanged={this.handlePrivacyChange} />
        </div>
      </form>
    );
  }

  onEnterKey(e: any) {
    if (e.which === 13) {
      this.onFormSubmit(e);
    }
  }
}
const mapStateToProps = (state: IRootState) => {
  return {
    currentTime: state.video.currentTime,
  };
};
const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AddComment);
