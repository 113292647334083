import { Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import React from 'react';
import sprites from '../../../../icons/fig-icons.svg';
import { EllipseAvatarIcon } from '../shared/EllipseAvatarIcon';
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'edit', numeric: false, label: '' },
    { id: 'name', numeric: false, label: 'Rubric Name ' },
    { id: 'updated_at', numeric: false, label: 'Modified' },
    { id: 'type', numeric: false, label: 'Type' },
    { id: 'createdby', numeric: false, label: 'Creator' },
    { id: 'clone', numeric: false, disablePadding: false, label: '' },
    { id: 'delete', numeric: false, disablePadding: false, label: '' },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead >
            <TableRow >
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {/* {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                     {order === 'desc' ? 'sorted descending' : 'sorted ascending'} 
                                </span>
                            ) : null} */}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead >
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};


export default function RubricsTable(props) {
    const { rows } = props;

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    return (
        <div >
            <Paper>
                <TableContainer >
                    <Table

                        aria-labelledby="tableTitle"
                        size={'small'}
                        aria-label="enhanced table"
                        stickyHeader
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody >
                            {stableSort(rows, getComparator(order, orderBy))
                                .map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={false}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={false}
                                        >
                                            <TableCell align="justify" component="th" id={labelId} scope="row">
                                                <IconButton aria-label="edit" size="small" onClick={() => props.handleEdit(row.id)}>
                                                    <EditIcon fontSize="small" />
                                                </IconButton>
                                            </TableCell>
                                            <TableCell >
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="left">{new Date(row.updated_at).toLocaleString()}</TableCell>
                                            <TableCell align="left">{row.type}</TableCell>
                                            <TableCell align="left"><EllipseAvatarIcon inverse style={{ width: 36, height: 35, fill: 'none' }} viewBox="0 0 36 35" />{` ${row.createdby}`}</TableCell>
                                            <TableCell align="justify" className="text-right">
                                                <Button className="fig-button" size="small"  variant="outlined" onClick={() => props.handleClone(row.id)}>
                                                    CLONE
                                                </Button>
                                            </TableCell>

                                            <TableCell align="justify">
                                                <IconButton aria-label="delete" className="fig-button fig-delete-rubric margin-top0" size="small" onClick={() => props.handleDelete(row.id)}>
                                                    <svg className="fig-icon-delete-rubric-row">
                                                        <use href={sprites + '#delete-icon'} />
                                                    </svg>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
}
RubricsTable.propTypes = {
    rows: PropTypes.array.isRequired,
    handleDelete: PropTypes.func.isRequired,
    handleClone: PropTypes.func.isRequired,
    handleEdit: PropTypes.func.isRequired,
};
