import React from 'react';
import sprites from '../../../../icons/fig-icons.svg';
import BorderedSelector, { IBorderedSelectorProps } from './BorderedSelector';

export default class BorderedSelectorSquares extends React.PureComponent<IBorderedSelectorProps> {
  static svgChecked = (
    <svg className="fig-checked-icon">
      <use href={sprites + '#checked-icon'} />
    </svg>
  );

  static svgNotChecked = (
    <svg className="fig-unchecked-icon">
      <use href={sprites + '#unchecked-icon'} />
    </svg>
  );

  render() {
    return (
      <BorderedSelector
        {...this.props}
        svgChecked={BorderedSelectorSquares.svgChecked}
        svgNotChecked={BorderedSelectorSquares.svgNotChecked}
      />
    );
  }
}
