import { Button, Link } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import { getUnauthedHomePath } from '../../../../app';
import { successNotification } from '../../../utils/utils';
import { confirmRegistration } from '../../actions/figaroApiActions';

type IProps = { history: any; match: any; location: any };

interface IState {
  confirmationCode: string;
  successful: boolean;
}

class RegisterEmailConfirmationPanel extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    let conf = new URLSearchParams(props.location.search).get('confirmation');
    console.log('RegisterEmailConfirmationPanel ctor,  props ', props);
    console.log('confirmation: ', conf);
    this.state = {
      confirmationCode: conf,
      successful: false,
    };
  }

  render() {
    return (
      <div className="login-wrapper">
        <Paper>
          <div className="login-wrapper__header">
            <div className="header-container no-margin text-center w-100">
              <p className="no-margin">Confirm Registering To Figaro</p>
            </div>
          </div>
          <form className="login-wrapper__form">
            {this.renderUserConfirmation()}
            {this.renderConfirmationResult()}
          </form>
        </Paper>
      </div>
    );
  }

  private renderConfirmationResult() {
    return this.state.successful ? <Link onClick={() => this.props.history.push(getUnauthedHomePath())}>Go to Figaro Login Page</Link> : null;
  }
  private renderUserConfirmation() {
    return (
      <Button className="fig-button ghost-to-dark-blue margin-top--30" onClick={this.sendConfirmation}>
        Send Confirmation
      </Button>
    );
  }

  onSuccesfullRegister = () => {
    successNotification('Confirmation sent! ');
    this.setState({
      successful: true,
    });
  };

  sendConfirmation = () => {
    if (this.state.confirmationCode) {
      confirmRegistration(this.state.confirmationCode).then(
        () => this.onSuccesfullRegister(),
        (err) => {
          //errorNotification('Confirm registration failed! ');
          console.log('Confirm registration error ', err);
        }
      );
    } else {
      alert('Mandatory field missing: confirmation');
    }
  };
}

export default RegisterEmailConfirmationPanel;
