import { combineReducers } from 'redux';
import authentication, { FigaroAuthenticationState } from '../../demo/reducers/authReducer';
import folders, { FoldersState } from '../../demo/reducers/foldersReducer';
import report, { ReportState } from '../../demo/reducers/reportReducer';
import rubrics, { RubricsState } from '../../demo/reducers/rubricsReducer';
import selections, { SelectionsState } from '../../demo/reducers/selectionsReducer';
import settings, { SettingsState } from '../../demo/reducers/settingsReducer';
import submissions, { SubmissionsState } from '../../demo/reducers/submissionsReducer';
import video, { FigaroVideoState } from '../../demo/reducers/videoReducer';
import userProfiles, { FigaroUsersProfileState } from '../../reducers/userProfileReducer';
import utils, { FigaroUtilsState } from '../../reducers/utilsReducer';

export interface IRootState {
  readonly authentication: FigaroAuthenticationState;
  // readonly locale: LocaleState;
  readonly utils: FigaroUtilsState;
  readonly video: FigaroVideoState;
  readonly submissions: SubmissionsState;
  readonly userProfiles: FigaroUsersProfileState;
  // readonly applicationProfile: ApplicationProfileState;
  readonly selections: SelectionsState;
  readonly settings: SettingsState;
  readonly rubrics: RubricsState;
  readonly folders: FoldersState;
  readonly report: ReportState;
}

const rootReducer = combineReducers<IRootState>({
  authentication,
  // locale,
  utils,
  video,
  submissions,
  userProfiles,
  selections,
  settings,
  rubrics,
  folders,
  report,
  // applicationProfile,
  // administration,
  // userManagement,
  // register,
  // activate,
  // // passwordReset,
  // // password,
  // // settings,
});

export default rootReducer;
